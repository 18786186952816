import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {PricingV2Slice} from 'prismicio-types'
import {memo} from 'react'
import {ActionButton} from 'src/common/ActionButton'
import {Icon} from 'src/common/Icon'
import {Context} from 'src/common/types'
import {FeaturesList} from './FeaturesList'
import {LeadCapture} from './LeadCapture'
import {EVENTS, track} from 'src/common/helpers'

const getPricingPlan = (index: number, context: Context) => {
  switch (index) {
    case 0:
      return context.pricing_plan_1
    case 1:
      return context.pricing_plan_2
    case 2:
      return context.pricing_plan_3
    default:
      return null
  }
}

const PricingV2 = memo(function (props: {context: Context; slice: PricingV2Slice; index: number}) {
  const {linkResolver} = useLinkResolver()
  return (
    <div className="grid scroll-mt-16 grid-cols-main pb-12 pt-30" id={String(props.slice.primary.id_anchor)}>
      <PrismicRichText
        components={{
          heading1: ({children}) => (
            <h1 className="col-[inner] mb-7 max-w-common justify-self-center text-center text-36 font-bold leading-none -tracking-common text-2a2928 sm:text-48 md:text-64 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
              {children}
            </h1>
          ),
        }}
        field={props.slice.primary.title}
      />

      <PrismicRichText
        components={{
          paragraph: ({children}) => (
            <p className="col-[inner] max-w-common justify-self-center text-center text-16 leading-6 text-2a2928 sm:text-18">
              {children}
            </p>
          ),
        }}
        field={props.slice.primary.description}
      />

      <div className="col-[entire] snap-x scroll-pl-6 overflow-x-auto sm:scroll-pl-10 lg:contents">
        <div className="my-14 grid w-[calc(300vw_-_theme(spacing.12))] grid-cols-3 gap-x-6 px-6 sm:w-[calc(200vw_-_theme(spacing.20))] sm:px-10 md:w-[calc(160vw_-_theme(spacing.20))] lg:col-[inner] lg:w-auto lg:px-0">
          {props.slice.items.map((card, index) => (
            <div
              className="relative flex snap-start flex-col gap-6 rounded-2xl bg-ffffff px-6 pb-6 pt-8 shadow-lp"
              key={index}
            >
              {(card.tag || isFilled.image(card.tag_image)) && (
                <div className="absolute left-6 top-0 flex h-11 -translate-y-1/2 items-center gap-x-2 rounded-full bg-2a2928 pl-3.5 pr-4 text-ffffff">
                  <PrismicNextImage alt="" className="shrink-0" field={card.tag_image} />
                  <span className="text-14 font-medium">{card.tag}</span>
                </div>
              )}

              <div className="min-h-[182px]">
                <h2 className="text-32 font-bold leading-10 text-2a2928 md:text-36 md:leading-11 lg:text-32 lg:leading-10">
                  {card.title}
                </h2>

                <p className="mt-0.5 text-14 leading-5 text-212121">{card.description}</p>
              </div>

              <div>
                {props.slice.primary.email_form_enabled ? (
                  <LeadCapture
                    placeholder={props.slice.primary.email_input_placeholder!}
                    submitLabel={card.link_label!}
                    index={index}
                    pageUid={props.context.pageUid}
                    componentIndex={props.index}
                  />
                ) : (
                  <ActionButton
                    className="text-center"
                    isOutline={!card.link_variant}
                    kind="demo"
                    data-page-location={`component-${props.index}-package-${index}`}
                    data-type={`pricing-form-${index}`}
                    data-mktg-id="book-demo-button"
                    data-page-name={props.context.pageUid}
                    id="demo-button"
                  >
                    {card.link_label}
                  </ActionButton>
                )}
                {isFilled.link(props.slice.primary.secondary_button_link) &&
                  props.slice.primary.secondary_button_text && (
                    <Link
                      className="mt-2 flex h-10 items-center justify-center gap-2 text-14 font-medium text-595755"
                      href={linkResolver(props.slice.primary.secondary_button_link)}
                      onClick={() => {
                        track({
                          event: EVENTS.CLICK,
                          location: ['starter-price', 'prof-price', 'enterprise-price'][index],
                          mktgId: 'explore-product-button',
                          pageName: props.context.pageUid,
                        })
                      }}
                    >
                      {props.slice.primary.secondary_button_text}
                      <Icon name="right-arrow" className="h-5 w-5 text-20 text-595755" />
                    </Link>
                  )}

                <h3 className="mt-6 text-10 font-medium uppercase leading-4 -tracking-common text-b8b6b4">
                  {card.features_label}
                </h3>

                {!!card.features_placeholder && (
                  <p className="mt-5 text-16 font-medium leading-5 text-212121">{card.features_placeholder}</p>
                )}

                <FeaturesList pricingPlan={getPricingPlan(index, props.context)} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
})

export default PricingV2
