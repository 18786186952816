import {PrismicNextImage} from '@prismicio/next'
import {KeyTextField, ImageField, SelectField} from '@prismicio/client'
import {Tag} from 'src/common/Tag'

type MediaCardProps = {
  cardBackgroundColor: string
  label: KeyTextField
  labelColor: SelectField<'orange' | 'blue' | 'green' | 'red' | 'yellow' | 'mossGreen' | 'pink' | 'purple'>
  title: KeyTextField
  description: KeyTextField
  image: ImageField
  date?: KeyTextField
}

export const MediaCard = ({
  cardBackgroundColor,
  label,
  title,
  description,
  date,
  image,
  labelColor,
}: MediaCardProps) => (
  <div className={`flex h-full w-full flex-col gap-y-8 rounded-2xl p-3 ${cardBackgroundColor} md:col-span-4`}>
    <PrismicNextImage
      field={image}
      className="
        max-h-[254px]
        min-h-[254px]
        rounded-lg
        md:max-h-none
        md:min-h-fit
        lg:max-h-[294px]
        lg:min-h-[294px]"
    />
    <div className="flex h-full flex-col gap-6 px-2 pb-2">
      <div className="flex flex-col gap-3">
        <Tag label={label} color={labelColor || 'blue'} />
        <h4 className="text-h4 text-moss-black">{title}</h4>
        <p className="text-body text-grey-550">{description}</p>
      </div>
      <p className="text-body-s mt-auto text-grey-300">{date}</p>
    </div>
  </div>
)
