import {KeyTextField, RichTextField, isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {InputHTMLAttributes, memo} from 'react'
import {Icon} from 'src/common/Icon'

export const Checkbox = memo(function Checkbox(props: {
  className?: string
  defaultChecked?: boolean
  disabled?: boolean
  title: KeyTextField
  description: RichTextField
  onChange?: InputHTMLAttributes<HTMLInputElement>['onChange']
}) {
  const {linkResolver} = useLinkResolver()
  return (
    <label className={cn('grid grid-flow-col items-start gap-x-3', props.className)}>
      <div className="grid h-5 w-5 place-items-center">
        <input
          className={cn(
            'peer h-full w-full cursor-pointer appearance-none rounded ring-1 ring-inset ring-d9d9d9 transition-colors span-full',
            'checked:bg-5c7168 checked:ring-0 hover:bg-d9d9d9 hover:checked:bg-5c7168',
            'disabled:cursor-auto disabled:bg-d9d9d9 hover:disabled:bg-d9d9d9',
          )}
          onChange={props.onChange}
          defaultChecked={props.defaultChecked}
          disabled={props.disabled}
          type="checkbox"
        />

        <Icon className="h-3 w-3 scale-0 text-ffffff span-full peer-checked:scale-100" name="check-mark" />
      </div>

      <div className="col-start-2 text-15 font-bold">{props.title}</div>

      <PrismicRichText
        components={{
          paragraph: ({children}) => <p className="col-start-2 text-12 leading-4 text-8f8d8b">{children}</p>,

          hyperlink: ({children, node}) => (
            <Link
              className="text-2b3d8f"
              href={linkResolver(node.data)}
              {...(isFilled.link(node.data) && node.data.link_type === 'Web' && {target: node.data.target})}
            >
              {children}
            </Link>
          ),
        }}
        field={props.description}
      />
    </label>
  )
})
