import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import {ToastProvider} from 'react-toast-notifications'
import {Container} from 'src/common/Container'
import {Icon} from 'src/common/Icon'

import {DemoForm} from 'slices/BookDemoSection/DemoForm'
import {RecaptchaWrapper} from 'slices/BookDemoSection/RecaptchaWrapper'
import {BookDemoSectionSliceDefaultPrimary} from 'prismicio-types'

/**
 * Props for `BookDemoSection`.
 */
export type BookDemoSectionDefaultProps = {
  slice: {
    primary: BookDemoSectionSliceDefaultPrimary
    variation: string
    slice_type: string
  }
  index: number
  context: {
    pageUid: string
  }
}

/**
 * Component for "BookDemoSection" Slices.
 */
export const BookDemoSectionDefault = ({
  slice: {
    primary: {title, description, top_images, ...formProps},
    ...slice
  },
  index: componentIndex,
  context: {pageUid},
}: BookDemoSectionDefaultProps): JSX.Element => (
  <RecaptchaWrapper>
    <ToastProvider>
      <Container
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        backgroundColor="Beige/300"
        id="book-demo"
      >
        <div className="grid-base lx:px-0 gap-y-10 px-5 py-2 md:px-10 xl:py-3">
          <div className="col-span-6 flex flex-col gap-5 text-moss-black">
            <div className="relative hidden h-[100px] lg:flex">
              {top_images.map((image, index) => (
                <PrismicNextImage
                  key={index}
                  field={image.image}
                  className="absolute top-0 h-[100px] w-[100px] rounded-full shadow-elevation-2"
                  style={{
                    left: `${index * 81}px`,
                    zIndex: top_images.length + index,
                  }}
                />
              ))}
            </div>
            <h3 className="text-h4 lg:text-h3">{title}</h3>
            <PrismicRichText
              field={description}
              components={{
                paragraph: ({children}) => <p className="text-body-m">{children}</p>,
                list: ({children}) => (
                  <ul className="text-body-m flex list-inside list-none flex-col gap-2.5">{children}</ul>
                ),
                listItem: ({children}) => (
                  <li className="flex gap-2.5">
                    <Icon name="filled-check-v2" className="h-5 w-5 text-moss-green-450" />
                    <span className="text-body-m">{children}</span>
                  </li>
                ),
              }}
            />
          </div>
          <DemoForm pageUid={pageUid} componentIndex={componentIndex} {...formProps} />
        </div>
      </Container>
    </ToastProvider>
  </RecaptchaWrapper>
)
