import {useCallback} from 'react'

import {useCalendlyEventListener, EventScheduledEvent, InlineWidget} from 'react-calendly'
import {useToasts} from 'react-toast-notifications'
import {useMossApi} from 'src/common/hooks/useMossApi'
import {PersonalDetailsValues} from './UserDetails'

export const CalendlyStep = ({
  calendlyUrl,
  demoId,
  prefilledData,
  trackDemoBooked,
}: {
  calendlyUrl: string
  demoId: string
  prefilledData: Pick<PersonalDetailsValues, 'firstName' | 'lastName' | 'email'>
  trackDemoBooked: (dataType: string) => void
}) => {
  const {addToast} = useToasts()
  const {mossApi} = useMossApi()

  const onEventScheduled = useCallback(
    async (message: EventScheduledEvent) => {
      const eventUri = message.data.payload.event.uri
      const eventId = eventUri?.split('/')?.pop()

      try {
        await mossApi.apiDemoController.booked(demoId, {eventId: eventId!})
        trackDemoBooked('demo-lead')
        // eslint-disable-next-line @typescript-eslint/no-unused-vars -- ToDo - Log error using datadog
      } catch (e) {
        addToast('Error', {appearance: 'error'})
      }
    },
    [demoId, addToast, trackDemoBooked, mossApi],
  )

  useCalendlyEventListener({
    onEventScheduled: onEventScheduled,
  })

  return (
    <div className="flex h-full flex-col items-center">
      <InlineWidget
        url={calendlyUrl}
        pageSettings={{hideEventTypeDetails: true, hideGdprBanner: true}}
        prefill={prefilledData}
        styles={{
          height: '100%',
          width: '100%',
        }}
      />
    </div>
  )
}
