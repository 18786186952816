import {KeyTextField} from '@prismicio/client'
import {useState} from 'react'
import {Button} from 'src/common/Button'
import {AnswerType} from 'slices/SurveySection/helpers'

export const Question = ({
  question,
  answers,
  onClickHandler,
  name,
  buttonLabel,
  index,
  pageUid,
}: {
  onClickHandler: (value: number) => void
  name: string
  question: string
  answers: AnswerType[]
  buttonLabel: KeyTextField
  index: number
  pageUid: string
}) => {
  const [selectedOption, setSelectedOption] = useState<number | null>(null)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(Number(e.target.value))
  }

  const onContinue = () => {
    onClickHandler(selectedOption!)
  }

  return (
    <div className="flex flex-col gap-3 md:px-8">
      <fieldset className="flex min-h-[370px] flex-col gap-4 md:min-h-[250px] xl:min-h-[220px]">
        <legend className="text-h4 text-moss-black">{question}</legend>
        <div className="flex max-w-[500px] flex-col justify-start gap-2 self-center px-6 pt-6 md:px-12">
          {answers.map((answer: any, i: number) => (
            <label key={i} className="text-body-s flex items-start gap-2 text-start text-moss-black">
              <input
                className="mt-1"
                type="radio"
                name={name}
                value={answer.value}
                checked={selectedOption === answer.value}
                onChange={onChange}
              />
              {answer.text}
            </label>
          ))}
        </div>
      </fieldset>
      <Button
        variant="fillV2"
        type="button"
        className="mt-6 w-min self-center"
        onClick={onContinue}
        disabled={selectedOption === null}
        data-mktg-id="continue-quiz"
        data-page-location={`survey-section-${index}`}
        data-page-name={pageUid}
        data-type={selectedOption?.toString()}
      >
        {buttonLabel}
      </Button>
    </div>
  )
}
