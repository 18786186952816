import cn from 'classnames'
import {HeroV2SliceDefaultItem} from 'prismicio-types'
import {IconTag} from 'src/common/IconTag'

export const AnimateRatings = ({isActive, review}: {isActive: boolean; review: HeroV2SliceDefaultItem}) => {
  return (
    <div
      className={cn('absolute ease-in', {
        'animate-fadeInUp': isActive,
        'animate-fadeOutUp': !isActive,
      })}
      style={{
        animationDuration: '.7s',
      }}
    >
      <IconTag iconName={review.review_platform} label={review.label as string} />
    </div>
  )
}
