import {PrismicNextImage} from '@prismicio/next'
import {ImageFullWidthSlice} from 'prismicio-types'
import {Fragment, memo, useEffect, useRef, useState} from 'react'

const ImageFullWidth = (props: {slice: ImageFullWidthSlice}) => {
  const imageTopRef = useRef<HTMLDivElement>(null)
  const [isAboveFold, setIsAboveFold] = useState(false)

  // Determines if image is above the fold
  useEffect(() => {
    if (!imageTopRef.current) {
      return
    }

    if (imageTopRef.current.scrollTop <= window.innerHeight) {
      setIsAboveFold(true)
    }
  }, [])

  return (
    <Fragment>
      <div ref={imageTopRef} />

      <PrismicNextImage
        className="w-full scroll-mt-16"
        field={props.slice.primary.image}
        quality={80}
        priority={isAboveFold}
        sizes="100vw"
        id={String(props.slice.primary.id_anchor)}
      />
    </Fragment>
  )
}

export default memo(ImageFullWidth)
