import {ReadonlyURLSearchParams} from 'next/navigation'

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[]
}

declare const window: WindowWithDataLayer
export const EVENTS = {
  CLICK: 'website_click',
  LOAD: 'component_load',
  SELECT_CLICK: 'select_drop_down',
  TIMER: 'timer_loading',
  FORM_SUBMIT: 'formSubmission',
  TOOLTIP_OPEN: 'tooltip_open',
  PAGE_LOAD: 'page_load',
  PRODUCT_TOUR_START: 'product_tour_start',
  PRODUCT_TOUR_END: 'product_tour_end',
  PRODUCT_TOUR_NEXT: 'product_tour_next_step',
  PRODUCT_TOUR_BACK: 'product_tour_back_step',
  TEXT_INPUT: 'text_input',
  DEMO_BOOKED: 'demo_booked',
  VIDEO: 'video',
} as const

export type TrackingProps = {
  location?: string
  dataType?: string
  mktgId?: string
  pageName?: string
  label?: string
  step?: number
  event: (typeof EVENTS)[keyof typeof EVENTS]
  customParams?: Record<string, string>
}

export const track = ({location, dataType, mktgId, pageName, event, label, step, customParams}: TrackingProps) => {
  const dataLayer = window.dataLayer || []
  return dataLayer.push({
    event: event,
    'data-page-location': location,
    'data-type': dataType,
    'data-mktg-id': mktgId,
    'data-page-name': pageName,
    'data-label': label,
    step,
    ...(customParams && {...customParams}),
  })
}

export const getUtmParams = (searchParams: ReadonlyURLSearchParams) => {
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

  return utmParams
    .map((param) => {
      const value = searchParams.get(param)
      return value ? {name: param, value} : null
    })
    .filter((param) => param !== null)
}
