import {DotLottiePlayer} from '@dotlottie/react-player'
import {ImageField, isFilled, LinkField} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {FeatureSlice} from 'prismicio-types'
import {memo} from 'react'
import {Button} from 'src/common/Button'
import {Context} from 'src/common/types'

type BackgroundColorType = 'Beige/100' | 'Beige/200' | 'Moss White' | 'Moss Black' | 'Green/900'

const brightColors = ['Beige/100', 'Beige/200', 'Moss White']
const darkColors = ['Moss Black', 'Green/900']

const getTitleColor = (backgroundColor: BackgroundColorType) => ({
  'text-moss-black': brightColors.includes(backgroundColor),
  'text-moss-white': darkColors.includes(backgroundColor),
})

const getDescriptionColor = (backgroundColor: BackgroundColorType) => ({
  'text-grey-550': brightColors.includes(backgroundColor),
  'text-grey-135': darkColors.includes(backgroundColor),
})

const Feature = (props: {slice: FeatureSlice; context: Context}) => {
  const {linkResolver} = useLinkResolver()
  const backgroundColor = props.slice.primary.background_color

  const titleClasses = cn(
    "[&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']",
    getTitleColor(backgroundColor || 'Moss White'),
  )

  const descriptionClasses = cn('text-body', getDescriptionColor(backgroundColor || 'Moss White'))

  return (
    <section
      className={cn(
        'grid scroll-mt-16 grid-cols-main items-center gap-y-6 py-8 md:py-0',
        {'bg-beige-100': props.slice.primary.background_color === 'Beige/100'},
        {'bg-beige-200': props.slice.primary.background_color === 'Beige/200'},
        {'bg-moss-white': props.slice.primary.background_color === 'Moss White'},
        {'bg-moss-black text-moss-white': props.slice.primary.background_color === 'Moss Black'},
        {'bg-moss-green-900': props.slice.primary.background_color === 'Green/900'},
      )}
      id={String(props.slice.primary.id_anchor)}
    >
      <div
        className={cn('col-[inner]', {
          'md:col-end-[track-end_6]': props.slice.primary.is_image_on_the_left,
          'md:col-start-[track-start_7]': !props.slice.primary.is_image_on_the_left,
        })}
      >
        <Asset
          image={props.slice.variation === 'default' ? props.slice.primary.image : undefined}
          lottie={props.slice.variation === 'lottieAnimation' ? props.slice.primary.lottie_animation : undefined}
        />
      </div>

      <div
        className={cn('col-[inner] grid place-items-start gap-y-3 md:row-start-1', {
          'md:col-end-[track-end_5]': !props.slice.primary.is_image_on_the_left,
          'md:col-start-[track-start_8]': props.slice.primary.is_image_on_the_left,
        })}
      >
        <div className="flex flex-col gap-3">
          {isFilled.keyText(props.slice.primary.tag) && (
            <div className="text-body-xs-caps uppercase text-neon-550">{props.slice.primary.tag}</div>
          )}
          <PrismicRichText
            components={{
              heading2: ({children}) => <h2 className={`text-h3 ${titleClasses}`}>{children}</h2>,
              heading3: ({children}) => <h3 className={`text-h4 ${titleClasses}`}>{children}</h3>,
              paragraph: ({children}) => <h3 className={`text-h3 ${titleClasses}`}>{children}</h3>,
            }}
            field={props.slice.primary.title}
          />
        </div>

        <PrismicRichText
          components={{
            paragraph: ({children}) => <p className={descriptionClasses}>{children}</p>,
            hyperlink: ({children, node}) => (
              <Link
                className="text-7290f3"
                href={linkResolver(node.data)}
                {...(node.data.link_type === 'Web' && node.data.target && {target: node.data.target})}
              >
                {children}
              </Link>
            ),
            list: ({children}) => <ul className="mt-[-16px] list-disc pl-5 pt-6">{children}</ul>,
            listItem: ({children}) => <li className={descriptionClasses}>{children}</li>,
          }}
          field={props.slice.primary.description}
        />

        {props.slice.primary.link_label && props.slice.primary.link && (
          <Button
            href={linkResolver(props.slice.primary.link, 'primary-button-feature')}
            {...(isFilled.link(props.slice.primary.link) &&
              props.slice.primary.link.link_type === 'Web' && {
                target: props.slice.primary.link.target,
              })}
            variant={brightColors.includes(backgroundColor || 'Moss White') ? 'outlineV2' : 'outlineV2-dark'}
            {...(isFilled.keyText(props.slice.primary.tracking_id) && {
              'data-mktg-id': props.slice.primary.tracking_id,
            })}
            data-page-name={props.context.pageUid}
          >
            {props.slice.primary.link_label}
          </Button>
        )}
      </div>
    </section>
  )
}

export default memo(Feature)

const Asset = ({image, lottie}: {image?: ImageField; lottie?: LinkField}) => {
  if (isFilled.link(lottie)) {
    return <DotLottiePlayer loop src={lottie.url!} autoplay className="overflow-hidden rounded-xl md:rounded-2xl" />
  } else {
    return (
      <PrismicNextImage
        field={image}
        className="rounded-xl md:rounded-2xl"
        sizes="(max-width: 640px) calc(100vw - 48px), (max-width: 768px) calc(100vw - 80px), (max-width: 1440px) 45vw, 548px"
      />
    )
  }
}
