import {FaqSliceV2} from 'prismicio-types'
import {useCallback, useRef, useState} from 'react'
import {useClickAway} from 'react-use'
import {ItemV2} from './Item'
import {Container} from 'src/common/Container'

const FaqV2 = ({slice}: {slice: FaqSliceV2}) => {
  const [activeItem, setActiveItem] = useState<null | number>(null)
  const close = useCallback(() => setActiveItem(null), [])
  const itemsContainerRef = useRef<HTMLDivElement>(null)
  useClickAway(itemsContainerRef, close)

  return (
    <Container backgroundColor={slice.primary.background_color}>
      <div className="grid-base gap-y-12 px-6 xl:px-0">
        <h2 className="text-h3 col-span-full break-words text-moss-black md:text-center">
          {slice.primary.title || 'FAQ'}
        </h2>

        <div
          ref={itemsContainerRef}
          className="col-span-full flex flex-col divide-y divide-grey-135 md:col-span-6 md:col-start-4"
        >
          {slice.items.map((item, index) => (
            <ItemV2
              activeItem={activeItem}
              answer={item.answer}
              index={index}
              key={index}
              question={item.question}
              setActiveItem={setActiveItem}
            />
          ))}
        </div>
      </div>
    </Container>
  )
}

export default FaqV2
