export const locales = {
  'en-gb': 'en-gb',
  'en-us': 'en-us',
  'de-de': 'de-de',
  'nl-nl': 'nl-nl',
} as const

export type LocalesType = (typeof locales)[keyof typeof locales]

export const DEFAULT_LOCALE = locales['en-us']

export const GET_MOSS_HOSTNAME = 'https://www.getmoss.com'

export const TAG_COLORS = {
  orange: {
    backgroundColor: '#FDF3ED',
    color: '#B95E09',
  },
  blue: {
    backgroundColor: '#E8EDFD',
    color: '#4C6EE5',
  },
  green: {
    backgroundColor: '#E6EED3',
    color: '#5B820D',
  },
  red: {
    backgroundColor: '#F9E8E7',
    color: '#CB4C43',
  },
  yellow: {
    backgroundColor: '#F3EAD3',
    color: '#947100',
  },
  mossGreen: {
    backgroundColor: '#E6EFEC',
    color: '#4E7E6C',
  },
  pink: {
    backgroundColor: '#F6E5EB',
    color: '#BA4F7A',
  },
  purple: {
    backgroundColor: '#EBE7F9',
    color: '#8064CE',
  },
  grey: {
    backgroundColor: '#F1F1F1',
    color: '#72716E',
  },
  'brand-purple': {
    backgroundColor: '#6C69FC',
    color: '#FFFFFF',
  },
  'neon-green': {
    backgroundColor: '#B9E97D',
    color: '#232F2A',
  },
  white: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    color: '#FFFFFF',
    backdropFilter: 'blur(10px)',
  },
} as const

export const PRODUCT_ANNOUNCEMENTS_CATEGORIES = {
  [locales['en-gb']]: {
    CARDS: 'Cards',
    ACCOUNTS_PAYABLE: 'Accounts Payable',
    REIMBURSEMENTS: 'Reimbursements',
    PAYMENTS: 'Payments',
    ACCOUNTING: 'Accounting',
    CONTROLS_AND_TEAM: 'Controls and Team',
    GENERAL: 'General',
  },
  [locales['en-us']]: {
    CARDS: 'Cards',
    ACCOUNTS_PAYABLE: 'Accounts Payable',
    REIMBURSEMENTS: 'Reimbursements',
    PAYMENTS: 'Payments',
    ACCOUNTING: 'Accounting',
    CONTROLS_AND_TEAM: 'Controls and Team',
    GENERAL: 'General',
  },
  [locales['de-de']]: {
    CARDS: 'Karten',
    ACCOUNTS_PAYABLE: 'Accounts Payable',
    REIMBURSEMENTS: 'Rückerstattungen',
    PAYMENTS: 'Zahlungen',
    ACCOUNTING: 'Buchhaltung',
    CONTROLS_AND_TEAM: 'Kontrolle & Teams',
    GENERAL: 'Allgemein',
  },
  [locales['nl-nl']]: {
    CARDS: 'Betaalkaarten',
    ACCOUNTS_PAYABLE: 'Crediteurenbeheer',
    REIMBURSEMENTS: 'Declaraties',
    PAYMENTS: 'Betalingen',
    ACCOUNTING: 'Boekhouding',
    CONTROLS_AND_TEAM: 'Controle & teams',
    GENERAL: 'Algemeen',
  },
} as const

export const CATEGORIES_COLORS = {
  CARDS: 'blue',
  ACCOUNTS_PAYABLE: 'green',
  REIMBURSEMENTS: 'pink',
  PAYMENTS: 'purple',
  ACCOUNTING: 'orange',
  CONTROLS_AND_TEAM: 'yellow',
  GENERAL: 'mossGreen',
} as const

export const NAVIGATION_QUERY = `{
    navigation {
      ...navigationFields
      menu_links {
        link_label
        link_url
        marketingid
        submenu {
          ... on submenu {
            slices
          }
        }
      }
    }
  }`

export const OPACITY_0 = {
  opacity: 0,
}

export const OPACITY_1 = {
  opacity: 1,
}

export const OPACITY_0_DURATION_0_5 = {
  opacity: 0,
  transition: {duration: 0.5},
}

export const DEFAULT_PRODUCT_TYPE = 'WALLET'
export const DEFAULT_MARKET = 'DE'
export const DEFAULT_SEGMENT_TYPE = 'STARTUP'
export const UTM_PARAMS_KEY = 'utm-params'

export const marketingConsentUrl =
  'https://getmoss.com/public/terms-and-conditions/20230803_Nufin_GmbH_User_Marketing_Consent_v1_EN.pdf'

export type Locales = 'en-gb' | 'en-us' | 'de-de' | 'nl-nl'

export const CATEGORIES = {
  All: {
    'en-gb': 'All',
    'en-us': 'All',
    'de-de': 'Alle',
    'nl-nl': 'Alle',
  },
  Ecommerce: {
    'en-gb': 'Ecommerce',
    'en-us': 'Ecommerce',
    'de-de': 'E-commerce',
    'nl-nl': 'E-commerce',
  },
  'Professional Services': {
    'en-gb': 'Professional Services',
    'en-us': 'Professional Services',
    'de-de': 'Dienstleistungen',
    'nl-nl': 'Professionele dienstverlening',
  },
  Hospitality: {
    'en-gb': 'Hospitality',
    'en-us': 'Hospitality',
    'de-de': 'Gastgewerbe',
    'nl-nl': 'Hospitality',
  },
  Technology: {
    'en-gb': 'Technology',
    'en-us': 'Technology',
    'de-de': 'Technologie',
    'nl-nl': 'Technologie',
  },
}

export const REVIEW_PLATFORMS = {
  G2: {
    'en-us': 'G2',
    'en-gb': 'G2',
    'de-de': 'G2',
    'nl-nl': 'G2',
  },
  Capterra: {
    'en-us': 'Capterra',
    'en-gb': 'Capterra',
    'de-de': 'Capterra',
    'nl-nl': 'Capterra',
  },
  'Datev marketplace': {
    'en-us': 'DATEV Marketplace',
    'en-gb': 'DATEV Marketplace',
    'de-de': 'DATEV-Marktplatz',
    'nl-nl': 'DATEV-Marktplatz',
  },
}

export const BASE_URL = 'https://www.getmoss.com'
