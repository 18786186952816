import {isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import {SectionHeadlineSlice} from 'prismicio-types'

const SectionHeadline = (props: {slice: SectionHeadlineSlice}) => (
  <section
    className="grid scroll-mt-16 grid-cols-main pb-10 pt-14 sm:pb-14 sm:pt-16 md:pb-16 md:pt-20 xl:pt-24"
    {...(isFilled.color(props.slice.primary.background_color) && {
      style: {backgroundColor: props.slice.primary.background_color},
    })}
    id={String(props.slice.primary.id_anchor)}
  >
    <div className="col-[inner] text-center">
      <PrismicRichText
        components={{
          heading2: ({children}) => (
            <h2 className="text-28 tracking-[-0.01] [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
              {children}
            </h2>
          ),
          strong: ({children}) => <strong className="tracking-normal xl:text-32">{children}</strong>,
        }}
        field={props.slice.primary.title}
      />
    </div>
  </section>
)

export default SectionHeadline
