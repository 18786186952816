import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import {BreakdownSlice} from 'prismicio-types'
import {memo} from 'react'

const Breakdown = (props: {slice: BreakdownSlice}) => (
  <section
    className="grid scroll-mt-16 grid-cols-main gap-y-14 pb-12 pt-16 md:gap-y-20 md:py-20"
    {...(isFilled.color(props.slice.primary.background_color) && {
      style: {backgroundColor: props.slice.primary.background_color},
    })}
    id={String(props.slice.primary.id_anchor)}
  >
    <PrismicRichText
      components={{
        heading2: ({children}) => (
          <h2 className="col-[inner] text-center text-28 font-bold leading-8 -tracking-common text-000000 sm:text-34 sm:leading-9 md:text-36 md:leading-10 xl:text-40 xl:leading-12 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
            {children}
          </h2>
        ),
      }}
      field={props.slice.primary.title}
    />

    <div className="col-[inner] grid auto-cols-fr gap-8 sm:grid-cols-2 md:grid-cols-4 md:gap-6">
      {props.slice.items.map((item, index) => (
        <div key={index}>
          <div className="relative h-9 select-none">
            <PrismicNextImage alt="" aria-hidden className="object-contain object-left-top" field={item.icon} fill />
          </div>

          <h3 className="mt-4 text-18 font-medium leading-5 text-232f2a lg:text-20">{item.title}</h3>

          <div className="mt-2 grid gap-y-2 text-16 leading-5 tracking-common text-72716e">
            <PrismicRichText field={item.description} />
          </div>
        </div>
      ))}
    </div>
  </section>
)

export default memo(Breakdown)
