import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {Container} from 'src/common/Container'
import {Headline} from 'src/common/Headline'
import {LinkWrapper} from 'src/common/LinkWrapper'
import {MediaCard} from 'src/common/MediaCard'
import {Context} from 'src/common/types'

/**
 * Props for `HorizontalMediaCardSection`.
 */
export type HorizontalMediaCardSectionProps = SliceComponentProps<Content.HorizontalMediaCardSectionSlice> & {
  context: Context
}

/**
 * Component for "HorizontalMediaCardSection" Slices.
 */
const HorizontalMediaCardSection = ({
  slice: {slice_type, variation, primary, items},
  index: componentIndex,
  context,
}: HorizontalMediaCardSectionProps): JSX.Element => {
  const cardBackgroundColor = primary.background_color === 'Moss White' ? 'bg-beige-100' : 'bg-moss-white'

  return (
    <Container
      data-slice-type={slice_type}
      data-slice-variation={variation}
      backgroundColor={primary.background_color!}
      className="md:px-5 xl:px-0"
    >
      <div className="grid-base flex w-full flex-col gap-y-8 md:gap-y-12">
        <Headline
          title={primary.title}
          subtext={primary.subtext}
          isCentered={false}
          backgroundColor={primary.background_color!}
          headingLevel="h3"
        />
        <section className="md:grid-base flex w-full flex-col gap-6 px-5 lg:px-0">
          {items.map((item, index) => {
            const defaultItem = item as Content.HorizontalMediaCardSectionSliceDefaultItem

            return (
              <LinkWrapper
                link={defaultItem.link_url}
                key={index}
                className="
                  relative
                  rounded-2xl
                  after:absolute
                  after:top-0
                  after:h-full
                  after:w-full
                  after:rounded-2xl
                  after:opacity-0
                  after:shadow-elevation-3-floating
                  after:transition-opacity
                  after:duration-300
                  after:ease-in-out
                  after:content-['']
                  hover:after:opacity-100
                  md:col-span-4
                "
                mktgId="media-card"
                location={`component-${componentIndex}-media-card-${index + 1}`}
                pageName={context.pageUid}
                dataType={defaultItem.label!}
              >
                <MediaCard
                  cardBackgroundColor={cardBackgroundColor}
                  label={defaultItem.label}
                  title={defaultItem.title}
                  description={defaultItem.description}
                  image={defaultItem.image}
                  date={defaultItem.date}
                  labelColor={defaultItem.label_color}
                />
              </LinkWrapper>
            )
          })}
        </section>
      </div>
    </Container>
  )
}

export default HorizontalMediaCardSection
