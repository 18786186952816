import {Content, isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {SliceComponentProps, SliceZone} from '@prismicio/react'
import {useContext, useEffect} from 'react'
import {components} from 'slices'
import {Context} from 'src/common/types'
import {DialogContext} from 'src/context/DialogContext'
import {AnimatePresence, motion} from 'framer-motion'
import cn from 'classnames'
import {OPACITY_0, OPACITY_1} from 'src/common/constants'

/**
 * Props for `Dialog`.
 */
export type DialogProps = SliceComponentProps<Content.DialogSlice> & {context: Context}

/**
 * Component for "Dialog" Slices.
 */
const Dialog = ({slice, context: {dialog_data, pageUid}}: DialogProps): JSX.Element => {
  const {isDialogOpen, setIsDialogOpen} = useContext(DialogContext)!

  // Start dialog opened if slice has start_opened set to true
  useEffect(() => {
    if (slice.primary.start_opened) {
      setIsDialogOpen(true)
    }
  }, [setIsDialogOpen, slice.primary.start_opened])

  // Set event listener for Hubspot form
  useEffect(() => {
    const handleHubsportSubmit = (event: MessageEvent) => {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
        setIsDialogOpen(false)
      }
    }

    window.addEventListener('message', handleHubsportSubmit)

    return () => window.removeEventListener('message', handleHubsportSubmit)
  }, [setIsDialogOpen])

  return (
    <section data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
      <AnimatePresence>
        {isDialogOpen && (
          <motion.div
            className="absolute left-0 top-0 z-20 flex min-h-full min-w-full items-center justify-center bg-transparent/[10%] pt-11 backdrop-blur-sm"
            initial={OPACITY_0}
            animate={OPACITY_1}
            exit={OPACITY_0}
          >
            <div className="flex h-[510px] w-[420px] flex-col rounded-md bg-moss-white shadow-elevation-1">
              <div className="overflow-hidden px-8 py-6">
                <div className="flex flex-col gap-5 divide-y divide-grey-300">
                  <div className="flex items-center gap-4 rounded-full">
                    {isFilled.image(slice.primary.logo) && (
                      <PrismicNextImage field={slice.primary.logo} className="h-12 w-12 rounded-full" />
                    )}
                    {isFilled.keyText(slice.primary.title) && <h1 className="text-h4">{slice.primary.title}</h1>}
                  </div>
                  <div className="pt-5">
                    <div
                      className={cn('max-h-[374px] overflow-y-scroll', {
                        scrollbar: slice.primary.show_scrollbar,
                      })}
                    >
                      <SliceZone components={components} slices={dialog_data?.data.slices} context={{pageUid}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  )
}

export default Dialog
