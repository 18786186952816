import {isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import {useLinkResolver} from 'prismicio'
import {ContactSlice} from 'prismicio-types'
import {Button} from 'src/common/Button'

const Contact = (props: {slice: ContactSlice}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <section
      className="grid scroll-mt-16 grid-cols-main pb-12 pt-8 md:pb-16 md:pt-10"
      id={String(props.slice.primary.id_anchor)}
    >
      <div className="col-[inner] grid place-items-center gap-y-8 rounded-[32px] bg-ffffff px-6 pb-12 pt-14 text-center md:px-24 md:pt-12">
        <PrismicRichText
          components={{heading2: ({children}) => <h2 className="text-28 leading-8 text-232f2a">{children}</h2>}}
          field={props.slice.primary.title}
        />

        <PrismicRichText
          components={{paragraph: ({children}) => <p className="leading-6 text-72716e">{children}</p>}}
          field={props.slice.primary.description}
        />

        {isFilled.link(props.slice.primary.link) && (
          <Button
            href={linkResolver(props.slice.primary.link)}
            {...(isFilled.link(props.slice.primary.link) &&
              props.slice.primary.link.link_type === 'Web' && {
                target: props.slice.primary.link.target,
              })}
            variant="fill"
          >
            {props.slice.primary.link_label}
          </Button>
        )}
      </div>
    </section>
  )
}

export default Contact
