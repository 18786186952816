import {SelectField, KeyTextField, LinkField, isFilled} from '@prismicio/client'
import {useLinkResolver} from 'prismicio'
import {Button} from 'src/common/Button'
import {IconType, Icon} from 'src/common/Icon'

export const ContentSection = ({
  highlight_1_icon,
  highlight_2_icon,
  highlight_1_title,
  highlight_1_description,
  highlight_2_title,
  highlight_2_description,
  primary_button_label,
  primary_button_url,
}: {
  highlight_1_icon: SelectField<'Up arrow' | 'Down arrow' | 'Plus bold'>
  highlight_2_icon: SelectField<'Up arrow' | 'Down arrow' | 'Plus bold'>
  highlight_1_title: KeyTextField
  highlight_1_description: KeyTextField
  highlight_2_title: KeyTextField
  highlight_2_description: KeyTextField
  primary_button_label: KeyTextField
  primary_button_url: LinkField
}) => {
  const {linkResolver} = useLinkResolver()
  const icon1 = highlight_1_icon?.toLowerCase().replace(/ /g, '-') as IconType
  const icon2 = highlight_2_icon?.toLowerCase().replace(/ /g, '-') as IconType

  return (
    <div className="flex flex-col gap-6 md:gap-10">
      <div className="flex gap-12 max-sm:pb-2">
        {isFilled.keyText(highlight_1_title) && (
          <div className="flex flex-col">
            <div className="flex items-center gap-1">
              <Icon name={icon1} className="h-3 w-3 text-neon-450" />
              <p className="text-body-l text-moss-white">{highlight_1_title}</p>
            </div>
            <span className="text-20 leading-6 text-grey-135">{highlight_1_description}</span>
          </div>
        )}
        {isFilled.keyText(highlight_2_title) && (
          <div className="flex flex-col">
            <div className="flex items-center gap-1">
              <Icon name={icon2} className="h-3 w-3 text-neon-450" />
              <p className="text-body-l text-moss-white">{highlight_2_title}</p>
            </div>
            <span className="text-20 leading-6 text-grey-135">{highlight_2_description}</span>
          </div>
        )}
      </div>
      <Button
        variant="fillV2"
        className="w-fit"
        href={linkResolver(primary_button_url, 'primary-button-content-section')}
      >
        {primary_button_label}
      </Button>
    </div>
  )
}
