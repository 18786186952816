import {useRef, useEffect} from 'react'

export default function useOutsideClick(callback: (e: Event) => void, mountListener = true) {
  const ref = useRef<HTMLDivElement>(null)

  // Set listener for outside click
  useEffect(() => {
    const handleClick = (event: Event) => {
      if (ref.current && !ref.current?.contains(event.target as Node) && mountListener) {
        callback(event)
      }
    }

    if (mountListener && ref.current) {
      document.addEventListener('click', handleClick)
    }

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [ref, callback, mountListener])

  return ref
}
