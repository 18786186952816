import {KeyTextField, ImageField, LinkField, Content} from '@prismicio/client'
import {useMemo} from 'react'

const categories = ['accounting', 'hris', 'sso', 'expenses'] as const

interface Integration {
  name: KeyTextField
  description: KeyTextField
  logo: ImageField
  link: LinkField
}

interface CategoryIntegrations {
  title: string
  integrations: Integration[]
}

const useGetIntegrations = (sliceContent: Content.IntegrationsContainerSliceDefaultPrimary): CategoryIntegrations[] => {
  return useMemo(() => {
    const result = categories.map((category) => ({title: category, integrations: [] as Integration[]}))

    let field: keyof Content.IntegrationsContainerSliceDefaultPrimary

    for (field in sliceContent) {
      const [category, resultKey] = field.split('_') as [(typeof categories)[number], 'title' | 'integrations']

      if (categories.includes(category)) {
        // @ts-expect-error -- TS doesn't know that `field` is a key of `Integration`
        result[categories.indexOf(category)][resultKey] = sliceContent[field]
      }
    }

    return result
  }, [sliceContent])
}

export default useGetIntegrations
