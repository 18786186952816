import {StarIcon} from './StarIcon'

export const StarRating = ({rating, color = 'Grey'}: {rating: number; color?: 'Neon/500' | 'Grey'}) => {
  return (
    <div className="relative flex items-center">
      <div className="relative flex gap-[0.5px]">
        {Array(BASE_RATING)
          .fill(1)
          .map((_, index) => {
            const fillPercentage = Math.min(1, Math.max(0, rating - index)) * 100

            return <StarIcon key={index} fillPercentage={fillPercentage} color={color} />
          })}
      </div>
    </div>
  )
}

const BASE_RATING = 5
