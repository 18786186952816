import {isFilled} from '@prismicio/client'
import {FaqSliceDefault} from 'prismicio-types'
import {useCallback, useRef, useState} from 'react'
import {useClickAway} from 'react-use'
import {Item} from './Item'

const DefaultFaq = (props: {slice: FaqSliceDefault}) => {
  const [activeItem, setActiveItem] = useState<null | number>(null)
  const close = useCallback(() => setActiveItem(null), [])
  const itemsContainerRef = useRef<HTMLDivElement>(null)
  useClickAway(itemsContainerRef, close)

  return (
    <section
      className="grid scroll-mt-16 grid-cols-main items-start gap-y-12 py-16"
      {...(isFilled.color(props.slice.primary.background_color) && {
        style: {backgroundColor: props.slice.primary.background_color},
      })}
      id={String(props.slice.primary.id_anchor)}
    >
      <h2 className="text-h2 col-[inner] break-words text-moss-black">{props.slice.primary.title || 'FAQ'}</h2>

      <div className="contents" ref={itemsContainerRef}>
        <div className="col-[inner] grid gap-6 md:col-end-[track-end_6]">
          {props.slice.items.map((item, index) => (
            <Item
              activeItem={activeItem}
              answer={item.answer}
              className="md:even:hidden"
              index={index}
              key={index}
              question={item.question}
              setActiveItem={setActiveItem}
            />
          ))}
        </div>

        <div className="col-start-[track-start_7] hidden gap-6 md:col-end-[inner-end] md:grid">
          {props.slice.items.map((item, index) => (
            <Item
              activeItem={activeItem}
              answer={item.answer}
              className="odd:hidden"
              index={index}
              key={index}
              question={item.question}
              setActiveItem={setActiveItem}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default DefaultFaq
