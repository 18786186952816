import {PrismicNextLink} from '@prismicio/next'
import {LinksColumnSliceDefault} from 'prismicio-types'
import cn from 'classnames'
import {isFilled} from '@prismicio/client'
import {track} from 'src/common/helpers'
import {useLinkResolver} from 'prismicio'

const LinksDefaultColumn = ({
  slice,
  context: {shouldHideTitle = false, pageUid},
  componentIndex,
}: {
  slice: LinksColumnSliceDefault
  context: {shouldHideTitle?: boolean; pageUid: string}
  componentIndex: number
}): JSX.Element => {
  const {linkResolver} = useLinkResolver()

  return (
    <div
      className="col-span-full flex w-full flex-col pt-4 text-moss-black lg:col-span-4 lg:gap-2 lg:p-0"
      data-slice-variation={slice.variation}
    >
      {isFilled.keyText(slice.primary.column_title) && (
        <span
          className={cn('text-body-bold lg:text-body-bold-l w-full pb-3 lg:px-3 lg:pb-0', {
            'max-lg:hidden': shouldHideTitle,
          })}
        >
          {slice.primary.column_title}
        </span>
      )}
      <ul className="grid w-full list-none grid-cols-2 gap-x-4 gap-y-3 lg:grid-cols-1 lg:gap-y-0">
        {slice.primary.links.map((link, index) => (
          <li key={link.link_label! + index} className="grow basis-0">
            <PrismicNextLink
              href={linkResolver(link.link_url)}
              onClick={() => {
                track({
                  event: 'website_click',
                  dataType: `order-${componentIndex}`,
                  location: 'main-nav-drop-down',
                  mktgId: `category-${slice.primary.marketing_id}-${index}`,
                  pageName: pageUid,
                })
              }}
            >
              <div className="flex flex-col gap-1 rounded-lg hover:bg-moss-white hover:outline hover:outline-1 hover:outline-grey-120 lg:p-3">
                <span className="text-body-bold-xs lg:text-body-bold">{link.link_label}</span>
                <span className="text-body-xs lg:text-body-s">{link.link_description}</span>
              </div>
            </PrismicNextLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default LinksDefaultColumn
