import {PrismicRichText} from '@prismicio/react'
import dayjs from 'dayjs'
import {useRouter} from 'next/router'
import {useLinkResolver} from 'prismicio'
import {ExchangeRatesSlice} from 'prismicio-types'
import {memo, useState} from 'react'
import {Icon} from 'src/common/Icon'
import {Link} from 'src/common/Link'
import useSWR, {Fetcher} from 'swr'

type Data = {
  ccy1: string
  ccy2: string
  rate: number
  rateDate: string
  mastercardRate: number
  mastercardRateDate: string
  markupOverMastercard: number
  ecbRate: number
  markupOverEcb: number
  ecbRateDate: string
}

const RATE_CBPR_URL = 'https://api.getmoss.com/v1/rate-cbpr2?'

const fetcher: Fetcher<Data, string> = (...args) => fetch(...args).then((res) => res.json())

const ExchangeRates = (props: {slice: ExchangeRatesSlice}) => {
  const {linkResolver} = useLinkResolver()
  const [currency, setCurrency] = useState<string>(props.slice.items[0].currency?.split(' ')[0] || 'USD')
  const {locale = 'en-us'} = useRouter()
  const currency2 = locale === 'en-gb' ? 'GBP' : 'EUR'
  const params = new URLSearchParams()
  params.set('ccy1', currency)
  params.set('ccy2', currency2)
  const {data} = useSWR<Data>(RATE_CBPR_URL + params.toString(), fetcher)

  return (
    <section
      className="grid scroll-mt-16 grid-cols-main pb-10 pt-8 lg:pt-2.5 xl:pt-20"
      id={String(props.slice.primary.id_anchor)}
    >
      <PrismicRichText
        components={{
          heading1: ({children}) => (
            <h1 className="col-[inner] mb-20 text-36 font-bold leading-11 text-232f2a lg:text-48 lg:leading-14 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
              {children}
            </h1>
          ),
        }}
        field={props.slice.primary.title}
      />

      <PrismicRichText
        components={{
          paragraph: ({children}) => (
            <p className="col-[inner] text-16 leading-6 text-666563 xl:col-end-[track-end_8]">{children}</p>
          ),
          hyperlink: ({children, node}) => (
            <Link
              className="text-16 leading-6 text-666563 underline underline-offset-[3px]"
              href={linkResolver(node.data)}
              {...(node.data.link_type === 'Web' && node.data.target && {target: node.data.target})}
            >
              {children}
            </Link>
          ),
        }}
        field={props.slice.primary.description}
      />

      <div className="col-[inner] mt-20 grid rounded-3xl bg-ffffff px-8 py-10 xl:col-end-[track-end_8]">
        <PrismicRichText
          components={{
            heading2: ({children}) => (
              <h2 className="text-24 font-medium leading-7 text-232f2a [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
                {children}
              </h2>
            ),
          }}
          field={props.slice.primary.card_title}
        />

        <label className="mt-6 grid w-1/2 gap-y-2">
          <span className="text-12 font-medium leading-4 text-72716e">{props.slice.primary.select_label}</span>

          <select
            className="col-start-1 row-start-2 h-11 appearance-none rounded-lg border border-dcdbda px-4 text-14 leading-5"
            onChange={(event) => setCurrency(event.target.value.split(' ')[0])}
          >
            {props.slice.items.map((item, index) => (
              <option className="" key={index}>
                {item.currency}
              </option>
            ))}
          </select>

          <Icon
            className="col-start-1 row-start-2 mr-4 h-3 w-2 rotate-90 self-center justify-self-end text-2a2928"
            name="pagination-arrow"
          />
        </label>

        <div className="mt-12 text-16 leading-6 text-232f2a">{props.slice.primary.ecb_rate_label}</div>

        <div className="mt-2 text-20 font-medium leading-6 text-232f2a">
          1 {currency} = {data?.ecbRate} {currency2}
        </div>

        <div className="mt-2 text-14 leading-5 text-8f8d8b">
          {props.slice.primary.date_updated_label} {dayjs(data?.ecbRateDate).format('D MMMM YYYY')}
        </div>

        <div className="mt-8 text-16 leading-6 text-232f2a">{props.slice.primary.mastercard_rate_label}</div>

        <div className="mt-2 text-20 font-medium leading-6 text-232f2a">
          1 {currency} = {data?.mastercardRate} {currency2}
        </div>

        <div className="mt-2 text-14 leading-5 text-8f8d8b">
          {props.slice.primary.date_updated_label} {dayjs(data?.mastercardRateDate).format('D MMMM YYYY')}
        </div>

        <div className="mt-8 text-16 leading-6 text-232f2a">{props.slice.primary.markup_over_mastercard_label}</div>

        <div className="mt-2 text-20 font-medium leading-6 text-232f2a">
          {data?.markupOverMastercard && (data.markupOverMastercard * 100).toFixed(4)}%
        </div>

        <div className="mt-8 text-16 leading-6 text-232f2a">{props.slice.primary.markup_over_ecb_label}</div>

        <div className="mt-2 text-20 font-medium leading-6 text-232f2a">
          {data?.markupOverEcb && (data.markupOverEcb * 100).toFixed(4)}%
        </div>
      </div>

      <PrismicRichText
        components={{
          paragraph: ({children}) => (
            <p className="col-[inner] mt-5 text-12 leading-4 tracking-common text-72716e xl:col-end-[track-end_8]">
              {children}
            </p>
          ),
        }}
        field={props.slice.primary.annotation}
      />
    </section>
  )
}

export default memo(ExchangeRates)
