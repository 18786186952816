import {isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {NavigationDocumentData} from 'prismicio-types'
import {ChangeEventHandler, Fragment, memo, useCallback, useEffect, useState} from 'react'
import {Button} from 'src/common/Button'
import {ANALYTICS_COOKIES_KEY, MARKETING_COOKIES_KEY, useCookies} from 'src/common/CookiesContext'
import {Portal} from 'src/common/Portal'
import {Checkbox} from './Checkbox'
import {useRouter} from 'next/navigation'

export const CookieConsentPopup = memo(function CookieConsentPopup(props: {
  className?: string
  close: VoidFunction
  isShown: boolean
  navigationData: NavigationDocumentData
}) {
  const {cookiesAccepted, setCookiesAccepted} = useCookies()
  const [isSettingsShown, setIsSettingsShown] = useState(false)
  const [marketingAccepted, setMarketingAccepted] = useState(cookiesAccepted?.[MARKETING_COOKIES_KEY] || false)
  const [analyticsAccepted, setAnalyticsAccepted] = useState(cookiesAccepted?.[ANALYTICS_COOKIES_KEY] || false)
  const showBanner = false
  const router = useRouter()
  const {linkResolver} = useLinkResolver()

  // Read cookiesAccepted
  useEffect(() => {
    if (!cookiesAccepted) {
      return
    }

    setMarketingAccepted(cookiesAccepted?.[MARKETING_COOKIES_KEY])
    setAnalyticsAccepted(cookiesAccepted?.[ANALYTICS_COOKIES_KEY])
  }, [cookiesAccepted])

  const setCookies = useCallback(
    (isMarketingAccepted: boolean, isAnalyticsAccepted: boolean) => {
      setCookiesAccepted({
        [MARKETING_COOKIES_KEY]: isMarketingAccepted,
        [ANALYTICS_COOKIES_KEY]: isAnalyticsAccepted,
      })

      const close = props.close
      close()
    },
    [props.close, setCookiesAccepted],
  )

  const acceptSelectionCookies = useCallback(
    () => setCookies(marketingAccepted, analyticsAccepted),
    [analyticsAccepted, marketingAccepted, setCookies],
  )

  const acceptAllCookies = useCallback(() => setCookies(true, true), [setCookies])
  const rejectAllCookies = useCallback(() => setCookies(false, false), [setCookies])

  const changeMarketingAccepted: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setMarketingAccepted(event.target.checked)
  }, [])

  const changeAnalyticsAccepted: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setAnalyticsAccepted(event.target.checked)
  }, [])

  // Reload after a change on cookie settings is saved
  useEffect(() => {
    window.addEventListener('UC_UI_CMP_EVENT', ({detail}: MessageEvent & {detail: {source: string; type: string}}) => {
      if ((detail.source === 'SECOND_LAYER' && detail.type === 'SAVE') || detail.type === 'DENY_ALL') {
        router.refresh()
      }
    })

    return () => {
      window.removeEventListener(
        'UC_UI_CMP_EVENT',
        ({detail}: MessageEvent & {detail: {source: string; type: string}}) => {
          if ((detail.source === 'SECOND_LAYER' && detail.type === 'SAVE') || detail.type === 'DENY_ALL') {
            router.refresh()
          }
        },
      )
    }
  }, [router])

  return showBanner ? (
    <Portal>
      <div className="fixed inset-x-0 bottom-8 z-10 m-auto grid max-h-[calc(100%_-_60px)] w-4/5 max-w-[460px] gap-y-5 overflow-y-auto rounded-xl bg-ffffff p-6 shadow-cookies">
        {!isSettingsShown && (
          <PrismicRichText
            components={{paragraph: ({children}) => <p className="text-12 leading-4 text-8f8d8b">{children}</p>}}
            field={props.navigationData.short_description}
          />
        )}

        {isSettingsShown && (
          <Fragment>
            <div>
              <PrismicRichText
                components={{heading2: ({children}) => <h2 className="text-15 font-bold">{children}</h2>}}
                field={props.navigationData.title}
              />

              <PrismicRichText
                components={{
                  paragraph: ({children}) => <p className="text-12 leading-4 text-8f8d8b">{children}</p>,

                  hyperlink: ({children, node}) => (
                    <Link
                      className="text-2b3d8f"
                      href={linkResolver(node.data)}
                      {...(isFilled.link(node.data) && node.data.link_type === 'Web' && {target: node.data.target})}
                    >
                      {children}
                    </Link>
                  ),
                }}
                field={props.navigationData.long_description}
              />
            </div>

            <Checkbox
              defaultChecked
              description={props.navigationData.essential_description}
              disabled
              title={props.navigationData.essential_label}
            />

            <Checkbox
              defaultChecked={marketingAccepted}
              description={props.navigationData.marketing_description}
              onChange={changeMarketingAccepted}
              title={props.navigationData.marketing_label}
            />

            <Checkbox
              defaultChecked={analyticsAccepted}
              description={props.navigationData.analysis_description}
              onChange={changeAnalyticsAccepted}
              title={props.navigationData.analysis_label}
            />

            <PrismicRichText
              components={{
                paragraph: ({children}) => <p className="text-12 leading-4 text-8f8d8b">{children}</p>,

                hyperlink: ({children, node}) => (
                  <Link
                    className="text-2b3d8f"
                    href={linkResolver(node.data)}
                    {...(isFilled.link(node.data) && node.data.link_type === 'Web' && {target: node.data.target})}
                  >
                    {children}
                  </Link>
                ),
              }}
              field={props.navigationData.footnote}
            />

            <Button className="justify-self-end" onClick={acceptSelectionCookies} variant="fill">
              {props.navigationData.accept_selection_label}
            </Button>
          </Fragment>
        )}

        <hr className="-mx-6 text-f2f2f4" />

        <div className="grid justify-end gap-2 sm:grid-flow-col">
          <button
            className="h-10 px-4 text-14 font-medium text-3a5f51"
            type="button"
            onClick={() => setIsSettingsShown((prev) => !prev)}
          >
            {props.navigationData.settings_label}
          </button>

          <Button onClick={rejectAllCookies} variant="outline">
            {props.navigationData.reject_all_label}
          </Button>

          <Button onClick={acceptAllCookies} variant="fill">
            {props.navigationData.accept_all_label}
          </Button>
        </div>
      </div>
    </Portal>
  ) : null
})
