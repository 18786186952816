import {RichTextField} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import {track, EVENTS} from 'src/common/helpers'
import {Icon} from 'src/common/Icon'
import {Tooltip as ReactTooltip, PlacesType} from 'react-tooltip'

export const UnderlinedTooltip = ({
  id,
  place = 'right',
  pageName = '',
  content,
  children,
}: {
  id: string
  place: PlacesType
  pageName?: string
  content: RichTextField
  children: React.ReactNode
}) => (
  <div className="tooltip-container inline">
    <a className="text-body-m cursor-pointer text-moss-black underline underline-offset-[3px]" id={`tooltip-${id}`}>
      {children}
    </a>
    <ReactTooltip
      anchorSelect={`#tooltip-${id}`}
      place={place}
      className="tooltip-show tooltip-background z-10 max-w-[300px] !p-4 md:max-w-[409px]"
      afterShow={() =>
        track({
          event: EVENTS.TOOLTIP_OPEN,
          location: 'ProductSelectorSection',
          dataType: 'tooltip',
          mktgId: id,
          pageName: pageName,
        })
      }
    >
      <PrismicRichText
        field={content}
        components={{
          paragraph: ({children: text}) => <p className="text-body-xxs pb-2 uppercase text-grey-150">{text}</p>,
          list: ({children: listItems}) => <ul className="flex flex-col gap-2 pb-2">{listItems}</ul>,
          listItem: ({children: text}) => (
            <li className="text-body-bold-s flex items-center gap-2 text-moss-white">
              <div className="relative flex h-3 w-3 justify-center rounded-full bg-green-300">
                <Icon name="check-mark" className="h-1.5 w-1.5 self-center text-moss-white" />
              </div>
              {text}
            </li>
          ),
          oList: ({children: listItems}) => <ol className="flex flex-col gap-2 ">{listItems}</ol>,
          oListItem: ({children: text}) => (
            <li className="text-body-bold-s flex items-center gap-2 text-moss-white">
              <div className="relative flex h-3 w-3 justify-center rounded-full bg-ui-red-450">
                <Icon name="cross" className="h-1.5 w-1.5 self-center text-moss-white" />
              </div>
              {text}
            </li>
          ),
        }}
      />
    </ReactTooltip>
  </div>
)
