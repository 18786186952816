import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import cn from 'classnames'
import Link from 'next/link'
import {linkResolver} from 'prismicio'
import {CustomerLogosSlice} from 'prismicio-types'
import {memo, useMemo} from 'react'

const CustomerLogos = (props: {slice: CustomerLogosSlice}) => {
  const hasLink = useMemo(
    () => isFilled.contentRelationship(props.slice.primary.link) && props.slice.primary.link_label,
    [props.slice.primary],
  )

  return (
    <section
      className={cn(
        'grid scroll-mt-16 grid-cols-main bg-f3f3f3 pt-15 sm:pt-14 md:pt-20',
        {'pb-10 sm:pb-14 md:pb-20': !hasLink},
        {'pb-10': hasLink},
      )}
      id={String(props.slice.primary.id_anchor)}
    >
      <div className="col-[inner] mb-15 text-center text-18 font-medium leading-6 text-8f8d8b sm:mb-12 xl:mb-14 xl:text-20 xl:leading-6">
        {props.slice.primary.title}
      </div>

      <div className="col-[inner] grid grid-cols-2 gap-y-3 sm:grid-cols-3 md:gap-y-4">
        {props.slice.items.map((item, index) => (
          <div
            className="relative grid h-20 border-r border-dcdbda py-3 max-md:odd:border-l sm:[&:nth-child(3n+1)]:border-l"
            key={index}
          >
            <div className="relative grid">
              <Link href={(isFilled.link(item.customer_link) && linkResolver(item.customer_link)) || '#'}>
                <PrismicNextImage className="object-contain" fill field={item.logo} />
              </Link>
            </div>
          </div>
        ))}
      </div>

      {hasLink && (
        <Link
          className="col-[entire] w-full bg-3d554b py-5 text-center text-20 font-medium leading-6 text-f3f3f3"
          href={linkResolver(props.slice.primary.link)}
        >
          {props.slice.primary.link_label}
        </Link>
      )}
    </section>
  )
}

export default memo(CustomerLogos)
