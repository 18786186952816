import {PrismicRichText} from '@prismicio/react'
import {ProductSelectorSectionSliceDefaultPrimaryProductsItem} from 'prismicio-types'
import {useState, useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {Icon} from 'src/common/Icon'
import {UnderlinedTooltip} from './UnderlinedTooltip'
import cn from 'classnames'

export const RadioButtonField = ({product}: {product: ProductSelectorSectionSliceDefaultPrimaryProductsItem}) => {
  const {register, watch} = useFormContext()
  const initialState = watch('product') === product.parameter_value
  const [isSelected, setIsSelected] = useState(initialState)

  // Subscribe to the product field
  useEffect(() => {
    const {unsubscribe} = watch((data) => {
      if (data.product === product.parameter_value) {
        setIsSelected(true)
      } else if (isSelected) {
        setIsSelected(false)
      }
    })

    return () => unsubscribe()
  }, [watch, product.parameter_value, isSelected])

  return (
    <label
      className={cn('flex w-full flex-col gap-2.5 rounded-lg bg-moss-white p-3 ring-[1px] md:p-4', {
        'ring-grey-120 hover:ring-grey-200 hover:shadow-elevation-1': !isSelected,
        'ring-green-300': isSelected,
      })}
    >
      <div className="flex gap-3">
        <input type="radio" value={product.parameter_value || ''} {...register('product')} className="peer sr-only" />
        <span className="flex h-5 w-5 items-center justify-center rounded-full border-[1px] border-grey-200 peer-checked:bg-grey-700">
          <span className="block h-2 w-2 rounded-full bg-moss-white" />
        </span>
        <span className="text-body-bold text-moss-black">{product.product_name}</span>
      </div>
      <div className="flex flex-col pl-8">
        <PrismicRichText
          field={product.locked_features}
          components={{
            list: ({children}) => <ul className="pb-1">{children}</ul>,
            listItem: ({children}) => (
              <li className="text-body-m flex items-start gap-1.5 text-moss-black">
                <Icon name="lock" className="mt-0.5 h-4 w-4 text-grey-900" />
                {children}
              </li>
            ),
          }}
        />
        <PrismicRichText
          field={product.features}
          components={{
            list: ({children}) => <ul className="flex flex-col gap-1 pb-1">{children}</ul>,
            listItem: ({children}) => (
              <li className="text-body-m flex items-start gap-1.5 text-moss-black">
                <Icon name="filled-check-v2" className="mt-0.5 h-4 w-4 min-w-4 text-green-300" />
                {children}
              </li>
            ),
            paragraph: ({children}) => <p className="text-body-m pt-1 text-moss-black">{children}</p>,
            hyperlink: ({children}) => (
              <UnderlinedTooltip
                id={product.product_name!.replace(/\s/g, '-').toLowerCase()}
                place="right"
                pageName="ProductSelectorSection"
                content={product.tooltip_content}
              >
                {children}
              </UnderlinedTooltip>
            ),
          }}
        />
      </div>
    </label>
  )
}
