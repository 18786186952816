import {KeyTextField} from '@prismicio/client'
import {SliceZoneLike} from '@prismicio/react'
import {GenericFormDocumentData, GenericFormDocumentDataSlicesSlice} from 'prismicio-types'

export type mappedSection = {
  title: unknown
  type: unknown
  slices: SliceZoneLike
  fields: fieldData[]
  isGrid?: unknown
}

export type fieldData = {
  option: KeyTextField
  defaultChecked: boolean
  paramValue: string
}

export const mapFormDataSections = (data?: GenericFormDocumentData) => {
  if (!data) {
    return []
  }

  let firstSection = {} as mappedSection
  let secondSection = {} as mappedSection
  let thirdSection = {} as mappedSection
  let key: keyof GenericFormDocumentData

  for (key in data) {
    if (key.includes('first') || key === 'slices') {
      firstSection = mapSectionFields({section: data, key, result: firstSection})
    }

    if (key.includes('second') || key === 'slices1') {
      secondSection = mapSectionFields({section: data, key, result: secondSection})
    }

    if (key.includes('third') || key === 'slices2') {
      thirdSection = mapSectionFields({section: data, key, result: thirdSection})
    }
  }

  return [firstSection, secondSection, thirdSection]
}

export const mapSectionFields = ({
  section,
  key,
  result,
}: {
  section: GenericFormDocumentData
  key: keyof GenericFormDocumentData
  result: mappedSection
}) => {
  switch (true) {
    case key.includes('title'):
      result.title = section[key]
      break
    case key.includes('type'):
      result.type = section[key]
      break
    case key.includes('grid'):
      result.isGrid = section[key]
      break
    case key.includes('slices'):
      const slices = section[key] as GenericFormDocumentDataSlicesSlice[]

      if (slices.length === 0) {
        break
      }

      result.slices = slices

      result.fields = slices.map(({primary}) => ({
        option: primary.title,
        defaultChecked: primary.pre_selected,
        paramValue: primary.param_value || primary.title!,
      }))

      break
    default:
      break
  }

  return result
}

export const createInitialFormState = (sections: mappedSection[]) => {
  const initialState: any = {}

  sections?.forEach(({fields}) => {
    fields?.forEach((field) => {
      initialState[field.paramValue!] = Boolean(field.defaultChecked)
    })
  })

  return initialState
}
