import {ProductDeepDiveSliceSingleSectionPrimarySectionsItem} from 'prismicio-types'
import {useContext} from 'react'
import {DEFAULT_DURATION, ProductDeepDiveContext} from '.'
import {EVENTS, track} from 'src/common/helpers'
import {isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import {Button} from 'src/common/Button'
import {useLinkResolver} from 'prismicio'
import {MediaContainer} from './MediaContainer'
import {motion} from 'framer-motion'
import cn from 'classnames'
import {useWindowSize} from 'react-use'

export const PDDContent = ({
  index: id,
  ...content
}: ProductDeepDiveSliceSingleSectionPrimarySectionsItem & {index: number}): JSX.Element => {
  const {activeChapter, handleChapterChange, duration, pageUid, selectorRef, running, componentIndex} =
    useContext(ProductDeepDiveContext)!

  const {linkResolver} = useLinkResolver()
  const isMobile = useWindowSize().width < 768

  const isOpen = id === activeChapter
  const progressBarDelay = isMobile ? 1 : 1.2

  const handleClick = () => {
    if (!isOpen) {
      handleChapterChange(id)
      scrollToTop()

      track({
        event: EVENTS.CLICK,
        location: `component-${componentIndex}-deep-dive`,
        dataType: `chapter-${id}`,
        mktgId: 'row-button',
        pageName: pageUid,
        label: content.tag!,
      })
    }
  }

  const scrollToTop = () => {
    if (isMobile) {
      setTimeout(() => {
        window?.scrollTo({
          top: selectorRef.current?.offsetTop - 120,
          behavior: 'smooth',
        })
      }, 100)
    }
  }

  return (
    <>
      <div
        className={cn('relative flex w-full flex-col  md:duration-700', {
          'grow-[1] transition-all duration-[700ms] md:duration-700 ease-in-out': isOpen,
          'grow-[.001] h-min transition-all duration-[700ms] md:duration-700 ease-in-out': !isOpen,
        })}
      >
        <div
          className={cn(
            'flex gap-2 py-4 text-14 font-medium leading-5 tracking-[.28px] transition-all duration-700 md:text-16 md:leading-6 md:tracking-[.16px] md:duration-700',
            {
              'text-grey-200 cursor-pointer': !isOpen,
              'text-moss-black cursor-default': isOpen,
            },
          )}
          onClick={handleClick}
        >
          <p>{`${id + 1}.`}</p>
          <p>{content.tag}</p>
        </div>
        <div
          className={cn('relative flex flex-col gap-6 overflow-hidden', {
            'max-h-[1000px] md:max-h-[428px] delay-700 transition-all duration-[900ms] md:delay-700 md:duration-700 ease-in-out':
              isOpen,
            'max-h-0 opacity-0 transition-all duration-[700ms] md:duration-700 ease-in-out': !isOpen,
          })}
        >
          <div
            className={cn('flex flex-col gap-3 transition-[margin] duration-100', {
              'mb-8': !isFilled.link(content.button_link),
            })}
          >
            <h3
              className={cn(
                'text-24 font-medium leading-8 text-moss-black transition-all delay-100 duration-700 md:pt-3 md:text-32 md:leading-10',
                {
                  'opacity-100': isOpen,
                  'opacity-0': !isOpen,
                },
              )}
            >
              {content.title}
            </h3>
            <PrismicRichText
              field={content.description}
              components={{
                paragraph: ({children}) => <p className="text-body text-grey-550">{children}</p>,
                list: ({children}) => <ul className="ml-4 list-outside list-disc pl-2 pt-3">{children}</ul>,
                listItem: ({children}) => <li className="text-body text-grey-550">{children}</li>,
              }}
            />
          </div>
          {isFilled.link(content.button_link) && (
            <Button
              variant="outlineV2"
              href={linkResolver(content.button_link)}
              className="mb-8 w-min whitespace-nowrap"
              data-mktg-id="product-landing-button"
              data-type={`section-${activeChapter}`}
              data-page-location={`component-${componentIndex}-deep-dive`}
              data-page-name={pageUid}
              data-label={content.button_lable}
            >
              {content.button_lable}
            </Button>
          )}
        </div>
        <span className="relative h-[1px] w-full bg-grey-135">
          {isOpen && running && (
            <motion.div
              animate={{width: '100%'}}
              transition={{
                duration: duration || DEFAULT_DURATION,
                delay: progressBarDelay,
              }}
              className="absolute bottom-0 left-0 z-10 h-[1px] bg-moss-black"
            />
          )}
        </span>
        <div
          id={`animation-mobile-container-${id}`}
          className={cn('flex items-center rounded-xl bg-beige-300', {
            'opacity-0 h-0 transition-all duration-700 ease-in-out': !isOpen && isMobile,
            'opacity-100 h-[350px] delay-700 transition-all duration-700 ease-in-out': isOpen && isMobile,
            'mt-6': isOpen && isMobile,
          })}
        />
      </div>
      {isOpen && (
        <MediaContainer
          animationSrc={content.animation_link}
          isOpen={isOpen}
          index={id}
          loop={false}
          image={content.image}
        />
      )}
    </>
  )
}
