import {KeyTextField} from '@prismicio/client'
import {Button} from 'src/common/Button'
import {sanitizeHtml} from 'src/common/helpers/sanitizeHtml'

export const Welcome = ({
  title,
  subtext,
  disclaimer,
  startLabel,
  onContinue,
  pageUid,
}: {
  onContinue: () => void
  title: KeyTextField
  subtext: KeyTextField
  startLabel: KeyTextField
  disclaimer: KeyTextField
  pageUid: string
}) => (
  <div className="flex flex-col items-center justify-center gap-8">
    <div className="flex flex-col gap-3">
      <h1 className="text-h3 font-bold text-moss-black" dangerouslySetInnerHTML={{__html: sanitizeHtml(title || '')}} />
      <p className="text-body text-grey-300">{subtext}</p>
    </div>
    <Button
      variant="fillV2"
      onClick={onContinue}
      data-mktg-id="start-quiz"
      data-page-location="survey-section"
      data-page-name={pageUid}
    >
      {startLabel}
    </Button>
    <p className="text-body-s italic text-grey-450">{disclaimer}</p>
  </div>
)
