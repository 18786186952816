import {PrismicRichText} from '@prismicio/react'
import {ImprintContentSlice} from 'prismicio-types'
import {memo} from 'react'

const ImprintContent = memo(function ImprintContent(props: {slice: ImprintContentSlice}) {
  return (
    <div
      className="grid scroll-mt-16 grid-cols-main pb-24 pt-10 sm:pt-12 md:pb-28 md:pt-16 xl:pt-20"
      id={String(props.slice.primary.id_anchor)}
    >
      <div className="col-[inner] xl:col-end-[track-end_8]">
        <PrismicRichText
          components={{
            heading1: ({children}) => (
              <h1 className="pb-4 text-36 font-bold leading-11 -tracking-common sm:pb-10 md:pb-6 md:text-40 md:leading-12 lg:text-48 lg:leading-14 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
                {children}
              </h1>
            ),
            heading2: ({children}) => (
              <h2 className="mt-12 text-24 font-medium leading-7 first:mt-0 sm:mt-10 md:mt-14">{children}</h2>
            ),
            paragraph: ({children}) => <p className="mt-6 leading-6 text-666563">{children}</p>,
          }}
          field={props.slice.primary.content}
        />
      </div>
    </div>
  )
})

export default ImprintContent
