import {ReactNode, useContext, useEffect, useRef} from 'react'
import {DialogContext} from 'src/context/DialogContext'
import {AnimatePresence, motion} from 'framer-motion'
import {createPortal} from 'react-dom'
import {OPACITY_0, OPACITY_1} from 'src/common/constants'

const Dialog = ({
  children,
  startOpened = false,
  onClose,
}: {
  children: ReactNode
  startOpened?: boolean
  onClose?: VoidFunction
}): JSX.Element => {
  const {isDialogOpen, setIsDialogOpen} = useContext(DialogContext)!
  const dialogContainer = useRef<HTMLElement | null>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  // Close dialog when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the event's origin is from the current document
      if (event.isTrusted && wrapperRef.current && !wrapperRef.current.contains(event.target as Node) && isDialogOpen) {
        setIsDialogOpen(false)
        onClose?.()
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [wrapperRef, setIsDialogOpen, isDialogOpen, onClose])

  // Start dialog opened if slice has start_opened set to true
  useEffect(() => {
    if (startOpened) {
      setIsDialogOpen(true)
    }
  }, [setIsDialogOpen, startOpened])

  // Set event listener for Hubspot form
  useEffect(() => {
    const handleHubspotSubmit = (event: MessageEvent) => {
      // Define a list of trusted origins
      const trustedOrigins = ['https://www.getmoss.com']

      // Check if the message event comes from a trusted origin
      if (!trustedOrigins.includes(event.origin)) {
        console.warn('Message received from untrusted origin:', event.origin)
        return
      }

      // Validate the message data
      if (event.data?.type === 'hsFormCallback' && event.data?.eventName === 'onFormSubmitted') {
        setIsDialogOpen(false)
      }
    }

    window.addEventListener('message', handleHubspotSubmit)

    return () => window.removeEventListener('message', handleHubspotSubmit)
  }, [setIsDialogOpen])

  // Disable scrolling while opened
  useEffect(() => {
    if (isDialogOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isDialogOpen])

  // Create portal for dialog
  useEffect(() => {
    dialogContainer.current = document?.getElementById('dialog-container')
  }, [])

  return dialogContainer.current ? (
    createPortal(
      <AnimatePresence>
        {isDialogOpen && (
          <motion.div
            className="fixed left-0 top-0 z-20 flex min-h-full min-w-full items-center justify-center bg-transparent/[10%] backdrop-blur-sm"
            initial={OPACITY_0}
            animate={OPACITY_1}
            exit={OPACITY_0}
          >
            <div ref={wrapperRef} id="dialog-wrapper">
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>,
      dialogContainer.current,
    )
  ) : (
    <></>
  )
}

export default Dialog
