import {Container} from 'src/common/Container'
import {CustomerSpotlightSliceNoImage} from 'prismicio-types'
import {NoImageCard} from './NoImageCard'

export const NoImageVariation = ({slice}: {slice: CustomerSpotlightSliceNoImage}): JSX.Element => {
  const {
    primary: {author, customer_icon, testimony, background_color, card_background_color},
    variation,
  } = slice

  return (
    <Container data-slice-variation={variation} backgroundColor={background_color}>
      <div className="grid w-full max-w-[1200px] grid-cols-1 gap-4 px-5 xl:px-0" data-aos="fade-up">
        <NoImageCard
          backgroundColor={background_color}
          cardBackgroundColor={card_background_color}
          testimony={testimony}
          author={author}
          customerIcon={customer_icon}
        />
      </div>
    </Container>
  )
}
