import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'

import {BookDemoSectionDefault} from './Default'
import {BookDemoSectionDirectMail} from './DirectMail'

/**
 * Props for `BookDemoSection`.
 */
export type BookDemoSectionProps = SliceComponentProps<Content.BookDemoSectionSlice> & {
  context: {
    pageUid: string
  }
}

/**
 * Component for "BookDemoSection" Slices.
 */
const BookDemoSection = ({slice, context, index}: BookDemoSectionProps): JSX.Element => {
  switch (slice.variation) {
    case 'directMail':
      return <BookDemoSectionDirectMail slice={slice} index={index} context={context} />
    default:
      return <BookDemoSectionDefault slice={slice} index={index} context={context} />
  }
}

export default BookDemoSection
