import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {FunnelController} from './Steps'

/**
 * Props for `SurveySection`.
 */
export type SurveySectionProps = SliceComponentProps<Content.SurveySectionSlice> & {
  context: {
    pageUid: string
  }
}

/**
 * Component for "SurveySection" Slices.
 */
const SurveySection = ({slice, context}: SurveySectionProps): JSX.Element => {
  return (
    <div className="flex min-h-lvh items-center justify-center bg-moss-green-500 px-5 py-8 md:px-[40px] md:py-12">
      <div className="flex min-h-[450px] w-full max-w-[660px] flex-col items-center justify-center rounded-2xl bg-moss-white text-center lg:px-12 lg:py-8">
        <div className="px-5 py-8 max-md:px-12 lg:p-0">
          <FunnelController funnel={slice.primary} pageUid={context.pageUid} />
        </div>
      </div>
    </div>
  )
}

export default SurveySection
