import {SwiperClass} from 'swiper/react'
import cn from 'classnames'
import {Icon} from 'src/common/Icon'

type SwiperNavButtonsProps = {
  swiperInstance: SwiperClass | null
}

const SwiperNavButtons = ({swiperInstance}: SwiperNavButtonsProps) => {
  return (
    <div className="flex items-end gap-3">
      <button
        className={cn('flex h-6 items-center focus:border-blue-550', {
          'pointer-events-none text-grey-200': swiperInstance?.isBeginning,
        })}
        onClick={() => swiperInstance?.slidePrev()}
      >
        <Icon className="h-6 w-6 rotate-180" name="right-arrow" />
      </button>
      <button
        className={cn('flex h-6 items-center focus:border-blue-550', {
          'pointer-events-none text-grey-200': swiperInstance?.isEnd,
        })}
        onClick={() => swiperInstance?.slideNext()}
      >
        <Icon className="h-6 w-6" name="right-arrow" />
      </button>
    </div>
  )
}

export default SwiperNavButtons
