import {Icon, IconType} from 'src/common/Icon'
import cn from 'classnames'

export const IconTag = ({iconName, label}: {iconName?: IconType; label: string}) => (
  <div
    className={cn('flex items-center gap-2 rounded-full border border-grey-135 bg-moss-white py-1 pr-2.5', {
      'pl-[5px]': iconName,
      'pl-2.5': !iconName,
    })}
    style={{
      animationDuration: '.7s',
    }}
  >
    {iconName ? (
      <div className="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-accent-neon">
        <Icon name={iconName} className="h-2.5 w-2.5" />
      </div>
    ) : null}
    <span className="text-14 font-medium leading-4 tracking-[.28px] text-moss-black">{label}</span>
  </div>
)
