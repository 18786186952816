import {PrismicNextImage} from '@prismicio/next'
import {TwoCardsSlice} from 'prismicio-types'
import {memo} from 'react'
import {Card} from './Card'
import cn from 'classnames'
import {isFilled} from '@prismicio/client'

const TwoCards = ({slice}: {slice: TwoCardsSlice}) => {
  const hasImage = slice.variation === 'default'

  return (
    <section
      className="grid scroll-mt-16 grid-cols-main items-start gap-y-20 pb-14 sm:py-12 md:items-center md:gap-y-12"
      id={String(slice.primary.id_anchor)}
    >
      {isFilled.keyText(slice.primary.title) && (
        <h3 className="text-h3 col-span-full text-center text-moss-black">{slice.primary.title}</h3>
      )}
      {hasImage && (
        <PrismicNextImage className="col-[inner] md:hidden" field={slice.primary.image_mobile} quality={90} />
      )}

      <Card
        className={cn('col-[inner] sm:col-end-[track-end_3]', {
          'md:col-end-[track-end_4]': hasImage,
          'md:col-end-[track-end_6]': !hasImage,
        })}
        title={slice.primary.left_card_title}
        description={slice.primary.left_card_description}
        list={slice.primary.left_card_list}
      />

      {hasImage && (
        <PrismicNextImage
          className="col-start-[track-start_5] col-end-[track-end_8] w-full justify-self-center max-md:hidden"
          field={slice.primary.image}
          quality={90}
        />
      )}

      <Card
        className={cn('col-[inner] sm:col-start-[track-start_4]', {
          'md:col-start-[track-start_9]': hasImage,
          'md:col-start-[track-start_7]': !hasImage,
        })}
        title={slice.primary.right_card_title}
        description={slice.primary.right_card_description}
        list={slice.primary.right_card_list}
      />
    </section>
  )
}

export default memo(TwoCards)
