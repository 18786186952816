import {memo} from 'react'
import {Icon} from 'src/common/Icon'

export const RatingsGroup = memo(function RatingsGroup({
  capterraRating,
  g2Rating,
  preposition = 'on',
}: {
  capterraRating: number
  g2Rating: number
  preposition?: string
}) {
  if (!capterraRating && !g2Rating) {
    return null
  }

  return (
    <div className="flex justify-center gap-3">
      {g2Rating && (
        <div className="flex gap-2 text-moss-black">
          <Icon name="G2" className="h-4 w-4" />
          <p className="text-12 font-medium leading-4 tracking-[.24px] text-grey-450">{`${g2Rating} ${preposition} G2`}</p>
        </div>
      )}
      {capterraRating && (
        <div className="flex gap-2 text-moss-black">
          <Icon name="Capterra" className="h-4 w-4" />
          <p className="text-12 font-medium leading-4 tracking-[.24px] text-grey-450">{`${capterraRating} ${preposition} Capterra`}</p>
        </div>
      )}
    </div>
  )
})
