import {isFilled} from '@prismicio/client'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {BreadcrumbsSlice} from 'prismicio-types'
import {Fragment, memo} from 'react'
import {Icon} from 'src/common/Icon'

const Breadcrumbs = (props: {slice: BreadcrumbsSlice}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <section
      className="grid scroll-mt-16 grid-cols-main border-b border-dcdbda py-4"
      id={String(props.slice.primary.id_anchor)}
    >
      <div className="col-[inner] flex flex-wrap items-center justify-start gap-x-1.5 text-14 sm:gap-x-3">
        <Link className="font-bold" href="/">
          Home
        </Link>

        {props.slice.items.map((item, index) =>
          isFilled.contentRelationship(item.link) ? (
            <Fragment key={index}>
              <Icon className="h-2 w-1" name="arrow-right" />

              <Link href={linkResolver(item.link)}>{item.label}</Link>
            </Fragment>
          ) : null,
        )}
      </div>
    </section>
  )
}

export default memo(Breadcrumbs)
