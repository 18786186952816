import {Content, isFilled} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {useRouter} from 'next/router'
import {memo, useContext, useEffect, useRef, useState} from 'react'
import {EVENTS, track} from 'src/common/helpers'
import {Context} from 'src/common/types'
import {DialogContext} from 'src/context/DialogContext'

/**
 * Props for `ProductTour`.
 */
export type ProductTourProps = SliceComponentProps<Content.ProductTourSlice>

/**
 * Component for "ProductTour" Slices.
 */

const ProductTourMessageData = {
  type: {
    TOUR_NAVIGATION: 'tour_navigation',
    TOUR_END: 'dsTourEnd',
  },
  payload: {
    DIRECTION: {
      BACK: 'back',
      NEXT: 'next',
    },
  },
} as const

const TOTAL_STEPS = 16

const ProductTour = memo(({slice, context}: ProductTourProps & {context: Context}): JSX.Element => {
  const {isDialogOpen} = useContext(DialogContext)!
  const [userEmail, setUserEmail] = useState('')
  const [currentStep, setCurrentStep] = useState(0)
  const isFormSubmitted = useRef(false)
  const isTourStarted = useRef(false)
  const isTourEnded = useRef(false)
  const {locale} = useRouter()

  const handleHubspotEvent = (event: MessageEvent) => {
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmitted' &&
      !isFormSubmitted.current
    ) {
      isFormSubmitted.current = true
      setUserEmail(event.data.data.submissionValues.email)
    }
  }

  // Set Hubspot form submission listener
  useEffect(() => {
    const handleDemostackEvents = ({origin, data}: MessageEvent) => {
      if (origin === 'https://demostack.app') {
        switch (true) {
          case data.type === ProductTourMessageData.type.TOUR_NAVIGATION && !isTourStarted.current:
            isTourStarted.current = true

            track({
              pageName: context.pageUid,
              dataType: locale,
              event: EVENTS.PRODUCT_TOUR_START,
              step: currentStep,
            })

            setCurrentStep(currentStep + 1)
            break

          case currentStep > 0 && currentStep < TOTAL_STEPS:
            const isNextStep = data.payload.direction === ProductTourMessageData.payload.DIRECTION.NEXT
            const nextStep = isNextStep ? currentStep + 1 : currentStep - 1

            track({
              pageName: context.pageUid,
              dataType: locale,
              event: isNextStep ? EVENTS.PRODUCT_TOUR_NEXT : EVENTS.PRODUCT_TOUR_BACK,
              step: currentStep,
            })

            setCurrentStep(nextStep)
            break

          case data.type === ProductTourMessageData.type.TOUR_END && !isTourEnded.current:
            isTourEnded.current = true

            track({
              pageName: context.pageUid,
              dataType: locale,
              event: EVENTS.PRODUCT_TOUR_END,
              step: currentStep,
            })

            break
        }
      }
    }

    if (userEmail.length > 0) {
      window.addEventListener('message', handleDemostackEvents)
    }

    return () => window.removeEventListener('message', handleDemostackEvents)
  }, [userEmail, currentStep, context.pageUid, locale])

  // Set demostack listener
  useEffect(() => {
    window.addEventListener('message', handleHubspotEvent)
    return () => window.removeEventListener('message', handleHubspotEvent)
  }, [])

  if (!isFilled.link(slice.primary.demostack_link) || (slice.primary.dialog_dependent && isDialogOpen)) {
    return <div id="test"></div>
  }

  return (
    <iframe
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      width="100%"
      height="100%"
      src={slice.primary.demostack_link.url + `?email=${encodeURIComponent(userEmail)}`}
      title="Embedded tour"
      frameBorder="0"
      style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, paddingTop: '44px'}}
    />
  )
})

export default ProductTour
