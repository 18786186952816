import {RTHeading2Node, RTNode} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {useLinkResolver} from 'prismicio'
import {CustomerStoryContentSlice} from 'prismicio-types'
import {useEffect, useMemo, useState} from 'react'
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share'
import {Icon} from 'src/common/Icon'
import {removeNonAlphanumeric} from 'slices/CustomerStoryContent/utils'

const filterH2 = (node: RTNode): node is RTHeading2Node => node.type === 'heading2'

const SECTION_GAP = 65

export const CustomerStoryContentDefault = (props: {slice: CustomerStoryContentSlice}) => {
  const router = useRouter()
  const {linkResolver} = useLinkResolver()
  const [isPageScrolled, setIsPageScrolled] = useState(false)
  const [isTOCExpanded, setIsTOCExpanded] = useState(false)
  const url = useMemo(() => `https://www.getmoss.com/${router.locale}${router.asPath}`, [router])

  const tableOfContents = useMemo(
    () => ['Intro', ...props.slice.primary.content.filter(filterH2).map((node: RTHeading2Node) => node.text)],
    [props.slice.primary.content],
  )

  const [activeSection, setActiveSection] = useState(null)

  const handleScroll = () => {
    const sections: NodeListOf<HTMLHeadingElement> = document.querySelectorAll('h2[id]')
    const pageYOffset = window.pageYOffset
    let newActiveSection = null

    if (pageYOffset > 0) {
      setIsPageScrolled(true)
    } else {
      setIsPageScrolled(false)
    }

    sections.forEach((section, index, array) => {
      const sectionOffsetTop = section.offsetTop - SECTION_GAP

      const nextSectionOffsetTop =
        index + 1 < array.length ? array[index + 1].offsetTop - SECTION_GAP : document.body.scrollHeight

      if (pageYOffset >= sectionOffsetTop && pageYOffset < nextSectionOffsetTop) {
        newActiveSection = section.id
      }
    })

    setActiveSection(newActiveSection)
  }

  // Active section detection
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <section className="grid grid-cols-main bg-f3f3f3 pb-18 pt-6 md:pt-10 lg:pt-14">
      <div
        className={cn(
          'fixed top-16 z-10 grid w-full self-start bg-f3f3f3 transition-all max-lg:grid-cols-main lg:sticky lg:top-24 lg:col-start-[track-start_1] lg:col-end-[track-end_3]',
          {'max-lg:grid-rows-[0fr_auto]': !isTOCExpanded},
          {'grid-rows-[1fr_auto]': isTOCExpanded},
          {'max-lg:-translate-y-full': !isPageScrolled},
          {'max-lg:shadow-toc': isPageScrolled},
        )}
      >
        <div className="col-[inner] grid min-h-0 overflow-hidden lg:col-start-[track-start_4]">
          {tableOfContents.map((item, index) => (
            <Link
              className={cn(
                'firstmax-lg:mt-8 justify-self-start px-4 py-2 text-16 font-medium leading-6 transition-colors last:mb-8 hover:text-232f2a',
                {
                  'text-8f8d8b': activeSection !== removeNonAlphanumeric(item),
                },
                {
                  'text-1e2a25 bg-ffffff rounded-lg shadow-toc-item': activeSection === removeNonAlphanumeric(item),
                },
              )}
              href={`#${removeNonAlphanumeric(item)}`}
              key={index}
              onClick={() => setIsTOCExpanded(false)}
              scroll={false}
            >
              {item}
            </Link>
          ))}

          <div className="mt-8 flex gap-x-2 p-1">
            <TwitterShareButton
              className="relative z-10 grid h-8 w-8 place-content-center transition-all hover:rounded-lg hover:bg-ffffff hover:shadow-social-button"
              url={url}
            >
              <Icon className="h-4 w-4 text-706f6f" name="twitter-icon" />
            </TwitterShareButton>

            <FacebookShareButton
              className="relative z-10 grid h-8 w-8 place-content-center transition-all hover:rounded-lg hover:bg-ffffff hover:shadow-social-button"
              url={url}
            >
              <Icon className="h-4 w-4 text-706f6f" name="facebook-icon" />
            </FacebookShareButton>

            <LinkedinShareButton
              className="relative z-10 grid h-8 w-8 place-content-center transition-all hover:rounded-lg hover:bg-ffffff hover:shadow-social-button"
              url={url}
            >
              <Icon className="h-4 w-4 text-706f6f" name="linkedin-icon" />
            </LinkedinShareButton>
          </div>
        </div>

        <button
          className="col-[inner] flex h-14 items-center justify-between lg:hidden"
          onClick={() => setIsTOCExpanded((prev) => !prev)}
        >
          <span className="text-16 leading-6 text-72716e">
            {
              {
                'en-us': 'Table of content',
                'en-gb': 'Table of content',
                'de-de': 'Table of content',
                'nl-nl': 'Table of content',
              }[router.locale || 'en-us']
            }
          </span>

          <Icon
            className={cn('h-3 w-3 text-4e7e6c', {'rotate-90': !isTOCExpanded}, {'-rotate-90': isTOCExpanded})}
            name="pagination-arrow"
          />
        </button>
      </div>

      <div className="col-[inner] lg:col-start-[track-start_5]">
        <h2 className="scroll-mt-32 lg:scroll-mt-16" id="intro" />

        <PrismicRichText
          components={{
            heading2: ({children, node}) => (
              <h2
                className="mb-6 scroll-mt-32 text-32 leading-10 -tracking-common text-1e2a25 sm:mb-8 md:mb-10 lg:scroll-mt-16 lg:text-40 lg:leading-11"
                id={removeNonAlphanumeric(node.text)}
              >
                {children}
              </h2>
            ),
            heading3: ({children}) => (
              <h3 className="mb-6 text-24 font-medium leading-7 text-1e2a25 sm:mb-8 md:mb-10 lg:mb-12">{children}</h3>
            ),
            heading6: ({children}) => (
              <blockquote className="mb-4 px-5 py-2.5 text-28 leading-8 text-232f2a md:pl-15">{children}</blockquote>
            ),
            paragraph: ({children}) => <p className="mb-6 text-18 leading-[26px] text-666563 md:mb-10">{children}</p>,
            hyperlink: ({children, node}) => (
              <Link
                className="text-456e5e underline transition-colors duration-150 hover:text-6c9d8b"
                href={linkResolver(node.data)}
                {...(node.data.link_type === 'Web' && node.data.target && {target: node.data.target})}
              >
                {children}
              </Link>
            ),
            image: ({node, key}) => {
              const img = (
                <PrismicNextImage
                  className="mb-10"
                  field={node}
                  data-copyright={node.copyright ? node.copyright : undefined}
                />
              )

              return <p key={key}>{node.linkTo ? <Link href={linkResolver(node.linkTo)}>{img}</Link> : img}</p>
            },
          }}
          field={props.slice.primary.content}
        />
      </div>
    </section>
  )
}
