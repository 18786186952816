import {Content, isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText, SliceComponentProps} from '@prismicio/react'
import {Container} from 'src/common/Container'
import {Link} from 'src/common/Link'
import {useLinkResolver} from 'prismicio'
import {Context} from 'src/common/types'
import {EVENTS, track} from 'src/common/helpers'
import {StarRating} from 'src/common/StarRating'
import {useRouter} from 'next/router'
import {locales, REVIEW_PLATFORMS} from 'src/common/constants'

/**
 * Props for `IdcReport`.
 */
export type IdcReportProps = SliceComponentProps<Content.IdcReportSlice>

/**
 * Component for "IdcReport" Slices.
 */
const IdcReport = ({
  slice,
  context: {pageUid},
  index: componentIndex,
}: IdcReportProps & {context: Context}): JSX.Element => {
  const {linkResolver} = useLinkResolver()
  const {locale} = useRouter()
  const ratingsPlatform = slice.primary.ratings_section?.[0]?.review_platform

  const handleLinkClick = () => {
    track({
      event: EVENTS.CLICK,
      pageName: pageUid,
      location: `component-${componentIndex}`,
      dataType: 'idc-report',
      mktgId: 'idc-report-link',
    })
  }

  return (
    <Container backgroundColor="Green/900" data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
      <div className="grid w-full max-w-[1200px] grid-cols-4 gap-4 gap-y-8 px-5 md:grid-cols-12 md:gap-x-6 xl:px-0">
        {isFilled.image(slice.primary.document_logo) && (
          <div className="col-span-2 flex h-[30px] items-center gap-3 md:col-span-12" data-aos="fade-up">
            <PrismicNextImage
              field={slice.primary.document_logo}
              className="h-[25px] w-[80px]"
              style={{
                filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(5deg) brightness(101%) contrast(105%)',
              }}
            />
            {isFilled.keyText(slice.primary.tag_line) && (
              <>
                <span className="h-full w-[1px] bg-grey-700" />
                <p className="text-14 leading-5 tracking-[.28px] text-grey-200">{slice.primary.tag_line}</p>
              </>
            )}
          </div>
        )}
        <div className="col-span-4 grid grid-cols-4 gap-y-12 md:col-span-12 md:grid-cols-12 md:gap-6 lg:gap-y-[120px]">
          <div className="col-span-4 flex flex-col gap-3 md:col-span-7 xl:col-span-6" data-aos="fade-up">
            {isFilled.group(slice.primary.ratings_section) && ratingsPlatform && (
              <div className="text-body flex items-center gap-[6px] text-moss-white">
                <span>{REVIEW_PLATFORMS[ratingsPlatform][locale as keyof typeof locales]}</span>
                <StarRating rating={slice.primary.ratings_section[0].rating!} color="Neon/500" />
                <span>{slice.primary.ratings_section[0].rating?.toFixed(1)}</span>
              </div>
            )}
            <h3 className="self-stretch text-32 font-medium leading-10 text-moss-white md:leading-12 lg:text-40">
              {slice.primary.title}
            </h3>
            <p className="self-stretch text-16 leading-6 tracking-[.16px] text-grey-200">{slice.primary.description}</p>
          </div>
          <div
            className="col-span-4 grid grid-cols-4 gap-4 md:col-span-12 md:grid md:grid-cols-12 md:gap-6"
            data-aos="fade-up"
          >
            {slice.items.map((card, index) => (
              <div className="col-span-2 flex flex-col gap-3 md:col-span-3" key={index}>
                <p className="self-stretch text-32 font-medium leading-10 text-moss-white">{card.headline}</p>
                <p
                  className="
                  self-stretch
                  text-14
                  leading-5
                  tracking-[.28px]
                  text-grey-200
                  md:text-16
                  md:leading-6
                  md:tracking-[.16px]
                  "
                >
                  {card.body}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-4 md:col-span-7 lg:pt-3 xl:col-span-6" data-aos="single-line-fade-up">
          <PrismicRichText
            components={{
              paragraph: ({children}) => (
                <p className="text-14 leading-5 tracking-[.28px] text-grey-200 md:tracking-[.16px]">{children}</p>
              ),
              hyperlink: ({children, node}) => (
                <Link
                  className="text-14 leading-5 tracking-[.28px] text-grey-200 underline underline-offset-[3px] md:tracking-[.16px]"
                  href={linkResolver(node.data)}
                  {...(node.data.link_type === 'Web' && node.data.target && {target: node.data.target})}
                  onClick={handleLinkClick}
                >
                  {children}
                </Link>
              ),
            }}
            field={slice.primary.footer}
          />
        </div>
      </div>
    </Container>
  )
}

export default IdcReport
