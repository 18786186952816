import {PrismicRichText} from '@prismicio/react'
import {CustomerStoriesSlice} from 'prismicio-types'
import {Fragment, memo, useEffect, useState} from 'react'
import {useWindowSize} from 'react-use'
import {Button} from 'src/common/Button'
import {Context} from 'src/common/types'
import {Card} from 'slices/CustomerStories/Card'

export const CustomerStoriesDefault = memo(function CustomerStories(props: {
  context: Context
  slice: CustomerStoriesSlice
}) {
  const {width} = useWindowSize()
  const [isShownAll, setIsShownAll] = useState(false)
  const [lessNumber, setLessNumber] = useState(3)

  // Define initial number of cards
  useEffect(() => {
    setIsShownAll(false)

    if (width < 640) {
      setLessNumber(3)
      return
    }

    if (width < 1024) {
      setLessNumber(5)
      return
    }

    setLessNumber(7)
  }, [width])

  return (
    <Fragment>
      {props.context.customerStories.length > 0 && (
        <section className="mb-20 grid grid-cols-main sm:mb-28 lg:mb-36">
          <Card customerStory={props.context.customerStories[0]} className="col-[inner]" isFeatured />
        </section>
      )}

      {props.context.customerStories.length > 1 && (
        <section className="grid grid-cols-main gap-y-10 pb-20 sm:pb-16 md:pb-24 lg:pb-48">
          <PrismicRichText
            components={{
              heading2: ({children}) => (
                <h2 className="col-[inner] text-28 leading-8 -tracking-common text-1e2a25 max-lg:text-center">
                  {children}
                </h2>
              ),
            }}
            field={props.slice.primary.stories_title}
          />

          <div className="col-[inner] grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {props.context.customerStories.slice(1, isShownAll ? undefined : lessNumber).map((customerStory, index) => (
              <Card customerStory={customerStory} key={index} />
            ))}
          </div>

          {!isShownAll &&
            props.context.customerStories.slice(1) > props.context.customerStories.slice(1, lessNumber) && (
              <Button
                className="col-[inner] mt-6 !h-12 justify-self-center !px-6 !text-16 md:mt-10"
                variant="fill"
                onClick={() => setIsShownAll(true)}
              >
                Show more
              </Button>
            )}
        </section>
      )}
    </Fragment>
  )
})
