import cn from 'classnames'
import {memo} from 'react'

export const Tab = memo(function Tab(props: {
  className?: string
  tabName: string
  activeTab: string | null
  isImageOnlyVariation: boolean
  onClick: (tabName: string) => void
}) {
  const isActive = props.tabName === props.activeTab

  return (
    <button
      className={cn(
        'h-9 rounded-lg px-4 font-medium leading-5 transition-[color,opacity] sm:h-10 sm:text-18 sm:leading-6 md:text-20',
        {
          'text-8f8d8b lg:hover:text-232f2a': !isActive && !props.isImageOnlyVariation,
          'text-42574f lg:hover:opacity-60': !isActive && props.isImageOnlyVariation,
          'bg-ffffff text-232f2a': isActive && !props.isImageOnlyVariation,
          'bg-5a7269 text-ecf0ef lg:hover:opacity-60': isActive && props.isImageOnlyVariation,
        },
      )}
      onClick={() => props.onClick(props.tabName)}
      type="button"
    >
      {props.tabName}
    </button>
  )
})
