import {useCallback, useMemo, useState} from 'react'

import {ConsentDtoV2} from '@getmoss/api-gateway'
import {useRouter} from 'next/router'
import {BookDemoSectionSliceDefaultPrimary} from 'prismicio-types'
import {useEffectOnce} from 'react-use'
import {DEFAULT_MARKET, DEFAULT_PRODUCT_TYPE, DEFAULT_SEGMENT_TYPE} from 'src/common/constants'
import {useMossApi} from 'src/common/hooks/useMossApi'

import {CalendlyStep} from './DemoForm/CalendlyStep'
import {PersonalDetailsValues, UserDetails} from './DemoForm/UserDetails'
import {isFilled} from '@prismicio/client'
import {EVENTS, track} from 'src/common/helpers'

enum DemoSectionSteps {
  DETAILS_STEP,
  CALENDLY_STEP,
}

type DemoFormDataType = PersonalDetailsValues & {
  demoId: string
}

export const DemoForm = ({
  first_name_field,
  last_name_field,
  form_title,
  company_email_field,
  company_name_field,
  phone_field,
  privacy_policy,
  continue_button_label,
  calendly_link,
  componentIndex,
  pageUid,
}: Omit<BookDemoSectionSliceDefaultPrimary, 'top_images' | 'title' | 'description'> & {
  componentIndex: number
  pageUid: string
}) => {
  const [demoId, setDemoId] = useState('')
  const [activeStep, setActiveStep] = useState<DemoSectionSteps>(DemoSectionSteps.DETAILS_STEP)
  const [formData, setFormData] = useState<Partial<DemoFormDataType> | null>(null)
  const {locale} = useRouter()
  const lang = locale?.split('-')[0].toUpperCase() ?? 'EN'
  const [consent, setConsent] = useState<ConsentDtoV2>()
  const calendlyUrl = isFilled.link(calendly_link) && calendly_link.url ? calendly_link.url : ''
  const {mossApi} = useMossApi()

  const consentUrl = useMemo(() => {
    const urls = consent?.urls ?? {}

    return urls[lang.toLowerCase()] ?? urls['en']
  }, [lang, consent])

  const trackStep = useCallback(
    (dataType: string) => {
      track({
        event: EVENTS.FORM_SUBMIT,
        dataType,
        pageName: pageUid,
        location: `component-demo-section-${componentIndex}`,
        mktgId: 'form-submission',
      })
    },
    [pageUid, componentIndex],
  )

  const onContinueUserDetails = useCallback(
    (data: PersonalDetailsValues, id: string) => {
      trackStep('demo-prospect')
      setDemoId(id)
      setFormData(data)
      setActiveStep(DemoSectionSteps.CALENDLY_STEP)
    },
    [trackStep],
  )

  const content = useMemo(() => {
    const prefilledData = {
      firstName: formData?.firstName || '',
      lastName: formData?.lastName || '',
      email: formData?.email || '',
    }

    switch (activeStep) {
      case DemoSectionSteps.DETAILS_STEP:
        return (
          <UserDetails
            formTitle={form_title}
            firstNameField={first_name_field[0]!}
            lastNameField={last_name_field[0]!}
            companyEmailField={company_email_field[0]!}
            companyNameField={company_name_field[0]!}
            phoneField={phone_field[0]!}
            privacyPolicy={privacy_policy}
            continueButtonLabel={continue_button_label}
            onContinue={onContinueUserDetails}
            consentUrl={consentUrl}
            consentId={consent?.id || ''}
          />
        )
      case DemoSectionSteps.CALENDLY_STEP:
        return (
          <CalendlyStep
            calendlyUrl={calendlyUrl}
            demoId={demoId}
            prefilledData={prefilledData}
            trackDemoBooked={trackStep}
          />
        )
      default:
        return null
    }
  }, [
    formData?.firstName,
    formData?.lastName,
    formData?.email,
    activeStep,
    form_title,
    first_name_field,
    last_name_field,
    company_email_field,
    company_name_field,
    phone_field,
    privacy_policy,
    continue_button_label,
    onContinueUserDetails,
    consentUrl,
    consent?.id,
    calendlyUrl,
    demoId,
    trackStep,
  ])

  useEffectOnce(() => {
    const getConsents = async () =>
      await mossApi.consentsController.findConsents({
        productType: DEFAULT_PRODUCT_TYPE,
        segmentType: DEFAULT_SEGMENT_TYPE,
        registrationCountry: DEFAULT_MARKET,
      })

    getConsents().then((res) => {
      const consents = res.data

      const mossPrivacyPolicy = consents.find(
        (element) => element.type === 'DPP' && element.category === 'PRODUCT_MOSS',
      )

      setConsent(mossPrivacyPolicy)
    })
  })

  return (
    <div className="col-span-6 flex min-h-[674px] flex-col gap-5 rounded-lg bg-moss-white p-5 shadow-elevation-1 lg:gap-10 lg:p-[50px]">
      {content}
    </div>
  )
}
