import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import {useLinkResolver} from 'prismicio'
import {CtaSlice} from 'prismicio-types'
import {memo} from 'react'
import {ActionButton} from 'src/common/ActionButton'
import {Button} from 'src/common/Button'

const Cta = (props: {slice: CtaSlice}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <section className="grid scroll-mt-16 grid-cols-main pt-20" id={String(props.slice.primary.id_anchor)}>
      <div className="col-[inner] mb-10 grid place-items-center gap-y-6 text-center md:mb-20">
        <h2 className="tg-h2">{props.slice.primary.title}</h2>

        <PrismicRichText
          components={{
            paragraph: ({children}) => <p className="tg-body-copy max-w-[474px]">{children}</p>,
          }}
          field={props.slice.primary.description}
        />
        <div className="flex gap-x-4">
          {isFilled.link(props.slice.primary.link) && !props.slice.primary.is_primary_link_intro && (
            <Button
              href={linkResolver(props.slice.primary.link)}
              {...(props.slice.primary.link.link_type === 'Web' && {
                target: props.slice.primary.link.target,
              })}
              variant="fill"
            >
              {props.slice.primary.link_label}
            </Button>
          )}

          {props.slice.primary.is_primary_link_intro && (
            <ActionButton kind="demo" data-page-location="bottom">
              {props.slice.primary.link_label}
            </ActionButton>
          )}
          {isFilled.link(props.slice.primary.secondary_link) && props.slice.primary.secondary_link_label && (
            <Button
              href={linkResolver(props.slice.primary.secondary_link, 'secondary-button-cta-0')}
              {...(props.slice.primary.secondary_link.link_type === 'Web' && {
                target: props.slice.primary.secondary_link.target,
              })}
              variant="outline"
              data-page-location="bottom"
              data-mktg-id="explore-product-button"
            >
              {props.slice.primary.secondary_link_label}
            </Button>
          )}
        </div>
      </div>

      <PrismicNextImage className="col-[inner]" field={props.slice.primary.image} />
    </section>
  )
}

export default memo(Cta)
