import {Container} from 'src/common/Container'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Swiper as SwiperClass} from 'swiper'
import {useCallback, useRef, useState} from 'react'
import {CustomerSpotlightSliceCarousel} from 'prismicio-types'
import {NoImageCard} from './NoImageCard'
import cn from 'classnames'
import dynamic from 'next/dynamic'

const NavigationButtons = dynamic(() => import('./NavigationButton').then((mod) => mod.NavigationButtons), {ssr: false})

export const CarouselVariation = ({
  slice: {
    variation,
    primary: {background_color, testimonials},
  },
}: {
  slice: CustomerSpotlightSliceCarousel
}): JSX.Element => {
  const [prevDisabled, setPrevDisabled] = useState(false)
  const [nextDisabled, setNextDisabled] = useState(false)
  let swiperInstance = useRef<SwiperClass | null>(null)

  const handleNext = useCallback(() => swiperInstance.current?.slideNext(), [swiperInstance])
  const handlePrev = useCallback(() => swiperInstance.current?.slidePrev(), [swiperInstance])

  return (
    <Container data-slice-variation={variation} backgroundColor={background_color || 'Moss White'}>
      <div className="grid-base relative max-w-[1200px] scroll-mt-16 px-5 md:px-10 lg:px-5 xl:px-0">
        <Swiper
          className="col-span-4 max-w-full md:col-span-12 md:w-full lg:col-span-10 lg:col-start-2 lg:!overflow-visible"
          spaceBetween={24}
          slidesPerView="auto"
          onSwiper={(swiper) => {
            swiperInstance.current = swiper
            swiper.slideNext()
          }}
          wrapperClass="ease-in-out duration-100"
          onSlideChange={() => {
            setPrevDisabled(!!swiperInstance.current?.isBeginning)
            setNextDisabled(!!swiperInstance.current?.isEnd)
          }}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide
              className={cn('w-full max-w-[1200px] rounded-xl', {
                'bg-beige-200': background_color === 'Moss White',
                'bg-moss-white': background_color === 'Beige/100',
              })}
              key={index}
            >
              <NoImageCard
                backgroundColor={background_color}
                cardBackgroundColor={testimonial.card_background_color}
                testimony={testimonial.testimony}
                author={testimonial.author}
                customerIcon={testimonial.customer_icon}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <NavigationButtons
          handlePrev={handlePrev}
          handleNext={handleNext}
          prevDisabled={prevDisabled}
          nextDisabled={nextDisabled}
        />
      </div>
    </Container>
  )
}
