import {ImageField, Content, KeyTextField, RichTextField} from '@prismicio/client'
import {useScroll} from 'framer-motion'
import {useCallback, useRef, useState} from 'react'
import {Tag} from 'src/common/Tag'
import cn from 'classnames'
import {Button} from 'src/common/Button'
import {Icon} from 'src/common/Icon'
import {FieldValues, useForm, UseFormHandleSubmit, UseFormRegister} from 'react-hook-form'
import {Dropdown} from './Dropdown'
import {PrivacyCheckbox} from './PrivacyCheckbox'
import {EVENTS, getUtmParams, track} from 'src/common/helpers'
import {PrismicNextImage} from '@prismicio/next'
import {useCookie, useEffectOnce, useWindowSize} from 'react-use'
import {useSearchParams} from 'next/navigation'

declare global {
  interface Window {
    growsumo: {
      data: {[key: string]: string}
      createSignup: () => void
    }
  }
}

type InputField = {
  [x: string]: string
}

type DropdownField = {
  [x: string]: {
    value: string
    label: string
  }
}

export const SignupSection = ({
  backgroundUrl,
  fields,
  tag,
  title,
  submitLabel,
  hubspotFormId,
  privacyPolicyLabel,
  pageUid,
  successSection,
  mobileBackgroundUrl,
}: {
  backgroundUrl: ImageField
  mobileBackgroundUrl: ImageField
  fields: Content.AiCampaignSlice['primary']['sign_up_card_fields']
  tag: KeyTextField
  title: KeyTextField
  submitLabel?: KeyTextField
  hubspotFormId: string
  privacyPolicyLabel: RichTextField
  pageUid: string
  successSection: Content.AiCampaignSlice['primary']['success_section']
}) => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: {isValid, isSubmitted},
  } = useForm({
    shouldUseNativeValidation: true,
  })

  const containerRef = useRef<HTMLDivElement>(null)
  const {width} = useWindowSize()
  const isMobile = width < 768
  const [backgroundSource, setBackgroundSource] = useState<string>('')
  const [errors, setErrors] = useState<{fieldName: string}[] | null>(null)
  const [isInCenter, setIsInCenter] = useState(false)
  const [hutk] = useCookie('hubspotutk')
  const params = useSearchParams()

  const submit = useCallback(
    async (data: {[x: string]: InputField | DropdownField}) => {
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/25068079/${hubspotFormId}`

      const dataFields = fields.map((field) => {
        const value = data[field.name!].value || data[field.name!]
        return {
          name: field.name!,
          value: value,
        }
      })

      const utmParams = getUtmParams(params)

      const preparedData = {
        submittedAt: new Date().getTime(),

        fields: [
          ...dataFields,
          ...utmParams,
          {
            name: 'privacyPolicy',
            value: true,
          },
        ],

        context: {
          pageName: pageUid,
          pageUri: window.location.href,
          ...(hutk?.length && {hutk}),
        },
      }

      if (window?.growsumo) {
        fields.forEach((field) => {
          if (field.name!) {
            // @ts-ignore -- expect error
            window.growsumo.data[field.name!] = data[field.name!].value || data[field.name!]
          }
        })

        // @ts-ignore -- expect error
        window.growsumo.data.customer_key = data.email
      }

      try {
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(preparedData),
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (!response.ok) {
          const parsedResponse = await response.json()

          const errorsWithFieldNames = parsedResponse.errors.map((error: {message: string; type: string}) => {
            const match = error.message.match(/Error in 'fields\.(\w+)'/)
            const fieldName = match ? match[1] : 'unknown'
            return {fieldName}
          })

          return setErrors(errorsWithFieldNames)
        }

        if (window.growsumo) {
          window.growsumo.createSignup()
        }

        setErrors(null)

        track({
          event: EVENTS.FORM_SUBMIT,
          location: 'component-2',
          pageName: pageUid,
          dataType: 'webinar-sign-up',
          mktgId: 'form-submission-button',
        })
      } catch (error) {
        console.error(error)
      }

      reset()
    },
    [fields, hubspotFormId, pageUid, reset, hutk, params],
  )

  const {scrollYProgress} = useScroll({
    target: containerRef,
    offset: ['start center', 'end end'],
  })

  scrollYProgress.on('change', (latest) => {
    if (latest > 0) {
      setIsInCenter(true)
    }

    if (latest === 0) {
      setIsInCenter(false)
    }
  })

  useEffectOnce(() => {
    setBackgroundSource(isMobile ? mobileBackgroundUrl.url! : backgroundUrl.url!)
  })

  return (
    <section
      className={cn('snap-y scroll-pt-6 py-6 transition-all duration-700 md:px-0', {
        'px-3 md:px-6': isInCenter,
      })}
      ref={containerRef}
      id="sign-up-section"
    >
      <div
        className="flex w-full flex-col items-center justify-between gap-6 rounded-2xl bg-cover px-3 py-[64px] xl:px-8"
        style={{backgroundImage: `url(${backgroundSource})`}}
        id="sign-up-section"
      >
        <Tag color="brand-purple" label={tag} />
        <div className="flex h-full flex-col items-center justify-center gap-4 xl:gap-12">
          <div className="flex max-w-[536px] flex-col items-center gap-8">
            <h2 className="text-h4 md:text-h2 text-center text-moss-black">{title}</h2>

            <div className="flex w-full max-w-[604px] justify-center">
              {isSubmitted && !errors ? (
                <SuccessMessage handleClose={reset} successSection={successSection} />
              ) : (
                <Form
                  fields={fields}
                  control={control}
                  submit={submit}
                  privacyPolicyLabel={privacyPolicyLabel}
                  pageUid={pageUid}
                  submitLabel={submitLabel!}
                  handleSubmit={handleSubmit}
                  register={register}
                  isValid={isValid}
                  errors={errors}
                />
              )}
            </div>
          </div>
        </div>
        <Icon name="logo" className="mt-12 h-[26px] w-[112px] text-moss-white md:mt-8" />
      </div>
    </section>
  )
}

const SuccessMessage = ({
  handleClose,
  successSection,
}: {
  handleClose: VoidFunction
  successSection: Content.AiCampaignSlice['primary']['success_section']
}) => {
  return (
    <div className="flex min-h-[616px] max-md:items-center md:min-h-[364px]">
      <div className="mt-4 flex h-[290px] w-full flex-col rounded-2xl bg-moss-white p-4 shadow-elevation-1 md:w-[467px]">
        <button className="h-5 w-5 self-end" onClick={handleClose}>
          <Icon name="cross" className="h-full w-full text-[#232F2A/50%]" />
        </button>
        <div className="flex flex-col items-center gap-10 pt-7">
          <PrismicNextImage field={successSection[0]?.success_asset} className="h-18 w-18 rounded-xl" />
          <div className="flex flex-col gap-2">
            <h4 className="text-h4 text-center text-moss-black">{successSection[0]?.success_title}</h4>
            <p className="text-center text-18 leading-[26px] tracking-[.18px] text-moss-black">
              {successSection[0]?.success_subtitle}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

type FormProps = {
  fields: Content.AiCampaignSlice['primary']['sign_up_card_fields']
  control: any
  submit: any
  privacyPolicyLabel: RichTextField
  pageUid: string
  submitLabel: KeyTextField
  handleSubmit: UseFormHandleSubmit<FieldValues, undefined>
  register: UseFormRegister<FieldValues>
  isValid: boolean
  errors: {fieldName: string}[] | null
}

const Form = ({
  fields,
  control,
  submit,
  privacyPolicyLabel,
  pageUid,
  submitLabel,
  handleSubmit,
  register,
  isValid,
  errors,
}: FormProps) => {
  return (
    <form
      className="flex min-w-full flex-col gap-4 max-md:px-2 md:grid md:grid-cols-2"
      onSubmit={handleSubmit(submit)}
      data-hs-do-not-collect
    >
      {fields.map((field, index) => {
        const select = field.type === 'Dropdown'

        if (select) {
          return <Dropdown key={index} control={control} name={field.name!} placeholder={field.placeholder!} />
        }

        return (
          <input
            key={index}
            className={cn(
              `
                col-span-1
                max-h-14
                w-full
                rounded-lg
                border
                border-grey-150
                px-3
                py-[18px]
                text-16
                font-normal
                leading-5
                tracking-[.16px]
                placeholder:text-grey-300
                hover:bg-grey-110
                focus:outline-none
                `,
              {
                'outline-1 outline-[red] outline -outline-offset-1': errors?.some((error) => {
                  return error.fieldName === field.name
                }),
              },
            )}
            type={field.type || 'text'}
            placeholder={field.placeholder!}
            {...register(field.name!, {
              required: true,
            })}
          />
        )
      })}
      <Button
        variant="brand-campaign"
        type="submit"
        className={cn('col-span-full my-4 md:mt-0', {
          'text-beige-100/[30%] pointer-events-none': !isValid,
          'hover:bg-gradient-to-r hover:from-moss-white/[10%] hover:to-moss-white/[10%]': isValid,
        })}
        data-type="webinar-sign-up"
        data-mktg-id="form-submission-button"
        data-page-name={pageUid}
        data-page-location="component-2"
      >
        {submitLabel}
      </Button>
      <PrivacyCheckbox control={control} label={privacyPolicyLabel!} />
    </form>
  )
}
