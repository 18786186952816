import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {AgendaSection} from './components/AgendaSection'
import {TypewriterEffectSection} from './components/TypewriterSection'
import {SpeakersSection} from './components/SpeakersSection'
import {SignupSection} from './components/SignupSection/SignupSection'
import {HeroSection} from './components/HeroSection'
import {Context} from 'src/common/types'
import {Gradient} from 'src/common/Gradient'
import {CSSProperties} from 'react'

/**
 * Props for `AiCampaign`.
 */
export type AiCampaignProps = SliceComponentProps<Content.AiCampaignSlice>

/**
 * Component for "AiCampaign" Slices.
 */
const AiCampaign = ({slice, context}: AiCampaignProps & {context: Context}): JSX.Element => {
  const heroData = slice.primary.hero[0]
  const eventCardData = slice.primary.event_card[0]
  const registerButtonData = slice.primary.register_button[0]
  const expertsSection = slice.primary.experts_section[0]
  const speakerCardsData = slice.primary.speaker_card
  const agendaData = slice.primary.agenda_section[0]
  const agendaTopics = slice.primary.agenda_topics

  return (
    <div data-slice-type={slice.slice_type} data-slice-variation={slice.variation} className="relative snap-mandatory">
      <div className="absolute inset-0 h-[490px] w-full  px-3 pt-3  md:h-[475px] md:px-4 md:pt-4 lg:h-[857px] lg:px-6 lg:pt-6">
        <div
          className="relative h-full w-full rounded-t-2xl bg-cover bg-center md:rounded-t-3xl"
          style={
            {
              backgroundImage: `url(${heroData?.backgroundimage.url})`,
              '--webkit-transform': 'translate3d(0,0,0)',
            } as CSSProperties
          }
        />
        <Gradient side="bottom" fromColor="Beige/100" className="-z-10" />
      </div>
      <HeroSection heroData={heroData} />

      <SpeakersSection
        speakerCardsData={speakerCardsData}
        eventCardData={eventCardData}
        registerButtonData={registerButtonData}
        expertsSection={expertsSection}
        pageUid={context.pageUid}
      />

      <AgendaSection
        agendaData={agendaData}
        agendaTopics={agendaTopics}
        registerButtonData={registerButtonData}
        pageUid={context.pageUid}
      />

      <TypewriterEffectSection
        icon1={slice.primary.quotation[0]?.icon}
        title1={slice.primary.quotation[0]?.title}
        description1={slice.primary.quotation[0]?.description}
        icon2={slice.primary.quotation[1]?.icon}
        title2={slice.primary.quotation[1]?.title}
        description2={slice.primary.quotation[1]?.description}
        backgroundImageUrl={slice.primary.quotation_background_image.url!}
      />

      <SignupSection
        backgroundUrl={slice.primary.sign_up_background}
        fields={slice.primary.sign_up_card_fields}
        title={slice.primary.sign_up_card_title}
        tag={slice.primary.sign_up_card_tag}
        submitLabel={slice.primary.register_button[0]?.register_button_label}
        hubspotFormId={slice.primary.sign_up_hubspot_form_id!}
        privacyPolicyLabel={slice.primary.privacy_policy_disclaimer}
        pageUid={context.pageUid}
        successSection={slice.primary.success_section}
        mobileBackgroundUrl={slice.primary.signup_background_mobile}
      />
    </div>
  )
}

export default AiCampaign
