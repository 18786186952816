import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {Container} from 'src/common/Container'
import {PrismicNextImage} from '@prismicio/next'
import {TextLink} from 'src/common/TextLink'
import {useWindowSize} from 'react-use'
import {SwiperClass} from 'swiper/react'
import {useState} from 'react'
import {Pagination} from 'swiper/modules'
import dynamic from 'next/dynamic'
import {Context} from 'src/common/types'
const SwiperNavButtons = dynamic(() => import('src/common/SwiperNavButtons'), {ssr: false})
const ConditionalSliderWrapper = dynamic(() => import('./ConditionalSliderWrapper'), {ssr: false})

/**
 * Props for `FourGrid`.
 */
export type FourGridProps = SliceComponentProps<Content.FourGridSlice>

/**
 * Component for "FourGrid" Slices.
 */
const FourGrid = ({slice, index: componentIndex, context}: FourGridProps & {context: Context}): JSX.Element => {
  const {width} = useWindowSize()
  const isMobile = width < 768
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null)

  return (
    <Container
      backgroundColor={slice.primary.background_color}
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div className="grid w-full max-w-[1200px] grid-cols-1 gap-12 px-5 lg:gap-15 xl:px-0" data-aos="fade-up">
        <div className="flex justify-between">
          <h3 className="max-w-[251px] text-24 font-medium md:max-w-[690px] lg:text-32">{slice.primary.title}</h3>
          {isMobile && <SwiperNavButtons swiperInstance={swiperInstance} />}
        </div>
        <ConditionalSliderWrapper
          isMobile={isMobile}
          modules={[Pagination]}
          slidesPerView={2.1}
          slidesPerGroup={1}
          spaceBetween={16}
          className="w-full !overflow-visible"
          data-aos="fade-up"
          onSwiper={(swiper) => setSwiperInstance(swiper)}
        >
          {slice.items.map((element, index) => (
            <div className="flex flex-col content-between gap-6" key={index}>
              <div className="flex flex-col gap-4">
                <div className="h-[30px] w-[150px] overflow-hidden">
                  <PrismicNextImage field={element.logo} />
                </div>
                <p className="text-16 leading-6 text-grey-550 antialiased">{element.description}</p>
              </div>
              <TextLink
                label={element.link_label!}
                variation="small"
                field={element.link_url}
                mktgId="news-button"
                location={`component-${componentIndex}-carousel-${index}`}
                pageName={context.pageUid}
                dataType={`slide-${index}`}
              />
            </div>
          ))}
        </ConditionalSliderWrapper>
      </div>
    </Container>
  )
}

export default FourGrid
