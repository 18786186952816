import {Content, ImageField, isFilled, KeyTextField} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import cn from 'classnames'

type AssetsProps = {
  assets: Content.BannerV2Slice['primary']['assets']
  variation: Content.BannerV2Slice['variation']
}

export const Assets = ({assets, variation}: AssetsProps) => {
  const assetsCount = assets.length
  const isImageOnly = variation === 'singleImage'

  return (
    <div
      className={cn('flex flex-col gap-4 lg:flex-row lg:items-center', {
        'lg:gap-12': assetsCount === 2,
        'lg:gap-8': assetsCount === 3,
      })}
    >
      {isImageOnly ? (
        <ImageOnly image={assets[0]!.image} />
      ) : (
        (assets as Content.BannerV2SliceDefaultPrimaryAssetsItem[]).map((asset, index) => (
          <ImageWithText image={asset.image} title={asset.title} subtitle={asset.description} key={index} />
        ))
      )}
    </div>
  )
}

const ImageOnly = ({image}: {image: ImageField}) => {
  return <PrismicNextImage field={image} className="aspect-video w-full rounded-xl" />
}

const ImageWithText = ({image, title, subtitle}: {image: ImageField; title: KeyTextField; subtitle: KeyTextField}) => {
  return (
    <div className="grid w-full grid-cols-7 gap-6 lg:flex lg:flex-col lg:self-start">
      <PrismicNextImage
        field={image}
        className="col-span-3 max-h-[127px] w-min rounded-md md:max-h-[277px] md:max-lg:col-span-2"
      />
      <div className="col-span-4 flex h-full flex-col justify-center gap-1 lg:justify-start">
        {isFilled.keyText(title) && (
          <span className="text-18 font-medium leading-[26px] text-accent-neon">{title}</span>
        )}
        {isFilled.keyText(subtitle) && <span className="text-body-s text-moss-white/[80%]">{subtitle}</span>}
      </div>
    </div>
  )
}
