import {Content, isFilled} from '@prismicio/client'
import {TextLink} from 'src/common/TextLink'
import {HighlightSectionProps} from '.'
import {HighlightsCard} from './HighlightsCard'
import {Container} from 'src/common/Container'
import {Context} from 'src/common/types'
import {Simplify} from 'prismicio-types'

export type DefaultVarProps = HighlightSectionProps & {
  context: Context
  slice: Omit<Content.HighlightSectionSlice, 'primary'> & {
    primary: Simplify<Content.HighlightSectionSliceDefaultPrimary>
    items: Simplify<Content.HighlightSectionSliceDefaultItem>[]
  }
}

export const DefaultVar = ({slice, context: {pageUid}, index: componentIndex}: DefaultVarProps): JSX.Element => {
  return (
    <Container
      backgroundColor={slice.primary.background_color || 'Beige/100'}
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div className="grid w-full max-w-[1200px] grid-cols-4 gap-4 gap-y-12 px-5 md:grid-cols-12 md:gap-x-6 xl:px-0">
        <div className="col-span-full grid grid-cols-4 gap-4 gap-y-12 md:grid-cols-12 md:gap-6 md:gap-y-[120px]">
          <div className="col-span-4 flex flex-col gap-3  md:col-span-5" data-aos="fade-up">
            <h2 className="text-32 font-medium leading-10 text-moss-black md:text-40 md:leading-12">
              {slice.primary.title}
            </h2>
            <p className="text-16 leading-6 tracking-[.16px] text-grey-550">{slice.primary.subtext}</p>
          </div>
          <div
            className="col-span-4 flex flex-col gap-6 md:col-span-10 md:grid md:grid-cols-10 md:gap-x-6"
            data-aos="fade-up"
          >
            {slice.items.map((highlightCard, index) => (
              <div className="col-span-4 flex flex-col gap-6 md:col-span-5" key={index}>
                <HighlightsCard {...highlightCard} />
              </div>
            ))}
          </div>
        </div>
        {isFilled.keyText(slice.primary.text_link_label) && (
          <div
            className="col-span-4"
            data-aos="single-line-fade-up"
            data-aos-delay={`${(slice.items.length - 1) * 300}`}
          >
            <TextLink
              label={slice.primary.text_link_label}
              field={slice.primary.text_link_url}
              mktgId="anchor-text-link"
              location={`component-${componentIndex}`}
              pageName={pageUid}
              dataType="section-0"
            />
          </div>
        )}
      </div>
    </Container>
  )
}
