import {isFilled, KeyTextField} from '@prismicio/client'

export const FormProgressBar = ({current, total, text}: {current: number; total: number; text?: KeyTextField}) => (
  <div className="flex w-full flex-col items-center justify-center gap-2">
    {isFilled.keyText(text) && (
      <p className="text-body-s text-moss-black">
        {text} {current + 1} / {total}
      </p>
    )}
    <div className="relative h-2.5 w-full max-w-[400px] rounded-full bg-grey-120">
      <div
        className="absolute h-full rounded-full bg-moss-black transition-all duration-700"
        style={{width: `${((current + 1) / total) * 100}%`}}
      />
    </div>
  </div>
)
