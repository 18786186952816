import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {useCreateHubspotForm} from 'src/common/hooks'
import {EVENTS, track} from 'src/common/helpers'
import {Context} from 'src/common/types'

/**
 * Props for `HubSpotForm`.
 */
export type HubSpotFormProps = SliceComponentProps<Content.HubSpotFormSlice>

/**
 * Component for "HubSpotForm" Slices.
 */
const HubSpotForm = ({slice, index, context}: HubSpotFormProps & {context: Context}) => {
  useCreateHubspotForm({
    region: 'eu1',
    portalId: slice.primary.portal_id!,
    formId: slice.primary.form_id!,
    target: '#hubspot-form-wrapper',
    onFormSubmit: () => {
      track({
        location: `component-${index}-hubspot-form`,
        dataType: slice.primary.form_type!,
        event: EVENTS.FORM_SUBMIT,
        mktgId: 'form-submission-button',
        pageName: context.pageUid,
      })
    },
  })

  return <div className={slice.primary.container_css_classes || ''} id="hubspot-form-wrapper" />
}

export default HubSpotForm
