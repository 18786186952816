import cn from 'classnames'
import {memo} from 'react'

export const Tab = memo(function Tab(props: {
  className?: string
  tabName: string
  activeTab: string | null
  onClick: (tabName: string) => void
}) {
  const isActive = props.tabName === props.activeTab

  return (
    <button
      className={cn(
        'h-10 rounded-lg px-4 leading-5 transition-[background-color,color,opacity,box-shadow] sm:text-14',
        {
          'text-232f2a hover:text-3a5f51 hover:shadow-md bg-ffffff': !isActive,
          'bg-3a5f51 text-ffffff hover:bg-456e5e': isActive,
        },
      )}
      onClick={() => props.onClick(props.tabName)}
      type="button"
    >
      {props.tabName}
    </button>
  )
})
