import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {PressMaterialsSlice} from 'prismicio-types'

const PressMaterials = (props: {slice: PressMaterialsSlice}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <section
      className="grid scroll-mt-16 grid-cols-main gap-y-24 bg-ffffff pb-14 pt-24 md:py-20 lg:gap-y-28 lg:pb-30 lg:pt-24"
      id={String(props.slice.primary.id_anchor)}
    >
      <PrismicRichText
        components={{
          heading2: ({children}) => (
            <h2 className="col-[inner] text-28 leading-8 -tracking-common text-1e2a25">{children}</h2>
          ),
        }}
        field={props.slice.primary.title}
      />

      <div className="col-[inner] grid auto-cols-fr gap-x-6 gap-y-18 lg:grid-flow-col">
        {props.slice.items.map((item, index) => (
          <div
            className={cn(
              'relative grid content-between rounded-[20px] px-6 py-8 lg:min-h-[184px] lg:px-8',
              {'bg-e6efec': item.color === 'Light Green'},
              {'bg-5a7269': item.color === 'Green'},
            )}
            key={index}
          >
            <PrismicNextImage
              alt=""
              className="absolute -top-18 left-1/2 max-h-24 w-max -translate-x-1/2 object-contain object-left"
              field={item.image}
            />

            <PrismicRichText
              components={{
                heading3: ({children}) => (
                  <h3
                    className={cn(
                      'text-28 leading-8 -tracking-common',
                      {'text-232f2a': item.color === 'Light Green'},
                      {'text-ffffff': item.color === 'Green'},
                    )}
                  >
                    {children}
                  </h3>
                ),
              }}
              field={item.title}
            />

            <Link
              className={cn(
                'text-14 font-medium transition-colors lg:text-16',
                {'text-456e5e hover:text-6c9d8b': item.color === 'Light Green'},
                {'text-cdd8d5 hover:text-f0fffb': item.color === 'Green'},
              )}
              href={linkResolver(item.link)}
              {...(isFilled.link(item.link) && item.link.link_type === 'Web' && {target: item.link.target})}
            >
              {item.link_label}
            </Link>
          </div>
        ))}
      </div>
    </section>
  )
}

export default PressMaterials
