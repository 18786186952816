'use client'
import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import dynamic from 'next/dynamic'
import {useRouter} from 'next/router'

const SaasBenchmarkPage = dynamic({
  loader: () => import('@getmoss/growth-frontend').then((mod) => mod.SaasBenchmarkPage),
  ssr: false,
})

/**
 * Props for `GrowthAppIntegration`.
 */
export type GrowthAppIntegrationProps = SliceComponentProps<Content.GrowthAppIntegrationSlice>

/**
 * Component for "GrowthAppIntegration" Slices.
 */

const GrowthAppIntegration = (): JSX.Element => {
  const {locale} = useRouter()
  return <SaasBenchmarkPage language={locale!.split('-')[0]} />
}

export default GrowthAppIntegration
