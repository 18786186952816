import cn from 'classnames'
import NextLink, {LinkProps} from 'next/link'
import {memo, ReactNode} from 'react'

export const Link = memo(function Link(
  props: {
    children: ReactNode
    className?: string
    isInverted?: boolean
    target?: string
  } & LinkProps,
) {
  return (
    <NextLink
      {...(Object.fromEntries(
        Object.entries(props).filter(([key]) => !['className', 'isInverted'].includes(key)),
      ) as LinkProps)}
      className={cn(
        'transition-colors',
        {'hover:text-72716e active:text-72716e': !props.isInverted},
        {'text-ffffff hover:text-9eaaa5 active:text-9eaaa5': props.isInverted},
        props.className,
      )}
    />
  )
})
