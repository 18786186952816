import {
  AlternateLanguage,
  ClientConfig,
  LinkField,
  isFilled,
  createClient as prismicCreateClient,
} from '@prismicio/client'
import * as prismicNext from '@prismicio/next'
import {useSearchParams} from 'next/navigation'
import pRetry from 'p-retry'
import {AllDocumentTypes, PageDocument, PageDocumentData} from 'prismicio-types'
import sm from 'slicemachine.config.json'
import {useGetOriginPageParam} from 'src/common/hooks/useGetPricingFormHandler/useGetPricingFormHandler'
const repositoryName = sm.repositoryName

function sleep(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

const baseUrl = 'https://www.getmoss.com'
const defaultLocale = 'en-us'

export const useLinkResolver = () => {
  const searchParams = useSearchParams()
  const query = Object.fromEntries(searchParams)
  const originPage = useGetOriginPageParam()

  const linkResolver = (link: LinkField | AlternateLanguage | AllDocumentTypes, element?: string) => {
    if ('link_type' in link && !isFilled.link(link)) {
      return {
        pathname: '/',
        query,
      }
    }

    if ('link_type' in link && link.link_type === 'Media') {
      return {
        pathname: link.url || '/',
        query,
      }
    }

    if ('link_type' in link && link.link_type === 'Web') {
      const url = new URL(link.url)
      const urlWithoutQuery = link.url?.split('?')[0]
      const queryFromLink = Object.fromEntries(url.searchParams.entries())
      queryFromLink['origin-page'] = originPage

      return {
        pathname: urlWithoutQuery,
        query: {
          ...query,
          ...queryFromLink,
          ...(element ? {element} : {}),
        },
      }
    }

    if (link.type === 'home_page') {
      return {
        pathname: link.lang === defaultLocale ? '/' : `/${link.lang}`,
        query,
      }
    }

    if (link.type === '404_page') {
      return {
        pathname: `${link.lang === defaultLocale ? '' : `/${link.lang}`}/404`,
        query,
      }
    }

    if (
      link.type === 'article' &&
      'data' in link &&
      isFilled.contentRelationship((link.data as PageDocumentData).parent_page)
    ) {
      const pathname = `${link.lang === defaultLocale ? '' : `/${link.lang}`}/${
        ((link.data as PageDocumentData).parent_page as unknown as PageDocument).uid
      }/${link.uid}`

      return {
        pathname,
        query,
      }
    }

    if (
      link.type === 'customer_story' &&
      'data' in link &&
      isFilled.contentRelationship((link.data as PageDocumentData).parent_page)
    ) {
      const pathname = `${link.lang === defaultLocale ? '' : `/${link.lang}`}/${
        ((link.data as PageDocumentData).parent_page as unknown as PageDocument).uid
      }/${link.uid}`

      return {
        pathname,
        query,
      }
    }

    if (
      link.type === 'page' &&
      'data' in link &&
      isFilled.contentRelationship((link.data as PageDocumentData).parent_page)
    ) {
      const pathname = `${link.lang === defaultLocale ? '' : `/${link.lang}`}/${
        ((link.data as PageDocumentData).parent_page as unknown as PageDocument).uid
      }/${link.uid}`

      return {
        pathname,
        query,
      }
    }

    if (link.type === 'page') {
      const pathname = `${link.lang === defaultLocale ? '' : `/${link.lang}`}/${link.uid}`

      return {
        pathname,
        query,
      }
    }

    if (link.type === 'customer_story' && 'url' in link) {
      return {
        pathname: link.url || '/',
        query,
      }
    }

    return {
      pathname: '/',
      query,
    }
  }

  return {linkResolver}
}

export const linkResolver = (link: LinkField | AlternateLanguage | AllDocumentTypes) => {
  if ('link_type' in link && !isFilled.link(link)) {
    return '/'
  }

  if ('link_type' in link && link.link_type === 'Media') {
    return '/'
  }

  if ('link_type' in link && link.link_type === 'Web') {
    // As /guide/ routes are hosted in a WordPress app we need to use it as an external link to avoid client routing
    // Leaving this in a unique block to keep a track of the scenario
    if (link.url?.includes('guide')) {
      return link.url
    }

    return link.url?.replace(baseUrl, '') || '/'
  }

  if (link.type === 'home_page') {
    return link.lang === defaultLocale ? '/' : `/${link.lang}`
  }

  if (link.type === '404_page') {
    return `${link.lang === defaultLocale ? '' : `/${link.lang}`}/404`
  }

  if (
    link.type === 'article' &&
    'data' in link &&
    isFilled.contentRelationship((link.data as PageDocumentData).parent_page)
  ) {
    return `${link.lang === defaultLocale ? '' : `/${link.lang}`}/${
      ((link.data as PageDocumentData).parent_page as unknown as PageDocument).uid
    }/${link.uid}`
  }

  if (
    link.type === 'customer_story' &&
    'data' in link &&
    isFilled.contentRelationship((link.data as PageDocumentData).parent_page)
  ) {
    return `${link.lang === defaultLocale ? '' : `/${link.lang}`}/${
      ((link.data as PageDocumentData).parent_page as unknown as PageDocument).uid
    }/${link.uid}`
  }

  if (
    link.type === 'page' &&
    'data' in link &&
    isFilled.contentRelationship((link.data as PageDocumentData).parent_page)
  ) {
    return `${link.lang === defaultLocale ? '' : `/${link.lang}`}/${
      ((link.data as PageDocumentData).parent_page as unknown as PageDocument).uid
    }/${link.uid}`
  }

  if (link.type === 'page') {
    return `${link.lang === defaultLocale ? '' : `/${link.lang}`}/${link.uid}`
  }

  return '/'
}

const routes: ClientConfig['routes'] = [
  {
    type: 'home_page',
    path: '/:lang?',
  },

  {
    type: '404_page',
    path: '/:lang?/404',
  },

  {
    type: 'article',
    resolvers: {
      magazine: 'parent_page',
    },
    path: '/:lang?/:magazine/:uid',
  },

  {
    type: 'customer_story',
    resolvers: {
      stories: 'parent_page',
    },
    path: '/:lang?/:stories/:uid',
  },

  {
    type: 'page',
    path: '/:lang?/:uid',
  },

  {
    type: 'page',
    resolvers: {
      parent: 'parent_page',
    },
    path: '/:lang?/:parent?/:uid',
  },
]

export const createClient = ({previewData, req, ...config}: prismicNext.CreateClientConfig = {}) => {
  const client = prismicCreateClient(repositoryName, {
    ...config,
    routes,

    fetch: async (url, options) => {
      try {
        const response = await pRetry(() => fetch(url, options), {
          retries: 50,
          maxRetryTime: 100000,

          onFailedAttempt: async (error) => {
            console.warn({error}, 'Retrying prismic client network request')
            await sleep(500)
          },
        })

        return response
      } catch (error) {
        console.error({error}, 'Prismic client network request failed too many times')
        throw new Error('Prismic client network request failed too many times')
      }
    },
  })

  prismicNext.enableAutoPreviews({client, previewData, req})
  return client
}
