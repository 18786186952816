import {KeyTextField, RichTextField} from '@prismicio/client'
import {useCallback, useState} from 'react'
import {Question} from 'slices/SurveySection/components/Question'
import {QuizDataType} from 'slices/SurveySection/helpers'
import {FormProgressBar} from 'slices/SurveySection/components/FormProgressbar'
import {LeadGeneration} from './Lead'
import {
  SurveySectionSliceDefaultPrimaryFormFieldsItem,
  SurveySectionSliceDefaultPrimaryResultsItem,
} from 'prismicio-types'

type QuizProps = {
  questions: QuizDataType
  onContinue: () => void
  funnelScore: number
  funnelScoreHandler: (newValue: number) => void
  progressbarText: KeyTextField
  formFields: SurveySectionSliceDefaultPrimaryFormFieldsItem[]
  formTitle: KeyTextField
  pageUid: string
  hubspotFormId: KeyTextField
  privacyPolicyLabel: RichTextField
  submitLabel: KeyTextField
  formDescription: KeyTextField
  contnueLabel: KeyTextField
  outcomeOptions: SurveySectionSliceDefaultPrimaryResultsItem[]
}

enum QuizSteps {
  QUESTIONS,
  FORM,
}

export const Quiz = ({
  questions,
  onContinue,
  funnelScore,
  funnelScoreHandler,
  contnueLabel,
  progressbarText,
  formTitle,
  formFields,
  hubspotFormId,
  formDescription,
  privacyPolicyLabel,
  submitLabel,
  pageUid,
  outcomeOptions,
}: QuizProps) => {
  const [activeStep, setActiveStep] = useState(QuizSteps.QUESTIONS)
  const [activeQuestion, setActiveQuestion] = useState(0)

  const nextStep = useCallback(() => setActiveStep((prev) => prev + 1), [])

  const handleOptionClick = (index: number, selectedOption: number) => {
    if (index === questions.length - 1) {
      nextStep()
    } else {
      setActiveQuestion((prevIndex) => prevIndex + 1)
      funnelScoreHandler(selectedOption)
    }
  }

  const questionComponents = questions.map((question, index) => (
    <Question
      key={index}
      question={question.question}
      answers={question.answers}
      onClickHandler={(selectedOption: number) => handleOptionClick(index, selectedOption)}
      name={`question-${index}`}
      buttonLabel={contnueLabel}
      pageUid={pageUid}
      index={index}
    />
  ))

  return (
    <div className="flex flex-col items-center justify-center gap-6">
      <FormProgressBar current={activeQuestion} total={questions.length} text={progressbarText} />
      {activeStep === QuizSteps.QUESTIONS ? (
        questionComponents[activeQuestion]
      ) : (
        <LeadGeneration
          funnelScore={funnelScore}
          fields={formFields}
          title={formTitle}
          hubspotFormId={hubspotFormId}
          privacyPolicyLabel={privacyPolicyLabel}
          submitLabel={submitLabel}
          description={formDescription}
          onContinue={onContinue}
          pageUid={pageUid}
          outcomeOptions={outcomeOptions}
        />
      )}
    </div>
  )
}
