import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import dynamic from 'next/dynamic'
import {ChangeEvent, useEffect, useRef, useState} from 'react'
import {Container} from 'src/common/Container'
import {useDebounce, useIntersection} from 'react-use'
import {EVENTS, track} from 'src/common/helpers'
import {AnimatePresence, motion, useScroll} from 'framer-motion'
import {ToolCard} from './ToolCard'
import useGetIntegrations from './userGetIntegrations'
import {DesktopStickySearchBox} from './DesktopStickySeachBox'
import {OPACITY_0} from 'src/common/constants'

const MobileStickySearchBox = dynamic(
  () => import('./MobileStickySearchBox').then((components) => components.MobileStickySearchBox),
  {ssr: false},
)

const PROGRESS_THRESHOLD = 0.985

/**
 * Props for `IntegrationsContainer`.
 */
export type IntegrationsContainerProps = SliceComponentProps<Content.IntegrationsContainerSlice> & {
  context: {pageUid: string}
}

/**
 * Component for "IntegrationsContainer" Slices.
 */
const IntegrationsContainer = ({slice, context, index: componentIndex}: IntegrationsContainerProps): JSX.Element => {
  const [searchText, setSearchText] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(0)
  const integrationsArr = useGetIntegrations(slice.primary)
  const [filteredIntegrations, setFilteredIntegrations] = useState(integrationsArr)
  const [loading, setLoading] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const searchBoxRef = useRef<HTMLDivElement | null>(null)
  const [shouldRenderRelative, setShouldRenderRelative] = useState(true)
  const categoryTimeout = useRef<NodeJS.Timeout | null>(null)

  const containerProgress = useScroll({
    target: containerRef,
    offset: ['start start', 'end end'],
  })

  containerProgress.scrollYProgress.on('change', (event) => {
    if (searchIntersection?.isIntersecting && shouldRenderRelative) {
      setShouldRenderRelative(false)
    }

    if (!searchIntersection?.isIntersecting && !shouldRenderRelative && event < PROGRESS_THRESHOLD) {
      setShouldRenderRelative(true)
    }

    if (event >= PROGRESS_THRESHOLD) {
      setShouldRenderRelative(false)
    }
  })

  const searchIntersection = useIntersection(searchBoxRef, {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 0.01,
  })

  const changeCategory = (categoryIndex: number) => {
    containerRef.current?.scrollIntoView({behavior: 'smooth'})

    categoryTimeout.current = setTimeout(() => setSelectedCategory(categoryIndex), 600)
  }

  const filterBySearch = () => {
    if (searchText === '') {
      setFilteredIntegrations(integrationsArr)
      setLoading(false)
      return
    }

    const filtered = integrationsArr
      .map((category) => {
        const filteredTools = category.integrations.filter((integration) => {
          const text = (integration.name ?? '') + (integration.description ?? '')
          return text.toLowerCase().includes(searchText.toLowerCase())
        })

        return {
          ...category,
          integrations: filteredTools,
        }
      })
      .filter((category) => category.integrations.length > 0)

    setFilteredIntegrations(filtered)
  }

  const handleChange = ({currentTarget}: ChangeEvent<HTMLInputElement>) => {
    containerRef.current?.scrollIntoView({behavior: 'smooth'})
    setSearchText(currentTarget.value)
  }

  // Handle category selection
  useEffect(() => {
    const formattedIndex = selectedCategory - 1
    setLoading(true)

    if (formattedIndex >= 0) {
      setFilteredIntegrations([integrationsArr[formattedIndex]])
    } else if (formattedIndex === -1) {
      setFilteredIntegrations(integrationsArr)
    }

    setLoading(false)

    return () => clearTimeout(categoryTimeout.current!)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this effect when `selectedCategory` changes
  }, [selectedCategory, setFilteredIntegrations])

  const [,] = useDebounce(
    () => {
      setLoading(true)

      const searchTimeout = setTimeout(() => {
        filterBySearch()
      }, 700)

      setLoading(false)

      track({
        event: EVENTS.TEXT_INPUT,
        mktgId: 'search-bar',
        dataType: searchText,
        pageName: context.pageUid,
        location: `component-${componentIndex}`,
      })

      return () => clearTimeout(searchTimeout)
    },
    500,
    [searchText],
  )

  return (
    <Container
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      backgroundColor="Beige/100"
      className="!overflow-visible"
      containerRef={containerRef}
    >
      <div className="grid-base gap-12 overflow-visible px-5">
        <div className="col-span-4 md:col-span-3 md:h-full xl:px-0">
          <DesktopStickySearchBox
            handleChange={handleChange}
            searchText={searchText}
            categories={slice.primary.categories}
            selectedCategory={selectedCategory}
            changeCategory={changeCategory}
            searchPlaceholder={slice.primary.search_placeholder}
            ref={searchBoxRef}
          />
        </div>
        <div className="col-span-full flex flex-col gap-12 md:col-span-9 md:grid md:grid-cols-subgrid">
          <AnimatePresence>
            {!loading &&
              filteredIntegrations.map((category, categoryIndex) => (
                <motion.div
                  initial={OPACITY_0}
                  animate={{opacity: 1, transition: {duration: 0.3, delay: 0.3}}}
                  exit={{...OPACITY_0, transition: {duration: 0.3}}}
                  className="flex flex-col gap-6 md:col-span-full md:grid md:h-min md:grid-cols-subgrid"
                  key={category.title! + categoryIndex}
                >
                  <h3 className="text-h3 text-moss-black md:col-span-full">{category.title}</h3>
                  {category.integrations.map((integration, integrationIndex) => (
                    <ToolCard integration={integration} key={integration.name! + integrationIndex} />
                  ))}
                </motion.div>
              ))}
          </AnimatePresence>
        </div>
        <AnimatePresence>
          {shouldRenderRelative && (
            <MobileStickySearchBox
              handleChange={handleChange}
              searchText={searchText}
              categories={slice.primary.categories}
              selectedCategory={selectedCategory}
              changeCategory={changeCategory}
              searchPlaceholder={slice.primary.search_placeholder}
            />
          )}
        </AnimatePresence>
      </div>
    </Container>
  )
}

export default IntegrationsContainer
