import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {Context} from 'src/common/types'
import MultipleItemsVar, {MultipleItemsVarProps} from './MultipleItemsVar'
import WithoutIconsVar, {WithoutIconsVarProps} from './WithoutIconsVar'
import {DefaultVar, DefaultVarProps} from './DefaultVar'

/**
 * Props for `HighlightSection`.
 */
export type HighlightSectionProps = SliceComponentProps<Content.HighlightSectionSlice>

/**
 * Component for "HighlightSection" Slices.
 */
const HighlightSection = (props: HighlightSectionProps & {context: Context}): JSX.Element => {
  switch (props.slice.variation) {
    case 'multipleItems':
      return <MultipleItemsVar {...(props as MultipleItemsVarProps)} />
    case 'withoutIcons':
      return <WithoutIconsVar {...(props as WithoutIconsVarProps)} />
    default:
      return <DefaultVar {...(props as DefaultVarProps)} />
  }
}

export default HighlightSection
