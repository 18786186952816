import {GroupField} from '@prismicio/client'
import {ProductDeepDiveSliceSingleSectionPrimarySectionsItem, Simplify} from 'prismicio-types'
import {useEffect, useState} from 'react'
import cn from 'classnames'
import {PDDContent} from './Content'

type PDDContainerProps = {
  sections: GroupField<Simplify<ProductDeepDiveSliceSingleSectionPrimarySectionsItem>>
  isMobile: boolean
}

export const PDDContainer = ({sections, isMobile}: PDDContainerProps) => {
  const [isMounted, setIsMounted] = useState(false)

  // add mounted transition
  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <div
      className={cn(
        'col-span-full grid min-h-full max-w-screen-xl grid-cols-12 items-center gap-4 opacity-0 transition-all duration-700 ease-in-out md:min-h-[600px]',
        {
          'opacity-100 translate-y-0': isMounted,
          'translate-y-[100px]': !isMounted,
        },
      )}
    >
      <div className="col-span-full flex h-full w-full flex-col md:col-start-1 md:col-end-6">
        {sections.map((section, index) => (
          <PDDContent key={index} {...section} index={index} />
        ))}
      </div>
      {isMounted && !isMobile && (
        <div className="relative col-start-7 col-end-13 h-full w-full overflow-hidden rounded-xl bg-beige-300 md:h-[540px]">
          <div id="animation-container" className="relative flex h-full w-full items-center justify-center" />
        </div>
      )}
    </div>
  )
}
