// eslint-disable-next-line @typescript-eslint/no-unused-vars -- This rule is disabled to keep the `iconNames` array in the codebase.
const profiles = ['dev', 'moss-staging', 'moss-production'] as const
export type Profile = (typeof profiles)[number]

type AppConfig = {
  API_URL: string
  GOOGLE_RECAPTCHA_SITE_KEY: string | null
}

const devConfig: AppConfig = {
  API_URL: 'https://api.runmoss.run',
  GOOGLE_RECAPTCHA_SITE_KEY: '6LehnCAqAAAAAGq6aq08xZvgXY8KnpoDtN9W2e85',
}

const stagingConfig: AppConfig = {
  API_URL: 'https://api.runmoss.run',
  GOOGLE_RECAPTCHA_SITE_KEY: '6LehnCAqAAAAAGq6aq08xZvgXY8KnpoDtN9W2e85',
}

const productionConfig: AppConfig = {
  API_URL: 'https://getmoss.com/api',
  GOOGLE_RECAPTCHA_SITE_KEY: '6Lcu38kbAAAAACU6N_Twi3sKL9uQhNm7YtQ1zTWO',
}

export const configs: Record<Profile, AppConfig> = {
  dev: devConfig,
  'moss-staging': stagingConfig,
  'moss-production': productionConfig,
}

export const getProfile = (): Profile => {
  const hostname = window.location.hostname

  switch (true) {
    case hostname.indexOf('runmoss.run') >= 0:
      return 'moss-staging'
    case hostname.indexOf('getmoss.com') >= 0:
      return 'moss-production'

    default:
      return 'dev'
  }
}
