import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import cn from 'classnames'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {CardsSlice} from 'prismicio-types'
import {memo} from 'react'
import {TextLink} from 'src/common/TextLink'

const Cards = (props: {slice: CardsSlice}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <section
      id={String(props.slice.primary.id_anchor)}
      className="grid scroll-mt-16 grid-cols-main gap-y-4 pb-16 pt-10 md:gap-y-16"
    >
      <h2 className="tg-h3 col-[inner] font-bold text-000000/80">{props.slice.primary.title}</h2>

      <div className="col-[inner] flex flex-col gap-8">
        <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4">
          {props.slice.items.map((card, index) => (
            <div className="relative grid content-start gap-y-2" key={index}>
              <div className="grid aspect-[262/117] place-content-center overflow-hidden rounded-xl bg-ffffff shadow-card">
                <PrismicNextImage className="max-h-full max-w-full" field={card.image} />
              </div>

              {isFilled.keyText(card.title) && (
                <h3 className="text-16 font-medium leading-6 text-2a2928">{card.title}</h3>
              )}
              {isFilled.keyText(card.category) && <p className="text-14 leading-6 text-8f8d8b">{card.category}</p>}

              {isFilled.keyText(card.tag) && (
                <div
                  className={cn(
                    'flex h-5 items-center justify-self-start rounded-4 px-1.5 text-10 font-medium uppercase leading-none',
                    {'bg-e8edfd text-4363d0': !card.is_tag_grey},
                    {'bg-f3f3f3 text-72716e': card.is_tag_grey},
                  )}
                >
                  {card.tag}
                </div>
              )}

              {isFilled.link(card.link) && <div className="text-13 text-435ccd underline">{card.link_label}</div>}

              {isFilled.link(card.link) && (
                <Link
                  className="absolute h-full w-full"
                  href={linkResolver(card.link)}
                  {...(card.link.link_type === 'Web' && {target: card.link.target})}
                />
              )}
            </div>
          ))}
        </div>

        {isFilled.keyText(props.slice.primary.cta_label) && (
          <TextLink field={props.slice.primary.cta_link} label={props.slice.primary.cta_label} underline />
        )}
      </div>
    </section>
  )
}

export default memo(Cards)
