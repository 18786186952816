import {isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import {FiguresContentSlice} from 'prismicio-types'
import {Fragment, memo} from 'react'

const FiguresContent = (props: {slice: FiguresContentSlice}) => (
  <section
    className="grid scroll-mt-16 grid-cols-main"
    {...(isFilled.color(props.slice.primary.background_color) && {
      style: {backgroundColor: props.slice.primary.background_color},
    })}
    id={String(props.slice.primary.id_anchor)}
  >
    <PrismicRichText
      components={{
        heading2: ({children}) => (
          <h2 className="col-[inner] pb-12 pt-14 text-center text-32 leading-tight sm:pb-14 sm:pt-16 md:pb-16 md:pt-20 lg:text-36 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
            {children}
          </h2>
        ),
      }}
      field={props.slice.primary.title}
    />

    <div className="col-[inner] grid gap-y-8 pb-16 pt-6 sm:grid-cols-2 sm:gap-x-6 sm:pt-8 md:grid-cols-[1fr_auto_1fr_auto_1fr_0fr] md:gap-x-0 md:gap-y-20 md:py-10 xl:gap-y-32 xl:py-16">
      {props.slice.items.map((item, index) => (
        <Fragment key={index}>
          <div className="grid content-start gap-y-2 md:gap-y-4 lg:gap-y-6">
            <PrismicRichText
              components={{
                heading3: ({children}) => (
                  <h3 className="text-68 leading-none lg:text-36 lg:leading-tight [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
                    {children}
                  </h3>
                ),
                heading4: ({children}) => (
                  <h3 className="text-56 leading-none lg:text-68 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
                    {children}
                  </h3>
                ),
                heading5: ({children}) => (
                  <h3 className="text-40 leading-tight lg:text-48 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
                    {children}
                  </h3>
                ),
              }}
              field={item.title}
            />

            <PrismicRichText
              components={{
                heading3: ({children}) => <p className="text-20 lg:text-36 lg:leading-tight">{children}</p>,
                heading4: ({children}) => <p className="text-20 lg:text-22">{children}</p>,
                heading5: ({children}) => <p className="text-20 lg:text-16">{children}</p>,
              }}
              field={item.description}
            />
          </div>

          <hr
            className={cn(
              'mx-6 hidden h-full self-center border-l-[1px] border-t-0 border-l-dcdbda md:max-h-32 md:w-px lg:mx-10 lg:max-h-none md:[&:nth-child(6n+6)]:mx-0 md:[&:nth-child(6n+6)]:w-0 md:[&:nth-child(6n+6)]:border-l-0',
              {'md:block': props.slice.items.length >= 2},
            )}
          />
        </Fragment>
      ))}
    </div>
  </section>
)

export default memo(FiguresContent)
