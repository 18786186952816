import {CustomerStoriesV2SliceAutoplayPrimaryCustomerStoriesItem} from 'prismicio-types'
import {Asset} from './Asset'
import {ContentSection} from './ContentSection'
import {SlideHeadlineSection} from './SlideHeadlineSection'

export const Slide = ({card}: {card: CustomerStoriesV2SliceAutoplayPrimaryCustomerStoriesItem}) => {
  return (
    <div className="flex h-full flex-col justify-between gap-y-8 p-4 md:flex-row md:p-6 lg:pl-12">
      <div className="flex w-full flex-col justify-between gap-y-6 px-2 pt-2 md:w-full md:py-2 md:pl-0 md:pr-10 lg:py-6">
        <SlideHeadlineSection tag={card.tag} title={card.title} />
        <ContentSection
          highlight_1_icon={card.highlight_1_icon}
          highlight_2_icon={card.highlight_2_icon}
          highlight_1_title={card.highlight_1_title}
          highlight_1_description={card.highlight_1_description}
          highlight_2_title={card.highlight_2_title}
          highlight_2_description={card.highlight_2_description}
          primary_button_label={card.primary_button_label}
          primary_button_url={card.primary_button_url}
        />
      </div>
      <Asset asset={card.image} />
    </div>
  )
}
