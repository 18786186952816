import {CustomerStoryContentSlice} from 'prismicio-types'
import {memo} from 'react'
import {CustomerStorySliceZoneContext} from 'src/CustomerStory'
import {CustomerStoryContentDefault} from './default'
import dynamic from 'next/dynamic'

const CustomerStoryContentV2 = dynamic(() => import('./v2').then((mod) => mod.CustomerStoryContentV2), {ssr: false})

type CustomerStoryContentProps = {
  slice: CustomerStoryContentSlice
  context: CustomerStorySliceZoneContext
}

const CustomerStoryContent = ({slice, context}: CustomerStoryContentProps) => {
  switch (slice.variation) {
    case 'v2':
      return <CustomerStoryContentV2 context={context} slice={slice} />

    default:
      return <CustomerStoryContentDefault slice={slice} />
  }
}

export default memo(CustomerStoryContent)
