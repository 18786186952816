import {isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import classNames from 'classnames'
import NextLink from 'next/link'
import {useLinkResolver} from 'prismicio'
import {CareersTeaserSlice} from 'prismicio-types'
import {memo} from 'react'

const CareersTeaser = (props: {slice: CareersTeaserSlice}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <section
      className="grid scroll-mt-16 grid-cols-main gap-y-12 py-12 sm:py-16 md:pt-20 xl:py-24"
      id={String(props.slice.primary.id_anchor)}
    >
      <div className="col-[inner] md:col-end-[track-end_6] xl:col-end-[track-end_5]">
        <PrismicRichText
          components={{
            heading2: ({children}) => (
              <h2 className="text-28 leading-8 -tracking-common [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
                {children}
              </h2>
            ),
            strong: ({children}) => <strong className="tracking-normal">{children}</strong>,
          }}
          field={props.slice.primary.title}
        />

        <PrismicRichText
          components={{paragraph: ({children}) => <p className="mt-6 leading-6 text-72716e">{children}</p>}}
          field={props.slice.primary.description}
        />
      </div>

      <div className="col-[inner] grid place-content-start gap-y-8 md:col-start-[track-start_7]">
        <div className="grid gap-y-4">
          {props.slice.items.map((item, index) => (
            <div key={index}>
              <div className="text-24 font-medium leading-7 text-232f2a">{item.title}</div>

              <div className="mt-2 grid grid-flow-col items-center justify-start gap-x-4 text-12 font-medium leading-4 tracking-common">
                <div className="text-8f8d8b">{item.subtitle}</div>

                <div
                  className={classNames('rounded-full py-0.5 px-2', {
                    'text-4c6ee5 bg-e8edfd': item.tag_color === 'blue' || !item.tag_color,
                    'text-d97736 bg-fae8dc': item.tag_color === 'orange',
                    'text-cb4c43 bg-f9e8e7': item.tag_color === 'red',
                  })}
                >
                  {item.tag}
                </div>
              </div>
            </div>
          ))}
        </div>

        <NextLink
          className="text-14 font-medium text-4e7e6c transition-colors hover:text-6c9d8b"
          href={linkResolver(props.slice.primary.link)}
          {...(isFilled.link(props.slice.primary.link) &&
            props.slice.primary.link.link_type === 'Web' && {target: props.slice.primary.link.target})}
        >
          {props.slice.primary.link_label}
        </NextLink>
      </div>
    </section>
  )
}

export default memo(CareersTeaser)
