import {Content, isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import {useInView, useScroll} from 'framer-motion'
import {useRef, useState} from 'react'
import {Button} from 'src/common/Button'
import {Tag} from 'src/common/Tag'
import cn from 'classnames'
import {useWindowSize} from 'react-use'

export const SpeakersSection = ({
  speakerCardsData,
  eventCardData,
  registerButtonData,
  expertsSection,
  pageUid,
}: {
  speakerCardsData: Content.AiCampaignSlice['primary']['speaker_card']
  eventCardData: Content.AiCampaignSlice['primary']['event_card'][0]
  registerButtonData: Content.AiCampaignSlice['primary']['register_button'][0]
  expertsSection: Content.AiCampaignSlice['primary']['experts_section'][0]
  pageUid: string
}) => {
  return (
    <div className="relative z-10 snap-start xl:h-[1236px]">
      <div className="xl:sticky xl:top-[-370px]">
        <section className="relative grid grid-cols-1 max-xl:gap-x-4 max-sm:gap-y-8 md:grid-cols-2 md:gap-y-[72px] xl:flex xl:h-[1224px] xl:items-center xl:justify-center xl:gap-8 xl:pb-[164px]">
          <div className="relative h-full max-xl:ml-auto max-md:col-span-1 max-sm:order-2 max-sm:w-full max-sm:px-4 md:row-start-2 md:w-[350px]">
            <Card speakerCardsData={speakerCardsData[0]!} />
          </div>
          <div className="flex h-full flex-col items-center justify-between pt-2 max-sm:order-1 max-sm:pb-6 md:col-span-full xl:w-[384px] xl:pb-[164px] xl:pt-[76px]">
            <EventCard eventCardData={eventCardData} registerButtonData={registerButtonData} pageUid={pageUid} />
            <ExpertsCard expertsSection={expertsSection} />
          </div>
          <div className="h-full max-xl:mr-auto max-md:order-3 max-sm:w-full max-sm:px-4 md:w-[350px]">
            <Card speakerCardsData={speakerCardsData[1]!} />
          </div>
        </section>
      </div>
    </div>
  )
}

const Card = ({speakerCardsData}: {speakerCardsData: Content.AiCampaignSliceDefaultPrimarySpeakerCardItem}) => {
  const [topOffset, setTopOffset] = useState<number | undefined>(undefined)
  const {width, height: windowHeight} = useWindowSize()
  const isMobile = width < 768
  const imageRef = useRef<HTMLDivElement>(null)
  const offsetSet = useRef(false)

  const {scrollYProgress} = useScroll({
    target: imageRef,
    offset: ['center start', 'center end'],
  })

  scrollYProgress.on('change', (latest) => {
    if (Number(latest.toFixed(2)) < 0.6 && !offsetSet.current) {
      const containerHeight = imageContainerRef.current?.getClientRects()[0].height ?? 0
      const offset = (windowHeight - containerHeight) / 2
      setTopOffset(offset)
      offsetSet.current = true
    }
  })

  const imageContainerRef = useRef<HTMLDivElement>(null)
  const isInView = useInView(imageContainerRef, {once: true})

  return (
    <div
      className={cn('flex w-full translate-y-7 flex-col gap-3 opacity-0', {
        sticky: topOffset !== undefined,
        'animate-fadeInUp20': isInView,
      })}
      style={{
        top: topOffset,
        ...(isMobile ? {animationDelay: '.9s'} : {animationDelay: '.6s'}),
      }}
      ref={imageContainerRef}
    >
      <div ref={imageRef} id="image-container">
        <PrismicNextImage
          field={speakerCardsData.image}
          className="rounded-2xl object-cover md:h-[320px] md:w-[350px]"
          priority
        />
      </div>
      <div className="flex flex-col rounded-2xl border border-[rgba(19,43,33,0.15)] bg-moss-white px-6 pb-6 pt-4">
        <span className="text-h4 pb-1 text-moss-black">{speakerCardsData.name}</span>
        <span className="pb-4 text-16 font-medium leading-[22px] tracking-[.16px] text-moss-black">
          {speakerCardsData.title}
        </span>
        <span className="text-body text-grey-550">{speakerCardsData.description}</span>
      </div>
    </div>
  )
}

const EventCard = ({
  eventCardData,
  registerButtonData,
  pageUid,
}: {
  eventCardData?: Content.AiCampaignSliceDefaultPrimaryEventCardItem
  registerButtonData?: Content.AiCampaignSliceDefaultPrimaryRegisterButtonItem
  pageUid: string
}) => {
  return (
    <div className="flex w-[351px] flex-col items-center justify-center gap-8 rounded-2xl bg-transparent-card max-xl:pb-10 xl:h-[460px] xl:w-[384px]">
      <div className="flex flex-col items-center gap-2.5 text-center">
        <PrismicNextImage field={eventCardData?.icon} className="h-6 w-6" priority />
        <span className="text-h5 text-moss-black">{eventCardData?.date}</span>
      </div>
      <Button
        variant="brand-campaign"
        data-mktg-id={registerButtonData?.register_button_marketing_id as string}
        href="#sign-up-section"
        data-page-location="component-1"
        data-type="webinar-sign-up"
        data-page-name={pageUid}
      >
        {registerButtonData?.register_button_label}
      </Button>
    </div>
  )
}

const ExpertsCard = ({expertsSection}: {expertsSection?: Content.AiCampaignSliceDefaultPrimaryExpertsSectionItem}) => {
  return (
    <div className="flex w-full flex-col items-center gap-4 pt-20 max-sm:px-4 md:w-[573px] md:pt-[152px] xl:w-[346px] xl:pt-[164px]">
      {isFilled.keyText(expertsSection?.tag) && <Tag color="neon-green" label={expertsSection.tag} />}
      {isFilled.richText(expertsSection?.quotation) && (
        <PrismicRichText
          field={expertsSection.quotation}
          components={{
            paragraph: ({children}) => (
              <p className="text-h4 text-center tracking-[.24px] text-moss-black">{children}</p>
            ),
            strong: ({children}) => <span className="text-h4 tracking-[.24px] text-[#6C69FC]">{children}</span>,
          }}
        />
      )}
    </div>
  )
}
