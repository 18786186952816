import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import Link from 'next/link'
import {ArticleDocument, AuthorDocumentData} from 'prismicio-types'
import {memo} from 'react'

export const Card = memo(function Card(props: {
  className?: string
  article: ArticleDocument
  isFeatured?: boolean
  magazineUid: string
}) {
  return (
    <Link
      className={cn(
        'grid gap-6 rounded-3xl bg-f3f3f3 transition-shadow hover:shadow-article',
        {'p-4 md:p-6 grid-rows-[auto_1fr]': !props.isFeatured},
        {'p-6 sm:p-8 md:px-8 md:py-10 sm:grid-cols-5 max-sm:grid-rows-[auto_1fr]': props.isFeatured},
        props.className,
      )}
      href={`/${props.magazineUid}/${props.article.uid}`}
    >
      <PrismicNextImage
        className={cn('rounded-2xl', {'sm:col-span-2 md:col-span-3 md:col-start-3': props.isFeatured})}
        field={props.article.data.poster}
        priority={props.isFeatured}
      />

      <div
        className={cn('grid content-between', {
          'sm:col-span-3 md:col-span-2 md:col-start-1 md:row-start-1 md:self-center': props.isFeatured,
        })}
      >
        <div className="grid">
          <div className="flex h-[22px] items-center justify-self-start rounded-4 bg-3a5f51 px-2 text-10 uppercase leading-4 tracking-[1px] text-ffffff">
            {props.article.data.tag}
          </div>

          <PrismicRichText
            components={{
              heading1: ({children}) =>
                props.isFeatured ? (
                  <h2 className="mt-4 text-32 leading-10 text-2a2928 lg:text-36 lg:leading-11">{children}</h2>
                ) : (
                  <h3 className="mt-4 text-18 leading-6 text-2a2928 md:text-20">{children}</h3>
                ),
              strong: ({children}) => (props.isFeatured ? <strong>{children}</strong> : <span>{children}</span>),
            }}
            field={props.article.data.title}
          />
        </div>

        {isFilled.contentRelationship(props.article.data.author) && (
          <div className="mt-6 flex h-6">
            <PrismicNextImage
              className="rounded-full"
              field={(props.article.data.author.data as AuthorDocumentData).photo}
              height={24}
              width={24}
              quality={90}
            />

            <span className="ml-2 text-14 leading-5 text-666563">
              {(props.article.data.author.data as AuthorDocumentData).name}
            </span>
          </div>
        )}
      </div>
    </Link>
  )
})
