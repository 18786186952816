import {Content, KeyTextField, SliceZone as SliceZoneType} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {createContext, memo, useEffect, useRef, useState} from 'react'
import {Container} from 'src/common/Container'
import {useTimer} from './useTimer'
import {useIntersection, useWindowSize} from 'react-use'
import PDDAccordion from './PPDSection'
import dynamic from 'next/dynamic'
import {ProductDeepDiveDocumentData, ProductDeepDiveSliceDefault} from 'prismicio-types'

const TabSelector = dynamic(() => import('./TabSelector'), {ssr: false})

/**
 * Props for `ProductDeepDive`.
 */
export type ProductDeepDiveProps = SliceComponentProps<Content.ProductDeepDiveSlice>

/**
 * Component for "ProductDeepDive" Slices.
 */

type PDDContextType = {
  activeTab: number
  handleTabChange: (selectedTab: number) => void
  sections: SectionsType
  isMobile: boolean
  timer: {
    start: () => void
    stop: () => void
    lap: number
    pause: () => void
  }
  activeChapter: number
  handleChapterChange: (chapter: number) => void
  duration: number | null
  mainRef: any
  selectorRef: any
  pageUid: string
  running: boolean
  componentIndex: number
}

export type SectionsType = {
  sectionLabel: KeyTextField
  slices: SliceZoneType<Content.ProductDeepDiveContentSlice>
}[]

type ProductDeepDiveDefaultProps = {
  slice: ProductDeepDiveSliceDefault
  context: {pageUid: string}
  index: number
  productDeepDiveData: ProductDeepDiveDocumentData
  slice_type: string
}

export const DEFAULT_DURATION = 12

export const ProductDeepDiveContext = createContext<PDDContextType | null>(null)

const ProductDeepDive = ({
  slice,
  context: {pageUid},
  index: componentIndex,
  productDeepDiveData,
  slice_type,
}: ProductDeepDiveDefaultProps): JSX.Element => {
  const sections = [
    {
      sectionLabel: productDeepDiveData?.first_section_label,
      slices: productDeepDiveData?.slices1,
    },
    {
      sectionLabel: productDeepDiveData?.second_section_label,
      slices: productDeepDiveData?.slices2,
    },
    {
      sectionLabel: productDeepDiveData?.third_section_label,
      slices: productDeepDiveData?.slices3,
    },
  ]

  const [activeTab, setActiveTab] = useState(0)
  const [activeChapter, setActiveChapter] = useState(0)
  const duration = slice.primary.chapter_duration
  const timer = useTimer({laps: 2, targetTime: duration || DEFAULT_DURATION})
  const {lap, start, fullReset, resetToLap, running} = timer
  const {width} = useWindowSize()
  const isMobile = width < 768
  const mainRef = useRef<HTMLDivElement>(null)
  const selectorRef = useRef<HTMLDivElement>(null)
  const sectionRef = useRef<HTMLDivElement>(null)
  const tabSelectorOptions = sections.map((section) => section.sectionLabel as string)

  const sectionObservable = useIntersection(sectionRef, {
    rootMargin: '0px',
  })

  const handleTabChange = (selectedTab: number) => {
    setActiveTab(selectedTab)
    setActiveChapter(0)
    fullReset()
  }

  const handleChapterChange = (chapter: number) => {
    setActiveChapter(chapter)
    resetToLap(chapter)
  }

  // Update Active Step
  useEffect(() => {
    setActiveChapter(lap)
  }, [lap])

  // Start timer
  useEffect(() => {
    if (sectionObservable?.isIntersecting) {
      start()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only trigger this effect when component is mounted
  }, [sectionObservable])

  return (
    <ProductDeepDiveContext.Provider
      value={{
        activeTab,
        handleTabChange,
        sections,
        isMobile,
        timer,
        activeChapter,
        handleChapterChange,
        duration,
        mainRef,
        selectorRef,
        pageUid,
        running,
        componentIndex,
      }}
    >
      <Container data-slice-type={slice_type} data-slice-variation={slice.variation}>
        <div
          className="grid w-full max-w-[1200px] scroll-mt-16 grid-cols-4 gap-4 gap-y-12 px-5 md:grid-cols-12 md:gap-y-[60px] xl:px-0"
          ref={mainRef}
        >
          <div
            className="col-span-4 mx-auto grid grid-cols-6 items-center gap-4 gap-y-8 md:col-start-3 md:col-end-11"
            data-aos="fade-up"
          >
            <div
              className="col-span-full grid grid-cols-6 flex-col gap-x-4 gap-y-3 text-center md:max-w-[628px]"
              ref={selectorRef}
            >
              <h2 className="col-span-full text-32 font-medium leading-10 text-moss-black lg:text-40 lg:leading-12">
                {slice.primary.title}
              </h2>
              <p className="col-span-full text-16 leading-6 tracking-[.16px] text-grey-550">{slice.primary.subtext}</p>
            </div>
            <TabSelector
              activeIndex={activeTab}
              onChangeHandler={handleTabChange}
              labels={tabSelectorOptions}
              containerRef={mainRef}
              selectorRef={selectorRef}
              pageUid={pageUid}
              componentIndex={componentIndex}
            />
          </div>
          <div className="col-span-full min-h-[877px] md:min-h-[540px]" data-aos="fade-up" ref={sectionRef}>
            <PDDAccordion />
          </div>
        </div>
      </Container>
    </ProductDeepDiveContext.Provider>
  )
}

export default memo(ProductDeepDive)
