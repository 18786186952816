import {UseHubSpotFormProps, useHubspotContext} from 'next-hubspot'
import {useState, useRef, useEffect} from 'react'

const useCreateHubspotForm = (formProps: UseHubSpotFormProps) => {
  const {loaded, error} = useHubspotContext()
  const [formCreated, setFormCreated] = useState(false)
  const formIdRef = useRef(formProps.locale)

  // Create the form when the script is loaded
  useEffect(() => {
    if (loaded && (!formCreated || formIdRef.current !== formProps.formId)) {
      try {
        window.hbspt.forms.create(formProps)
        setFormCreated(true)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars -- ToDo - Log error using datadog
      } catch (e) {
        setFormCreated(false)
      }
    }
    // Don't include 'formProps' here to avoid rerenders
  }, [loaded, formCreated, setFormCreated, formProps])

  return {loaded, formCreated, error}
}

export default useCreateHubspotForm
