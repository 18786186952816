import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {SummarySlice} from 'prismicio-types'
import {memo} from 'react'
import {Icon} from 'src/common/Icon'

const Summary = (props: {slice: SummarySlice}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <section
      className="grid scroll-mt-16 grid-cols-main bg-4e655c pb-10 pt-14 text-ffffff md:pb-18 lg:pb-18 lg:pt-24"
      id={String(props.slice.primary.id_anchor)}
    >
      <PrismicNextImage className="col-[inner] ml-4 max-w-[110px]" field={props.slice.primary.logo} />

      <div className="col-[inner] mt-6 flex flex-wrap items-center text-12 font-medium leading-4 tracking-common">
        <Icon className="h-4 w-3" name="calendar" />
        <span className="ml-2">{props.slice.primary.year}</span>
        <Icon className="ml-4 h-4 w-3" name="map" />
        <span className="ml-2">{props.slice.primary.place}</span>
        {isFilled.keyText(props.slice.primary.size) && (
          <>
            <Icon className="ml-4 h-4 w-3" name="avatars" />
            <span className="ml-2">{props.slice.primary.size}</span>
          </>
        )}
      </div>

      <PrismicRichText
        components={{
          paragraph: ({children}) => (
            <p className="col-[inner] mt-6 max-w-[29rem] text-18 leading-[1.625rem] sm:col-end-[track-end_3] sm:row-span-2 sm:mt-4 md:col-end-[track-end_6] md:mt-6">
              {children}
            </p>
          ),
          hyperlink: ({node, children}) => (
            <Link
              className="underline"
              href={linkResolver(node.data)}
              {...(node.data.link_type === 'Web' && node.data.target && {target: node.data.target})}
            >
              {children}
            </Link>
          ),
        }}
        field={props.slice.primary.description}
      />

      <div className="col-[inner] mt-7 grid gap-y-4 self-start sm:col-start-[track-start_5] sm:mt-4 md:col-start-[track-start_7] md:mt-6 lg:col-end-[track-end_9]">
        <div className="text-20 leading-6">{props.slice.primary.challenges_label || 'Challenges'}</div>

        <PrismicRichText
          components={{
            list: ({children}) => <ul className="grid gap-y-2">{children}</ul>,
            listItem: ({children}) => (
              <li className="grid grid-cols-[auto_1fr] items-baseline gap-x-2 text-16 leading-6">
                <Icon className="h-3 w-4" name="small-logo" />
                <span>{children}</span>
              </li>
            ),
          }}
          field={props.slice.primary.challenges}
        />
      </div>

      <div className="col-[inner] mt-8 grid gap-y-4 self-start sm:col-start-[track-start_5] sm:mt-6 md:col-start-[track-start_7] lg:col-start-[track-start_10]">
        <div className="text-20 leading-6">{props.slice.primary.solutions_label || 'Solutions'}</div>

        <PrismicRichText
          components={{
            list: ({children}) => <ul className="grid gap-y-2">{children}</ul>,
            listItem: ({children}) => (
              <li className="grid grid-cols-[auto_1fr] items-baseline gap-x-2 text-16 leading-6">
                <Icon className="h-3 w-4" name="small-logo" />
                <span>{children}</span>
              </li>
            ),
          }}
          field={props.slice.primary.solutions}
        />
      </div>
    </section>
  )
}

export default memo(Summary)
