import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {ArticleDocumentData, AuthorDocumentData, PressReleaseSlice} from 'prismicio-types'

const PressRelease = (props: {slice: PressReleaseSlice}) => {
  const {linkResolver} = useLinkResolver()

  const article = (
    isFilled.contentRelationship(props.slice.primary.link) ? props.slice.primary.link.data : {}
  ) as ArticleDocumentData

  const author = (isFilled.contentRelationship(article.author) ? article.author.data : {}) as AuthorDocumentData

  return (
    <section
      className="grid scroll-mt-16 grid-cols-main bg-f3f3f3 pb-5 pt-10 lg:pb-10"
      id={String(props.slice.primary.id_anchor)}
    >
      <PrismicRichText
        components={{
          heading2: ({children}) => <h2 className="col-[inner] text-28 leading-8 text-232f2a">{children}</h2>,
        }}
        field={props.slice.primary.title}
      />

      <Link
        className="col-[inner] mt-12 grid items-start gap-x-6 gap-y-4 rounded-3xl bg-ffffff px-6 pb-12 pt-6 transition-shadow hover:shadow-article md:grid-cols-12 md:py-10 lg:px-12"
        href={linkResolver(props.slice.primary.link)}
      >
        {article.poster && (
          <PrismicNextImage className="rounded-[30px] md:col-span-6 md:col-start-7" field={article.poster} />
        )}

        <div className="grid h-full content-between gap-y-4 md:col-span-6 md:col-start-1 md:row-start-1 xl:col-span-5">
          <div className="grid place-items-start gap-y-4">
            <div className="grid h-[22px] items-center rounded-4 bg-3a5f51 px-2 text-10 uppercase leading-4 tracking-[1px] text-ffffff">
              {article.tag}
            </div>

            <PrismicRichText
              components={{
                heading1: ({children}) => (
                  <h3 className="text-32 font-bold leading-10 -tracking-common lg:text-36 lg:leading-11">{children}</h3>
                ),
              }}
              field={article.title}
            />
          </div>

          <div className="grid grid-flow-col items-center justify-start gap-x-2">
            {author.photo && <PrismicNextImage className="h-6 w-6 rounded-full" field={author.photo} />}
            <div className="text-14 text-666563">{author.name}</div>
          </div>
        </div>
      </Link>
    </section>
  )
}

export default PressRelease
