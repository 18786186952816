import {SurveySectionSliceDefaultPrimary} from 'prismicio-types'
import {useState, useCallback, useMemo} from 'react'
import {transformData} from 'slices/SurveySection/helpers'
import {EndOfFunnel} from './End'
import {Quiz} from './Quiz'
import {ResultsStep} from './Results'
import {Welcome} from './Welcome'

enum SurveyFunnel {
  WELCOME_STEP,
  QUIZ_STEP,
  RESULTS_STEP,
  THANK_YOU_STEP,
}

export const FunnelController = ({funnel, pageUid}: {funnel: SurveySectionSliceDefaultPrimary; pageUid: string}) => {
  const [activeStep, setActiveStep] = useState(SurveyFunnel.WELCOME_STEP)
  const [funnelScore, setFunnelScore] = useState(0)
  const handleContinue = () => setActiveStep(activeStep + 1)
  const increaseFunnelScore = useCallback((newValue: number) => setFunnelScore((prev) => prev + newValue), [])
  const quizData = useMemo(() => transformData(funnel), [funnel])

  switch (activeStep) {
    case SurveyFunnel.QUIZ_STEP:
      return (
        <Quiz
          onContinue={handleContinue}
          questions={quizData}
          funnelScoreHandler={increaseFunnelScore}
          progressbarText={funnel.progress_bar_text}
          funnelScore={funnelScore}
          formFields={funnel.form_fields}
          formTitle={funnel.form_title}
          pageUid={pageUid}
          hubspotFormId={funnel.hubspot_form_id}
          privacyPolicyLabel={funnel.privacy_policy_disclaimer}
          submitLabel={funnel.continue_button}
          formDescription={funnel.form_description}
          contnueLabel={funnel.continue_button}
          outcomeOptions={funnel.results}
        />
      )
    case SurveyFunnel.RESULTS_STEP:
      return (
        <ResultsStep
          funnelScore={funnelScore}
          demoLink={funnel.demo_link}
          demoLabel={funnel.book_demo_button}
          continueWithoutDemoHandler={handleContinue}
          continueWithoutDemoLabel={funnel.continue_without_demo_button}
          results={funnel.results}
          pageUid={pageUid}
        />
      )
    case SurveyFunnel.THANK_YOU_STEP:
      return <EndOfFunnel title={funnel.end_of_funnel[0]?.title} content={funnel.end_of_funnel[0]?.content} />
    case SurveyFunnel.WELCOME_STEP:
    default:
      return (
        <Welcome
          title={funnel.welcome_title}
          subtext={funnel.welcome_subtext}
          startLabel={funnel.continue_button}
          disclaimer={funnel.welcome_disclaimer}
          onContinue={handleContinue}
          pageUid={pageUid}
        />
      )
  }
}
