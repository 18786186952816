import {useRef, useState, useEffect, useCallback} from 'react'
import {useScroll, useTransform, motion, AnimatePresence} from 'framer-motion'
import {ImageField, KeyTextField} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {useWindowSize} from 'react-use'
import {OPACITY_0, OPACITY_0_DURATION_0_5, OPACITY_1} from 'src/common/constants'

const useTypewriterEffect = ({
  scrollYProgress,
  icon1,
  title1,
  description1,
  icon2,
  title2,
  description2,
}: {
  scrollYProgress: any
  icon1?: ImageField
  title1?: KeyTextField
  description1?: KeyTextField
  icon2?: ImageField
  title2?: KeyTextField
  description2?: KeyTextField
}) => {
  const [displayedHeading, setDisplayedHeading] = useState<string[]>([''])
  const [displayedText, setDisplayedText] = useState<string[]>([''])
  const [displayBlock, setDisplayBlock] = useState(false)
  const [activeIcon, setActiveIcon] = useState(icon1)
  const {height, width} = useWindowSize()
  const isMobile = width ? width < 760 : false
  const top = height ? height * 0.18 : 0

  const titleWriteProgress1 = useTransform(scrollYProgress, [0.03, 0.05], [0, 1])
  const typeWriteProgress1 = useTransform(scrollYProgress, [0.06, 0.36], [0, 1])
  const titleWriteProgress2 = useTransform(scrollYProgress, [0.53, 0.56], [0, 1])
  const typeWriteProgress2 = useTransform(scrollYProgress, [0.57, 0.71], [0, 1])

  const backgroundY = useTransform(scrollYProgress, [0.44, 0.65], [0, -320])

  const getTextByScrollProgress = (fullText: string, progress: number) => {
    const words = fullText.split(' ')
    const length = Math.floor(words.length * progress)
    return words.slice(0, length)
  }

  const updateText1 = useCallback(() => {
    const textProgress1 = Math.min(Math.max(typeWriteProgress1.get(), 0), 1)
    const headingProgress1 = Math.min(Math.max(titleWriteProgress1.get(), 0), 1)
    setDisplayedHeading(getTextByScrollProgress(title1!, headingProgress1))
    setDisplayedText(getTextByScrollProgress(description1!, textProgress1))
  }, [typeWriteProgress1, titleWriteProgress1, title1, description1])

  const clearText1 = useCallback(() => {
    setDisplayBlock(false)
    setDisplayedHeading([])
    setDisplayedText([])
    setActiveIcon(icon1)
  }, [icon1])

  const updateText2 = useCallback(() => {
    setActiveIcon(icon2)
    const textProgress2 = Math.min(Math.max(typeWriteProgress2.get(), 0), 1)
    const headingProgress2 = Math.min(Math.max(titleWriteProgress2.get(), 0), 1)
    setDisplayedHeading(getTextByScrollProgress(title2!, headingProgress2))
    setDisplayedText(getTextByScrollProgress(description2!, textProgress2))
  }, [icon2, typeWriteProgress2, titleWriteProgress2, title2, description2])

  //Handle scroll change
  useEffect(() => {
    const handleScrollChange = (latest: number) => {
      const progress = Number(latest.toFixed(2))

      if (progress <= 0.02 && displayBlock) {
        setDisplayBlock(false)
        clearText1()
      } else if (progress > 0.02 && progress < 0.44 && !displayBlock) {
        setDisplayBlock(true)
      }

      if (progress > 0.03 && progress < 0.4 && displayBlock) {
        updateText1()
      } else if (progress > 0.44 && progress < 0.53 && displayBlock) {
        clearText1()
      } else if (progress > 0.53 && progress < 0.57 && !displayBlock) {
        setDisplayBlock(true)
      } else if (progress > 0.53 && progress < 0.9 && displayBlock) {
        updateText2()
      } else if (progress > 0.9 && displayBlock) {
        setDisplayBlock(false)
      } else if (progress > 0.53 && progress < 0.9 && !displayBlock) {
        setDisplayBlock(true)
      }
    }

    const unsubscribe = scrollYProgress.on('change', handleScrollChange)
    return () => unsubscribe()
  }, [
    scrollYProgress,
    typeWriteProgress1,
    titleWriteProgress1,
    displayBlock,
    title1,
    description1,
    typeWriteProgress2,
    titleWriteProgress2,
    title2,
    description2,
    updateText1,
    updateText2,
    clearText1,
  ])

  return {
    displayedHeading,
    displayedText,
    displayBlock,
    backgroundY: isMobile ? 0 : backgroundY,
    activeIcon,
    top,
  }
}

export const TypewriterEffectSection = ({
  icon1,
  title1,
  description1,
  icon2,
  title2,
  description2,
  backgroundImageUrl,
}: {
  icon1?: ImageField
  title1?: KeyTextField
  description1?: KeyTextField
  icon2?: ImageField
  title2?: KeyTextField
  description2?: KeyTextField
  backgroundImageUrl?: string
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const {scrollYProgress} = useScroll({
    target: containerRef,
    offset: ['start center', 'end center'],
  })

  const {displayedHeading, displayedText, displayBlock, backgroundY, activeIcon, top} = useTypewriterEffect({
    scrollYProgress,
    icon1,
    title1,
    description1,
    icon2,
    title2,
    description2,
  })

  return (
    <div
      ref={containerRef}
      className="relative flex h-[1000px] flex-col items-center bg-transparent px-6 pb-[100px] pt-[150px] md:h-[100vh] md:pt-15 xl:h-[1500px] xl:pb-[150px] xl:pt-[200px]"
    >
      <motion.div
        className="absolute left-0 top-0 h-full w-full rounded-b-2xl px-3 md:top-[300px] md:px-6 xl:top-[320px]"
        style={{y: backgroundY}}
      >
        <div
          className="h-full w-full rounded-b-2xl bg-cover bg-center"
          style={{backgroundImage: `url(${backgroundImageUrl})`}}
        />
      </motion.div>
      <div className="sticky top-[230px] z-10 min-h-[400px] md:w-[80%]  xl:top-[150px] xl:w-[900px]" style={{top: top}}>
        <AnimatePresence>
          {displayBlock && (
            <>
              <motion.div initial={OPACITY_0} animate={OPACITY_1} exit={OPACITY_0_DURATION_0_5}>
                <PrismicNextImage field={activeIcon} className="mx-auto mb-4 h-10 w-10" />
              </motion.div>
              <motion.h4
                className="xl:text-h2 md:text-h3 pb-4 text-center text-20 font-medium leading-7 text-[#6C69FC]"
                initial={OPACITY_0}
                animate={OPACITY_1}
                exit={OPACITY_0_DURATION_0_5}
              >
                {displayedHeading.map((word, index) => (
                  <motion.span
                    key={word + index}
                    initial={OPACITY_0}
                    animate={{
                      opacity: 1,
                      transition: {
                        staggerChildren: 0.07,
                        duration: 0.3,
                      },
                    }}
                  >
                    {word}{' '}
                  </motion.span>
                ))}
              </motion.h4>
              <motion.p
                className="xl:text-h2 md:text-h3 text-center text-20 font-medium leading-7 text-moss-black"
                initial={OPACITY_0}
                animate={OPACITY_1}
                exit={OPACITY_0_DURATION_0_5}
              >
                <AnimatePresence>
                  {displayedText.map((word, index) => (
                    <motion.span
                      key={word + index}
                      initial={OPACITY_0}
                      animate={{
                        opacity: 1,
                        transition: {
                          staggerChildren: 0.1,
                          duration: 0.5,
                        },
                      }}
                    >
                      {word}{' '}
                    </motion.span>
                  ))}
                </AnimatePresence>
              </motion.p>
            </>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}
