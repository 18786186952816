import {Control, Controller} from 'react-hook-form'
import {MossUIField, MossUICheckbox} from './MossUiComponents'
import {isFilled, RichTextField} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import Link from 'next/link'
import {linkResolver} from 'prismicio'
import cn from 'classnames'

const textSizes = {
  small: 'text-10 leading-4 lg:text-body-s',
  medium: 'text-16 leading-5 tracking-[0.16px]',
}

export const PrivacyCheckbox = ({
  control,
  label,
  textSize = 'medium',
  textColor = 'text-grey-110',
  linkColor = 'text-grey-110',
}: {
  control: Control<any>
  label: RichTextField
  textSize?: 'small' | 'medium' | 'x-small'
  textColor?: string
  linkColor?: string
}) => {
  return (
    <div className="col-span-full">
      <MossUIField>
        <Controller
          control={control}
          name="privacyPolicy"
          rules={{required: true}}
          render={({field}) => (
            <MossUICheckbox onChange={(e) => field.onChange((e.target as HTMLInputElement).checked)}>
              <PrismicRichText
                field={label}
                components={{
                  paragraph: ({children}) => (
                    <p
                      className={cn(`${textColor}`, {
                        [textSizes.small]: textSize === 'small',
                        [textSizes.medium]: textSize === 'medium',
                        'text-body-xs': textSize === 'x-small',
                      })}
                    >
                      {children}
                    </p>
                  ),
                  hyperlink: ({node, children}) => (
                    <Link
                      className={cn(
                        `${linkColor} underline underline-offset-2 transition-colors hover:text-grey-110/[80%]`,
                        {
                          [textSizes.small]: textSize === 'small',
                          [textSizes.medium]: textSize === 'medium',
                        },
                      )}
                      href={linkResolver(node.data)}
                      {...(isFilled.link(node.data) && node.data.link_type === 'Web' && {target: node.data.target})}
                    >
                      {children}
                    </Link>
                  ),
                }}
              />
            </MossUICheckbox>
          )}
        />
      </MossUIField>
    </div>
  )
}
