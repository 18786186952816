import {PrismicRichText} from '@prismicio/react'
import {PageTitleSlice} from 'prismicio-types'
import {memo} from 'react'

const PageTitle = memo(function PageTitle(props: {slice: PageTitleSlice}) {
  return (
    <div
      className="grid scroll-mt-16 grid-cols-main py-16 sm:pb-20 xl:pt-20"
      id={String(props.slice.primary.id_anchor)}
    >
      <PrismicRichText
        components={{
          heading1: ({children}) => (
            <h1 className="col-[inner] text-36 leading-11 md:text-40 md:leading-12 lg:text-48 lg:leading-14 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
              {children}
            </h1>
          ),
        }}
        field={props.slice.primary.title}
      />
    </div>
  )
})

export default PageTitle
