import dynamic from 'next/dynamic'
import {FieldSkeleton} from './Skeletons'

export const MossUIField = dynamic({
  loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.Field),
  loading: FieldSkeleton,
  ssr: false,
})

export const MossUICheckbox = dynamic({
  loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.Checkbox),
  ssr: false,
})

export const MossUISelect = dynamic({
  loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.Select),
  ssr: false,
})

export const MossUIMenuItem = dynamic({
  loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.MenuItem),
  ssr: false,
})

export const MossUIMenuItemLabel = dynamic({
  loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.MenuItem).then((mod) => mod.Label),
  ssr: false,
})

export const MossUITextInput = dynamic({
  loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.TextInput),
  ssr: false,
})

export const MossUIFieldLabel = dynamic({
  loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.Field.Label),
  ssr: false,
})

export const MossUIPhoneInput = dynamic({
  loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.PhoneInput),
  ssr: false,
})

const MossUIComponents = {
  Field: MossUIField,
  FieldLabel: MossUIFieldLabel,
  Checkbox: MossUICheckbox,
  Select: MossUISelect,
  MenuItem: MossUIMenuItem,
  MenuItemLabel: MossUIMenuItemLabel,
  TextInput: MossUITextInput,
  PhoneInput: MossUIPhoneInput,
}

export default MossUIComponents
