import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {EVENTS, getUtmParams, track} from 'src/common/helpers'
import {SignupSectionDefault} from './Default'
import {SignupSectionV2} from './V2'
import {useSearchParams} from 'next/navigation'
import {useCookie} from 'react-use'

type FormData = {
  firstName: string
  lastName: string
  email: string
  checkbox: boolean
}

/**
 * Props for `SignupSection`.
 */
export type SignupSectionProps = SliceComponentProps<Content.SignupSectionSlice> & {
  context: {pageUid: string}
}

/**
 * Component for "SignupSection" Slices.
 */
const SignupSection = ({slice, context: {pageUid}, index}: SignupSectionProps): JSX.Element => {
  const {handleSubmit, register, formState} = useForm<FormData>({
    shouldUseNativeValidation: true,
  })

  const params = useSearchParams()
  const [hutk] = useCookie('hubspotutk')

  const {primary, variation} = slice

  const submit = useCallback(
    async (data: FormData) => {
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${primary.hubspot_portal_id}/${primary.hubspot_form_id}`

      const utmParams = getUtmParams(params)

      const preparedData = {
        submittedAt: new Date().getTime(),

        fields: [
          {
            name: 'firstname',
            value: data.firstName,
          },

          {
            name: 'lastname',
            value: data.lastName,
          },

          {
            name: 'email',
            value: data.email,
          },
          ...utmParams,
        ],

        context: {
          pageName: pageUid,
          pageUri: window.location.href,
          ...(hutk?.length && {hutk}),
        },
      }

      try {
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(preparedData),
          headers: {
            'Content-Type': 'application/json',
          },
        })

        track({
          event: EVENTS.FORM_SUBMIT,
          location: `component-${index}-hubspot-form`,
          dataType: primary.form_type!,
          mktgId: 'form-submission-button',
          pageName: pageUid,
        })

        if (!response.ok) {
          console.log('Failed to submit form')
          throw new Error('Failed to submit form')
        }
      } catch (error) {
        console.error(error)
      }
    },
    [primary.hubspot_portal_id, primary.hubspot_form_id, primary.form_type, params, pageUid, hutk, index],
  )

  switch (variation) {
    case 'v2':
      return (
        <SignupSectionV2 slice={slice} formHandler={handleSubmit(submit)} register={register} formState={formState} />
      )
    default:
      return (
        <SignupSectionDefault
          slice={slice}
          formHandler={handleSubmit(submit)}
          register={register}
          formState={formState}
        />
      )
  }
}

export default SignupSection
