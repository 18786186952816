import {Container} from 'src/common/Container'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Swiper as SwiperType} from 'swiper/types'
import {Pagination, Autoplay, EffectFade} from 'swiper/modules'
import {useRef, useState} from 'react'
import {Context} from 'src/common/types'
import {CustomerStoriesV2SliceAutoplayPrimary} from 'prismicio-types'
import {Slide} from './Slide'
import dynamic from 'next/dynamic'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'

const AutoplayNavigation = dynamic(() => import('./AutoplayNavigation').then((mod) => mod.AutoplayNavigation), {
  ssr: false,
})

type AutoplayCustomerStoriesV2Props = {
  slice: {
    primary: CustomerStoriesV2SliceAutoplayPrimary
    slice_type: string
    variation: string
  }
  pageUid: string
  componentIndex: number
  context: Context
}

/**
 * Component for "CustomerStoriesV2" Slices.
 */
export const AutoplayCustomerStoriesV2 = ({
  slice: {primary, slice_type, variation},
}: AutoplayCustomerStoriesV2Props): JSX.Element => {
  const progressBarRef = useRef<HTMLDivElement>(null)
  const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const slideDelay = (primary.slide_duration || 8) * 1000

  const updateProgress = (progress: number) => {
    if (progressBarRef.current) {
      // Update the scaleX transform directly based on progress
      progressBarRef.current.style.transform = `scaleX(${1 - progress})`
    }
  }

  return (
    <Container
      data-slice-type={slice_type}
      data-slice-variation={variation}
      backgroundColor={primary.background_color || 'Moss White'}
    >
      <div className="grid-base max-w-[1200px] gap-y-5 px-5 md:gap-12 md:gap-y-8 lg:gap-y-12 xl:px-0">
        <Swiper
          autoplay={{
            delay: slideDelay,
          }}
          onAutoplayTimeLeft={(_, __, progress) => {
            updateProgress(progress)
          }}
          onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          slidesPerView={1}
          modules={[Pagination, EffectFade, Autoplay]}
          wrapperClass="ease-in-out duration-100 col-span-full"
          className="col-span-full w-full"
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          effect="fade"
        >
          {primary.customer_stories.map((card, index) => (
            <SwiperSlide
              className="!w-full max-w-[1200px] rounded-xl bg-moss-green-500 md:!h-[448px]"
              key={card.title ?? '' + index}
            >
              <Slide card={card} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="col-span-full flex w-full max-w-[1200px] gap-1">
          <AutoplayNavigation
            progressBarRef={progressBarRef}
            items={primary.customer_stories}
            swiperInstance={swiperInstance}
            activeIndex={activeIndex}
          />
        </div>
      </div>
    </Container>
  )
}
