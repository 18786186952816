import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {Context} from 'src/common/types'
import {DefaultHeadlineSection} from './Default'
import {HighlightsHeadlineSection} from './Highlights'

/**
 * Props for `HeadlineSection`.
 */
export type HeadlineSectionProps = SliceComponentProps<Content.HeadlineSectionSlice>

/**
 * Component for "HeadlineSection" Slices.
 */
const HeadlineSection = ({
  slice,
  context,
  index: componentIndex,
}: HeadlineSectionProps & {context: Context}): JSX.Element => {
  switch (slice.variation) {
    case 'highlights':
      return <HighlightsHeadlineSection slice={slice} />
    default:
      return (
        <DefaultHeadlineSection
          slice={slice}
          pageUid={context.pageUid}
          componentIndex={componentIndex}
          context={context}
        />
      )
  }
}

export default HeadlineSection
