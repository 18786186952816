import DOMPurify from 'isomorphic-dompurify'

/**
 * Sanitizes a given HTML string using DOMPurify.
 *
 * @param dirtyHtml - The HTML string that needs to be sanitized
 * @returns The sanitized HTML string
 */
export const sanitizeHtml = (dirtyHtml: string = ''): string => {
  return DOMPurify.sanitize(dirtyHtml, {USE_PROFILES: {html: true}})
}
