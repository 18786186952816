import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {useEffect, useMemo, useRef, useState} from 'react'
import {useDebounce} from 'react-use'
import {Container} from 'src/common/Container'
import {Headline} from 'src/common/Headline'
import {Icon} from 'src/common/Icon'
import {Context} from 'src/common/types'
import dynamic from 'next/dynamic'
import {ProductAnnouncementCard} from './ProductAnnouncementCard'
import {CATEGORIES_COLORS, PRODUCT_ANNOUNCEMENTS_CATEGORIES, locales} from 'src/common/constants'
import {useRouter} from 'next/router'
import {EVENTS, track} from 'src/common/helpers'

const MossUIComponents = {
  MenuSelect: dynamic({
    loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.Menu.Select),
    ssr: false,
  }),
  MenuToggle: dynamic({
    loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.Menu.Toggle),
    ssr: false,
  }),
  MenuList: dynamic({
    loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.Menu.List),
    ssr: false,
  }),
  Menu: dynamic({
    loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.Menu),
    ssr: false,
  }),
  MenuItem: dynamic({
    loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.MenuItem),
    ssr: false,
  }),
  MenuItemLabel: dynamic({
    loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.MenuItem.Label),
    ssr: false,
  }),
  TextInput: dynamic({
    loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.TextInput),
    ssr: false,
  }),
}

const getAnnouncementCategories = (locale: keyof typeof locales) => {
  const categoriesSet = PRODUCT_ANNOUNCEMENTS_CATEGORIES[locale]
  const result = []

  for (const [key, value] of Object.entries(categoriesSet)) {
    result.push({label: value, value: key})
  }

  return {
    categoriesOptions: result,
    categoriesSet,
  }
}

/**
 * Props for `ProductAnnouncementsContainer`.
 */
export type ProductAnnouncementsContainerProps = SliceComponentProps<Content.ProductAnnouncementsContainerSlice> & {
  context: Context
}

/**
 * Component for "ProductAnnouncementsContainer" Slices.
 */
const ProductAnnouncementsContainer = ({
  slice,
  context,
  index: componentIndex,
}: ProductAnnouncementsContainerProps): JSX.Element => {
  const [filteredCards, setFilteredCards] = useState(context.product_announcements_data || [])
  const router = useRouter()
  const locale = (router.locale as keyof typeof locales) || 'en-us'
  const [searchText, setSearchText] = useState('')
  const [selectedCategory, setSelectedCategory] = useState({label: '', value: ''})
  const [selectItemWidth, setSelectItemWidth] = useState(0)
  const selectContainerRef = useRef<HTMLDivElement>(null)

  const {categoriesOptions, categoriesSet} = useMemo(() => getAnnouncementCategories(locale), [locale])

  const [,] = useDebounce(
    () => {
      setFilteredCards(
        context.product_announcements_data!.filter((announcement) => {
          const text = announcement.data.title! + announcement.data.description || ''
          return text.toLowerCase().includes(searchText.toLowerCase())
        }),
      )

      track({
        event: EVENTS.TEXT_INPUT,
        mktgId: 'search-bar',
        dataType: searchText,
        pageName: context.pageUid,
        location: `component-${componentIndex}`,
      })
    },
    2000,
    [searchText],
  )

  // Filter cards based on selected category
  useEffect(() => {
    if (selectedCategory.value === '') {
      setFilteredCards(context.product_announcements_data!)
    } else {
      setFilteredCards(
        context.product_announcements_data!.filter((announcement) => {
          return (
            announcement.data.category?.toUpperCase().replaceAll(' ', '_') ===
            selectedCategory.value.toUpperCase().replaceAll(' ', '_')
          )
        }),
      )
    }
  }, [selectedCategory, context.product_announcements_data])

  //workaround to have the select items the same width as the select
  useEffect(() => {
    if (selectContainerRef.current) {
      setSelectItemWidth(selectContainerRef.current.getBoundingClientRect().width)
    }
  }, [])

  return (
    <Container data-slice-type={slice.slice_type} data-slice-variation={slice.variation} className="md:px-5 xl:px-0">
      <div className="grid-base w-full gap-y-6">
        <div className="col-span-full">
          <Headline title={slice.primary.title} headingLevel="h3" />
        </div>
        <div className="relative col-span-4 flex flex-col flex-wrap gap-3 px-5 md:col-span-8 md:flex-row md:flex-nowrap lg:px-0">
          <MossUIComponents.TextInput
            className="
            text-body-m
            max-h-10
            w-full
            rounded-lg
            border
            border-grey-150
            py-2.5
            !pl-7
            !pr-3
            text-moss-black
            placeholder:text-grey-300
            hover:bg-grey-110
            focus:outline-none
            lg:w-[520px]
            "
            placeholder={slice.primary.search_placeholder || ''}
            value={searchText}
            onChange={({currentTarget}) => {
              setSearchText(currentTarget.value)
            }}
          />
          <Icon name="search" className="absolute left-8 top-2.5 h-5 w-5 md:left-3" />
          <div className="text-body-m w-full text-grey-300 md:w-[260px]" ref={selectContainerRef}>
            <MossUIComponents.Menu>
              <MossUIComponents.MenuToggle width="100%">
                <MossUIComponents.MenuSelect
                  placeholder={slice.primary.filter_by_category_placeholder || ''}
                  appearance="regular"
                  autoFocus={false}
                  onClear={() => setSelectedCategory({label: '', value: ''})}
                >
                  {selectedCategory.label}
                </MossUIComponents.MenuSelect>
              </MossUIComponents.MenuToggle>
              <MossUIComponents.MenuList>
                {categoriesOptions.map((category, index) => (
                  <MossUIComponents.MenuItem
                    key={index}
                    onClick={() => {
                      setSelectedCategory(category)

                      track({
                        event: EVENTS.SELECT_CLICK,
                        mktgId: 'filter',
                        dataType: category.label,
                        pageName: context.pageUid,
                        location: `component-${componentIndex}`,
                      })
                    }}
                    selected={selectedCategory.value === category.value}
                    minWidth={`${selectItemWidth}px`}
                  >
                    <MossUIComponents.MenuItemLabel>{category.label}</MossUIComponents.MenuItemLabel>
                  </MossUIComponents.MenuItem>
                ))}
              </MossUIComponents.MenuList>
            </MossUIComponents.Menu>
          </div>
        </div>
        <div className=" col-span-full flex min-h-[658px] flex-col gap-12 px-5 pt-10 lg:px-0">
          {filteredCards.length ? (
            filteredCards.map((announcement, index) => {
              const category = announcement.data.category?.toUpperCase().replace(/ /g, '_')
              const categoryLabel = categoriesSet[category as keyof typeof categoriesSet]
              const categoryColor = CATEGORIES_COLORS[category as keyof typeof CATEGORIES_COLORS]

              const formattedDate = new Date(announcement.data.date!).toLocaleDateString(locale, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })

              return (
                <ProductAnnouncementCard
                  key={index}
                  categoryLabel={categoryLabel}
                  tagColor={categoryColor}
                  location={`component-${componentIndex}-media-card-${index}`}
                  pageName={context.pageUid}
                  formattedDate={formattedDate}
                  {...announcement.data}
                />
              )
            })
          ) : (
            <Headline
              title={slice.primary.no_results_title}
              headingLevel="h3"
              subtext={slice.primary.no_results_subtext}
            />
          )}
        </div>
      </div>
    </Container>
  )
}

export default ProductAnnouncementsContainer
