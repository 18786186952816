import {Content, isFilled} from '@prismicio/client'
import {PrismicRichText, SliceComponentProps} from '@prismicio/react'
import cn from 'classnames'
import {useFormContext} from 'react-hook-form'
import {Icon, IconType} from 'src/common/Icon'
import {PrismicNextImage} from '@prismicio/next'
import {InfoTooltip} from 'src/common/InfoTooltip'
import {memo} from 'react'
import {EVENTS, track} from 'src/common/helpers'
import {Tag} from 'src/common/Tag'

/**
 * Props for `FormTile`.
 */
export type FormTileProps = SliceComponentProps<Content.FormTileSlice> & {
  context: {parentFormType: string; fieldName: string; pageUid: string; isGrid?: boolean; sectionLength: number}
}

/**
 * Component for "FormTile" Slices.
 */
const FormTile = memo(({slice: {primary, items}, context, index}: FormTileProps): JSX.Element => {
  const registrationName = primary.param_value || primary.title!

  const {watch, register} = useFormContext()
  const isSelected = Boolean(watch(registrationName))
  const hasIcon = isFilled.select(primary.icon) && primary.icon !== '(Empty)'

  return (
    <>
      <label
        htmlFor={`${primary.title!}-input`}
        className={cn('flex items-center justify-between gap-[22px] bg-moss-white', {
          'shadow-elevation-1 outline outline-green-300 -outline-offset-1': isSelected,
          'group hover:shadow-elevation-1 hover:outline hover:outline-grey-150 hover:-outline-offset-1':
            !primary.not_register && !isSelected,
          'cursor-pointer': !primary.not_register,
          'md:col-span-4 lg:flex-col lg:gap-[21px]': context.isGrid,
          'lg:gap-[30px]': !context.isGrid,
          'rounded-t-lg lg:rounded-tl-lg lg:rounded-bl-lg lg:rounded-tr-none': context.isGrid && index === 0,
          'rounded-b-lg lg:rounded-tr-lg lg:rounded-br-lg lg:rounded-bl-none':
            context.isGrid && index === context.sectionLength - 1,
          'p-6': !isFilled.select(primary.icon),
          'p-[22px] gap-2': isFilled.select(primary.icon),
          'rounded-t-lg': !context.isGrid && index === 0,
          'rounded-b-lg': !context.isGrid && index === context.sectionLength - 1,
        })}
        onClick={() => {
          track({
            event: EVENTS.CLICK,
            location: `component-${index}`,
            dataType: `selector-${primary.param_value}`,
            mktgId: `${context.parentFormType?.toLowerCase()}-${isSelected ? 'deselect' : 'select'}`,
            pageName: context.pageUid,
            label: primary.title || '',
          })
        }}
      >
        <div className="flex flex-col gap-2">
          {hasIcon && (
            <div className="flex items-center gap-2">
              <Icon name={primary.icon as IconType} className="h-6 w-6 text-moss-black" />
              {isFilled.keyText(primary.tag) && <Tag label={primary.tag} color={primary.tag_color} variant="small" />}
            </div>
          )}
          <div
            className={cn('flex flex-row-reverse items-center gap-x-6', {
              'md:flex-col-reverse md:gap-y-6 md:gap-x-0 h-full': context.isGrid,
            })}
          >
            <div className="flex h-full w-full flex-col gap-2">
              <div
                className={cn('flex items-center gap-1.5', {
                  'lg:justify-between': context.isGrid,
                })}
              >
                <p className="text-body-bold text-moss-black">{primary.title}</p>
                {primary.tooltip_enabled && (
                  <InfoTooltip
                    id={`${index}-${primary.title?.replace(/ /g, '_')}`}
                    data-page-location={`component-${index}`}
                    data-type={`selector-${primary.param_value}`}
                    data-mktg-id="tool-tips-icon"
                    data-page-name={context.pageUid}
                    className="self-end pb-1"
                  >
                    <PrismicRichText
                      field={primary.tooltip_message_rte}
                      components={{
                        paragraph: ({children}) => (
                          <p
                            className={cn('text-body-s', {
                              'text-grey-135 text-center': items.length,
                              'text-moss-white': !items.length,
                            })}
                          >
                            {children}
                          </p>
                        ),
                        heading6: ({children}) => (
                          <p className="text-body-xxs px-2 pb-2 pt-3 uppercase text-grey-150">{children}</p>
                        ),
                        list: ({children}) => <ul className="flex flex-col gap-2 px-2 pb-3">{children}</ul>,
                        listItem: ({children}) => (
                          <li className="text-body-bold-s flex items-center gap-2 align-middle text-moss-white">
                            <Icon name="check" className="h-4 w-4 min-w-4" />
                            {children}
                          </li>
                        ),
                      }}
                    />
                    {items.length > 0 && (
                      <div className="flex w-[178px] flex-wrap justify-center gap-2 px-1.5 pt-2">
                        {items.map((logo, i) => {
                          return (
                            <PrismicNextImage
                              key={i}
                              width={50}
                              height={30}
                              className="max-h-[30px] max-w-[50px] self-center"
                              field={logo.tooltip_image}
                            />
                          )
                        })}
                      </div>
                    )}
                  </InfoTooltip>
                )}
                {!hasIcon && isFilled.keyText(primary.tag) && (
                  <Tag label={primary.tag} color={primary.tag_color} variant="small" />
                )}
              </div>
              {isFilled.keyText(primary.description) && (
                <p className="text-body-s text-grey-450">{primary.description}</p>
              )}
            </div>
          </div>
        </div>
        {!primary.not_register && (
          <div
            className={cn(
              'flex h-8 w-8 shrink-0 justify-center rounded-[40px] align-middle transition-[background-color] duration-200 focus:outline-none focus:ring-2 focus:ring-blue-550',
              {
                'border-grey-150 border-[1.5px] group-hover:bg-grey-150': !isSelected,
                'bg-green-200': isSelected,
                'lg:self-start': context.isGrid,
              },
            )}
            tabIndex={0}
          >
            <Icon
              className={cn('h-5 w-5 self-center', {
                'bg-grey-150 group-hover:bg-moss-white': !isSelected && !primary.not_register,
                'bg-moss-white': isSelected,
              })}
              name={isSelected ? 'check-mark-v2' : 'plus'}
            />
          </div>
        )}
      </label>
      <input
        id={`${primary.title!}-input`}
        type={context.parentFormType}
        defaultChecked={primary.pre_selected}
        {...(!primary.not_register && {...register(registrationName)})}
        {...(context.parentFormType === 'Radio' && {value: primary.title!})}
        className="hidden appearance-none"
      />
    </>
  )
})

export default FormTile
