import {isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import NextLink from 'next/link'
import {JobListPreviewSlice} from 'prismicio-types'
import {Fragment, memo, useCallback, useEffect, useState} from 'react'
import {Tab} from './Tab'

const firstTab = 'All Departments'

type Department = {
  id: number
  name: string
  jobs: Array<{id: number; absolute_url: string; location: {name: string}; title: string}>
}

const JobListPreview = (props: {slice: JobListPreviewSlice}) => {
  const [activeTab, setActiveTab] = useState<string | null>(firstTab)
  const [isLoading, setIsLoading] = useState(true)
  const [listDepartments, setListDepartments] = useState<Array<Department>>([])
  const [isError, setIsError] = useState(false)

  // Fetch jobs
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const data: {departments: Array<Department>} = await (
          await fetch('https://boards-api.greenhouse.io/v1/boards/moss/departments')
        ).json()

        const filterByJobs = data.departments.map((department) => ({
          ...department,
          jobs: department.jobs.filter((job) =>
            isFilled.keyText(props.slice.primary.list_of_job_ids)
              ? props.slice.primary.list_of_job_ids
                  .split('_')
                  .map((id) => Number(id))
                  .includes(job.id)
              : true,
          ),
        }))

        setListDepartments(filterByJobs.filter((department) => department.jobs.length))
      } catch (error) {
        console.error(error)
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }

    fetchJobs()
  }, [props.slice.primary.list_of_job_ids])

  const changeTab = useCallback((tabName: string) => setActiveTab(tabName), [])

  return (
    <section
      id={String(props.slice.primary.id_anchor)}
      className="grid scroll-mt-16 grid-cols-main pb-18 pt-9 sm:pb-20 sm:pt-12 md:pb-24 md:pt-14 xl:pt-20"
    >
      <PrismicRichText
        components={{
          heading2: ({children}) => (
            <h2 className="col-[inner] text-32 leading-10 -tracking-common text-2a2928 lg:text-36 lg:leading-11 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
              {children}
            </h2>
          ),
        }}
        field={props.slice.primary.title}
      />

      {isLoading && <div className="col-[outer] mt-14 text-20 md:col-[inner] md:mt-20">Loading...</div>}

      {!isLoading && isError && (
        <div className="col-[outer] mt-14 text-20 md:col-[inner] md:mt-20">Something went wrong</div>
      )}

      {!isLoading && !isError && (
        <Fragment>
          <div className="col-[outer] mt-14 flex gap-2 overflow-x-auto whitespace-nowrap px-6 pb-5 md:col-[inner] md:mt-20 md:flex-wrap md:px-0">
            <Tab activeTab={activeTab} tabName={firstTab} onClick={changeTab} />

            {listDepartments.map((department) => (
              <Tab key={department.id} activeTab={activeTab} tabName={department.name} onClick={changeTab} />
            ))}
          </div>

          <div className="col-[inner] mt-10 grid gap-y-10 md:mt-15 md:gap-y-15">
            {listDepartments.map((department) => (
              <div
                className={cn({hidden: activeTab !== department.name && activeTab !== firstTab})}
                key={department.id}
              >
                <div className="text-22 leading-8 text-1e2a25 sm:text-28">{department.name}</div>

                <div className="mt-5 grid gap-6 sm:grid-cols-2 md:mt-8 md:grid-cols-4">
                  {department.jobs.map((item) => (
                    <NextLink
                      key={item.id}
                      href={item.absolute_url}
                      className="grid content-between gap-y-4 rounded-xl border border-ececec p-5 transition-shadow hover:shadow-lg"
                      target="_blank"
                    >
                      <div>
                        <div className="text-20 font-bold leading-6 text-2a2928 sm:text-24 sm:leading-7">
                          {item.title}
                        </div>

                        <div className="mt-4 text-14 leading-5 text-8f8d8b">{item.location.name}</div>
                      </div>

                      <div className="text-14 font-medium leading-5 text-4e7e6c">
                        {props.slice.primary.links_label || 'Take me there →'}
                      </div>
                    </NextLink>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Fragment>
      )}
    </section>
  )
}

export default memo(JobListPreview)
