import {KeyTextField, RichTextField} from '@prismicio/client'
import {useSearchParams} from 'next/navigation'
import {
  SurveySectionSliceDefaultPrimaryFormFieldsItem,
  SurveySectionSliceDefaultPrimaryResultsItem,
} from 'prismicio-types'
import {useState, useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useCookie} from 'react-use'
import {Button} from 'src/common/Button'
import {getUtmParams, track, EVENTS} from 'src/common/helpers'
import {PrivacyPolicyCheckbox} from 'slices/SurveySection/components/PrivacyCheckbox'
import cn from 'classnames'
import {getResult} from './Results'

type InputField = {
  [x: string]: string
}

export const LeadGeneration = ({
  onContinue,
  funnelScore,
  fields,
  title,
  hubspotFormId,
  privacyPolicyLabel,
  submitLabel,
  description,
  pageUid,
  outcomeOptions,
}: {
  onContinue: () => void
  funnelScore: number
  fields: SurveySectionSliceDefaultPrimaryFormFieldsItem[]
  title: KeyTextField
  hubspotFormId: KeyTextField
  privacyPolicyLabel: RichTextField
  submitLabel: KeyTextField
  description: KeyTextField
  pageUid: string
  outcomeOptions: SurveySectionSliceDefaultPrimaryResultsItem[]
}) => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: {isValid, errors: formErrors},
  } = useForm({
    shouldUseNativeValidation: true,
  })

  const [errors, setErrors] = useState<{fieldName: string}[] | null>(null)
  const [hutk] = useCookie('hubspotutk')
  const params = useSearchParams()

  const submit = useCallback(
    async (data: {[x: string]: InputField}) => {
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/25068079/${hubspotFormId}`

      const dataFields = fields.map((field) => {
        const value = data[field.name!].value || data[field.name!]
        return {
          name: field.name!,
          value: value,
        }
      })

      const utmParams = getUtmParams(params)

      const preparedData = {
        submittedAt: new Date().getTime(),

        fields: [
          ...dataFields,
          ...utmParams,
          {
            name: 'privacyPolicy',
            value: true,
          },
          {
            name: 'e_invoicing_quiz_result',
            value: getResult(funnelScore, outcomeOptions)?.type?.replace(' ', '_').toLowerCase(),
          },
        ],

        context: {
          pageName: document?.title,
          pageUri: window?.location.href,
          ...(hutk?.length && {hutk}),
        },
      }

      if (window?.growsumo) {
        fields.forEach((field) => {
          if (field.name!) {
            // @ts-ignore -- expect error
            window.growsumo.data[field.name!] = data[field.name!].value || data[field.name!]
          }
        })

        // @ts-ignore -- expect error
        window.growsumo.data.customer_key = data.email
      }

      try {
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(preparedData),
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (!response.ok) {
          const parsedResponse = await response.json()

          const errorsWithFieldNames = parsedResponse.errors.map((error: {message: string; type: string}) => {
            const match = error.message.match(/Error in 'fields\.(\w+)'/)
            const fieldName = match ? match[1] : 'unknown'
            return {fieldName}
          })

          return setErrors(errorsWithFieldNames)
        }

        if (window.growsumo) {
          window.growsumo.createSignup()
        }

        setErrors(null)

        track({
          event: EVENTS.FORM_SUBMIT,
          location: 'component-2',
          pageName: pageUid,
          dataType: 'e-invoicing-quiz',
          mktgId: 'form-submission-button',
        })

        window?.scrollTo({top: 0, behavior: 'smooth'})

        onContinue()
      } catch (error) {
        console.error(error)
      }

      reset()
    },
    [hubspotFormId, fields, params, funnelScore, outcomeOptions, hutk, reset, pageUid, onContinue],
  )

  const validateField = (fieldName: string) => {
    if (formErrors[fieldName]) {
      return true
    } else if (errors) {
      return errors.some((error) => error.fieldName === fieldName)
    }

    return false
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-3">
        <h3 className="text-h3 font-bold text-moss-black">{title}</h3>
        <p className="text-body text-moss-black">{description}</p>
      </div>
      <form
        className="flex min-w-full max-w-[500px] flex-col gap-4 max-md:px-2 md:grid md:grid-cols-2"
        onSubmit={handleSubmit(submit)}
        data-hs-do-not-collect
      >
        <input type="hidden" name="e_invoicing_quiz_result" value="high_priority" />
        {fields.map((field, index) => {
          if (!field.type) {
            return null
          }

          return (
            <label
              key={index}
              className={cn('flex flex-col gap-1', {
                'col-span-full': fields.length % 2 !== 0 && index === fields.length - 1,
              })}
            >
              <span className="text-body-s text-start text-moss-black">{field.label}</span>
              <input
                className={cn(
                  `
                    text-body-s
                    max-h-10
                    w-full
                    rounded-lg
                    border
                    border-grey-150
                    px-3
                    py-[18px]
                    font-normal
                    leading-5
                    tracking-[.16px]
                    placeholder:text-grey-300
                    hover:bg-grey-110
                    focus:outline-none
                    `,
                  {
                    'outline-1 outline-[red] outline -outline-offset-1': validateField(field.name!),
                  },
                )}
                type={field.type || 'text'}
                placeholder={field.placeholder!}
                {...register(field.name!, {
                  required: true,
                })}
              />
            </label>
          )
        })}
        <Button
          variant="fillV2"
          type="submit"
          className={cn('col-span-full my-4 md:mt-0', {
            'text-beige-100/[30%] pointer-events-none': !isValid,
            'hover:bg-gradient-to-r hover:from-moss-white/[10%] hover:to-moss-white/[10%]': isValid,
          })}
          data-type="webinar-sign-up"
          data-mktg-id="form-submission-button"
          data-page-name={pageUid}
          data-page-location="component-2"
          size="large"
        >
          {submitLabel}
        </Button>
        <div className="col-span-full text-start">
          <PrivacyPolicyCheckbox control={control} label={privacyPolicyLabel!} />
        </div>
      </form>
    </div>
  )
}
