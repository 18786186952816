import {ReactNode, RefObject} from 'react'
import cn from 'classnames'
import {BackgroundColors} from 'src/common/types'

interface ContainerProps {
  children: ReactNode
  'data-slice-type'?: string
  'data-slice-variation'?: string
  backgroundColor?: BackgroundColors
  className?: string
  containerRef?: RefObject<HTMLDivElement>
  overflowHidden?: boolean
  style?: Record<string, string | number>
  id?: string
}

export const Container = ({
  backgroundColor = 'Moss White',
  children,
  className,
  containerRef,
  overflowHidden = true,
  style,
  id,
  ...dataSliceProps
}: ContainerProps) => {
  const isDataNavDark = backgroundColor === 'Green/900' || backgroundColor === 'Moss Black'
  return (
    <section
      className={cn(`flex scroll-mt-16 justify-center py-8 md:py-12 ${className || ''}`, {
        'bg-beige-100': backgroundColor === 'Beige/100',
        'bg-beige-200': backgroundColor === 'Beige/200',
        'bg-beige-300': backgroundColor === 'Beige/300',
        'bg-moss-white': backgroundColor === 'Moss White',
        'bg-moss-black text-moss-white': backgroundColor === 'Moss Black',
        'bg-moss-green-500': backgroundColor === 'Green/500',
        'bg-moss-green-900': backgroundColor === 'Green/900',
        'bg-5a7269': backgroundColor === 'Green (legacy)',
        'overflow-hidden': overflowHidden,
      })}
      {...dataSliceProps}
      {...(containerRef && {ref: containerRef})}
      data-nav-dark={isDataNavDark}
      style={style}
      {...(id && {id})}
    >
      {children}
    </section>
  )
}
