import {asText, isFilled} from '@prismicio/client'
import cn from 'classnames'
import {CodeSlice} from 'prismicio-types'

const Code = (props: {slice: CodeSlice}) => {
  if (!isFilled.richText(props.slice.primary.code)) {
    return null
  }

  return (
    <div
      className={cn(props.slice.primary.code_css_classes, {hidden: props.slice.primary.hidden})}
      id={isFilled.keyText(props.slice.primary.code_name) ? props.slice.primary.code_name : props.slice.id}
      dangerouslySetInnerHTML={{__html: asText(props.slice.primary.code)}}
    />
  )
}

export default Code
