import {isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import {Button} from 'src/common/Button'
import {Container} from 'src/common/Container'
import {Tag} from 'src/common/Tag'
import cn from 'classnames'
import {Assets} from 'slices/BannerV2/components/Assets'
import {BannerV2Slice, BannerV2SliceCtaPrimary} from 'prismicio-types'
import {useLinkResolver} from 'prismicio'

/**
 * Props for `BannerV2 Cta variation`.
 */
export type CtaVariationProps = {
  slice: {
    primary: BannerV2SliceCtaPrimary
    slice_type: string
    variation: BannerV2Slice['variation']
  }
  index: number
  context: {
    pageUid: string
  }
}

/**
 * Component for "BannerV2 Cta variation" Slices.
 */
export const CtaVariation = ({slice, index, context}: CtaVariationProps): JSX.Element => {
  const hasTag = isFilled.group(slice.primary.tag)
  const {linkResolver} = useLinkResolver()

  return (
    <Container
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      backgroundColor={slice.primary.background_color}
      className="!py-16 px-4"
      id={slice.primary.section_id || 'banner'}
    >
      <div className="grid-base gap-y-12">
        <div className="col-span-full flex flex-col items-center gap-6 lg:col-span-5 lg:items-start lg:justify-center lg:gap-8">
          <div className="flex flex-col items-center gap-6 lg:items-start lg:gap-8">
            {hasTag && <Tag icon={slice.primary.tag[0]?.icon} color="white" label={slice.primary.tag[0]?.tag!} />}
            <div className="flex flex-col gap-4">
              <PrismicRichText
                components={{
                  heading1: ({children}) => (
                    <h2 className="text-h2 text-center text-moss-white lg:text-start">{children}</h2>
                  ),
                  strong: ({children}) => <span className="text-accent-neon">{children}</span>,
                }}
                field={slice.primary.title}
              />
              {isFilled.richText(slice.primary.subtitle) && (
                <PrismicRichText
                  components={{
                    paragraph: ({children}) => (
                      <p className="text-body text-center text-moss-white lg:text-start">{children}</p>
                    ),
                  }}
                  field={slice.primary.subtitle}
                />
              )}
            </div>
          </div>
          <Button
            href={linkResolver(slice.primary.button_link, 'primary-button-banner-v2')}
            variant="fillV2"
            data-page-name={context.pageUid}
            data-page-location={`component-${index}-banner-v2`}
            data-type={slice.variation}
            data-mktg-id={slice.primary.button_marketing_id!}
          >
            {slice.primary.button_label}
          </Button>
        </div>
        <div
          className={cn('col-span-full lg:col-span-6 lg:col-start-7 lg:flex', {
            'lg:items-start': slice.variation === 'singleImage',
            'lg:items-center': slice.variation === 'default',
          })}
        >
          <Assets assets={slice.primary.assets} variation={slice.variation} />
        </div>
      </div>
    </Container>
  )
}
