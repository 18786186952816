import {PrismicRichText} from '@prismicio/react'
import {CustomerStoriesSliceV2Primary} from 'prismicio-types'
import {memo, useEffect, useRef, useState} from 'react'
import {useWindowSize} from 'react-use'
import {Button} from 'src/common/Button'
import {Context} from 'src/common/types'
import {Container} from 'src/common/Container'
import dynamic from 'next/dynamic'
import {CustomerStoryCard} from 'src/common/CustomerStoryCard'
import {useFilteredCustomerStories} from './useGetCustomerStories'
import {Spinner} from 'src/common/Spinner'

const TabSelector = dynamic(() => import('slices/ProductDeepDive/Default/TabSelector'), {ssr: false})

type CustomerStoriesV2Props = {
  slice: {
    primary: CustomerStoriesSliceV2Primary
    slice_type: string
    variation: string
  }
  context: Context
}

export const CustomerStoriesV2Variation = memo(function CustomerStories(props: CustomerStoriesV2Props) {
  const {width} = useWindowSize()
  const containerRef = useRef<HTMLDivElement>(null)
  const selectorRef = useRef<HTMLDivElement>(null)
  const [isShownAll, setIsShownAll] = useState(false)
  const [lessNumber, setLessNumber] = useState(3)
  const [activeCategory, setActiveCategory] = useState(0)
  const {selectedStories, availableCategories, loading} = useFilteredCustomerStories(activeCategory)

  // Define initial number of cards
  useEffect(() => {
    setIsShownAll(false)

    if (width < 640) {
      setLessNumber(3)
      return
    }

    setLessNumber(6)
  }, [width])

  return (
    <Container
      data-slice-type={props.slice.slice_type}
      data-slice-variation={props.slice.variation}
      className="px-5"
      containerRef={containerRef}
    >
      <div className="grid-base gap-y-12">
        <PrismicRichText
          components={{
            heading2: ({children}) => (
              <h2 className="text-h2 col-span-full md:col-span-8 md:col-start-3 md:text-center" ref={selectorRef}>
                {children}
              </h2>
            ),
          }}
          field={props.slice.primary.stories_title}
        />

        <TabSelector
          activeIndex={activeCategory}
          labels={availableCategories}
          onChangeHandler={(index) => setActiveCategory(index)}
          containerRef={containerRef}
          selectorRef={selectorRef}
          pageUid=""
          componentIndex={1}
          fullWidthMobile
          disableFixedBehaviour
        />

        {selectedStories.length > 0 && !loading ? (
          <section className="col-span-full grid grid-cols-subgrid gap-y-12">
            <div className="col-span-full grid grid-cols-subgrid gap-6 md:gap-y-6">
              {selectedStories.slice(0, isShownAll ? undefined : lessNumber).map((customerStory, index) => (
                <CustomerStoryCard
                  customerStory={customerStory}
                  key={index + customerStory.id + customerStory.lang}
                  readMoreLabel={props.slice.primary.read_more_card_label}
                />
              ))}
            </div>

            {!isShownAll && selectedStories.length > lessNumber && (
              <div className="col-span-full flex justify-center">
                <Button variant="fillV2" onClick={() => setIsShownAll(true)}>
                  {props.slice.primary.show_more_label}
                </Button>
              </div>
            )}
          </section>
        ) : (
          <Spinner />
        )}
      </div>
    </Container>
  )
})
