import {Content, isFilled} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {PrismicNextImage} from '@prismicio/next'
import {Container} from 'src/common/Container'
import {Gradient} from 'src/common/Gradient'

/**
 * Props for `LogoBar`.
 */
export type LogoBarProps = SliceComponentProps<Content.LogoBarSlice>

/**
 * Component for "LogoBar" Slices.
 */
const LogoBar = ({slice}: LogoBarProps): JSX.Element => {
  const {items, primary} = slice

  return (
    <Container
      backgroundColor={primary.background_color || 'Beige/100'}
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="!py-6"
    >
      <div className="flex w-full max-w-[1200px] scroll-mt-16 flex-col gap-6 md:gap-12" data-aos="fade-up">
        {isFilled.keyText(primary.tag_line) && (
          <h3 className="text-center text-16 font-medium leading-6 text-moss-black md:text-20 md:leading-7">
            {primary.tag_line}
          </h3>
        )}
        <div className="relative flex h-15 select-none gap-4 overflow-hidden" data-aos="fade-left">
          <Gradient side="left" fromColor={primary.background_color || 'Beige/100'} />
          <Gradient side="right" fromColor={primary.background_color || 'Beige/100'} />
          <div className="flex shrink-0 animate-[slide_20s_linear_infinite] items-center justify-around md:gap-4">
            {items.map((item, i) => (
              <div className="grid place-items-center" key={i}>
                <PrismicNextImage
                  field={item.logo}
                  className="aspect-video h-[40px] w-[120px] md:h-[60px] md:w-[180px]"
                />
              </div>
            ))}
          </div>
          <div
            className="flex shrink-0 animate-[slide_20s_linear_infinite] items-center justify-around md:gap-4"
            aria-hidden
          >
            {items.map((item, i) => (
              <div className="grid place-items-center" key={i}>
                <PrismicNextImage
                  field={item.logo}
                  className="aspect-video h-[40px] w-[120px] md:h-[60px] md:w-[180px]"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default LogoBar
