import dynamic from 'next/dynamic'
import Dialog from 'src/common/Dialog'
import {VideoPlayerProps} from 'src/common/VideoPlayer'

const VideoPlayer = dynamic({
  loader: () => import('src/common/VideoPlayer').then((mod) => mod.VideoPlayer),
  ssr: false,
})

export const ModalVideoPlayer = (props: VideoPlayerProps) => {
  return (
    <Dialog startOpened={false}>
      <div className="flex w-full max-w-[1000px] flex-col rounded-2xl bg-moss-white shadow-elevation-1" id="player">
        <VideoPlayer {...props} aspectRatio="aspect-video" autoplay />
      </div>
    </Dialog>
  )
}
