import {RichTextField, isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {Control, Controller} from 'react-hook-form'

export const PrivacyPolicyCheckbox = ({control, label}: {control: Control<any>; label: RichTextField}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <div className="col-span-full">
      <Controller
        control={control}
        name="privacyPolicy"
        rules={{required: true}}
        render={({field}) => (
          <label className="flex items-start gap-2 ">
            <input
              type="checkbox"
              onChange={(e) => field.onChange((e.target as HTMLInputElement).checked)}
              className="mt-1"
            />
            <PrismicRichText
              field={label}
              components={{
                paragraph: ({children}) => <p className="text-body-s text-moss-black">{children}</p>,
                hyperlink: ({node, children}) => (
                  <Link
                    className="text-moss-black underline underline-offset-2 transition-colors hover:text-grey-110/[80%]"
                    href={linkResolver(node.data)}
                    {...(isFilled.link(node.data) && node.data.link_type === 'Web' && {target: node.data.target})}
                  >
                    {children}
                  </Link>
                ),
              }}
            />
          </label>
        )}
      />
    </div>
  )
}
