import {Button} from 'src/common/Button'
import {ImageField, isFilled} from '@prismicio/client'
import {useLinkResolver} from 'prismicio'
import {HeroV2SliceSideAssetVideoItem, HeroV2SliceSideAssetVideoPrimary} from 'prismicio-types'
import {Tag} from 'src/common/Tag'
import {PrismicNextImage} from '@prismicio/next'
import {Container} from 'src/common/Container'
import {Icon} from 'src/common/Icon'
import {ModalVideoPlayer} from 'src/common/ModalVideoPlayer'
import {useCallback, useContext, MouseEvent as ReactMouseEvent} from 'react'
import {DialogContext} from 'src/context/DialogContext'
import dynamic from 'next/dynamic'
import {sanitizeHtml} from 'src/common/helpers/sanitizeHtml'

const VideoPlayer = dynamic({
  loader: () => import('src/common/VideoPlayer').then((mod) => mod.VideoPlayer),
  ssr: false,
})

type DefaultHeroV2Props = {
  slice: {
    primary: HeroV2SliceSideAssetVideoPrimary
    items: HeroV2SliceSideAssetVideoItem[]
    slice_type: string
    variation: string
  }
  pageUid: string
  componentIndex: number
}

export const SideAssetVideoHero = ({slice, pageUid, componentIndex}: DefaultHeroV2Props) => {
  const {linkResolver} = useLinkResolver()
  const videoSrc = isFilled.link(slice.primary.video_source) ? slice.primary.video_source.url : ''
  const isPopUpPlayerEnabled = slice.primary.enable_pop_up
  const sanitizedHeading = sanitizeHtml(slice.primary.heading!)

  return (
    <>
      <Container
        backgroundColor="Beige/300"
        className="flex flex-col gap-12 !py-12 md:gap-[60px] md:!pt-10"
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
      >
        <div className="mx-auto grid w-full max-w-[1200px] scroll-mt-16 grid-cols-4 gap-4 gap-y-12 px-5 md:grid-cols-12 md:gap-x-6 md:px-10 lg:px-5 xl:px-0">
          <div className="col-span-full grid grid-cols-subgrid gap-y-12 md:grid-cols-subgrid md:gap-y-0">
            <div
              className="col-span-4 flex flex-col items-center gap-6 md:col-span-6 md:items-start md:gap-8 md:self-center lg:col-span-5 lg:gap-10"
              data-aos="fade-up"
              data-aos-offset="0"
            >
              <div className="col-span-4 grid grid-cols-4 gap-4 gap-y-3 text-center md:col-span-6 md:grid-cols-6 md:gap-x-6 md:gap-y-4 md:text-start">
                <h1
                  className="text-h2 lg:text-h0 col-span-full text-moss-black"
                  dangerouslySetInnerHTML={{__html: sanitizedHeading}}
                />
                <p className="text-body col-span-full text-grey-550">{slice.primary.subtext}</p>
              </div>
              <div className="flex gap-2">
                {isFilled.keyText(slice.primary.primary_button_label) && (
                  <Button
                    variant="fillV2"
                    href={linkResolver(slice.primary.primary_button_url, 'primary-button-hero')}
                    data-mktg-id={slice.primary.primary_button_mktg_id || ''}
                    data-page-location={`component-${componentIndex}-hero`}
                    data-type="hero-button"
                    data-page-name={pageUid}
                    data-label={slice.primary.primary_button_label}
                    id={`component-${componentIndex}-hero-1`}
                  >
                    {slice.primary.primary_button_label}
                  </Button>
                )}
                {isFilled.keyText(slice.primary.secondary_button_label) && (
                  <Button
                    href={linkResolver(slice.primary.secondary_button_url, 'secondary-button-hero')}
                    variant="outlineV2"
                    data-mktg-id={slice.primary.secondary_button_mktg_id || ''}
                    data-page-location={`component-${componentIndex}-hero`}
                    data-type="hero-button"
                    data-page-name={pageUid}
                    data-label={slice.primary.secondary_button_label}
                    id={`component-${componentIndex}-hero-2`}
                  >
                    {slice.primary.secondary_button_label}
                  </Button>
                )}
              </div>
              {isFilled.keyText(slice.primary.tag) && <Tag label={slice.primary.tag} />}
            </div>
            {isPopUpPlayerEnabled && <PopUpPlayer image={slice.primary.fallback_image} videoSrc={videoSrc} />}
            {!isPopUpPlayerEnabled && (
              <div className="relative col-span-full flex w-full items-center justify-center md:col-span-6 lg:col-start-7">
                <div className="w-full">
                  <VideoPlayer
                    src={videoSrc}
                    aspectRatio="aspect-video"
                    autoplay
                    fallbackImg={slice.primary.fallback_image}
                    playIcon={
                      <div className="relative h-16 w-16 rounded-full bg-moss-black">
                        <Icon name="play-circle" className="absolute -left-2 -top-2 h-20 w-20 text-accent-neon" />
                      </div>
                    }
                  />
                </div>
              </div>
            )}
          </div>
          {!!slice.items.length && (
            <div className="col-span-full flex flex-col gap-6 md:flex-row">
              {slice.items.map((item, index) => (
                <div className="flex w-full flex-col items-center gap-6 md:items-start" key={item.title! + index}>
                  <PrismicNextImage field={item.icon} className="h-6 w-6 object-cover" />
                  <div className="flex flex-col gap-3">
                    <h3 className="text-h5 text-center leading-7 text-moss-black md:text-start">{item.title}</h3>
                    <p className="text-body text-center text-grey-450 md:text-start">{item.subtext}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Container>
    </>
  )
}

const PopUpPlayer = ({image, videoSrc}: {image: ImageField; videoSrc?: string}) => {
  const {setIsDialogOpen} = useContext(DialogContext)!

  const openDialog = useCallback(
    (e: ReactMouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      setIsDialogOpen(true)
    },
    [setIsDialogOpen],
  )

  return (
    <>
      <button
        className="relative col-span-full flex w-full justify-center align-middle md:col-span-6 lg:col-start-7"
        onClick={openDialog}
      >
        <PrismicNextImage field={image} className="h-full w-full object-cover" />
        {videoSrc && (
          <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
            <div className="relative h-16 w-16 rounded-full bg-moss-black">
              <Icon name="play-circle" className="absolute -left-2 -top-2 h-20 w-20 text-accent-neon" />
            </div>
          </div>
        )}
      </button>
      {videoSrc && <ModalVideoPlayer src={videoSrc} autoplay />}
    </>
  )
}
