import {useEffect, useState} from 'react'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import {useGetConfig} from 'src/common/hooks/useGetConfig'

export const RecaptchaWrapper = ({children}: {children: React.ReactNode}) => {
  const [isMounted, setIsMounted] = useState(false)
  const config = useGetConfig()

  // Only render provider when we have the correct config
  useEffect(() => {
    setIsMounted(true)

    return () => setIsMounted(false)
  }, [])

  if (!isMounted) {
    return children
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.GOOGLE_RECAPTCHA_SITE_KEY || ''}>{children}</GoogleReCaptchaProvider>
  )
}
