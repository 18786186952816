import {KeyTextField} from '@prismicio/client'

export const SlideHeadlineSection = ({tag, title}: {tag: KeyTextField; title: KeyTextField}) => {
  return (
    <div className="flex max-w-[416px] flex-col gap-3">
      <p className="text-body-xs-caps text-neon-450">{tag}</p>
      <h2 className="text-h4 lg:text-h3 text-moss-white">{title}</h2>
    </div>
  )
}
