import {isFilled, GroupField, Content} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import {ComparisonSectionSliceDefaultPrimary, Simplify} from 'prismicio-types'
import {Icon} from 'src/common/Icon'
import {InfoTooltip} from 'src/common/InfoTooltip'
import cn from 'classnames'
import {AvailabilityIcon} from './AvailabilityIcon'

export const ComparisonTableHead = ({
  comparisonProducts,
  backgroundColor,
}: {
  comparisonProducts: ComparisonSectionSliceDefaultPrimary['comparison_products']
  backgroundColor: ComparisonSectionSliceDefaultPrimary['background_color']
}) => (
  <div
    className={cn(
      'z-10 flex gap-5 rounded-t-xl p-5 max-md:sticky max-md:top-[64px] md:gap-6 md:rounded-t-2xl md:px-12 md:pb-8 md:pt-12',
      {
        'bg-beige-200': backgroundColor === 'Moss White' || !isFilled.select(backgroundColor),
        'bg-moss-white': backgroundColor === 'Beige/200',
      },
    )}
  >
    <div className="w-full p-0 max-md:hidden" />
    {comparisonProducts.map((product, index) => (
      <ComparisonTableHeaderCell key={index} product={product} index={index} />
    ))}
  </div>
)

const ComparisonTableHeaderCell = ({
  product,
  index,
}: {
  product: ComparisonSectionSliceDefaultPrimary['comparison_products'][0]
  index: number
}) => {
  if (product === undefined) {
    return null
  }

  return (
    <div key={index} className="flex w-full flex-col gap-2 p-0">
      {isFilled.image(product.image) && <PrismicNextImage field={product.image} className="h-10 w-10" />}
      <div className="flex w-full flex-col gap-1 text-start">
        <h4 className="text-h4 text-moss-black">{product.title}</h4>
        <span className="text-body-s font-normal text-grey-300">{product.subtext}</span>
      </div>
    </div>
  )
}

export const ComparisonTableBody = ({
  tableBody,
  backgroundColor,
  contentCenter = false,
}: {
  tableBody: GroupField<Simplify<Content.ComparisonSectionSliceDefaultPrimaryTableItem>>
  backgroundColor: ComparisonSectionSliceDefaultPrimary['background_color']
  contentCenter?: boolean
}) => (
  <div>
    {tableBody.map((row, index) => (
      <TableRow key={index} row={row} index={index} backgroundColor={backgroundColor} contentCenter={contentCenter} />
    ))}
  </div>
)

const TooltipContent = ({tooltipMessage}: {tooltipMessage: any}) => (
  <PrismicRichText
    field={tooltipMessage}
    components={{
      paragraph: ({children}) => <p className="text-body-s text-moss-white">{children}</p>,
      heading6: ({children}) => <p className="text-body-xxs px-2 pb-2 pt-3 uppercase text-grey-150">{children}</p>,
      list: ({children}) => <ul className="flex flex-col gap-2 px-2 pb-3">{children}</ul>,
      listItem: ({children}) => (
        <li className="text-body-bold-s flex items-center gap-2 align-middle text-moss-white">
          <Icon name="check" className="h-4 w-4 min-w-4" />
          {children}
        </li>
      ),
    }}
  />
)

const TableCell = ({children, textCenter = false}: {children: React.ReactNode; textCenter?: boolean}) => (
  <div
    className={cn('flex w-full items-center gap-3 px-0 py-5 md:py-[22px]', {
      'justify-center': textCenter,
    })}
  >
    {children}
  </div>
)

const TableRow = ({
  row,
  index,
  backgroundColor,
  contentCenter = false,
}: {
  row: any
  index: number
  backgroundColor: ComparisonSectionSliceDefaultPrimary['background_color']
  contentCenter?: boolean
}) => (
  <div
    key={index}
    className={cn('grid grid-cols-2 gap-5 gap-y-0 px-5 md:grid-cols-3 md:gap-6 md:px-12', {
      'bg-moss-white': index % 2 === 0 && backgroundColor === 'Beige/200',
      'bg-beige-200': index % 2 === 0 && (backgroundColor === 'Moss White' || !isFilled.select(backgroundColor)),
      'bg-[#FAFBF9]': index % 2 !== 0,
    })}
  >
    <div className="text-body-bold-s w-full pt-[18px] text-moss-black max-md:col-span-full md:px-0 md:py-[22px]">
      <div className="flex items-center gap-1.5">
        <span className="w-full">{row.benefit_header}</span>
        {isFilled.richText(row.tooltip_message) && (
          <InfoTooltip
            id={`${index}-${row.benefit_header?.replace(/[^\w\s]/g, '').replace(/ /g, '_')}`}
            className="self-end pb-[3.5px]"
          >
            <TooltipContent tooltipMessage={row.tooltip_message} />
          </InfoTooltip>
        )}
      </div>
    </div>
    <TableCell textCenter={contentCenter}>
      <AvailabilityIcon available={row.available_in_product_1} />
      <span className="text-body-s font-normal text-grey-450">{row.note_product_1}</span>
    </TableCell>
    <TableCell textCenter={contentCenter}>
      <AvailabilityIcon available={row.available_in_product_2} />
      <span className="text-body-s font-normal text-grey-450">{row.note_product_2}</span>
    </TableCell>
  </div>
)
