import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import cn from 'classnames'
import NextLink from 'next/link'
import {useLinkResolver} from 'prismicio'
import {PeopleGridSlice} from 'prismicio-types'
import {memo} from 'react'

const PeopleGrid = (props: {slice: PeopleGridSlice}) => {
  const {linkResolver} = useLinkResolver()
  const itemsAmount = props.slice.items.length

  return (
    <section
      className={cn('grid scroll-mt-16 grid-cols-main bg-ffffff', {'pb-24': props.slice.primary.has_bottom_padding})}
      id={String(props.slice.primary.id_anchor)}
    >
      <div
        className={cn('col-[inner] grid grid-cols-2 gap-x-[15px] gap-y-8 sm:grid-cols-3 sm:gap-x-[23.5px] md:gap-x-6', {
          'md:grid-cols-4': itemsAmount > 3,
          'md:grid-cols-3': itemsAmount === 3,
        })}
      >
        {props.slice.items.map((item, index) => (
          <div key={index}>
            <PrismicNextImage className="aspect-[262/185] rounded-lg object-cover sm:rounded-xl" field={item.photo} />
            <div className="mt-4 text-18 font-medium leading-6 text-232f2a">{item.name}</div>
            <div className="text-16 leading-6 text-72716e">{item.position}</div>

            {isFilled.link(item.link) && (
              <NextLink
                className="mt-8 text-14 leading-5 text-4e7e6c"
                href={linkResolver(item.link)}
                locale={item.link_locale ? item.link_locale : false}
              >
                {item.link_label}
              </NextLink>
            )}
          </div>
        ))}
      </div>
    </section>
  )
}

export default memo(PeopleGrid)
