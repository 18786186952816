import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import {TextHeaderSlice} from 'prismicio-types'
import {memo} from 'react'
import {sanitizeHtml} from 'src/common/helpers/sanitizeHtml'

const TextHeader = (props: {slice: TextHeaderSlice}) => (
  <section
    className={cn(
      'grid scroll-mt-16 grid-cols-main gap-y-4 pb-14 text-center sm:py-20 md:py-24 xl:py-30',
      {'text-232f2a pt-20': props.slice.primary.color === 'Default' || !props.slice.primary.color},
      {'bg-926077 text-ffffff pt-36': props.slice.primary.color === 'Purple'},
      {'bg-3f8896 text-ffffff pt-20': props.slice.primary.color === 'Blue'},
      {'bg-moss-green-500 text-ffffff pt-20': props.slice.primary.color === 'Green/500'},
    )}
    id={String(props.slice.primary.id_anchor)}
  >
    <PrismicRichText
      components={{
        heading1: ({node}) => (
          <h1
            className="col-[inner] text-36 font-medium leading-tight tracking-tight md:col-start-[track-start_3] md:col-end-[track-end_10] md:text-40 lg:text-48 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']"
            dangerouslySetInnerHTML={{__html: sanitizeHtml(node.text)}}
          />
        ),
      }}
      field={props.slice.primary.title}
    />

    <PrismicRichText
      components={{
        paragraph: ({children}) => (
          <p
            className={cn(
              'col-[inner] text-20 leading-tight tracking-tight md:col-start-[track-start_3] md:col-end-[track-end_10] lg:text-22',
              {'text-ffffff/70': props.slice.primary.color === 'Purple' || props.slice.primary.color === 'Blue'},
            )}
          >
            {children}
          </p>
        ),
      }}
      field={props.slice.primary.subtitle}
    />
  </section>
)

export default memo(TextHeader)
