import {Content, isFilled} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {Container} from 'src/common/Container'
import {TextLink} from 'src/common/TextLink'
import {Context} from 'src/common/types'
import {HighlightsCard} from './HighlightsCard'
import {Simplify} from 'prismicio-types'
import cn from 'classnames'

export type WithoutIconsVarProps = HighlightSectionProps & {
  context: Context
  slice: Omit<Content.HighlightSectionSlice, 'primary'> & {
    primary: Simplify<Content.HighlightSectionSliceWithoutIconsPrimary>
  }
}

/**
 * Props for `HighlightSection`.
 */
export type HighlightSectionProps = SliceComponentProps<Content.HighlightSectionSlice>

/**
 * Component for "HighlightSection" Slices.
 */
const WithoutIconsVar = ({slice, context: {pageUid}, index: componentIndex}: WithoutIconsVarProps): JSX.Element => {
  const isCenterAligned = slice.primary.center_aligned
  const limitedItems = slice.items.slice(0, 4)

  return (
    <Container
      backgroundColor={slice.primary.background_color || 'Beige/100'}
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div
        className={cn('grid w-full max-w-[1200px] grid-cols-4 gap-4 gap-y-12 px-5 md:grid-cols-12 md:gap-x-6 xl:px-0', {
          'text-center': isCenterAligned,
        })}
      >
        <div className="col-span-full grid grid-cols-4 gap-4 gap-y-12 md:grid-cols-12 md:gap-6 md:gap-y-[96px]">
          <div
            className={cn('col-span-4 flex flex-col gap-3', {
              'md:col-span-6': !isCenterAligned,
              'col-span-full': isCenterAligned,
            })}
            data-aos="fade-up"
          >
            <h2 className="text-h3 text-moss-black">{slice.primary.title}</h2>
            {isFilled.keyText(slice.primary.subtext) && (
              <p className="text-16 leading-6 tracking-[.16px] text-grey-550">{slice.primary.subtext}</p>
            )}
          </div>
          <div className="col-span-4 flex flex-col gap-6 md:col-span-12 md:flex-row md:gap-x-6" data-aos="fade-up">
            {limitedItems.map((highlightCard, index) => (
              <div
                className={cn('col-span-4 flex grow basis-0 flex-col gap-6', {
                  'items-center': isCenterAligned,
                })}
                key={index}
              >
                <HighlightsCard {...highlightCard} />
              </div>
            ))}
          </div>
        </div>
        {isFilled.keyText(slice.primary.text_link_label) && (
          <div
            className="col-span-4"
            data-aos="single-line-fade-up"
            data-aos-delay={`${(slice.items.length - 1) * 300}`}
          >
            <TextLink
              label={slice.primary.text_link_label}
              field={slice.primary.text_link_url}
              mktgId="anchor-text-link"
              location={`component-${componentIndex}`}
              pageName={pageUid}
              dataType="section-0"
            />
          </div>
        )}
      </div>
    </Container>
  )
}

export default WithoutIconsVar
