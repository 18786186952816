import {Content, isFilled} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {Button} from 'src/common/Button'
import {Container} from 'src/common/Container'
import {sanitizeHtml} from 'src/common/helpers/sanitizeHtml'
import {
  appendCurrentParams,
  useGetOriginPageParam,
} from 'src/common/hooks/useGetPricingFormHandler/useGetPricingFormHandler'
import {FieldValues, FormProvider, useForm} from 'react-hook-form'
import {useSearchParams} from 'next/navigation'
import {RadioButtonField} from './components/RadioButton'
import {Context} from 'src/common/types'

/**
 * Props for `ProductSelectorSection`.
 */
export type ProductSelectorSectionProps = SliceComponentProps<Content.ProductSelectorSectionSlice>

/**
 * Component for "ProductSelectorSection" Slices.
 */
const ProductSelectorSection = ({
  slice: {primary, slice_label, slice_type, variation},
  index,
  context: {pageUid},
}: ProductSelectorSectionProps & {context: Context}): JSX.Element => {
  const sanitizedTitle = sanitizeHtml(primary.title || '')
  const defaultOption = primary.products[0]?.parameter_value || ''
  const successUrl = isFilled.link(primary.submit_button_link) ? primary.submit_button_link.url : ''
  const params = new URLSearchParams()
  const currentParams = useSearchParams()
  const originPage = useGetOriginPageParam()

  const formHandler = (data: FieldValues) => {
    const selectedProduct = data.product || ''

    params.append(selectedProduct as string, 'true')

    appendCurrentParams(params, currentParams)

    params.append('origin-page', originPage)
    params.append('element', slice_type)
    params.append('intent', 'self_serve')

    return (location.href = `${successUrl}?${params.toString()}`)
  }

  const methods = useForm({
    defaultValues: {
      product: defaultOption,
    },
  })

  const {handleSubmit} = methods
  const onSubmit = handleSubmit(formHandler)

  return (
    <Container
      data-slice-type={slice_type}
      data-slice-variation={variation}
      data-slice-label={slice_label}
      backgroundColor="Moss White"
      className="max-md:px-5 md:max-lg:px-10"
    >
      <div className="mx-auto flex w-full max-w-[1200px] flex-col items-center gap-3 rounded-xl bg-beige-100 px-6 py-[30px] md:gap-6 md:rounded-2xl md:px-10 md:pb-5 lg:px-[90px]">
        <div className="flex flex-col gap-1 text-center max-md:pb-2">
          <h2
            id="section-title"
            className="text-h3 text-center font-medium tracking-[-.32px] text-moss-black"
            dangerouslySetInnerHTML={{__html: sanitizedTitle}}
          />
          <p className="text-body-s text-grey-550">{primary.subtitle}</p>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} data-hs-do-not-collect className="flex w-full flex-col gap-3 md:gap-6">
            <fieldset aria-labelledby="section-title" className="w-full">
              <div className="flex w-full flex-col gap-2 md:flex-row md:gap-3">
                {isFilled.group(primary.products) &&
                  primary.products.map((product) => <RadioButtonField key={product.product_name} product={product} />)}
              </div>
            </fieldset>
            <div className="flex w-full flex-col gap-2">
              {isFilled.link(primary.submit_button_link) && (
                <Button
                  variant="fillV2"
                  className="h-[50px] w-full max-w-[400px] self-center"
                  type="submit"
                  data-mktg-id="product-selector-button"
                  data-page-location={`component-${index}-product-selector-section`}
                  data-page-name={pageUid}
                  data-type="freemium-button"
                >
                  {primary.submit_button_text}
                </Button>
              )}
              {isFilled.keyText(primary.disclaimer) && (
                <p className="text-12 font-normal leading-4 tracking-[.24px] text-grey-300">{primary.disclaimer}</p>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
    </Container>
  )
}

export default ProductSelectorSection
