import cn from 'classnames'

export interface StarIconProps {
  /** Icon width / height in `px` */
  size?: number
  color?: 'Grey' | 'Neon/500'
  fillPercentage?: number
}

export function StarIcon({size = 16, fillPercentage = 100, color = 'Grey'}: StarIconProps) {
  return (
    <div className="relative" style={{width: size, height: size}}>
      <svg
        viewBox="0 0 16 16"
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
        className={cn('absolute h-full w-full text-accent-neon', {
          'text-accent-neon': color === 'Neon/500',
          'text-grey-200': color === 'Grey',
        })}
      >
        <path
          d="M7.83606 2.37214C7.97756 1.93664 8.59368 1.93664 8.73518 2.37214L9.87754 5.88795C9.94082 6.08271 10.1223 6.21457 10.3271 6.21457H14.0238C14.4818 6.21457 14.6721 6.80054 14.3017 7.0697L11.311 9.24258C11.1453 9.36295 11.076 9.57631 11.1392 9.77108L12.2816 13.2869C12.4231 13.7224 11.9246 14.0845 11.5542 13.8154L8.56347 11.6425C8.39779 11.5221 8.17345 11.5221 8.00777 11.6425L5.01705 13.8154C4.64659 14.0845 4.14814 13.7224 4.28964 13.2869L5.432 9.77108C5.49528 9.57631 5.42595 9.36295 5.26028 9.24258L2.26956 7.0697C1.8991 6.80054 2.08949 6.21457 2.5474 6.21457H6.24414C6.44892 6.21457 6.63042 6.08271 6.6937 5.88795L7.83606 2.37214Z"
          fill="currentColor"
        />
      </svg>
      <svg
        fill="#75BA28"
        viewBox="0 0 16 16"
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
        className={cn('absolute h-full w-full', {
          'text-neon-550': color === 'Neon/500',
          'text-moss-black': color === 'Grey',
        })}
        style={{
          clipPath: `inset(0 ${100 - fillPercentage}% 0 0)`,
        }}
      >
        <path
          d="M7.83606 2.37214C7.97756 1.93664 8.59368 1.93664 8.73518 2.37214L9.87754 5.88795C9.94082 6.08271 10.1223 6.21457 10.3271 6.21457H14.0238C14.4818 6.21457 14.6721 6.80054 14.3017 7.0697L11.311 9.24258C11.1453 9.36295 11.076 9.57631 11.1392 9.77108L12.2816 13.2869C12.4231 13.7224 11.9246 14.0845 11.5542 13.8154L8.56347 11.6425C8.39779 11.5221 8.17345 11.5221 8.00777 11.6425L5.01705 13.8154C4.64659 14.0845 4.14814 13.7224 4.28964 13.2869L5.432 9.77108C5.49528 9.57631 5.42595 9.36295 5.26028 9.24258L2.26956 7.0697C1.8991 6.80054 2.08949 6.21457 2.5474 6.21457H6.24414C6.44892 6.21457 6.63042 6.08271 6.6937 5.88795L7.83606 2.37214Z"
          fill="currentColor"
        />
      </svg>
    </div>
  )
}
