import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {Context} from 'src/common/types'
import {DefaultCustomerStoriesV2} from './Default'
import {AutoplayCustomerStoriesV2} from './Autoplay'

/**
 * Props for `CustomerStoriesV2`.
 */
export type CustomerStoriesV2Props = SliceComponentProps<Content.CustomerStoriesV2Slice>

/**
 * Component for "CustomerStoriesV2" Slices.
 */
const CustomerStoriesV2 = ({
  slice,
  context,
  index: componentIndex,
}: CustomerStoriesV2Props & {context: Context}): JSX.Element => {
  switch (slice.variation) {
    case 'autoplay':
      return (
        <AutoplayCustomerStoriesV2
          slice={slice}
          pageUid={context.pageUid}
          componentIndex={componentIndex}
          context={context}
        />
      )

    default:
      return (
        <DefaultCustomerStoriesV2
          slice={slice}
          pageUid={context.pageUid}
          componentIndex={componentIndex}
          context={context}
        />
      )
  }
}

export default CustomerStoriesV2
