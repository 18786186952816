import {useEffect, useState} from 'react'

export default function useDynamicBackground({initialState = false}: {initialState?: boolean}) {
  const [isBackgroundDark, setIsBackgroundDark] = useState(initialState)

  // Create observer
  useEffect(() => {
    const targetSections = document.querySelectorAll('[data-nav-dark="true"]')

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          setIsBackgroundDark(true)
        } else {
          setIsBackgroundDark(false)
        }
      },
      {
        rootMargin: '0px 0px -85% 0px',
      },
    )

    targetSections.forEach((section) => {
      observer.observe(section)
    })
  }, [])

  return {isBackgroundDark}
}
