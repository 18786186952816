import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {PodcastsSlice} from 'prismicio-types'
import {memo} from 'react'

const Podcasts = (props: {slice: PodcastsSlice}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <section className="mb-15 mt-10 grid scroll-mt-16 grid-cols-main" id={String(props.slice.primary.id_anchor)}>
      <div className="col-[inner] grid w-full max-w-[46.25rem] justify-start justify-self-center">
        {props.slice.items.map((item, index) => (
          <div
            className="mb-4 grid max-w-[500px] grid-cols-2 items-center gap-x-4 border-b-2 border-2a2928/5 pb-4"
            key={index}
          >
            <PrismicNextImage className="max-w-[70%]" field={item.logo} />

            <Link
              className="max-w-[180px] rounded-md border border-dcdbda px-8 py-2 text-center text-22 text-8f8d8b shadow-link"
              href={linkResolver(item.link)}
              {...(isFilled.link(item.link) && item.link.link_type === 'Web' && {target: item.link.target})}
            >
              {item.label}
            </Link>
          </div>
        ))}
      </div>
    </section>
  )
}

export default memo(Podcasts)
