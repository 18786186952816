import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {ArticleContentSlice} from 'prismicio-types'
import {memo} from 'react'
import {Icon} from 'src/common/Icon'

const ArticleContent = (props: {slice: ArticleContentSlice}) => {
  const {linkResolver} = useLinkResolver()

  return (
    <section className="grid grid-cols-main">
      <div className="col-[inner] max-w-[46.25rem] justify-self-center">
        <PrismicRichText
          components={{
            heading2: ({children}) => (
              <h2 className="mb-6 text-32 leading-10 -tracking-common text-1e2a25 sm:mb-8 md:mb-10 lg:text-36 lg:leading-11">
                {children}
              </h2>
            ),
            heading3: ({children}) => (
              <h3 className="mb-8 text-24 font-medium leading-7 text-1e2a25 md:mb-10 lg:mb-12">{children}</h3>
            ),
            heading5: ({children}) => (
              <p className="-mt-7.5 mb-10 text-center text-14 leading-5 text-595755">{children}</p>
            ),
            heading6: ({children}) => (
              <blockquote className="mb-4 px-5 py-2.5 text-28 leading-8 text-232f2a md:pl-15">{children}</blockquote>
            ),
            paragraph: ({children}) => (
              <p className="mb-6 text-18 leading-[1.625rem] text-666563 md:mb-10">{children}</p>
            ),
            list: ({children}) => (
              <ul className="mb-4 list-disc pl-6 text-18 leading-[1.625rem] text-666563 md:mb-10 md:pl-9">
                {children}
              </ul>
            ),
            listItem: ({children}) => <li className="mb-2 pl-2">{children}</li>,
            oList: ({children}) => <ul className="text-18 leading-[1.625rem] text-666563 md:pl-8">{children}</ul>,
            oListItem: ({children}) => (
              <li className="mb-2.5 grid grid-cols-[auto_1fr] gap-x-4">
                <Icon className="h-[1.625rem] w-4 gap-x-4" name="small-logo" />
                <span>{children}</span>
              </li>
            ),
            hyperlink: ({node, children}) => (
              <Link
                className="text-456e5e underline transition-colors hover:text-6c9d8b"
                href={linkResolver(node.data)}
                {...(isFilled.link(node.data) && node.data.link_type === 'Web' && {target: node.data.target})}
              >
                {children}
              </Link>
            ),
            image: ({node, key}) => {
              const img = (
                <PrismicNextImage
                  className="mb-10"
                  field={node}
                  data-copyright={node.copyright ? node.copyright : undefined}
                />
              )

              return <p key={key}>{node.linkTo ? <Link href={linkResolver(node.linkTo)}>{img}</Link> : img}</p>
            },
          }}
          field={props.slice.primary.content}
        />
      </div>
    </section>
  )
}

export default memo(ArticleContent)
