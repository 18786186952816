import {isFilled} from '@prismicio/client'
import {useLinkResolver} from 'prismicio'
import {HeroV2SliceVideoPrimary} from 'prismicio-types'
import {Button} from 'src/common/Button'
import {Container} from 'src/common/Container'
import {IconRatings} from './RatingsSection/IconRatings'
import {StarRatings} from './RatingsSection/StarRatings'
import cn from 'classnames'
import dynamic from 'next/dynamic'
import {Icon} from 'src/common/Icon'
import {sanitizeHtml} from 'src/common/helpers/sanitizeHtml'

const VideoPlayer = dynamic(() => import('src/common/VideoPlayer').then((mod) => mod.VideoPlayer), {ssr: false})

type VideoHeroProps = {
  slice: {
    primary: HeroV2SliceVideoPrimary
    slice_type: string
    variation: string
  }
  pageUid: string
  componentIndex: number
}

export const VideoHero = ({slice, componentIndex, pageUid}: VideoHeroProps) => {
  const {linkResolver} = useLinkResolver()
  const headlineSection = slice.primary.headlinesection[0]
  const ratingsSection = slice.primary.ratings
  const videoSrc = isFilled.link(slice.primary.video_source) ? slice.primary.video_source.url : ''

  if (!headlineSection) {
    return null
  }

  const sanitizedHeading = sanitizeHtml(headlineSection.heading!)

  return (
    <Container
      backgroundColor="Beige/200"
      className="flex flex-col gap-12 py-12 md:gap-[60px]"
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div className="mx-auto grid w-full max-w-[1200px] scroll-mt-16 grid-cols-4 gap-4 gap-y-12 overflow-hidden px-5 md:grid-cols-12 md:gap-x-6 md:gap-y-[60px] md:pt-12 xl:px-0">
        <div
          className={cn('col-span-4 flex flex-col gap-6 md:col-start-2 md:col-end-12', {
            'lg:col-start-4 lg:col-end-10': !headlineSection.extended_text_width,
            'lg:col-start-3 lg:col-end-11': headlineSection.extended_text_width,
          })}
          data-aos="fade-up"
          data-aos-offset="0"
        >
          <div className="flex justify-center gap-3">
            {ratingsSection &&
              ratingsSection.map((review, index) => {
                return headlineSection.show_stars_in_rating ? (
                  <StarRatings review={review} key={`${review.review_platform} - ${index}`} />
                ) : (
                  <IconRatings review={review} key={`${review.review_platform} - ${index}`} />
                )
              })}
          </div>
          <div className="col-span-4 grid grid-cols-4 gap-4 gap-y-3 text-center md:grid-cols-6 md:gap-x-6">
            <h1
              className="col-span-full text-40 font-medium leading-[48px] text-moss-black md:text-[56px] md:leading-[64px]"
              dangerouslySetInnerHTML={{__html: sanitizedHeading}}
            />
            <p className="col-span-full text-16 leading-6 tracking-[.16px] text-grey-550 md:col-start-2 md:col-end-6">
              {headlineSection.subtext}
            </p>
          </div>
          <div className="mx-auto mb-6 flex gap-2">
            {isFilled.keyText(headlineSection.primary_button_label) && (
              <Button
                variant="fillV2"
                href={linkResolver(headlineSection.primary_button_url, 'primary-button-hero')}
                data-mktg-id="book-demo-button"
                data-page-location={`component-${componentIndex}-hero`}
                data-type={headlineSection.primary_button_marketing_id || ''}
                data-page-name={pageUid}
                data-label={headlineSection.primary_button_label}
                id={`component-${componentIndex}-hero-1`}
              >
                {headlineSection.primary_button_label}
              </Button>
            )}
            {isFilled.keyText(headlineSection.secondary_button_label) && (
              <Button
                href={linkResolver(headlineSection.secondary_button_link, 'secondary-button-hero')}
                variant="outlineV2"
                data-mktg-id={headlineSection.secondary_button_marketing_id || ''}
                data-page-location={`component-${componentIndex}-hero`}
                data-type="hero-button"
                data-page-name={pageUid}
                data-label={headlineSection.secondary_button_label}
                id={`component-${componentIndex}-hero-2`}
              >
                {headlineSection.secondary_button_label}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="mx-auto aspect-video w-full max-w-[1200px] px-5 xl:px-0">
        <VideoPlayer
          src={videoSrc}
          fallbackImg={slice.primary.fallback_image}
          playIcon={
            <div className="relative h-16 w-16 rounded-full bg-moss-black">
              <Icon name="play-circle" className="absolute -left-2 -top-2 h-20 w-20 text-accent-neon" />
            </div>
          }
          pageUid={pageUid}
          location={`component-${componentIndex}-hero`}
          variation={slice.variation}
          autoplay
        />
      </div>
    </Container>
  )
}
