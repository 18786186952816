import {isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {TopBannerSlice} from 'prismicio-types'
import {memo} from 'react'
import {EVENTS, track} from 'src/common/helpers'
import {Context} from 'src/common/types'

const TopBanner = (props: {slice: TopBannerSlice; context: Context}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <header className="sticky top-0 z-20 flex h-11 flex-row items-center justify-center gap-1 bg-3a5f51 text-ffffff">
      <PrismicRichText field={props.slice.primary.title} />
      <Link
        className="underline"
        href={linkResolver(props.slice.primary.link, 'primary-button-top-banner')}
        {...(isFilled.link(props.slice.primary.link) &&
          props.slice.primary.link.link_type === 'Web' &&
          props.slice.primary.link && {target: props.slice.primary.link.target})}
        onClick={() => {
          track({
            event: EVENTS.CLICK,
            mktgId: 'book-demo-link',
            location: `banner-${props.context.pageUid}`,
            pageName: props.context.pageUid,
            dataType: 'banner-link',
            label: props.slice.primary.link_text!,
          })
        }}
      >
        {props.slice.primary.link_text}
      </Link>
    </header>
  )
}

export default memo(TopBanner)
