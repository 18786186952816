import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import NextLink from 'next/link'
import {useLinkResolver} from 'prismicio'
import {DocumentSlice} from 'prismicio-types'

const Document = (props: {slice: DocumentSlice}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <section className="grid scroll-mt-16 grid-cols-main pb-20" id={String(props.slice.primary.id_anchor)}>
      <div className="col-[inner] flex flex-wrap gap-x-6 gap-y-4">
        {props.slice.items.map((item, index) => (
          <NextLink
            className="rounded-[20px] bg-ffffff py-6 pl-6 pr-10 transition-shadow hover:shadow-article max-md:w-full md:max-w-[357px] md:flex-1"
            key={index}
            href={linkResolver(item.link)}
            {...(isFilled.link(item.link) && item.link.link_type === 'Web' && {target: item.link.target})}
          >
            <PrismicNextImage alt="" field={item.icon} />

            <PrismicRichText
              components={{
                heading3: ({children}) => (
                  <h2 className="mt-4 text-28 leading-8 text-2a2928 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
                    {children}
                  </h2>
                ),
              }}
              field={item.title}
            />

            <div className="mt-2 font-medium text-6c9d8b">{item.label}</div>
          </NextLink>
        ))}
      </div>
    </section>
  )
}

export default Document
