import {KeyTextField, LinkField, ImageField, isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {useLinkResolver} from 'prismicio'
import {Button} from 'src/common/Button'

interface CardProps {
  title?: KeyTextField
  description?: KeyTextField
  buttonLabel?: KeyTextField
  buttonLink: LinkField
  image?: ImageField
  logo?: ImageField
  index: number
  pageUid: string
  componentIndex: number
  locale?: string
}

export const Card = ({
  title,
  description,
  buttonLabel,
  buttonLink,
  image,
  logo,
  index,
  pageUid,
  componentIndex,
  locale,
}: CardProps) => {
  const {linkResolver} = useLinkResolver()
  return (
    <div className="flex h-full flex-col-reverse justify-between gap-8 p-4 md:flex-row md:gap-12 md:p-6 lg:pl-12 xl:items-center xl:gap-0">
      <div className="flex h-full flex-col justify-between px-2 pb-4 md:px-0 md:pb-0 xl:max-h-[362px]">
        <div className="flex max-w-[540px] flex-col gap-3 pb-6">
          <h2 className="text-32 font-medium leading-10 text-moss-black md:text-40 lg:leading-12">{title}</h2>
          <p className="text-16 leading-6 text-grey-550 antialiased md:text-20 md:leading-[30px]">{description}</p>
        </div>
        {isFilled.keyText(buttonLabel) && (
          <Button
            variant="outline"
            className="w-fit bg-transparent"
            href={linkResolver(buttonLink)}
            data-mktg-id="customer-stories-button"
            data-page-location={`component-${componentIndex}-carousel-${index}`}
            data-type={`slide-${index}`}
            data-page-name={pageUid}
            locale={locale}
          >
            {buttonLabel}
          </Button>
        )}
      </div>
      <div className="relative md:w-[295px] lg:w-[40.96%]">
        <PrismicNextImage field={image} className="rounded-lg" priority />
        <PrismicNextImage
          className="absolute left-[50%] top-[50%] h-8 w-auto translate-x-[-50%] translate-y-[-50%]"
          style={{
            filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(5deg) brightness(101%) contrast(105%)',
          }}
          field={logo}
        />
      </div>
    </div>
  )
}
