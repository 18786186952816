import {isFilled} from '@prismicio/client'
import {Container} from 'src/common/Container'
import {PrismicNextImage} from '@prismicio/next'
import cn from 'classnames'
import {StarRating} from 'src/common/StarRating'
import {CustomerSpotlightSliceDefault} from 'prismicio-types'

export const DefaultVariation = ({slice}: {slice: CustomerSpotlightSliceDefault}): JSX.Element => {
  const {
    primary: {
      image_right_side: isImageRight,
      background_color,
      image,
      ratings,
      card_background_color,
      testimony,
      author,
      customer_icon,
    },
    variation,
  } = slice

  const isDefault = variation === 'default'

  return (
    <Container data-slice-variation={slice.variation} backgroundColor={background_color}>
      <div
        className={cn('grid w-full max-w-[1200px] grid-cols-1 gap-4 px-5 xl:px-0', {
          'md:grid-cols-2 md:items-center': isDefault,
          'lg:grid-cols-[auto_38%] xl:grid-cols-[auto_494px]': isDefault && isImageRight,
          'lg:grid-cols-[38%_auto] xl:grid-cols-[494px_auto]': isDefault && !isImageRight,
        })}
        data-aos="fade-up"
      >
        <div
          className={cn('relative', {
            'md:order-2': isImageRight,
          })}
        >
          <PrismicNextImage field={image} className="rounded-lg" />
          {isFilled.group(ratings) && (
            <div className="absolute bottom-6 z-10 flex w-full flex-col items-center justify-center gap-2 lg:flex-row">
              {ratings.map((platform, index) => (
                <div
                  className="flex h-10 w-min items-center gap-1.5 rounded-lg bg-moss-white px-4"
                  key={platform.platform || '' + index}
                >
                  <span className="text-body-s">{platform.platform}</span>
                  <StarRating rating={platform.rating!} />
                  <span className="text-body-s">{platform.rating}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          className={cn(
            'flex w-full flex-col gap-8 rounded-lg px-6 py-8 md:h-full md:justify-between md:gap-4 md:px-8 lg:gap-8 lg:p-12',
            {
              'bg-beige-200': background_color === 'Moss White' && !isFilled.select(card_background_color),
              'bg-moss-white':
                (background_color === 'Beige/100' || !background_color) && !isFilled.select(card_background_color),
              'bg-accent-neon': card_background_color === 'Neon Green',
              'bg-moss-black': card_background_color === 'Moss Black',
            },
          )}
        >
          <div className="flex flex-col gap-3 lg:max-w-[540px]">
            <p className="text-20 font-medium leading-7 text-moss-black lg:text-24 lg:leading-8">{testimony}</p>
            <p className="text-grey-550">{author}</p>
          </div>
          {isFilled.image(customer_icon) && (
            <img src={customer_icon.url} alt={customer_icon.alt || ''} className="self-start" />
          )}
        </div>
      </div>
    </Container>
  )
}
