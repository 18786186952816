import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {PartnersSliceDefaultItem, Simplify} from 'prismicio-types'
import {memo} from 'react'

export const Card = memo(function Card(props: {data: Simplify<PartnersSliceDefaultItem>; className?: string}) {
  const {linkResolver} = useLinkResolver()
  return (
    <Link
      className={cn(
        'grid place-content-between gap-y-4 rounded-xl border border-ececec p-5 transition-shadow hover:shadow-partner',
        props.className,
      )}
      href={linkResolver(props.data.link)}
      {...(isFilled.link(props.data.link) &&
        props.data.link.link_type === 'Web' &&
        props.data.link.target && {target: props.data.link.target})}
    >
      <div className="grid gap-y-4">
        <div className="grid grid-flow-col items-start justify-between">
          <PrismicNextImage alt="" className="h-10 max-w-[40px] object-contain" field={props.data.logo} sizes="40px" />

          {props.data.is_certified && (
            <div className="grid h-5 items-center rounded-4 bg-e7d39d px-1 text-10 uppercase leading-none tracking-[1px] text-6e560c">
              CERTIFIED
            </div>
          )}
        </div>

        <PrismicRichText
          components={{
            heading3: ({children}) => (
              <h2 className="text-20 leading-6 text-2a2928 sm:text-24 sm:leading-7">{children}</h2>
            ),
          }}
          field={props.data.title}
        />

        <PrismicRichText
          components={{
            paragraph: ({children}) => <p className="text-14 leading-5 text-8f8d8b">{children}</p>,
          }}
          field={props.data.description}
        />
      </div>

      <div className="text-14 font-medium leading-5 text-4e7e6c">{props.data.link_label}</div>
    </Link>
  )
})
