import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import {MerchantConnectionsSlice} from 'prismicio-types'
import {memo} from 'react'

const MerchantConnections = (props: {slice: MerchantConnectionsSlice}) => (
  <section className="grid scroll-mt-16 grid-cols-main pb-18 pt-16" id={String(props.slice.primary.id_anchor)}>
    <PrismicRichText
      components={{
        heading2: ({children}) => (
          <h2 className="col-[inner] text-center text-32 leading-10 text-2a2928 xl:col-start-[track-start_3] xl:col-end-[track-end_10] [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
            {children}
          </h2>
        ),
      }}
      field={props.slice.primary.title}
    />

    <PrismicRichText
      components={{
        paragraph: ({children}) => (
          <p className="col-[inner] mt-6 text-center text-18 leading-6 text-2a2928 xl:col-start-[track-start_3] xl:col-end-[track-end_10]">
            {children}
          </p>
        ),
      }}
      field={props.slice.primary.description}
    />

    <div className="col-[inner] mt-18 grid grid-cols-4 gap-x-6 gap-y-2.5 xl:col-start-[track-start_3] xl:col-end-[track-end_10]">
      {props.slice.items.map((item, index) => (
        <PrismicNextImage className="h-16 object-cover" field={item.logo} key={index} />
      ))}
    </div>
  </section>
)

export default memo(MerchantConnections)
