import {PrismicRichText} from '@prismicio/react'
import {ChecklistSlice} from 'prismicio-types'
import {Fragment, memo} from 'react'

const Checklist = (props: {slice: ChecklistSlice}) => (
  <Fragment>
    <section className="grid scroll-mt-16 grid-cols-main py-14 md:py-20" id={String(props.slice.primary.id_anchor)}>
      <div className="col-[inner] grid gap-y-4">
        <PrismicRichText
          components={{
            heading2: ({children}) => (
              <h2 className="text-32 leading-10 -tracking-common text-232f2a xl:text-36 xl:leading-11 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
                {children}
              </h2>
            ),
            paragraph: ({children}) => (
              <p className="text-18 leading-6 text-72716e [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
                {children}
              </p>
            ),
          }}
          field={props.slice.primary.title}
        />
      </div>
    </section>

    <section className="grid grid-cols-main bg-ffffff pt-12 sm:pt-14 md:pt-16">
      <div className="col-[inner] grid content-start gap-y-4 md:col-end-[track-end_6] xl:col-end-[track-end_5]">
        <PrismicRichText
          components={{
            heading3: ({children}) => <h3 className="text-28 leading-8 -tracking-common">{children}</h3>,
            strong: ({children}) => <strong className="tracking-normal">{children}</strong>,
            paragraph: ({children}) => <p className="leading-6 text-72716e">{children}</p>,
          }}
          field={props.slice.primary.subtitle}
        />
      </div>

      <div className="col-[inner] mt-10 grid gap-y-3 md:col-start-[track-start_7] md:mt-16">
        {props.slice.items.map((item, index) => (
          <div className="grid grid-flow-col justify-start gap-x-4" key={index}>
            <img aria-hidden alt="" className="h-6 w-6 select-none" src="/icons/fill-check.svg" />
            <div className="leading-6 text-232f2a">{item.item}</div>
          </div>
        ))}
      </div>
    </section>
  </Fragment>
)

export default memo(Checklist)
