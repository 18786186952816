import {Content} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {SliceComponentProps} from '@prismicio/react'
import {Container} from 'src/common/Container'
import {Tag} from 'src/common/Tag'
import {TextLink} from 'src/common/TextLink'
import {Context} from 'src/common/types'

/**
 * Props for `HighlightsMediaSection`.
 */
export type HighlightsMediaSectionProps = SliceComponentProps<Content.HighlightsMediaSectionSlice>

/**
 * Component for "HighlightsMediaSection" Slices.
 */
const HighlightsMediaSection = ({
  slice: {primary, items, slice_type, variation},
  index: componentIndex,
  context,
}: HighlightsMediaSectionProps & {context: Context}): JSX.Element => {
  return (
    <Container
      backgroundColor={primary.background_color || 'Beige/100'}
      data-slice-type={slice_type}
      data-slice-variation={variation}
    >
      <div className="flex flex-col gap-12 px-5 lg:px-0">
        {primary.title && (
          <div className="grid-base gap-y-6 px-5 lg:px-0">
            <div className="col-span-full grid grid-cols-[subgrid] gap-y-3 lg:col-span-6">
              <h2 className="text-h3 md:text-h2 col-span-full text-moss-black">{primary.title}</h2>
              {primary.subtext && <p className="text-body col-span-full text-grey-550">{primary.subtext}</p>}
            </div>
          </div>
        )}
        <div className="grid-base gap-6">
          {items.map((item, index) => (
            <div className="col-span-4 flex flex-col gap-8 md:col-span-6" key={index}>
              <PrismicNextImage field={item.image} width={588} height={330} className="aspect-video rounded-lg" />
              <div className="flex h-full flex-col gap-6">
                <div className="flex flex-col gap-3">
                  <Tag label={item.tag} />
                  <h4 className="text-h4 text-moss-black">{item.headline}</h4>
                  <p className="text-body text-grey-550">{item.description}</p>
                </div>
                <div className="flex h-full flex-col justify-end gap-6">
                  <p className="text-body-s text-grey-300">{item.date}</p>
                  <TextLink
                    label={item.link_text!}
                    field={item.link_url}
                    dataType="highlight-media-link"
                    mktgId={item.link_mktg_id!}
                    pageName={context.pageUid}
                    location={`component-${componentIndex}-highlight-media-${index}`}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}

export default HighlightsMediaSection
