import {SurveySectionSliceDefaultPrimary} from 'prismicio-types'

export type AnswerType = {
  value: number
  text: string
}

export type QuizDataType = {
  question: string
  answers: AnswerType[]
}[]

export const transformData = (data: SurveySectionSliceDefaultPrimary): QuizDataType => {
  let result = []

  for (let key in data) {
    if (key.startsWith('question_')) {
      let questionKey = key
      let answersKey = `answers_${questionKey}`

      if (data[answersKey as keyof SurveySectionSliceDefaultPrimary]) {
        result.push({
          question: String(data[questionKey as keyof SurveySectionSliceDefaultPrimary]),
          answers: (data[answersKey as keyof SurveySectionSliceDefaultPrimary] as unknown as AnswerType[]) || [],
        })
      }
    }
  }

  return result
}
