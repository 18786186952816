import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {useContext, memo} from 'react'
import {Button} from 'src/common/Button'
import {DEFAULT_DURATION, ProductDeepDiveContext} from 'slices/ProductDeepDive/Default/ProductDeepDiveDefault'
import cn from 'classnames'
import {useLinkResolver} from 'prismicio'
import dynamic from 'next/dynamic'
import {Context} from 'src/common/types'
import {motion} from 'framer-motion'
import {track, EVENTS} from 'src/common/helpers'
const LottiePlayer = dynamic(() => import('./LottiePlayer'), {ssr: false})

/**
 * Props for `ProductDeepDiveContent`.
 */
export type ProductDeepDiveContentProps = SliceComponentProps<Content.ProductDeepDiveContentSlice>

/**
 * Component for "ProductDeepDiveContent" Slices.
 */
const ProductDeepDiveContent = ({
  slice: {primary},
  index: id,
}: ProductDeepDiveContentProps & {context: Context}): JSX.Element => {
  const {
    activeChapter,
    handleChapterChange,
    duration,
    isMobile,
    activeTab,
    pageUid,
    selectorRef,
    running,
    componentIndex,
  } = useContext(ProductDeepDiveContext)!

  const {linkResolver} = useLinkResolver()
  const isOpen = id === activeChapter
  const progressBarDelay = isMobile ? 1 : 1.2

  const handleClick = () => {
    if (!isOpen) {
      handleChapterChange(id)
      scrollToTop()

      track({
        event: EVENTS.CLICK,
        location: `component-${componentIndex}-deep-dive-${activeTab}`,
        dataType: `chapter-${id}`,
        mktgId: 'row-button',
        pageName: pageUid,
        label: primary.tag!,
      })
    }
  }

  const scrollToTop = () => {
    if (isMobile) {
      setTimeout(() => {
        window?.scrollTo({
          top: selectorRef.current?.offsetTop + 100,
          behavior: 'smooth',
        })
      }, 100)
    }
  }

  return (
    <>
      <div
        className={cn('relative flex w-full flex-col  md:duration-700', {
          'grow-[1] transition-all duration-[700ms] md:duration-700 ease-in-out': isOpen,
          'grow-[.001] h-min transition-all duration-[700ms] md:duration-700 ease-in-out': !isOpen,
        })}
      >
        <div
          className={cn(
            'flex gap-2 py-4 text-14 font-medium leading-5 tracking-[.28px] transition-all duration-700 md:text-16 md:leading-6 md:tracking-[.16px] md:duration-700',
            {
              'text-grey-200 cursor-pointer': !isOpen,
              'text-moss-black cursor-default': isOpen,
            },
          )}
          onClick={handleClick}
        >
          <p>{`${id + 1}.`}</p>
          <p>{primary.tag}</p>
        </div>
        <div
          className={cn('relative flex flex-col gap-6 overflow-hidden', {
            'max-h-[1000px] md:max-h-[428px] delay-700 transition-all duration-[900ms] md:delay-700 md:duration-700 ease-in-out':
              isOpen,
            'max-h-0 opacity-0 transition-all duration-[700ms] md:duration-700 ease-in-out': !isOpen,
          })}
        >
          <div className="flex flex-col gap-3 transition-[margin] duration-100">
            <h3
              className={cn(
                'pt-3 text-24 font-medium leading-8 text-moss-black transition-all delay-100 duration-700 md:pt-6 md:text-32 md:leading-10',
                {
                  'opacity-100': isOpen,
                  'opacity-0': !isOpen,
                },
              )}
            >
              {primary.title}
            </h3>
            <p className="text-16 leading-6 tracking-[.16px] text-grey-550">{primary.description}</p>
          </div>
          <Button
            variant="outlineV2"
            href={linkResolver(primary.lean_more_url)}
            className="mb-8 w-min whitespace-nowrap"
            data-mktg-id="product-landing-button"
            data-type={`section-${activeChapter}`}
            data-page-location={`component-${componentIndex}-deep-dive-${activeTab}`}
            data-page-name={pageUid}
            data-label={primary.learn_more_label}
          >
            {primary.learn_more_label}
          </Button>
        </div>
        <span className="relative h-[1px] w-full bg-grey-135">
          {isOpen && running && (
            <motion.div
              animate={{width: '100%'}}
              transition={{
                duration: duration || DEFAULT_DURATION,
                delay: progressBarDelay,
              }}
              className="absolute bottom-0 left-0 z-10 h-[1px] bg-moss-black"
            />
          )}
        </span>
        <div
          id={`animation-mobile-container-${id}`}
          className={cn('flex items-center rounded-xl bg-beige-300', {
            'opacity-0 h-0 transition-all duration-700 ease-in-out': !isOpen && isMobile,
            'opacity-100 h-[350px] delay-700 transition-all duration-700 ease-in-out': isOpen && isMobile,
            'mt-6': isOpen && isMobile,
          })}
        />
      </div>
      {isOpen && (
        <LottiePlayer src={linkResolver(primary.lottie_animation).pathname} isOpen={isOpen} index={id} loop={false} />
      )}
    </>
  )
}

export default memo(ProductDeepDiveContent)
