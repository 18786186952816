import {ReactNode, memo, useEffect, useRef, useState} from 'react'
import {createPortal} from 'react-dom'

export const Portal = memo((props: {children: ReactNode}) => {
  const ref = useRef<Element | null>(null)
  const [mounted, setMounted] = useState(false)

  // Use the state variable mounted to ensure the component is mounted.
  useEffect(() => {
    ref.current = document.getElementById('portal')
    setMounted(true)
  }, [])

  return mounted && ref.current ? createPortal(props.children, ref.current) : null
})
