import {DotLottiePlayer} from '@dotlottie/react-player'
import '@dotlottie/react-player/dist/index.css'
import {createPortal} from 'react-dom'
import {PrismicNextImage} from '@prismicio/next'
import {useWindowSize} from 'react-use'
import {useEffect, useRef, useState} from 'react'
import {ImageField, isFilled, LinkField} from '@prismicio/client'
import {useLinkResolver} from 'prismicio'
import {AnimatePresence, motion} from 'framer-motion'
import cn from 'classnames'
import {OPACITY_0, OPACITY_1} from 'src/common/constants'

const ANIMATION_PLAY_DELAY = 1000

export const MediaContainer = ({
  animationSrc,
  isOpen,
  index,
  loop,
  image,
}: {
  animationSrc: LinkField
  isOpen: boolean
  index: number
  loop: boolean
  image: ImageField
}) => {
  const {width} = useWindowSize()
  const {linkResolver} = useLinkResolver()
  const isMobile = width < 768
  const [shouldAppear, setshouldAppear] = useState(false)
  const animationContainer = useRef<Element | null>(null)
  const dLottiePlayerRef = useRef<any>(null)
  const isAnimationMounted = Boolean(dLottiePlayerRef?.current)

  // Play Lottie when it's opened
  useEffect(() => {
    setTimeout(() => {
      if (isOpen && isAnimationMounted) {
        dLottiePlayerRef.current?.play()
      }
    }, ANIMATION_PLAY_DELAY)
  }, [isOpen, isAnimationMounted])

  // Create Portal to set animation
  useEffect(() => {
    setTimeout(() => {
      const selector = isMobile ? `#animation-mobile-container-${index}` : '#animation-container'
      animationContainer.current = document?.querySelector(selector)
    }, 700)
  }, [isMobile, index])

  const Component = isFilled.link(animationSrc) ? (
    <DotLottiePlayer
      loop={loop}
      src={linkResolver(animationSrc)}
      ref={dLottiePlayerRef}
      onEvent={(event) => {
        if (event === 'ready') {
          setshouldAppear(true)
        }

        if (event === 'frame') {
          const lottieInstance = dLottiePlayerRef.current._lottie || {}

          if (lottieInstance.currentFrame === lottieInstance.totalFrames) {
            dLottiePlayerRef.current.pause()
          }
        }
      }}
      className={cn('ease overflow-hidden transition-all duration-700', {
        'opacity-100': shouldAppear,
        'opacity-0': !shouldAppear,
      })}
    />
  ) : (
    <PrismicNextImage field={image} priority className="object-cover" />
  )

  return animationContainer.current
    ? createPortal(
        <AnimatePresence mode="wait">
          {isOpen && (
            <motion.div
              initial={OPACITY_0}
              animate={OPACITY_1}
              exit={OPACITY_0}
              transition={{duration: 0.7, delay: 0.3}}
              className="flex h-full items-center justify-center"
            >
              {Component}
            </motion.div>
          )}
        </AnimatePresence>,
        animationContainer.current,
      )
    : null
}
