import {Content, isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {SliceComponentProps} from '@prismicio/react'
import {Container} from 'src/common/Container'
import {ComparisonTableBody, ComparisonTableHead} from './components/Table'
import cn from 'classnames'

/**
 * Props for `ComparisonSection`.
 */
export type ComparisonSectionProps = SliceComponentProps<Content.ComparisonSectionSlice>

/**
 * Component for "ComparisonSection" Slices.
 */
const ComparisonSection = ({slice}: ComparisonSectionProps): JSX.Element => {
  return (
    <Container
      backgroundColor={slice.primary.background_color}
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="overflow-visible px-5 md:max-lg:px-10"
    >
      <div className="flex w-full flex-col gap-12 md:gap-4 lg:flex-row xl:max-w-[1200px]">
        <div
          className={cn('order-2 w-full overflow-visible rounded-2xl pb-4 lg:order-1', {
            'bg-beige-200':
              slice.primary.background_color === 'Moss White' || !isFilled.select(slice.primary.background_color),
            'bg-moss-white': slice.primary.background_color === 'Beige/200',
          })}
        >
          <div className="w-full border-0">
            <ComparisonTableHead
              comparisonProducts={slice.primary.comparison_products}
              backgroundColor={slice.primary.background_color}
            />
            <ComparisonTableBody
              tableBody={slice.primary.table}
              backgroundColor={slice.primary.background_color}
              contentCenter={slice.primary.centralise_table_content}
            />
          </div>
        </div>
        <div className="order-1 flex w-full flex-col items-center justify-between rounded-xl bg-[#CDE6A7] px-8 pt-8 md:rounded-2xl md:max-lg:hidden lg:order-2 lg:max-w-[328px]">
          <h4 className="text-h4 md:text-h3 text-center text-moss-black md:text-start">
            {slice.primary.banner_section[0]?.title}
          </h4>
          <PrismicNextImage
            field={slice.primary.banner_section[0]?.image}
            className="mt-[38px] w-full max-w-[290px] md:max-w-full"
          />
        </div>
      </div>
    </Container>
  )
}

export default ComparisonSection
