import {BookDemoSectionSliceDirectMailPrimary} from 'prismicio-types'
import {AssetSection} from './components/AssetSection'
import {EmailValidationForm} from './components/EmailValidationForm'
import classNames from 'classnames'
import {useState} from 'react'

/**
 * Props for `BookDemoSection`.
 */
export type BookDemoSectionDirectMailProps = {
  slice: {
    primary: BookDemoSectionSliceDirectMailPrimary
    slice_type: string
    variation: string
  }
  index: number
  context: {
    pageUid: string
  }
}

/**
 * Component for "BookDemoSection" Slices.
 */
export const BookDemoSectionDirectMail = ({slice: {primary}, context}: BookDemoSectionDirectMailProps): JSX.Element => {
  const [activeStep, setActiveStep] = useState(0)

  return (
    <div className="flex h-[calc(100vh-64px)] flex-col max-md:justify-center md:flex-row">
      <div className="w-full xl:max-w-[50%]">
        <div
          className={classNames(
            'flex h-full min-h-min w-full max-w-[600px] items-center max-md:px-5 max-md:py-12 md:float-end md:pr-12 md:max-lg:px-10 lg:max-xl:px-5',
            {
              'max-md:hidden max-md:h-[calc(100%-65px)]': activeStep === 0,
            },
          )}
        >
          <EmailValidationForm
            title={primary.title}
            company_email_field={primary.company_email_field}
            company_name={primary.company_name}
            continue_button_label={primary.continue_button_label}
            description={primary.description}
            calendar_link={primary.calendar_link}
            hubspot_form_id={primary.hubspot_form_id}
            campaign_id={primary.campaign_id}
            pageUid={context.pageUid}
            disclaimer={primary.disclaimer}
          />
        </div>
      </div>
      <div
        className={classNames(
          'w-full grow gap-y-10 bg-[#0c3a31]  bg-cover bg-center bg-no-repeat px-5 py-12 md:max-w-[50%] md:px-10 xl:px-0 xl:py-3 transition-all duration-1000',
          {
            'max-md:hidden': activeStep === 1,
          },
        )}
        style={{backgroundImage: `url(${primary.asset_section[0]?.background_image.url!})`}}
      >
        <AssetSection
          assetSection={primary.asset_section[0]}
          badges={primary.badges}
          ratingsTitle={primary.ratings_title}
          continueHandler={() => setActiveStep(1)}
          continueLabel={primary.continue_button_label}
        />
      </div>
    </div>
  )
}
