import {Content, isFilled, KeyTextField, SliceZone as SliceZoneType} from '@prismicio/client'
import {createContext, memo, useEffect, useRef, useState} from 'react'
import {Container} from 'src/common/Container'
import {useTimer} from 'slices/ProductDeepDive/Default/useTimer'
import {useIntersection, useWindowSize} from 'react-use'
import {ProductDeepDiveDocumentData, ProductDeepDiveSliceSingleSection} from 'prismicio-types'
import {PDDContainer} from './PDDContainer'

type PDDContextType = {
  activeChapter: number
  handleChapterChange: (chapter: number) => void
  duration: number | null
  selectorRef: any
  pageUid: string
  running: boolean
  componentIndex: number
}

export type SectionsType = {
  sectionLabel: KeyTextField
  slices: SliceZoneType<Content.ProductDeepDiveContentSlice>
}[]

type ProductDeepDiveSingleSectionProps = {
  slice: ProductDeepDiveSliceSingleSection
  context: {pageUid: string}
  index: number
  productDeepDiveData: ProductDeepDiveDocumentData
  slice_type: string
}

export const DEFAULT_DURATION = 12

export const ProductDeepDiveContext = createContext<PDDContextType | null>(null)

const ProductDeepDive = ({
  slice,
  context: {pageUid},
  index: componentIndex,
  slice_type,
}: ProductDeepDiveSingleSectionProps): JSX.Element => {
  const [activeChapter, setActiveChapter] = useState(0)
  const duration = slice.primary.chapter_duration
  const timer = useTimer({laps: slice.primary.sections.length - 1, targetTime: duration || DEFAULT_DURATION})
  const {lap, start, resetToLap, running} = timer
  const {width} = useWindowSize()
  const isMobile = width < 768
  const mainRef = useRef<HTMLDivElement>(null)
  const selectorRef = useRef<HTMLDivElement>(null)
  const sectionRef = useRef<HTMLDivElement>(null)

  const sectionObservable = useIntersection(sectionRef, {
    rootMargin: '0px',
  })

  const handleChapterChange = (chapter: number) => {
    setActiveChapter(chapter)
    resetToLap(chapter)
  }

  // Update Active Step
  useEffect(() => {
    setActiveChapter(lap)
  }, [lap])

  // Start timer
  useEffect(() => {
    if (sectionObservable?.isIntersecting) {
      start()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only trigger this effect when component is mounted
  }, [sectionObservable])

  return (
    <ProductDeepDiveContext.Provider
      value={{
        activeChapter,
        handleChapterChange,
        duration,
        selectorRef,
        pageUid,
        running,
        componentIndex,
      }}
    >
      <Container
        data-slice-type={slice_type}
        data-slice-variation={slice.variation}
        backgroundColor="Beige/100"
        containerRef={selectorRef}
        className="!scroll-mt-0"
      >
        <div
          className="grid w-full max-w-[1200px] scroll-mt-16 grid-cols-4 gap-4 gap-y-12 px-5 md:grid-cols-12 md:gap-y-[60px] xl:px-0"
          ref={mainRef}
        >
          {isFilled.keyText(slice.primary.title) && (
            <div
              className="col-span-4 mx-auto grid grid-cols-6 items-center gap-4 gap-y-8 md:col-start-3 md:col-end-11"
              data-aos="fade-up"
            >
              <div
                className="col-span-full grid grid-cols-6 flex-col gap-x-4 gap-y-3 text-center md:max-w-[628px]"
                ref={selectorRef}
              >
                <h2 className="text-h2 col-span-full text-moss-black">{slice.primary.title}</h2>
                {isFilled.keyText(slice.primary.subtext) && (
                  <p className="col-span-full text-16 leading-6 tracking-[.16px] text-grey-550">
                    {slice.primary.subtext}
                  </p>
                )}
              </div>
            </div>
          )}
          <div className="col-span-full min-h-[877px] md:min-h-[540px]" data-aos="fade-up" ref={sectionRef}>
            <PDDContainer sections={slice.primary.sections} isMobile={isMobile} />
          </div>
        </div>
      </Container>
    </ProductDeepDiveContext.Provider>
  )
}

export default memo(ProductDeepDive)
