import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {AuthorDocumentData, CustomerStoryDocument} from 'prismicio-types'
import {memo} from 'react'
import {Icon} from 'src/common/Icon'

export const Card = memo(function Card(props: {
  className?: string
  customerStory: CustomerStoryDocument
  isFeatured?: boolean
}) {
  const {linkResolver} = useLinkResolver()
  return (
    <Link
      className={cn(
        'grid gap-6 bg-ffffff transition-shadow hover:shadow-article max-md:grid-rows-[auto_1fr]',
        {'p-4 md:p-6 rounded-xl content-start': !props.isFeatured},
        {'p-6 sm:p-8 md:p-12 md:grid-cols-2 rounded-3xl': props.isFeatured},
        props.className,
      )}
      href={linkResolver(props.customerStory)}
    >
      <div
        className={cn(
          'h-[22px] items-center justify-self-start rounded-4 bg-ecf0ef px-2 text-12 font-medium leading-4 tracking-common text-5a7269',
          {flex: !props.isFeatured},
          {hidden: props.isFeatured},
        )}
      >
        {props.customerStory.data.tag}
      </div>

      <PrismicNextImage
        className={cn('rounded-2xl', {
          'md:col-start-2 md:row-start-1 aspect-[500/320] object-cover': props.isFeatured,
        })}
        field={props.customerStory.data.image}
        priority={props.isFeatured}
      />

      <div
        className={cn('grid content-between', {
          'md:col-start-1 md:row-start-1 md:self-center max-w-md': props.isFeatured,
        })}
      >
        <div className="grid">
          <div
            className={cn(
              'h-[22px] items-center justify-self-start rounded-4 bg-ecf0ef px-2 text-12 leading-4 tracking-common text-5a7269',
              {hidden: !props.isFeatured},
              {flex: props.isFeatured},
            )}
          >
            {props.customerStory.data.tag}
          </div>

          <div
            className={cn(
              'items-center gap-x-2 justify-self-start text-12 font-medium leading-4 tracking-common text-2a2928',
              {flex: !props.isFeatured},
              {hidden: props.isFeatured},
            )}
          >
            <Icon className="h-4 w-3" name="avatars" />
            <span>{props.customerStory.data.employees_number}</span>
          </div>

          <PrismicRichText
            components={{
              heading1: ({children}) =>
                props.isFeatured ? (
                  <h2 className="mt-4 text-32 font-bold leading-10 text-2a2928 lg:text-36 lg:leading-11">{children}</h2>
                ) : (
                  <h3 className="mt-4 text-18 leading-6 text-2a2928 md:text-20">{children}</h3>
                ),
              strong: ({children}) => (props.isFeatured ? <strong>{children}</strong> : <span>{children}</span>),
            }}
            field={props.customerStory.data.title}
          />

          {isFilled.contentRelationship(props.customerStory.data.author) && (
            <div className={cn('mt-6 h-6', {hidden: !props.isFeatured}, {flex: props.isFeatured})}>
              <PrismicNextImage
                field={(props.customerStory.data.author.data as AuthorDocumentData).photo}
                height={24}
                width={24}
                quality={90}
              />

              <span className="ml-2 text-14 leading-5 text-666563">
                {(props.customerStory.data.author.data as AuthorDocumentData).name}
              </span>
            </div>
          )}
        </div>
      </div>
    </Link>
  )
})
