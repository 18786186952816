import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import cn from 'classnames'

/**
 * Props for `Spacing`.
 */
export type SpacingProps = SliceComponentProps<Content.SpacingSlice>

/**
 * Component for "Spacing" Slices.
 */
const Spacing = ({slice: {primary}}: SpacingProps): JSX.Element => (
  <div
    className={cn(`${spacingClasses[primary.space]}`, {
      'bg-beige-100': primary.background_color === 'Beige/100',
      'bg-beige-200': primary.background_color === 'Beige/200',
      'bg-moss-white': primary.background_color === 'Moss White',
      'bg-moss-black': primary.background_color === 'Moss Black',
      'bg-moss-green-900': primary.background_color === 'Green/900',
      'bg-beige-300': primary.background_color === 'Beige/300',
    })}
  />
)

export default Spacing

const spacingClasses = {
  '16px': 'pt-4',
  '24px': 'pt-0 md:pt-6',
  '48px': 'pt-4 md:pt-12',
  '144px': 'pt-4 md:pt-[144px]',
}
