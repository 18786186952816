import {useState} from 'react'
import {useEffectOnce} from 'react-use'
import {
  HeadlineSectionSliceDefaultItem,
  HeroV2SliceBentoGridPrimaryRatingsItem,
  HeroV2SliceDefaultItem,
} from 'prismicio-types'
import {SelectField} from '@prismicio/client'
import {StarRatings} from './StarRatings'
import {IconRatings} from './IconRatings'
import cn from 'classnames'
import {AnimateRatings} from './AnimatedRatings'

export const RatingsSection = ({
  ratings,
  type,
  centered = true,
}: {
  ratings: HeroV2SliceDefaultItem[] | HeadlineSectionSliceDefaultItem[] | HeroV2SliceBentoGridPrimaryRatingsItem[]
  type: SelectField<'Icons' | 'Stars' | 'Animated', 'filled'>
  centered?: boolean
}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  // Set interval to animate ratings
  useEffectOnce(() => {
    if (type === 'Animated') {
      const interval = setInterval(() => {
        setActiveIndex((prev) => (prev === 0 ? 1 : 0))
      }, 2500)

      return () => clearInterval(interval)
    }

    return
  })

  if (ratings.length === 0) {
    return null
  }

  let RatingsComponent

  switch (type) {
    case 'Stars':
      RatingsComponent = StarRatings
      break
    case 'Animated':
      RatingsComponent = AnimateRatings
      break
    default:
      RatingsComponent = IconRatings
  }

  return (
    <div
      className={cn('flex', {
        'gap-3': type !== 'Animated',
        'relative h-7 w-full items-center': type === 'Animated',
        'justify-start': !centered,
        'justify-center': centered,
      })}
    >
      {ratings.map((review, index) => (
        <RatingsComponent key={index} review={review} isActive={activeIndex === index} />
      ))}
    </div>
  )
}
