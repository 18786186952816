import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {FaqSlice} from 'prismicio-types'
import {Dispatch, SetStateAction, memo, useCallback, useMemo} from 'react'
import {Icon} from 'src/common/Icon'

export const Item = memo(function Item(props: {
  activeItem: number | null
  answer: FaqSlice['items'][number]['answer']
  className?: string
  index: number
  question: FaqSlice['items'][number]['question']
  setActiveItem: Dispatch<SetStateAction<number | null>>
}) {
  const isOpen = useMemo(() => props.activeItem === props.index, [props.activeItem, props.index])

  const toggleActiveItem = useCallback(
    () => props.setActiveItem((prev) => (prev === props.index ? null : props.index)),
    [props],
  )

  return (
    <button
      className={cn(
        'grid overflow-hidden border-b border-grey-150 pb-4 text-left',
        {
          'grid-rows-[auto_0fr]': !isOpen,
          'grid-rows-[auto_1fr]': isOpen,
        },
        props.className,
      )}
      onClick={toggleActiveItem}
    >
      <h3 className="grid cursor-pointer grid-flow-col items-center justify-between gap-x-4">
        <div className="text-h5 leading-7 text-moss-black">{props.question}</div>
        <Icon className="h-6 w-6 text-moss-black" name={isOpen ? 'minus' : 'plus'} />
      </h3>

      <div
        className={cn('grid min-h-0 gap-y-4 text-16 leading-6 text-666563', {'opacity-0': !isOpen}, {'mt-4': isOpen})}
      >
        <PrismicRichText
          components={{
            list: ({children}) => <ul className="grid list-disc gap-y-2 pl-10">{children}</ul>,
            listItem: ({children}) => <li className="pl-2">{children}</li>,
            oList: ({children}) => <ul className="grid list-decimal gap-y-2 pl-10">{children}</ul>,
            oListItem: ({children}) => <li className="pl-2">{children}</li>,
          }}
          field={props.answer}
        />
      </div>
    </button>
  )
})

export const ItemV2 = memo(function ItemV2(props: {
  activeItem: number | null
  answer: FaqSlice['items'][number]['answer']
  className?: string
  index: number
  question: FaqSlice['items'][number]['question']
  setActiveItem: Dispatch<SetStateAction<number | null>>
}) {
  const isOpen = useMemo(() => props.activeItem === props.index, [props.activeItem, props.index])
  const {linkResolver} = useLinkResolver()

  const toggleActiveItem = useCallback(
    () => props.setActiveItem((prev) => (prev === props.index ? null : props.index)),
    [props],
  )

  return (
    <button
      className={cn(
        'box-border grid overflow-hidden py-4 text-left transition-all duration-300',
        {
          'grid-rows-[auto_0fr]': !isOpen,
          'grid-rows-[auto_1fr]': isOpen,
        },
        props.className,
      )}
      onClick={toggleActiveItem}
    >
      <h3 className="grid cursor-pointer grid-flow-col items-center justify-between gap-x-4">
        <div className="text-body-bold text-moss-black">{props.question}</div>
        <Icon className="h-5 w-5 text-moss-black transition-all duration-300" name={isOpen ? 'minus' : 'plus'} />
      </h3>

      <div
        className={cn('text-body grid min-h-0 gap-y-4 text-grey-550 transition-all duration-300', {
          'opacity-0': !isOpen,
        })}
      >
        <PrismicRichText
          components={{
            list: ({children}) => <ul className="mt-2 grid list-disc gap-y-2 pl-10">{children}</ul>,
            listItem: ({children}) => <li className="mt-2 pl-2">{children}</li>,
            oList: ({children}) => <ul className="mt-2 grid list-decimal gap-y-2 pl-10">{children}</ul>,
            oListItem: ({children}) => <li className="mt-2 pl-2">{children}</li>,
            paragraph: ({children}) => <p className="mt-2">{children}</p>,
            hyperlink: ({node, children}) => (
              <Link
                className="underline underline-offset-2 transition-colors duration-150"
                href={linkResolver(node.data)}
                {...(node.data.link_type === 'Web' && node.data.target && {target: node.data.target})}
              >
                {children}
              </Link>
            ),
          }}
          field={props.answer}
        />
      </div>
    </button>
  )
})
