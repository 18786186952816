import {memo} from 'react'
import {Icon} from 'src/common/Icon'
import {Context} from 'src/common/types'

export const FeaturesList = memo(function FeaturesList(props: {
  pricingPlan: Context['pricing_plan_1'] | Context['pricing_plan_2'] | Context['pricing_plan_3'] | null
}) {
  if (!props.pricingPlan) {
    return null
  }

  return (
    <ul className="mt-4 grid gap-y-2">
      {props.pricingPlan.map((item, index) => (
        <li key={index} className="group flex items-start gap-x-2 text-14 leading-5">
          <Icon className="mt-0.5 h-4 w-4 shrink-0 text-6c9d8b" name="check" />
          <span>{item.feature}</span>
        </li>
      ))}
    </ul>
  )
})
