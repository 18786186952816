import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import Image from 'next/image'
import {useLinkResolver} from 'prismicio'
import {HeroSlice} from 'prismicio-types'
import {memo} from 'react'
import {ActionButton} from 'src/common/ActionButton'
import {Button} from 'src/common/Button'
import {Icon} from 'src/common/Icon'
import {Context} from 'src/common/types'
import circleSegment from './assets/circle-segment.svg'
import {RatingsGroup} from 'src/common/RatingsGroup'

const Hero = (props: {context: Context; slice: HeroSlice; index: number}) => {
  const {linkResolver} = useLinkResolver()
  const isFramedBottomRight = props.slice.primary.image_size === 'Framed bottom right'
  const isFramedBottomLeft = props.slice.primary.image_size === 'Framed bottom left'
  const isMossBlack = props.slice.primary.color_variant === 'Moss Black'

  const shouldShowRatings =
    isFilled.number(props.slice.primary.capterra_rating) || isFilled.number(props.slice.primary.g2_rating)

  return (
    <section
      className={cn(
        'relative grid scroll-mt-16 grid-cols-main overflow-hidden',
        {'pt-9 md:pb-0 md:pt-20': isFramedBottomRight || isFramedBottomLeft},
        {'py-9 items-center': !isFramedBottomRight && !isFramedBottomLeft},
        {'bg-5a7269': props.slice.primary.color_variant === 'Green'},
        {'bg-cdd8d5': props.slice.primary.color_variant === 'Light Green'},
        {'bg-3a5f51': props.slice.primary.color_variant === 'Ghost'},
        {'bg-926077': props.slice.primary.color_variant === 'Purple'},
        {'bg-b47b52': props.slice.primary.color_variant === 'Orange'},
        {'bg-3f8896': props.slice.primary.color_variant === 'Blue'},
        {'bg-b56659': props.slice.primary.color_variant === 'Red'},
        {'bg-moss-black': isMossBlack},
        {'gap-y-5': !props.slice.primary.is_mobile_image_last},
        {'gap-y-20': props.slice.primary.is_mobile_image_last},
      )}
      id={String(props.slice.primary.id_anchor)}
    >
      {props.slice.primary.color_variant === 'Ghost' && (
        <Image alt="" className="absolute -top-16 right-0 max-w-[62rem]" src={circleSegment} />
      )}

      <div
        className={cn('relative col-[inner] grid max-md:justify-self-center md:col-start-[track-start_7]', {
          'md:col-end-[outer-end] md:-mr-[15%]': props.slice.primary.image_size === 'Full bleed',
          'md:col-end-[inner-end]':
            props.slice.primary.image_size === 'Framed' || isFramedBottomRight || isFramedBottomLeft,
          'md:place-items-end': isFramedBottomRight,
          'max-w-[378px]': isFramedBottomLeft,
        })}
      >
        <PrismicNextImage
          field={props.slice.primary.image}
          priority
          quality={90}
          sizes="(max-width: 640px) calc(100vw - 48px), (max-width: 768px) calc(100vw - 80px), (max-width: 1440px) 57vw, 815px"
        />
      </div>

      <div
        className={cn(
          'relative col-[inner] grid place-items-start content-start md:col-end-[track-end_5] md:row-start-1',
          {
            'gap-y-5': !isFramedBottomRight,
            'md:pb-15': isFramedBottomRight,
            'md:pt-20 md:pb-15': isFramedBottomLeft,
            'row-start-1': props.slice.primary.is_mobile_image_last,
          },
        )}
      >
        <div className="grid gap-y-2.5">
          {isFilled.keyText(props.slice.primary.tag) && (
            <div
              className={cn('text-12 font-medium uppercase tracking-[.02em]', {
                'text-accent-purple': isMossBlack,
                'text-4c6ee5': !isMossBlack,
              })}
            >
              {props.slice.primary.tag}
            </div>
          )}

          <PrismicRichText
            components={{
              heading1: ({children}) => (
                <h1
                  className={cn(
                    '[&_>_br]:max-md:content-[""] [&_>_br]:max-md:after:content-["_"]',
                    {
                      'tg-h1':
                        props.slice.primary.color_variant === 'Transparent' || !props.slice.primary.color_variant,
                    },
                    {
                      'text-32 leading-10 lg:text-36 lg:leading-11 tracking-[-.015em] text-ffffff':
                        props.slice.primary.color_variant === 'Green' ||
                        props.slice.primary.color_variant === 'Ghost' ||
                        props.slice.primary.color_variant === 'Purple' ||
                        props.slice.primary.color_variant === 'Orange' ||
                        props.slice.primary.color_variant === 'Blue' ||
                        props.slice.primary.color_variant === 'Red',
                    },
                    {'tg-h1 !font-normal': props.slice.primary.color_variant === 'Light Green'},
                    {'text-h1 text-moss-white': isMossBlack},
                  )}
                >
                  {children}
                </h1>
              ),
              paragraph: ({children}) => (
                <h1
                  className={cn(
                    '[&_>_br]:max-md:content-[""] [&_>_br]:max-md:after:content-["_"]',
                    {
                      'tg-h1':
                        props.slice.primary.color_variant === 'Transparent' || !props.slice.primary.color_variant,
                    },
                    {
                      'text-36 leading-11 tracking-[-.015em] text-ffffff':
                        props.slice.primary.color_variant === 'Green' ||
                        props.slice.primary.color_variant === 'Ghost' ||
                        props.slice.primary.color_variant === 'Purple' ||
                        props.slice.primary.color_variant === 'Orange' ||
                        props.slice.primary.color_variant === 'Blue' ||
                        props.slice.primary.color_variant === 'Red',
                    },
                    {'tg-h1 !font-normal': props.slice.primary.color_variant === 'Light Green'},
                  )}
                >
                  {children}
                </h1>
              ),
            }}
            field={props.slice.primary.title}
          />
        </div>

        <PrismicRichText
          components={{
            paragraph: ({children}) => (
              <p
                className={cn(
                  {
                    'tg-body-copy':
                      props.slice.primary.color_variant === 'Transparent' || !props.slice.primary.color_variant,
                  },
                  {
                    'text-20 leading-7 lg:text-22 lg:leading-8 text-ffffff/80':
                      props.slice.primary.color_variant === 'Green' ||
                      props.slice.primary.color_variant === 'Ghost' ||
                      props.slice.primary.color_variant === 'Purple' ||
                      props.slice.primary.color_variant === 'Orange' ||
                      props.slice.primary.color_variant === 'Blue' ||
                      props.slice.primary.color_variant === 'Red',
                  },
                  {'text-22 leading-8 text-2f2e2d': props.slice.primary.color_variant === 'Light Green'},
                  {'mt-4 !text-20 !leading-7 md:!text-16 md:!leading-6': isFramedBottomRight},
                  {'text-body text-grey-200': isMossBlack},
                )}
              >
                {children}
              </p>
            ),
          }}
          field={props.slice.primary.description}
        />

        <div
          className={cn('grid grid-flow-col gap-x-4', {
            'mt-12': isFramedBottomRight,
          })}
        >
          {(props.slice.primary.primary_button_anchor || isFilled.link(props.slice.primary.primary_button_url)) &&
            !props.slice.primary.is_primary_button_intro && (
              <Button
                href={
                  props.slice.primary.primary_button_anchor
                    ? `#${props.slice.primary.primary_button_anchor}`
                    : linkResolver(props.slice.primary.primary_button_url, 'primary-button-hero-1')
                }
                {...(props.slice.primary.primary_button_anchor && {scroll: false})}
                {...(isFilled.link(props.slice.primary.primary_button_url) &&
                  props.slice.primary.primary_button_url.link_type === 'Web' && {
                    target: props.slice.primary.primary_button_url.target,
                  })}
                variant="fillV2"
                data-mktg-id={props.slice.primary.primary_button_mktg_id || undefined}
                data-page-location={`component-${props.index}`}
                data-page-name={props.context.pageUid}
                data-type="hero-button"
              >
                {props.slice.primary.primary_button_text}
              </Button>
            )}

          {props.slice.primary.is_primary_button_intro && (
            <ActionButton kind="demo" data-page-location="hero" data-page-name={props.context.pageUid}>
              {props.slice.primary.primary_button_text}
            </ActionButton>
          )}

          {(props.slice.primary.secondary_button_anchor || isFilled.link(props.slice.primary.secondary_button_url)) && (
            <Button
              href={
                props.slice.primary.secondary_button_anchor
                  ? `#${props.slice.primary.secondary_button_anchor}`
                  : linkResolver(props.slice.primary.secondary_button_url, 'secondary-button-hero-1')
              }
              {...(props.slice.primary.secondary_button_anchor && {scroll: false})}
              {...(isFilled.link(props.slice.primary.secondary_button_url) &&
                props.slice.primary.secondary_button_url.link_type === 'Web' && {
                  target: props.slice.primary.secondary_button_url.target,
                })}
              variant="outline"
              data-page-location="hero"
              data-mktg-id={
                props.slice.primary.secondary_button_marketing_id
                  ? props.slice.primary.secondary_button_marketing_id
                  : 'price-button'
              }
              data-page-name={props.context.pageUid}
            >
              {props.slice.primary.secondary_button_text}
            </Button>
          )}
        </div>
        {shouldShowRatings && (
          <div className="pt-6">
            <RatingsGroup
              capterraRating={props.slice.primary.capterra_rating as number}
              g2Rating={props.slice.primary.g2_rating as number}
              preposition={props.slice.primary.ratings_preposition as string}
            />
          </div>
        )}
        {isFilled.keyText(props.slice.primary.disclaimer) && (
          <p className="pt-6 text-14 leading-5 tracking-[.28px] text-grey-300 md:tracking-[.16px]">
            {props.slice.primary.disclaimer}
          </p>
        )}
        {props.slice.items.length > 0 && props.slice.items[0].feature && (
          <div className="grid items-center gap-x-4 gap-y-2 sm:grid-flow-col">
            {props.slice.items.map((item, index) =>
              isFilled.keyText(item.feature) ? (
                <div key={index} className="grid grid-flow-col items-center justify-start gap-x-2.5">
                  <Icon className="h-3 w-3 text-6c9d8b" name="check" />
                  <div className="text-10 font-medium uppercase tracking-[1px] text-72716e">{item.feature}</div>
                </div>
              ) : null,
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default memo(Hero)
