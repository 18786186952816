import {LinkField} from '@prismicio/client'
import {useRouter} from 'next/router'
import {useLinkResolver} from 'prismicio'
import {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useCookie} from 'react-use'
import {Button} from 'src/common/Button'
import {EVENTS, track} from 'src/common/helpers'

type FormData = {
  email: string
}

type PricingFormProps = {
  submitLabel: string
  placeholder: string
  index?: number
  pageUid: string
  componentIndex: number
  href?: LinkField
  pageLocation?: string
  dataTypeForm?: string
  dataTypeButton?: string
  hubspotFormId?: string
  mktgId?: string
}

export const LeadCapture = ({
  submitLabel,
  placeholder,
  pageUid,
  href,
  pageLocation,
  dataTypeForm,
  dataTypeButton,
  hubspotFormId,
  mktgId,
}: PricingFormProps) => {
  const {handleSubmit, register} = useForm<FormData>({
    mode: 'onSubmit',
  })

  const {linkResolver} = useLinkResolver()
  const router = useRouter()
  const [hutk] = useCookie('hubspotutk')

  const submit = useCallback(
    async (data: FormData) => {
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/25068079/${hubspotFormId}`

      const preparedData = {
        submittedAt: new Date().getTime(),

        fields: [
          {
            objectTypeId: '0-1',
            name: 'email',
            value: data.email,
          },
        ],

        context: {
          pageName: pageUid,
          pageUri: window.location.href,
          ...(hutk?.length && {hutk}),
        },
      }

      track({
        location: pageLocation,
        event: EVENTS.FORM_SUBMIT,
        dataType: dataTypeForm,
        mktgId: 'form-submission-button',
        pageName: pageUid,
      })

      try {
        await fetch(url, {
          method: 'POST',
          body: JSON.stringify(preparedData),
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (href) {
          router.push(linkResolver(href))
        }
      } catch (error) {
        return console.error(error)
      }
    },
    [hubspotFormId, pageUid, hutk, pageLocation, dataTypeForm, href, router, linkResolver],
  )

  return (
    <form id="pricing-form" className="flex gap-2" onSubmit={handleSubmit(submit)} data-hs-do-not-collect>
      <input
        className="
          max-h-10
          w-[200px]
          rounded-lg
          border
          border-grey-150
          px-3
          py-2.5
          text-center 
          text-14
          font-normal
          leading-5
          tracking-[.14px]
          placeholder:text-grey-300
          hover:bg-grey-110
          focus:outline-none
          xl:w-[250px]
          "
        type="email"
        placeholder={placeholder}
        required
        {...register('email')}
      />
      <Button
        variant="fillV2"
        type="submit"
        className="text-nowrap"
        data-page-location={pageLocation}
        data-type={dataTypeButton}
        data-mktg-id={mktgId}
        data-page-name={pageUid}
        id={pageLocation + '-button'}
      >
        {submitLabel}
      </Button>
    </form>
  )
}
