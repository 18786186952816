import {isFilled, KeyTextField} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import {useRouter} from 'next/router'
import {useLinkResolver} from 'prismicio'
import {CustomerStoryDocument} from 'prismicio-types'
import {memo} from 'react'
import {Button} from 'src/common/Button'
import {CATEGORIES, LocalesType} from 'src/common/constants'

type LinkResolverObject = {
  pathname: string
  query: {
    [k: string]: string
  }
}

const getUrl = (href: LinkResolverObject) => {
  const queryString = new URLSearchParams(href.query).toString()
  return `${href.pathname}${queryString ? `?${queryString}` : ''}`
}

export const CustomerStoryCard = memo(function Card(props: {
  customerStory: CustomerStoryDocument
  readMoreLabel: KeyTextField
}) {
  const {linkResolver} = useLinkResolver()
  const link = linkResolver(props.customerStory)
  const url = getUrl(link)
  const {locale} = useRouter()

  return (
    <a
      className="col-span-full flex flex-col gap-3 rounded-2xl bg-beige-100 px-3 pb-6 pt-3 active:bg-beige-300 md:col-span-6 lg:col-span-4"
      href={url}
    >
      <div className="relative w-full rounded-2xl lg:max-h-[217px]">
        <PrismicNextImage
          className="w-full rounded-lg lg:max-h-[217px]"
          field={props.customerStory.data.image}
          priority
        />
        {props.customerStory.data.category && (
          <span className="text-body-xs-caps absolute left-[14px] top-[13px] rounded-[4px] bg-moss-white px-2 py-1 text-moss-black">
            {CATEGORIES[props.customerStory.data.category][locale as LocalesType]}
          </span>
        )}
      </div>

      <div className="flex flex-col gap-6 px-2 pt-2">
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-3">
            <PrismicNextImage field={props.customerStory.data.company_icon} className="h-8 w-8" />
            <span className="text-body-bold text-grey-550">{props.customerStory.data.company_name}</span>
          </div>
          <PrismicRichText
            field={props.customerStory.data.title}
            components={{
              heading1: ({children}) => (
                <span className="text-h5 overflow-hidden text-ellipsis leading-7 text-moss-black">{children}</span>
              ),
            }}
          />
        </div>
        {isFilled.keyText(props.readMoreLabel) && (
          <Button className="w-min text-nowrap" variant="outlineV2">
            {props.readMoreLabel}
          </Button>
        )}
      </div>
    </a>
  )
})
