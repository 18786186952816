import cn from 'classnames'
import {memo} from 'react'
import {BackgroundColors} from 'src/common/types'

type GradientProps = {
  side: 'left' | 'right' | 'top' | 'bottom'
  fromColor: BackgroundColors
  className?: string
}

export const Gradient = memo(({side, fromColor, className}: GradientProps) => {
  const dimensions =
    side === 'left' || side === 'right' ? 'min-h-full w-[90px] md:w-[180px]' : 'min-w-full h-[60px] md:h-[180px]'

  return (
    <span
      className={cn(`absolute z-10 to-transparent ${dimensions} ${className || ''}`, {
        'left-0 top-0 bg-gradient-to-r': side === 'left',
        'right-0 top-0 bg-gradient-to-l': side === 'right',
        'top-0 left-0 bg-gradient-to-b': side === 'top',
        'bottom-0 left-0 bg-gradient-to-t': side === 'bottom',
        'from-moss-white': fromColor === 'Moss White',
        'from-beige-100': fromColor === 'Beige/100',
        'from-beige-200': fromColor === 'Beige/200',
        'from-moss-black': fromColor === 'Moss Black',
        'from-dark-green': fromColor === 'Dark Green',
      })}
    />
  )
})
