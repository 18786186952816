import {Button} from 'src/common/Button'
import {isFilled} from '@prismicio/client'
import {useLinkResolver} from 'prismicio'
import {ButtonDocument, HeroV2SliceBentoGridItem, HeroV2SliceBentoGridPrimary} from 'prismicio-types'
import {Tag} from 'src/common/Tag'
import {PrismicNextImage} from '@prismicio/next'
import {Container} from 'src/common/Container'
import Image from 'next/image'
import cn from 'classnames'
import {RatingsSection} from './RatingsSection'
import {sanitizeHtml} from 'src/common/helpers/sanitizeHtml'
import {getButtonsData} from 'src/common/helpers'

type DefaultHeroV2Props = {
  slice: {
    primary: HeroV2SliceBentoGridPrimary
    items: HeroV2SliceBentoGridItem[]
    slice_type: string
    variation: string
  }
  pageUid: string
  componentIndex: number
  buttonsData?: ButtonDocument[]
}

export const BentoGridHeroV2 = ({slice, pageUid, componentIndex, buttonsData}: DefaultHeroV2Props) => {
  const {linkResolver} = useLinkResolver()
  const sanitizedHeading = sanitizeHtml(slice.primary.heading!)

  const {primaryButtonData, secondaryButtonData} = getButtonsData({
    buttonsData,
    primaryButton: slice.primary.primary_button,
    secondaryButton: slice.primary.secondary_button,
  })

  return (
    <Container
      backgroundColor={slice.primary.background_color!}
      className="flex flex-col gap-12 !py-12 md:gap-[60px] md:!pt-10"
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div className="mx-auto grid w-full max-w-[1200px] scroll-mt-16 grid-cols-4 gap-4 gap-y-12 px-5 md:grid-cols-12 md:gap-x-6 md:px-10 lg:px-5 xl:px-0">
        <div className="col-span-full flex flex-col gap-y-18 md:grid md:grid-cols-subgrid md:gap-y-0">
          <div
            className="max-md:grid-base grid items-center gap-y-4 md:col-span-5 md:grid-cols-subgrid md:items-start md:gap-y-6 md:self-center"
            data-aos="fade-up"
            data-aos-offset="0"
          >
            <div className="col-span-4 grid grid-cols-subgrid gap-4 text-center md:col-span-full md:grid-cols-subgrid md:gap-6 md:text-start">
              <h1
                className="text-h2 lg:text-h1 col-span-full text-moss-black"
                dangerouslySetInnerHTML={{__html: sanitizedHeading}}
              />
              <p className="text-body col-span-4 font-medium text-grey-550">{slice.primary.subtext}</p>
            </div>
            <div className="col-span-full flex flex-wrap justify-center gap-2 md:justify-start md:pt-2">
              {(isFilled.keyText(slice.primary.primary_button_label) || primaryButtonData) && (
                <Button
                  variant="fillV2"
                  href={
                    slice.primary.primary_button_anchor
                      ? `#${slice.primary.primary_button_anchor}`
                      : linkResolver(primaryButtonData?.link || slice.primary.primary_button_url, 'primary-button-hero')
                  }
                  data-mktg-id={primaryButtonData?.marketing_id || slice.primary.primary_button_mktg_id || ''}
                  data-page-location={`component-${componentIndex}-hero`}
                  data-type={primaryButtonData?.intent || 'hero-button'}
                  data-page-name={pageUid}
                  data-label={primaryButtonData?.label || slice.primary.primary_button_label}
                  id={`component-${componentIndex}-hero-1`}
                  width={primaryButtonData?.width}
                >
                  {primaryButtonData?.label || slice.primary.primary_button_label}
                </Button>
              )}
              {(isFilled.keyText(slice.primary.secondary_button_label) || secondaryButtonData) && (
                <Button
                  href={linkResolver(
                    secondaryButtonData?.link || slice.primary.secondary_button_url,
                    'secondary-button-hero',
                  )}
                  variant="outlineV2"
                  data-mktg-id={secondaryButtonData?.marketing_id || slice.primary.secondary_button_mktg_id || ''}
                  data-page-location={`component-${componentIndex}-hero`}
                  data-type={secondaryButtonData?.intent || 'hero-button'}
                  data-page-name={pageUid}
                  data-label={secondaryButtonData?.label || slice.primary.secondary_button_label}
                  id={`component-${componentIndex}-hero-2`}
                  width={secondaryButtonData?.width}
                >
                  {secondaryButtonData?.label || slice.primary.secondary_button_label}
                </Button>
              )}
            </div>
            {isFilled.keyText(slice.primary.tag) && <Tag label={slice.primary.tag} />}
            {isFilled.group(slice.primary.ratings) && (
              <div
                className={cn('col-span-full flex gap-3', {
                  'row-start-1 justify-center md:justify-start': slice.primary.ratings_on_top,
                })}
              >
                <RatingsSection ratings={slice.primary.ratings} type={slice.primary.ratings_type} centered={false} />
              </div>
            )}
            {isFilled.keyText(slice.primary.disclaimer) && (
              <p className="text-body-s col-span-full text-grey-300">{slice.primary.disclaimer}</p>
            )}
          </div>
          <div className="max-md:grid-base grid aspect-square gap-y-4 self-center md:col-span-6 md:col-start-7 md:grid-cols-subgrid md:gap-y-6">
            {slice.primary.bento_grid.map((item, index) => {
              if (!isFilled.linkToMedia(item.asset)) {
                return null
              }

              switch (item.asset.kind) {
                case 'image':
                  return (
                    <div
                      className={cn(
                        'h-full w-full self-center overflow-hidden rounded-lg md:rounded-xl lg:max-h-[282px]',
                        {
                          'col-span-2 md:col-span-3 aspect-square': index !== slice.primary.bento_grid.length - 1,
                          'col-span-full aspect-[2/1]': index === slice.primary.bento_grid.length - 1,
                        },
                      )}
                    >
                      <Image
                        src={item.asset.url}
                        alt=" "
                        width={700}
                        height={700}
                        key={index}
                        className="h-full w-full"
                        priority
                      />
                    </div>
                  )
                default:
                  return null
              }
            })}
          </div>
        </div>
        {!!slice.items.length && (
          <div className="col-span-full flex flex-col gap-6 md:flex-row">
            {slice.items.map((item, index) => (
              <div className="flex w-full flex-col items-center gap-6 md:items-start" key={item.title! + index}>
                <PrismicNextImage field={item.icon} className="h-6 w-6 object-cover" />
                <div className="flex flex-col gap-3">
                  <h3 className="text-h5 text-center leading-7 text-moss-black md:text-start">{item.title}</h3>
                  <p className="text-body text-center text-grey-450 md:text-start">{item.subtext}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Container>
  )
}
