import {KeyTextField, RichTextField} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'

export const EndOfFunnel = ({title, content}: {title?: KeyTextField; content?: RichTextField}) => (
  <div className="flex flex-col items-center justify-center gap-6 text-center">
    <h3 className="text-h3 font-bold text-moss-black">{title}</h3>
    <PrismicRichText
      field={content}
      components={{
        paragraph: ({children}) => <p className="text-body text-moss-black">{children}</p>,
        heading3: ({children}) => <h3 className="text-h5 text-moss-black">{children}</h3>,
      }}
    />
  </div>
)
