import {isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import {useForm} from 'react-hook-form'
import {PrivacyCheckbox} from 'slices/AiCampaign/components/SignupSection/PrivacyCheckbox'
import {Button} from 'src/common/Button'
import {Container} from 'src/common/Container'
import {Tag} from 'src/common/Tag'
import {TextInput} from 'src/common/TextInput'
import cn from 'classnames'
import {useCookie} from 'react-use'
import {useSearchParams} from 'next/navigation'
import {useCallback, useState} from 'react'
import {EVENTS, getUtmParams, track} from 'src/common/helpers'
import {Assets} from 'slices/BannerV2/components/Assets'
import {Icon} from 'src/common/Icon'
import {BannerV2Slice, BannerV2SliceDefaultPrimary, BannerV2SliceSingleImagePrimary} from 'prismicio-types'

/**
 * Props for `BannerV2`.
 */
export type BannerV2Props = {
  slice: {
    primary: BannerV2SliceDefaultPrimary | BannerV2SliceSingleImagePrimary
    slice_type: string
    variation: BannerV2Slice['variation']
  }
  index: number
  context: {
    pageUid: string
  }
}

/**
 * Component for "BannerV2" Slices.
 */
export const FormVariations = ({slice, index, context}: BannerV2Props): JSX.Element => {
  const hasTag = isFilled.group(slice.primary.tag)

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: {isSubmitSuccessful},
  } = useForm()

  const [errors, setErrors] = useState<{fieldName: string}[] | null>(null)
  const [hutk] = useCookie('hubspotutk')
  const params = useSearchParams()

  const privacyPolicyText =
    slice.primary.privacy_checkbox_text[0]?.type === 'paragraph' ? slice.primary.privacy_checkbox_text[0].text : ''

  const submit = useCallback(
    async (data: {[x: string]: string}) => {
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/25068079/${slice.primary.hubspot_form}`

      const firstNameFieldName = slice.primary.first_name_field[0]?.name
      const lastNameFieldName = slice.primary.last_name_field[0]?.name
      const emailFieldName = slice.primary.email_field[0]?.name

      const utmParams = getUtmParams(params)

      const preparedData = {
        submittedAt: new Date().getTime(),

        fields: [
          {
            name: firstNameFieldName,
            value: data.firstName,
          },
          {
            name: lastNameFieldName,
            value: data.lastName,
          },
          {
            name: emailFieldName,
            value: data.email,
          },
          ...utmParams,
        ],
        context: {
          pageUri: window.location.href,
          ...(hutk?.length && {hutk}),
        },
        legalConsentOptions: {
          consent: {
            consentToProcess: true,
            text: privacyPolicyText,
          },
        },
      }

      try {
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(preparedData),
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (!response.ok) {
          const parsedResponse = await response.json()

          const errorsWithFieldNames = parsedResponse.errors.map((error: {message: string; type: string}) => {
            const match = error.message.match(/Error in 'fields\.(\w+)'/)
            const fieldName = match ? match[1] : 'unknown'
            return {fieldName}
          })

          return setErrors(errorsWithFieldNames)
        }

        track({
          event: EVENTS.FORM_SUBMIT,
          location: `component-${index}`,
          pageName: context.pageUid,
          dataType: slice.primary.mktg_data_type || 'sign-up-form',
          mktgId: 'form-submission-button',
        })
      } catch (error) {
        console.error(error)
      }

      reset()
    },
    [
      slice.primary.hubspot_form,
      slice.primary.first_name_field,
      slice.primary.last_name_field,
      slice.primary.email_field,
      slice.primary.mktg_data_type,
      params,
      hutk,
      privacyPolicyText,
      reset,
      index,
      context.pageUid,
    ],
  )

  return (
    <Container
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      backgroundColor={slice.primary.background_color}
      className="!py-16 px-4"
      id={slice.primary.section_id || 'banner'}
    >
      <div className="grid-base gap-y-12">
        <div className="col-span-full flex flex-col items-center gap-6 lg:col-span-5 lg:items-start lg:gap-8">
          <div className="flex flex-col items-center gap-6 lg:items-start lg:gap-8">
            {hasTag && <Tag icon={slice.primary.tag[0]?.icon} color="white" label={slice.primary.tag[0]?.tag!} />}
            <div className="flex flex-col gap-4">
              <PrismicRichText
                components={{
                  heading1: ({children}) => (
                    <h2 className="text-h2 text-center text-moss-white lg:text-start">{children}</h2>
                  ),
                  strong: ({children}) => <span className="text-accent-neon">{children}</span>,
                }}
                field={slice.primary.title}
              />
              {isFilled.richText(slice.primary.subtitle) && (
                <PrismicRichText
                  components={{
                    paragraph: ({children}) => (
                      <p className="text-body text-center text-moss-white lg:text-start">{children}</p>
                    ),
                  }}
                  field={slice.primary.subtitle}
                />
              )}
            </div>
          </div>
          <form className="flex flex-col lg:gap-4" onSubmit={handleSubmit(submit)} data-hs-do-not-collect>
            <div className="grid grid-cols-6 gap-2.5 pb-4 lg:gap-[15px]">
              {isSubmitSuccessful && !errors ? (
                <div className="relative col-span-full flex h-[140px] flex-col items-center justify-center rounded-xl bg-moss-white/[20%] md:h-[172px] lg:h-[127px]">
                  <button className="absolute right-[9px] top-1.5" onClick={reset}>
                    <Icon name="cross" className="h-6 w-6 text-moss-white" />
                  </button>
                  <Icon name="filled-check-v2" className="h-8 w-8 text-accent-neon" />
                  <p className="text-body pt-2 text-moss-white">{slice.primary.success_message}</p>
                </div>
              ) : (
                <>
                  <div className="col-span-3">
                    <TextInput
                      errors={errors}
                      field={{...slice.primary.first_name_field[0]}}
                      register={register}
                      required
                    />
                  </div>
                  <div className="col-span-3">
                    <TextInput
                      errors={errors}
                      field={{...slice.primary.last_name_field[0]}}
                      register={register}
                      required
                    />
                  </div>
                  <div className="col-span-full lg:col-span-4">
                    <TextInput
                      errors={errors}
                      field={{...slice.primary.email_field[0], type: 'email'}}
                      register={register}
                      required
                    />
                  </div>
                  <Button
                    variant="fillV2"
                    className="col-span-full text-nowrap lg:col-span-2 lg:h-full lg:text-16"
                    type="submit"
                  >
                    {slice.primary.button_label}
                  </Button>
                </>
              )}
            </div>
            <PrivacyCheckbox label={slice.primary.privacy_checkbox_text} control={control} textSize="small" />
          </form>
        </div>
        <div
          className={cn('col-span-full lg:col-span-6 lg:col-start-7 lg:flex', {
            'lg:items-start': slice.variation === 'singleImage',
            'lg:items-center': slice.variation === 'default',
          })}
        >
          <Assets assets={slice.primary.assets} variation={slice.variation} />
        </div>
      </div>
    </Container>
  )
}
