import {KeyTextField} from '@prismicio/client'
import {Fragment, memo, useMemo} from 'react'
import {Context} from 'src/common/types'
import {Feature} from './Feature'

export const FeaturesList = memo(function FeaturesList(props: {
  appliedFeatures: KeyTextField
  features: Context['features']
  title?: KeyTextField
}) {
  const parsedAppliedFeatures = useMemo(() => props.appliedFeatures?.split('_') || [], [props.appliedFeatures])

  return (
    <Fragment>
      {props.title && (
        <h3 className="mt-3 text-10 font-medium uppercase leading-4 -tracking-common text-b8b6b4">{props.title}</h3>
      )}

      <ul className="mt-4 grid gap-y-2">
        {props.features.map((feature, index) => (
          <Feature
            feature={feature}
            replacementText={
              parsedAppliedFeatures[index] !== 'no' && parsedAppliedFeatures[index] !== 'yes'
                ? parsedAppliedFeatures[index]
                : undefined
            }
            isApplied={
              parsedAppliedFeatures[index] === 'yes' ||
              (parsedAppliedFeatures[index] !== 'no' && parsedAppliedFeatures[index] !== 'yes')
            }
            key={index}
          />
        ))}
      </ul>
    </Fragment>
  )
})
