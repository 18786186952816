import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {ProductAnnouncementCardDocumentData} from 'prismicio-types'
import {Tag} from 'src/common/Tag'
import {TextLink} from 'src/common/TextLink'
import {TAG_COLORS} from 'src/common/constants'
import {TrackingProps} from 'src/common/helpers'

type ProductAnnouncementCardProps = ProductAnnouncementCardDocumentData & {
  tagColor: keyof typeof TAG_COLORS
  categoryLabel: string
  formattedDate: string
} & Omit<TrackingProps, 'event' | 'mktgId' | 'dataType'>

export const ProductAnnouncementCard = ({
  tagColor,
  categoryLabel,
  formattedDate,
  description,
  image,
  link_label,
  link_url,
  title,
  location,
  pageName,
  hide_date,
}: ProductAnnouncementCardProps) => (
  <div className="grid-base grid items-center gap-y-8">
    <div className="col-span-full row-start-2 flex flex-col gap-6 md:col-span-6 md:row-start-1">
      {categoryLabel && <Tag label={categoryLabel} color={tagColor} />}
      <div className="flex flex-col gap-3">
        <h3 className="text-h4 lg:text-h3 text-moss-black">{title}</h3>
        <p className="text-body text-grey-550">{description}</p>
      </div>
      {!hide_date && <p className="text-body-s text-grey-300">{formattedDate}</p>}
      {isFilled.keyText(link_label) && (
        <TextLink
          underline
          label={link_label}
          field={link_url}
          variation="small"
          mktgId="anchor-text-link"
          location={location}
          pageName={pageName}
          dataType="media-card"
        />
      )}
    </div>
    <PrismicNextImage
      field={image}
      width={474}
      height={387}
      className="col-span-full row-start-1 w-full rounded-[10px] md:col-span-5 md:col-start-8 md:row-start-1"
    />
  </div>
)
