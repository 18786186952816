import cn from 'classnames'
import {HeroV2SliceDefaultItem, HeroV2SliceDefaultWithLeadCaptureItem} from 'prismicio-types'
import {Icon} from 'src/common/Icon'

export const IconRatings = ({
  review,
  color = 'Moss black',
}: {
  review: HeroV2SliceDefaultItem | HeroV2SliceDefaultWithLeadCaptureItem
  isActive?: boolean
  color?: 'Moss black' | 'Moss white'
}) => (
  <div
    className={cn('flex gap-2', {
      'text-moss-white': color === 'Moss white',
      'text-moss-black': color === 'Moss black',
    })}
  >
    <Icon name={review.review_platform} className="h-4 w-4" />
    <p
      className={cn('text-12 font-medium leading-4 tracking-[.24px]', {
        'text-moss-white': color === 'Moss white',
        'text-grey-450': color === 'Moss black',
      })}
    >{`${review.rating} on ${review.review_platform}`}</p>
  </div>
)
