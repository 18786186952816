import {PrismicNextImage} from '@prismicio/next'
import {LogosSlice} from 'prismicio-types'
import {memo} from 'react'

const Logos = (props: {slice: LogosSlice}) => (
  <section className="grid scroll-mt-16 grid-cols-main py-11" id={String(props.slice.primary.id_anchor)}>
    <div className="col-[inner] grid grid-flow-col justify-around">
      {props.slice.items.map((item, index) => (
        <PrismicNextImage key={index} field={item.logo} />
      ))}
    </div>
  </section>
)

export default memo(Logos)
