import {ImageField, KeyTextField, LinkField} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {LinkWrapper} from 'src/common/LinkWrapper'

type ToolCardProps = {
  integration: {
    link: LinkField
    logo: ImageField
    name: KeyTextField
    description: KeyTextField
  }
}

export const ToolCard = ({integration: {link, logo, name, description}}: ToolCardProps) => {
  return (
    <LinkWrapper link={link} className="group md:col-span-3 md:h-full">
      <div className="flex flex-col gap-4 rounded-lg border border-moss-white bg-moss-white pb-4 md:col-span-3 md:h-full">
        <div className="flex h-[140px] items-center justify-center rounded-t-lg bg-grey-120">
          <PrismicNextImage
            field={logo}
            className="transition-all duration-300 group-hover:scale-110"
            height={logo.dimensions?.height}
            width={logo.dimensions?.width}
          />
        </div>
        <div className="flex flex-col gap-1 px-4">
          <span className="text-body-bold text-moss-black">{name}</span>
          <span className="text-body-m text-grey-450">{description}</span>
        </div>
      </div>
    </LinkWrapper>
  )
}
