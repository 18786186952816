import {isFilled} from '@prismicio/client'
import cn from 'classnames'
import {Source} from 'src/common/SourceSet'
import {HeroV2SliceDefaultWithLeadCaptureItem, HeroV2SliceDefaultWithLeadCapturePrimary} from 'prismicio-types'
import Image from 'next/image'
import {Container} from 'src/common/Container'
import {StarRatings} from './RatingsSection/StarRatings'
import {IconRatings} from './RatingsSection/IconRatings'
import {LeadCapture} from 'slices/PricingV2/LeadCapture'
import {sanitizeHtml} from 'src/common/helpers/sanitizeHtml'

type DefaultHeroV2Props = {
  slice: {
    primary: HeroV2SliceDefaultWithLeadCapturePrimary
    items: HeroV2SliceDefaultWithLeadCaptureItem[]
    slice_type: string
    variation: string
  }
  pageUid: string
  componentIndex: number
}

export const DefaultHeroV2WithLeadCapture = ({slice, pageUid, componentIndex}: DefaultHeroV2Props) => (
  <Container
    backgroundColor="Beige/200"
    className="flex flex-col gap-12 pb-0 pt-12 md:max-h-[calc(100vw-80px)] md:gap-[60px] md:pb-0"
    data-slice-type={slice.slice_type}
    data-slice-variation={slice.variation}
  >
    <div className="mx-auto grid w-full max-w-[1200px] scroll-mt-16 grid-cols-4 gap-4 gap-y-12 overflow-hidden px-5 md:grid-cols-12 md:gap-x-6 md:gap-y-[60px] md:pt-12 xl:px-0">
      <div
        className={cn('col-span-4 flex flex-col gap-6 md:col-start-2 md:col-end-12', {
          'lg:col-start-4 lg:col-end-10': !slice.primary.extended_text_width,
          'lg:col-start-3 lg:col-end-11': slice.primary.extended_text_width,
        })}
        data-aos="fade-up"
        data-aos-offset="0"
      >
        <div className="col-span-4 grid grid-cols-4 gap-4 gap-y-3 text-center md:grid-cols-6 md:gap-x-6">
          <h1
            className="col-span-full text-40 font-medium leading-[48px] text-moss-black md:text-[56px] md:leading-[64px]"
            dangerouslySetInnerHTML={{__html: sanitizeHtml(slice.primary.heading!)}}
          />
          <p className="col-span-full text-16 leading-6 tracking-[.16px] text-grey-550 md:col-start-2 md:col-end-6">
            {slice.primary.subtext}
          </p>
        </div>
        <div className="mx-auto mb-6 flex gap-2">
          <LeadCapture
            placeholder={slice.primary.input_field_placeholder || ''}
            pageUid={pageUid}
            componentIndex={componentIndex}
            submitLabel={slice.primary.primary_button_label || ''}
            href={slice.primary.primary_button_url || ''}
            pageLocation={`component-${componentIndex}-hero`}
            dataTypeForm="self-sign-up-form"
            dataTypeButton="self-sign-up-button"
            hubspotFormId={slice.primary.hubspot_form || ''}
            mktgId={slice.primary.primary_button_mktg_id || ''}
          />
        </div>
        <div className="flex justify-center gap-3">
          {slice.items.length &&
            slice.items.map((review, index) => {
              return slice.primary.show_stars_in_ratings ? (
                <StarRatings review={review} key={`${review.review_platform} - ${index}`} />
              ) : (
                <IconRatings review={review} key={`${review.review_platform} - ${index}`} />
              )
            })}
        </div>
      </div>
    </div>
    <div
      className="relative left-[20px] max-h-[480px] w-full md:left-0 md:overflow-hidden"
      data-aos="fade-up"
      data-aos-delay="300"
    >
      {isFilled.image(slice.primary.background_image_desktop) &&
        isFilled.image(slice.primary.background_image_mobile) && (
          <picture>
            <Source src={slice.primary.background_image_mobile.url || ''} media="(max-width: 680px)" />
            <Image
              className="mx-auto"
              src={slice.primary.background_image_desktop.url!}
              alt=""
              width={1200}
              height={500}
            />
          </picture>
        )}
    </div>
  </Container>
)
