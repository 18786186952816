import {Content, isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {Button} from 'src/common/Button'
import {Tag} from 'src/common/Tag'
import cn from 'classnames'
import {useInView} from 'framer-motion'
import {useRef} from 'react'
import {useWindowSize} from 'react-use'
import {Gradient} from 'src/common/Gradient'

export const AgendaSection = ({
  agendaData,
  agendaTopics,
  registerButtonData,
  pageUid,
}: {
  agendaData: Content.AiCampaignSlice['primary']['agenda_section'][0]
  agendaTopics: Content.AiCampaignSlice['primary']['agenda_topics']
  registerButtonData: Content.AiCampaignSlice['primary']['register_button'][0]
  pageUid: string
}) => {
  const headingRef = useRef<HTMLHeadingElement>(null)

  const inView = useInView(headingRef, {
    margin: '-10%',
    once: true,
  })

  return (
    <section className="relative flex flex-col pb-[130px] pt-[111px] max-lg:items-center">
      <Gradient side="top" fromColor="Beige/100" />
      <PrismicNextImage
        field={agendaData?.background_image}
        className="absolute inset-0 h-full w-full object-cover"
        priority
      />
      <div className="flex flex-col items-center gap-4 pb-[64px] text-center max-md:w-[343px] md:pb-[56px] xl:pb-[80px]">
        {isFilled.keyText(agendaData?.tag) && <Tag color="neon-green" label={agendaData.tag} />}
        {isFilled.keyText(agendaData?.title) && (
          <h3
            className={cn('text-h2 xl:text-h0 text-moss-black opacity-0', {
              'animate-fadeInUp20': inView,
            })}
            ref={headingRef}
          >
            {agendaData.title}
          </h3>
        )}
      </div>
      <div className="relative mb-[64px] grid min-h-[566px] grid-cols-1 justify-center gap-6 max-md:px-4 md:mb-[56px] md:grid-cols-2 xl:mb-[80px] xl:flex xl:gap-8">
        {agendaTopics.map((topic, index) => (
          <Card topic={topic} index={index} key={index} isLastCard={index === agendaTopics.length - 1} />
        ))}
      </div>
      <Button
        variant="brand-campaign"
        className="z-20 w-fit self-center"
        data-mktg-id={registerButtonData?.register_button_marketing_id as string}
        href="#sign-up-section"
        data-page-location="component-1"
        data-type="webinar-sign-up"
        data-page-name={pageUid}
      >
        {registerButtonData?.register_button_label}
      </Button>
      <Gradient side="bottom" fromColor="Beige/100" />
    </section>
  )
}

const Card = ({
  index,
  topic,
  isLastCard,
}: {
  index: number
  topic: Content.AiCampaignSliceDefaultPrimaryAgendaTopicsItem
  isLastCard: boolean
}) => {
  const cardRef = useRef<HTMLDivElement>(null)
  const {width} = useWindowSize()
  const isMobile = width < 768

  const isInView = useInView(cardRef, {
    once: true,
    margin: '0px 30px',
  })

  return (
    <div
      className={cn({
        'md:col-span-full md:flex md:justify-center': isLastCard,
        'justify-self-end': index === 0,
      })}
      ref={cardRef}
    >
      <div
        className={cn(
          'z-10 flex h-min flex-col gap-8 rounded-2xl bg-moss-white p-3 opacity-0 md:w-[343px] xl:sticky xl:w-[384px]',
          {
            'top-[250px]': index === 1,
            'animate-fadeInUp20': isInView,
          },
        )}
        key={index}
        style={{...(isMobile && {animationDelay: '0.7s'})}}
      >
        <PrismicNextImage field={topic.image} className="rounded-xl md:w-[360px] xl:h-[294px]" priority />
        <div className="px-2 pb-2">
          {isFilled.keyText(topic.tag) && <Tag color="neon-green" label={topic.tag} />}
          <p className="text-h4 pb-2 pt-3 text-moss-black">{topic.title}</p>
          <p className="text-body text-grey-550">{topic.subtitle}</p>
        </div>
      </div>
    </div>
  )
}
