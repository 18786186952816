import {ContentRelationshipField, isFilled} from '@prismicio/client'
import {ButtonDocument} from 'prismicio-types'

export * from './wrong-uids'
export * from './track'
export * from './config'

export const getIconName = (name: string) => {
  if (typeof name !== 'string') {
    return ''
  }

  return name?.toLowerCase().replace(/ /g, '-')
}

export const getButtonsData = ({
  buttonsData,
  primaryButton,
  secondaryButton,
}: {
  buttonsData?: ButtonDocument[]
  primaryButton?: ContentRelationshipField
  secondaryButton?: ContentRelationshipField
}) => {
  const primaryButtonDocId = isFilled.contentRelationship(primaryButton) ? primaryButton.id : null
  const secondaryButtonDocId = isFilled.contentRelationship(secondaryButton) ? secondaryButton.id : null

  const primaryButtonData = buttonsData?.find((button) => button.id === primaryButtonDocId)?.data ?? null
  const secondaryButtonData = buttonsData?.find((button) => button.id === secondaryButtonDocId)?.data ?? null

  return {
    primaryButtonData,
    secondaryButtonData,
  }
}
