import {ApiDemoControllerV2, ConsentControllerV2, HttpClient} from '@getmoss/api-gateway'
import axios from 'axios'
import {useGetConfig} from 'src/common/hooks/useGetConfig'

export const useMossApi = () => {
  const config = useGetConfig()

  const httpClient = axios.create({
    baseURL: config.API_URL,
    timeout: 60000,
  })

  const client = new HttpClient()

  client.instance = httpClient

  const mossApi = {
    apiDemoController: new ApiDemoControllerV2(client),
    consentsController: new ConsentControllerV2(client),
  }

  return {mossApi}
}
