import {useEffect, useMemo, useState} from 'react'
import {useRouter} from 'next/router'
import {createClient} from 'prismicio'
import {CustomerStoryDocument} from 'prismicio-types'
import {CATEGORIES, Locales} from 'src/common/constants'

export const useCustomerStories = () => {
  const [customerStories, setCustomerStories] = useState<CustomerStoryDocument<string>[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const {locale} = useRouter()

  // Fetch customer stories
  useEffect(() => {
    const getCustomerStories = async () => {
      const client = createClient()

      try {
        if (!loading) {
          setLoading(true)
        }

        const response = await client.getAllByType('customer_story', {
          graphQuery: `
            {
              customer_story {
                author {
                  ...on author {
                    photo
                    name
                  }
                }

                company_name
                company_icon
                category
                tag
                image
                title
                parent_page
              }
            }
          `,
          lang: locale,
          orderings: {field: 'my.customer_story.order', direction: 'desc'},
        })

        setCustomerStories(response)
      } catch (e) {
        console.log(e)
        setError(e)
      } finally {
        setLoading(false)
      }
    }

    getCustomerStories()

    // eslint-disable-next-line react-hooks/exhaustive-deps -- not need to include loading
  }, [locale])

  return {customerStories, loading, error}
}

export const useFilteredCustomerStories = (activeCategory: number) => {
  const {locale} = useRouter()
  const {customerStories, loading, error} = useCustomerStories()
  const [selectedStories, setSelectedStories] = useState(customerStories)

  const availableCategories = useMemo(() => {
    if (customerStories.length > 0) {
      const categories = Array.from(
        new Set(
          customerStories.map((customerStory) => {
            return CATEGORIES[customerStory.data.category!]?.[locale! as Locales]
          }),
        ),
      )

      const allCategory = CATEGORIES['All'][locale! as Locales]

      return [allCategory, ...categories]
    }

    return []
  }, [customerStories, locale]).filter((value) => value !== undefined)

  // Filter by category
  useEffect(() => {
    if (availableCategories.length > 0 && activeCategory !== null && !loading) {
      if (activeCategory === 0) {
        setSelectedStories(customerStories)
      } else {
        const filteredStories = customerStories.filter((story) => {
          const categoryInLocale = CATEGORIES[story.data.category!][locale! as Locales]
          return categoryInLocale.toLowerCase() === availableCategories[activeCategory].toLowerCase()
        })

        setSelectedStories(filteredStories)
      }
    } else {
      setSelectedStories(customerStories)
    }
  }, [activeCategory, loading, customerStories, availableCategories, locale])

  return {selectedStories, availableCategories, loading, error}
}
