import {GroupField, KeyTextField} from '@prismicio/client'
import dynamic from 'next/dynamic'
import {Simplify, IntegrationsContainerSliceDefaultPrimaryCategoriesItem} from 'prismicio-types'
import {forwardRef} from 'react'
import {Icon} from 'src/common/Icon'
import cn from 'classnames'

const MossUiTextInput = dynamic({
  loader: () => import('@getmoss/lib-ui-components/moss-ui').then((mod) => mod.TextInput),
  ssr: false,
})

type DesktopStickySearchBoxProps = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  searchText: string
  categories: GroupField<Simplify<IntegrationsContainerSliceDefaultPrimaryCategoriesItem>>
  selectedCategory: number
  changeCategory: (index: number) => void
  searchPlaceholder?: KeyTextField
}

export const DesktopStickySearchBox = forwardRef<HTMLDivElement, DesktopStickySearchBoxProps>(
  ({handleChange, searchText, categories, selectedCategory, changeCategory, searchPlaceholder}, ref) => {
    return (
      <div className="flex w-full flex-col gap-3 md:sticky md:top-[80px]" ref={ref}>
        <div className="relative flex">
          <MossUiTextInput
            className="
            text-body-m
            max-h-10
            w-full
            rounded-lg
            border
            border-grey-150
            py-2.5
            !pl-7
            !pr-3
            text-moss-black
            placeholder:text-grey-300
            hover:bg-grey-110
            focus:outline-none
            lg:w-[520px]
          "
            placeholder={searchPlaceholder || ''}
            value={searchText}
            onChange={handleChange}
          />
          <Icon name="search" className="absolute left-3 top-2.5 h-5 w-5 md:left-3" />
        </div>
        <ul className="flex w-full flex-col rounded-lg outline outline-grey-150">
          {categories.map((category, tabIndex) => (
            <li
              className={cn('cursor-pointer px-4 py-2.5', {
                'bg-grey-120': selectedCategory === tabIndex,
                'bg-moss-white hover:bg-grey-110': selectedCategory !== tabIndex,
              })}
              key={category.category_name! + tabIndex}
              value={tabIndex}
              onClick={() => changeCategory(tabIndex)}
            >
              {category.category_name}
            </li>
          ))}
        </ul>
      </div>
    )
  },
)
