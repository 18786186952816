import {isFilled} from '@prismicio/client'
import {HeadlineSectionSliceHighlightsPrimary} from 'prismicio-types'
import {Container} from 'src/common/Container'
import {sanitizeHtml} from 'src/common/helpers/sanitizeHtml'
import {PrismicRichText} from '@prismicio/react'
import {Icon, IconType} from 'src/common/Icon'
import {getIconName} from 'src/common/helpers'
import cn from 'classnames'

type HighlightsHeadlineSectionProps = {
  slice: {
    primary: HeadlineSectionSliceHighlightsPrimary
    slice_type: string
    variation: string
  }
}

/**
 * Component for "HeadlineSection" Slices.
 */
export const HighlightsHeadlineSection = ({
  slice: {
    primary: {eyebrow, highlights, background_color, title, subtext_rte},
    variation,
    slice_type,
  },
}: HighlightsHeadlineSectionProps): JSX.Element => {
  const sanitizedTitle = sanitizeHtml(title!)

  return (
    <Container
      {...(slice_type && {'data-slice-type': slice_type})}
      {...(variation && {'data-slice-variation': variation})}
      backgroundColor={background_color}
      className="flex flex-col items-center md:px-5 xl:px-0"
    >
      <div className="grid-base gap-y-8 px-5 lg:px-0">
        <div className="col-span-full grid grid-cols-[subgrid] gap-y-12 lg:col-span-8 lg:gap-y-16">
          <div className="col-span-full grid grid-cols-subgrid gap-y-3 md:gap-y-4">
            {eyebrow && <p className="text-body-xs-caps col-span-4 text-green-300">{eyebrow}</p>}
            <h1
              className="text-h2 lg:text-h0 col-span-full text-moss-black"
              dangerouslySetInnerHTML={{__html: sanitizedTitle}}
            />
            <PrismicRichText
              components={{
                paragraph: ({children}) => (
                  <p className="text-body col-span-4 text-grey-550 md:col-span-8 md:max-w-[469px] lg:col-span-5 ">
                    {children}
                  </p>
                ),
              }}
              field={subtext_rte}
            />
          </div>
          {isFilled.group(highlights) && (
            <div className="col-span-full flex flex-col gap-8 md:flex-row md:gap-12 lg:gap-[96px]">
              {highlights.map((highlight, index) => {
                const iconName = getIconName(highlight.icon)

                return (
                  <div className="flex flex-col" key={highlight.title! + index}>
                    <div className="flex items-center gap-1.5">
                      <Icon
                        name={iconName as IconType}
                        className={cn('text-neon-550', {
                          'h-[13px] w-[13px] lg:h-[17px] lg:w-[17px]': iconName === 'plus-bold',
                          'h-[18px] w-[18px] lg:h-[23px] lg:w-[23px]': iconName !== 'plus-bold',
                        })}
                      />
                      <h3 className="text-h3 lg:text-h2 text-moss-black md:text-start">{highlight.title}</h3>
                    </div>
                    <p className="text-body text-grey-450 md:text-start">{highlight.subtext}</p>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}
