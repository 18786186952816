import {SelectField, KeyTextField, ImageField, isFilled} from '@prismicio/client'
import cn from 'classnames'

type NoImageCardProps = {
  backgroundColor: SelectField<'Moss White' | 'Beige/100' | 'Beige/200' | 'Beige/300', 'filled'>
  cardBackgroundColor: SelectField<'Beige/100' | 'Moss Black' | 'Neon Green' | 'Beige/200' | 'Beige/300'>
  testimony: KeyTextField
  author: KeyTextField
  customerIcon: ImageField
}

export const NoImageCard = ({
  backgroundColor,
  cardBackgroundColor,
  testimony,
  author,
  customerIcon,
}: NoImageCardProps) => (
  <div
    className={cn(
      'flex h-full w-full flex-col-reverse items-center justify-center gap-6 rounded-xl px-6 py-12 md:h-full md:py-16 xl:py-24',
      {
        'bg-beige-200 text-moss-black':
          backgroundColor === 'Moss White' &&
          (!isFilled.select(cardBackgroundColor) || cardBackgroundColor === 'Beige/200'),
        'bg-moss-white text-moss-black':
          (backgroundColor === 'Beige/100' || !backgroundColor) && !isFilled.select(cardBackgroundColor),
        'bg-accent-neon text-moss-black': cardBackgroundColor === 'Neon Green',
        'bg-moss-black text-moss-white': cardBackgroundColor === 'Moss Black',
        'bg-beige-100 text-moss-black': cardBackgroundColor === 'Beige/100',
        'bg-beige-300 text-moss-black': cardBackgroundColor === 'Beige/300',
      },
    )}
  >
    <div className="flex flex-col gap-6 text-center md:w-8/12 md:max-w-[710px]">
      <p className="text-20 font-medium leading-7 lg:text-24 lg:leading-8">{testimony}</p>
      <p
        className={cn({
          'text-grey-550': cardBackgroundColor !== 'Moss Black',
          'text-grey-200': cardBackgroundColor === 'Moss Black',
        })}
      >
        {author}
      </p>
    </div>
    {isFilled.image(customerIcon) && (
      <img src={customerIcon.url} alt={customerIcon.alt || ''} className="max-h-[32px] self-center" />
    )}
  </div>
)
