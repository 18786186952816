import cn from 'classnames'
import {Button} from 'src/common/Button'
import {BackgroundColors} from 'src/common/types'
import {ContentRelationshipField, KeyTextField, LinkField, RichTextField, isFilled} from '@prismicio/client'
import {useLinkResolver} from 'prismicio'
import {PrismicRichText} from '@prismicio/react'
import Link from 'next/link'
import {EVENTS, getButtonsData, track} from 'src/common/helpers'
import {ButtonDocument, HeadlineSectionSliceDefaultItem} from 'prismicio-types'
import {Icon} from 'src/common/Icon'
import {sanitizeHtml} from 'src/common/helpers/sanitizeHtml'
import {RatingsSection} from 'slices/HeroV2/RatingsSection'

type HeadlineProps = {
  sliceType?: string
  variation?: string
  backgroundColor?: BackgroundColors
  isCentered?: boolean
  eyebrow?: KeyTextField
  title?: KeyTextField
  subtext?: KeyTextField
  isSubtextFullWidth?: boolean
  primaryButtonLabel?: KeyTextField
  primaryButtonLink?: LinkField
  secondaryButtonLabel?: KeyTextField
  secondaryButtonLink?: LinkField
  secondaryButtonMktgId?: KeyTextField
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4'
  primaryButtonMktgId?: KeyTextField
  pageUid?: string
  componentIndex?: number
  parentComponent?: string
  subtextRte?: RichTextField
  ratings?: HeadlineSectionSliceDefaultItem[]
  ratingsOnTop?: boolean
  primaryButtonAnchor?: KeyTextField
  ratingsType?: 'Icons' | 'Stars' | 'Animated'
  primaryButton?: ContentRelationshipField
  secondaryButton?: ContentRelationshipField
  buttonsData?: ButtonDocument[]
}

export const Headline = ({
  isCentered,
  eyebrow,
  title,
  subtext,
  isSubtextFullWidth,
  primaryButtonLabel,
  primaryButtonLink,
  secondaryButtonLabel,
  secondaryButtonLink,
  secondaryButtonMktgId,
  headingLevel = 'h1',
  primaryButtonMktgId,
  pageUid,
  componentIndex,
  parentComponent,
  subtextRte,
  ratings,
  ratingsOnTop = false,
  primaryButtonAnchor = '',
  ratingsType = 'Stars',
  primaryButton,
  secondaryButton,
  buttonsData,
}: HeadlineProps) => {
  const {linkResolver} = useLinkResolver()
  const sanitizedTitle = sanitizeHtml(title!)
  const {primaryButtonData, secondaryButtonData} = getButtonsData({buttonsData, primaryButton, secondaryButton})
  const primaryLink = primaryButtonData?.link || primaryButtonLink

  const href = isFilled.link(primaryLink)
    ? linkResolver(primaryLink as LinkField, 'primary-button-headline')
    : `#${primaryButtonAnchor}`

  const handleLinkClick = () => {
    track({
      event: EVENTS.CLICK,
      pageName: pageUid,
      location: `component-${componentIndex}`,
      dataType: 'link',
      mktgId: 'subtext-link',
    })
  }

  return (
    <div className="grid-base gap-y-8 px-5 lg:px-0">
      <div
        className={cn('col-span-full grid grid-cols-[subgrid] gap-y-3', {
          'lg:col-span-6': !isCentered,
          'md:col-start-3 md:col-end-11 text-center': isCentered,
        })}
      >
        {eyebrow && (
          <p
            className={cn('text-body-xs col-span-4 uppercase text-accent-purple', {
              'md:col-start-3 md:col-end-7': isCentered,
            })}
          >
            {eyebrow}
          </p>
        )}
        <h2
          className={cn('col-span-full text-moss-black', {
            'text-h2 md:text-h1': headingLevel === 'h1',
            'text-h3 md:text-h2': headingLevel === 'h2',
            'text-h4 md:text-h3': headingLevel === 'h3',
            'text-h5 md:text-h4': headingLevel === 'h4',
          })}
          dangerouslySetInnerHTML={{__html: sanitizedTitle}}
        />
        {isFilled.keyText(subtext) && !isFilled.richText(subtextRte) && (
          <p
            className={cn('text-body col-span-4 text-grey-550', {
              'md:col-start-2 md:col-end-8': isCentered && !isSubtextFullWidth,
              'col-span-full': isSubtextFullWidth,
            })}
          >
            {subtext}
          </p>
        )}
        {isFilled.richText(subtextRte) && (
          <PrismicRichText
            field={subtextRte}
            components={{
              paragraph: ({children}) => (
                <p
                  className={cn('text-body col-span-4 text-grey-550', {
                    'md:col-start-2 md:col-end-8': isCentered && !isSubtextFullWidth,
                    'col-span-full': isSubtextFullWidth,
                  })}
                >
                  {children}
                </p>
              ),
              hyperlink: ({children, node}) => (
                <Link
                  className="text-body underline underline-offset-[3px]"
                  href={linkResolver(node.data)}
                  {...(node.data.link_type === 'Web' && node.data.target && {target: node.data.target})}
                  onClick={handleLinkClick}
                >
                  {children}
                </Link>
              ),
              list: ({children}) => (
                <ul
                  className={cn('text-body flex list-inside list-none justify-center gap-4 pt-5 text-moss-black', {
                    'col-span-full md:col-start-2 md:col-end-8': isCentered && !isSubtextFullWidth,
                    'col-span-full': isSubtextFullWidth,
                  })}
                >
                  {children}
                </ul>
              ),
              listItem: ({children}) => (
                <li className="text-body-bold-s flex gap-2">
                  <Icon name="filled-check-v2" className="h-5 w-5 text-moss-green-450-a" />
                  {children}
                </li>
              ),
            }}
          />
        )}
      </div>
      {(primaryButtonLabel || primaryButtonData) && (
        <div
          className={cn('col-span-full row-start-2 flex gap-2', {
            'justify-center': isCentered,
            'justify-start': !isCentered,
            'row-start-2': !ratingsOnTop,
            'row-start-3': ratingsOnTop,
          })}
        >
          <Button
            variant="fillV2"
            href={href}
            data-mktg-id={primaryButtonData?.marketing_id || (primaryButtonMktgId as string)}
            data-page-location={`component-${componentIndex}-${parentComponent}`}
            data-type={primaryButtonData?.intent || 'cta-button'}
            data-page-name={pageUid}
            id={`component-${componentIndex}-${parentComponent}-1`}
            width={primaryButtonData?.width}
          >
            {primaryButtonData?.label || primaryButtonLabel}
          </Button>
          {(secondaryButtonData || (secondaryButtonLabel && secondaryButtonLink)) && (
            <Button
              variant="outlineV2"
              href={linkResolver(
                secondaryButtonData?.link || (secondaryButtonLink as LinkField),
                'secondary-button-headline',
              )}
              data-mktg-id={secondaryButtonData?.marketing_id || (secondaryButtonMktgId as string)}
              data-page-location={`component-${componentIndex}-${parentComponent}`}
              data-type={secondaryButtonData?.intent || 'cta-button'}
              data-page-name={pageUid}
              id={`component-${componentIndex}-${parentComponent}-2`}
              width={secondaryButtonData?.width}
            >
              {secondaryButtonData?.label || secondaryButtonLabel}
            </Button>
          )}
        </div>
      )}
      {!!ratings?.length && (
        <div
          className={cn('col-span-full flex gap-2', {
            'justify-center': isCentered,
            'justify-start': !isCentered,
            'row-start-1': ratingsOnTop,
          })}
        >
          <RatingsSection ratings={ratings} type={ratingsType} />
        </div>
      )}
    </div>
  )
}
