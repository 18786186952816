import {PrismicNextImage} from '@prismicio/next'
import {HeroV2SliceFullWidthImagePrimary} from 'prismicio-types'
import {Icon, IconType} from 'src/common/Icon'
import cn from 'classnames'
import {getIconName} from 'src/common/helpers'

type HeroV2FullWidthImageProps = {
  slice: {
    primary: HeroV2SliceFullWidthImagePrimary
    variation: string
    slice_type: string
  }
  pageUid: string
  componentIndex: number
}

export const HeroV2FullWidthImage = ({
  slice: {
    primary: {background_image, company_logo, heading, hightlights},
  },
}: HeroV2FullWidthImageProps) => {
  return (
    <div className="relative max-md:flex max-md:flex-col">
      <div
        className="bg-cover bg-[right_center] max-md:order-2 max-md:aspect-square md:aspect-auto md:h-[375px] md:w-full md:max-lg:max-h-[375px] lg:h-[720px] xl:aspect-video"
        style={{
          backgroundImage: `url(${background_image.url})`,
        }}
      />
      <div className="lg:absolute lg:bottom-[96px] lg:left-10 lg:top-[64px] xl:left-1/2 xl:w-full xl:max-w-[1200px] xl:-translate-x-1/2">
        <div className="flex flex-col gap-8 bg-moss-green-500 px-5 py-12 md:flex-row md:gap-16 md:px-10 lg:h-auto lg:w-1/2 lg:flex-col lg:justify-between lg:rounded-2xl lg:p-16">
          <div className="flex flex-col gap-6 md:w-full lg:gap-8">
            <PrismicNextImage field={company_logo} className="h-8 w-fit opacity-60" />
            <h1 className="text-h2 xl:text-h1 text-moss-white">{heading}</h1>
          </div>
          {hightlights.length > 0 && (
            <div className="flex flex-col gap-6 md:w-full md:max-w-[216px] md:gap-8 lg:min-w-full lg:flex-row lg:gap-12">
              {hightlights.map((highlight, index) => {
                const iconName = getIconName(highlight.icon) as IconType

                return (
                  <div key={highlight.text! + index} className="flex flex-col lg:w-full">
                    <div className="flex items-center gap-1">
                      <Icon
                        name={iconName}
                        className={cn('text-neon-450', {
                          'h-2.5 w-2.5 md:h-3 md:w-3': iconName !== 'plus-bold',
                          'h-2 w-2 md:h-2.5 md:w-2.5': iconName === 'plus-bold',
                        })}
                      />
                      <p className="text-body-bold-l lg:text-h4 text-moss-white">{highlight.text}</p>
                    </div>
                    <span className="text-body text-moss-white/[.6] lg:text-wrap">{highlight.subtext}</span>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
