import {Content, isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {SliceComponentProps} from '@prismicio/react'
import {Container} from 'src/common/Container'
import cn from 'classnames'

/**
 * Props for `BadgesSection`.
 */
export type BadgesSectionProps = SliceComponentProps<Content.BadgesSectionSlice>

/**
 * Component for "BadgesSection" Slices.
 */
const BadgesSection = ({slice: {primary, variation, slice_type, items}}: BadgesSectionProps): JSX.Element => {
  const hasTitle = isFilled.keyText(primary.title)

  return (
    <Container backgroundColor={primary.background_color} data-slice-type={slice_type} data-slice-variation={variation}>
      <div
        className="grid w-full max-w-[1200px] grid-cols-4 gap-4 gap-y-8 px-5 md:flex md:justify-between lg:gap-[120px] xl:px-0"
        data-aos="fade-up"
      >
        {hasTitle && (
          <div className="col-span-4 text-center md:self-center md:text-left">
            <h2 className="text-h4 md:text-h3 text-moss-black">{primary.title}</h2>
          </div>
        )}
        <div
          className={cn('col-span-4 flex flex-wrap justify-center gap-4 md:min-w-max md:flex-nowrap', {
            'w-full': !hasTitle,
            'md:justify-end': hasTitle,
          })}
        >
          {items.map((item, index) => (
            <PrismicNextImage field={item.badge} className="h-[112px] w-auto md:h-[120px]" key={index} />
          ))}
        </div>
      </div>
    </Container>
  )
}

export default BadgesSection
