import {ReactNode, memo, useEffect, useState} from 'react'
import {Button, ButtonVariants} from 'src/common/Button'

type ActionButtonProps = {
  className?: string
  children: ReactNode
  isOutline?: boolean
  kind: 'demo' | 'login'
  'data-page-location'?: string
  'data-page-name'?: string
  variant?: ButtonVariants
  id?: string
  funnel?: 0 | 1
}

const OLD_LEAD_FUNNEL_STAGING = 'https://onboarding.internal.runmoss.run/demo'
const OLD_LEAD_FUNNEL_PRODUCTION = 'https://onboarding.getmoss.com/demo'
const NEW_LEAD_FUNNEL_STAGING = 'https://webapp.internal.runmoss.run/get-started'
const NEW_LEAD_FUNNEL_PRODUCTION = 'https://getmoss.com/get-started'

const FUNNEL_URLS = [
  {
    STAGING: OLD_LEAD_FUNNEL_STAGING,
    PRODUCTION: OLD_LEAD_FUNNEL_PRODUCTION,
  },
  {
    STAGING: NEW_LEAD_FUNNEL_STAGING,
    PRODUCTION: NEW_LEAD_FUNNEL_PRODUCTION,
  },
]

export const ActionButton = memo(function ActionButton(props: ActionButtonProps) {
  const [href, setHref] = useState('/')
  const [dataMktgId, setDataMktgId] = useState('book-demo-button')
  const v1Variant = props.isOutline ? 'outlineV2' : 'fillV2'
  const funnel = props.funnel || 0

  // Sets href
  useEffect(() => {
    const isProduction = window.location.host.includes('getmoss.com')

    switch (props.kind) {
      case 'demo': {
        setHref(isProduction ? FUNNEL_URLS[funnel].PRODUCTION : FUNNEL_URLS[funnel].STAGING)
        setDataMktgId('book-demo-button')
        break
      }

      case 'login': {
        setHref(isProduction ? 'https://getmoss.com/login' : 'https://webapp.internal.runmoss.run/login')
        setDataMktgId('login-button')
        break
      }
    }
  }, [props.kind, funnel])

  return (
    <Button
      className={props.className}
      href={href}
      variant={props.variant || v1Variant}
      data-mktg-id={dataMktgId}
      data-page-location={props['data-page-location']}
      {...(props['data-page-name'] && {'data-page-name': props['data-page-name']})}
      {...(props.id && {id: props.id})}
    >
      {props.children}
    </Button>
  )
})
