import {Content, isFilled} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {Container} from 'src/common/Container'
import {Button} from 'src/common/Button'
import {useLinkResolver} from 'prismicio'
import dynamic from 'next/dynamic'
import {Context} from 'src/common/types'
import cn from 'classnames'
import {StarRating} from 'src/common/StarRating'
import {getButtonsData} from 'src/common/helpers'
const ImageContainer = dynamic(() => import('./ImageContainer'), {ssr: false})

/**
 * Props for `CtaV2`.
 */
export type CtaV2Props = SliceComponentProps<Content.CtaV2Slice>

/**
 * Component for "CtaV2" Slices.
 */
const CtaV2 = ({
  slice: {primary},
  slice,
  context: {pageUid, buttonsData},
  index: componentIndex,
}: CtaV2Props & {context: Context}): JSX.Element => {
  const {linkResolver} = useLinkResolver()
  const isWithoutImg = slice.variation === 'withoutImage'

  const {primaryButtonData, secondaryButtonData} = getButtonsData({
    buttonsData,
    primaryButton: primary.primary_button,
    secondaryButton: primary.secondary_button,
  })

  return (
    <Container
      backgroundColor="Beige/200"
      className="!py-12 md:!py-15"
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div className="grid w-full max-w-[1200px] grid-cols-12 px-5 md:gap-3 xl:px-0" data-aos="fade-up" data-aos-once>
        <div
          className={cn(
            'col-span-12 flex flex-col justify-center rounded-lg bg-moss-white px-6 py-12 align-middle lg:p-15',
            {
              'md:col-span-6 lg:col-span-7': !isWithoutImg,
            },
          )}
        >
          <div>
            <h4 className="m-auto max-w-[588px] pb-6 text-center text-24 font-medium leading-8 text-moss-black md:text-32 md:leading-10">
              {primary.title}
            </h4>
          </div>
          <div className="flex flex-wrap justify-center gap-2 pb-8">
            <Button
              variant="fillV2"
              href={linkResolver(primaryButtonData?.link || primary.primary_button_url, 'primary-button-cta')}
              data-mktg-id={primaryButtonData?.marketing_id || primary.primary_button_mktg_id || 'book-demo-button'}
              data-page-location={`component-${componentIndex}-cta`}
              data-page-name={pageUid}
              data-label={primaryButtonData?.label || primary.primary_button_label}
              data-type={primaryButtonData?.intent || 'cta-button'}
              id={`component-${componentIndex}-cta-1`}
              width={primaryButtonData?.width}
            >
              {primaryButtonData?.label || primary.primary_button_label}
            </Button>
            {(isFilled.keyText(primary.secondary_button_label) || secondaryButtonData) && (
              <Button
                variant="outlineV2"
                className="inline-block"
                href={linkResolver(secondaryButtonData?.link || primary.secondary_button_url, 'secondary-button-cta')}
                data-mktg-id={
                  secondaryButtonData?.marketing_id || primary.secondary_button_mktg_id || 'explore-product-button'
                }
                data-page-location={`component-${componentIndex}-cta`}
                data-page-name={pageUid}
                data-label={secondaryButtonData?.label || primary.secondary_button_label}
                id={`component-${componentIndex}-cta-2`}
                data-type={secondaryButtonData?.intent || 'cta-button'}
                width={secondaryButtonData?.width}
              >
                {secondaryButtonData?.label || primary.secondary_button_label}
              </Button>
            )}
          </div>
          {slice.items.map((review, index) => (
            <div
              className="flex justify-center gap-[6px] text-14 leading-5 tracking-[.28px] text-grey-550"
              key={review.review_platform + index}
            >
              <p>{review.review_platform}</p>
              <StarRating rating={review.rating || 5} />
              <p>{review.rating}</p>
            </div>
          ))}
        </div>
        <ImageContainer slice={slice} />
      </div>
    </Container>
  )
}

export default CtaV2
