import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {Context} from 'src/common/types'
import {DefaultHeroV2} from './DefaultHeroV2'
import {SideAssetHeroV2} from './SideAssetHeroV2'
import {DefaultHeroV2WithLeadCapture} from './DefaultWithLeadCapture'
import {VideoHero} from './VideoHero'
import {SideAssetVideoHero} from './SideAssetVideoHero'
import {BentoGridHeroV2} from './BentoGrid'
import {HeroV2FullWidthImage} from './FullWidthImage'

/**
 * Props for `HeroV2`.
 */
export type HeroV2Props = SliceComponentProps<Content.HeroV2Slice>

/**
 * Component for "HeroV2" Slices.
 */
const HeroV2 = ({
  slice,
  context: {pageUid, buttonsData},
  index: componentIndex,
}: HeroV2Props & {context: Context}): JSX.Element => {
  switch (slice.variation) {
    case 'sideAsset':
      return (
        <SideAssetHeroV2 slice={slice} pageUid={pageUid} componentIndex={componentIndex} buttonsData={buttonsData} />
      )
    case 'defaultWithLeadCapture':
      return <DefaultHeroV2WithLeadCapture slice={slice} pageUid={pageUid} componentIndex={componentIndex} />
    case 'video':
      return <VideoHero slice={slice} pageUid={pageUid} componentIndex={componentIndex} />
    case 'sideAssetVideo':
      return <SideAssetVideoHero slice={slice} pageUid={pageUid} componentIndex={componentIndex} />
    case 'bentoGrid':
      return (
        <BentoGridHeroV2 slice={slice} pageUid={pageUid} componentIndex={componentIndex} buttonsData={buttonsData} />
      )
    case 'fullWidthImage':
      return <HeroV2FullWidthImage slice={slice} pageUid={pageUid} componentIndex={componentIndex} />
    default:
      return <DefaultHeroV2 slice={slice} pageUid={pageUid} componentIndex={componentIndex} buttonsData={buttonsData} />
  }
}

export default HeroV2
