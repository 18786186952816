import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {DefaultVariation} from './DefaultVariation'
import {NoImageVariation} from './NoImageVariation'
import {CarouselVariation} from './CarouselVariation'

/**
 * Props for `CustomerSpotlight`.
 */
export type CustomerSpotlightProps = SliceComponentProps<Content.CustomerSpotlightSlice>

/**
 * Component for "CustomerSpotlight" Slices.
 */
const CustomerSpotlight = ({slice}: CustomerSpotlightProps): JSX.Element => {
  switch (slice.variation) {
    case 'carousel':
      return <CarouselVariation slice={slice} />
    case 'noImage':
      return <NoImageVariation slice={slice} />
    default:
      return <DefaultVariation slice={slice} />
  }
}

export default CustomerSpotlight
