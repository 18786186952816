import {DotLottiePlayer} from '@dotlottie/react-player'
import {useRef, useState} from 'react'
import cn from 'classnames'
import {LinkField} from '@prismicio/client'
import {linkResolver} from 'prismicio'

export const AnimationPlayer = ({src, loop = false}: {src: LinkField; loop?: boolean}) => {
  const dLottiePlayerRef = useRef<any>(null)
  const [shouldAppear, setshouldAppear] = useState(false)
  return (
    <DotLottiePlayer
      loop={loop}
      src={linkResolver(src)}
      ref={dLottiePlayerRef}
      onEvent={(event) => {
        if (event === 'ready') {
          setTimeout(() => setshouldAppear(true), 300)

          setTimeout(() => {
            dLottiePlayerRef.current?.play()
          }, 600)
        }

        if (event === 'frame') {
          const lottieInstance = dLottiePlayerRef.current._lottie || {}

          if (lottieInstance.currentFrame === lottieInstance.totalFrames) {
            dLottiePlayerRef.current.pause()
          }
        }
      }}
      className={cn('ease overflow-hidden transition-all duration-300', {
        'opacity-100': shouldAppear,
        'opacity-0': !shouldAppear,
      })}
    />
  )
}
