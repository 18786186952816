import {ImageField} from '@prismicio/client'

export const Asset = ({asset}: {asset: ImageField}) => {
  return (
    <div
      className="h-[256px] w-full rounded-lg bg-cover bg-center md:h-auto"
      style={{
        backgroundImage: `url(${asset.url})`,
      }}
    />
  )
}
