import {LinkField, KeyTextField} from '@prismicio/client'
import {useLinkResolver} from 'prismicio'
import {SurveySectionSliceDefaultPrimaryResultsItem} from 'prismicio-types'
import {Button} from 'src/common/Button'

export const ResultsStep = ({
  funnelScore,
  demoLink,
  demoLabel,
  continueWithoutDemoHandler,
  continueWithoutDemoLabel,
  results,
  pageUid,
}: {
  funnelScore: number
  demoLink: LinkField
  demoLabel: KeyTextField
  continueWithoutDemoHandler: VoidFunction
  continueWithoutDemoLabel: KeyTextField
  results: SurveySectionSliceDefaultPrimaryResultsItem[]
  pageUid: string
}) => {
  const {linkResolver} = useLinkResolver()

  const content = getResult(funnelScore, results)

  return (
    <div className="flex flex-col items-center justify-center gap-8">
      <div className="flex flex-col gap-6">
        <h3 className="text-h3 font-bold text-moss-black">{content?.title}</h3>
        <p className="text-body text-moss-black">{content?.content}</p>
      </div>
      <div className="flex flex-col gap-3">
        <Button
          variant="fillV2"
          href={linkResolver(demoLink)}
          data-mktg-id="book-demo-button"
          data-page-location="survey-section-results"
          data-type={content?.type || ''}
          data-page-name={pageUid}
        >
          {demoLabel}
        </Button>
        <Button
          variant="outlineV2"
          onClick={continueWithoutDemoHandler}
          data-mktg-id="continue-button"
          data-page-location="survey-section-results"
          data-type={content?.type || ''}
          data-page-name={pageUid}
        >
          {continueWithoutDemoLabel}
        </Button>
      </div>
    </div>
  )
}

export const getResult = (score: number, options: SurveySectionSliceDefaultPrimaryResultsItem[]) => {
  let category

  if (score >= 18) {
    category = 'high_priority'
  } else if (score >= 10) {
    category = 'medium_priority'
  } else {
    category = 'low_priority'
  }

  return options.find((result) => result.type?.replace(' ', '_').toLowerCase() === category)
}
