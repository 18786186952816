import {KeyTextField} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {
  BookDemoSectionSliceDirectMailPrimaryAssetSectionItem,
  BookDemoSectionSliceDirectMailPrimaryBadgesItem,
} from 'prismicio-types'
import {Button} from 'src/common/Button'

export const AssetSection = ({
  assetSection,
  badges,
  ratingsTitle,
  continueHandler,
  continueLabel,
}: {
  assetSection?: BookDemoSectionSliceDirectMailPrimaryAssetSectionItem
  badges?: BookDemoSectionSliceDirectMailPrimaryBadgesItem[]
  ratingsTitle: KeyTextField
  continueHandler: VoidFunction
  continueLabel: KeyTextField
}) => {
  return (
    <div className="flex min-h-full w-full flex-col items-center justify-center gap-6 md:mx-auto xl:max-w-[600px]">
      <PrismicNextImage
        field={assetSection?.image}
        className="h-auto w-2/3 self-center justify-self-center pb-10 lg:w-1/2"
      />
      <div className="flex w-full flex-col gap-9 border-y-[1px] border-dashed border-grey-150 px-3 pb-6 pt-4">
        <p className="text-body text-center text-moss-white">{ratingsTitle}</p>
      </div>
      <div className="flex gap-4 self-center">
        {badges?.map((rating, index) => (
          <PrismicNextImage field={rating.badge} key={index} className="h-20 max-h-20 w-min lg:h-30 lg:max-h-30" />
        ))}
      </div>
      <Button variant="fillV2" className="md:hidden" onClick={continueHandler}>
        {continueLabel}
      </Button>
    </div>
  )
}
