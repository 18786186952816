import {useParams, useSearchParams} from 'next/navigation'
import {useRouter} from 'next/router'
import {FieldValues} from 'react-hook-form'

export const appendCurrentParams = (params: URLSearchParams, currentParams: URLSearchParams) => {
  currentParams.forEach((value, key) => {
    if (!params.has(key)) {
      params.append(key, value)
    }
  })

  return params
}

export const useGetPricingFormHandler = ({successUrl}: {successUrl?: string}) => {
  const params = new URLSearchParams()
  const currentParams = useSearchParams()
  const originPage = useGetOriginPageParam()

  const pricingFormHandler = (data: FieldValues) => {
    for (const key in data) {
      if (data[key]) {
        params.append(key.replace(/ /g, '').toLowerCase(), data[key])
      }
    }

    appendCurrentParams(params, currentParams)

    params.append('origin-page', originPage)
    params.append('element', 'pricing-menu')
    params.append('intent', 'pricing')

    return (location.href = `${successUrl}?${params.toString()}`)
  }

  return {pricingFormHandler}
}

export const useGetOriginPageParam = (): string => {
  const {locale} = useRouter()
  const {uid, subpageUid} = useParams() || {}

  const originPage = [locale, uid, subpageUid].filter(Boolean).join('/')

  return originPage
}
