import {ImageField, KeyTextField, RichTextField, isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import Link from 'next/link'
import {linkResolver} from 'prismicio'

type HighlightsCardProps = {
  icon?: ImageField
  headline: KeyTextField
  body: KeyTextField
  body_rte?: RichTextField
}

export const HighlightsCard = ({icon, headline, body, body_rte}: HighlightsCardProps) => {
  const hasIcon = isFilled.image(icon)
  const hasRTE = isFilled.richText(body_rte)

  return (
    <>
      {hasIcon && <PrismicNextImage field={icon} className="h-5 w-5" />}
      <div className="flex flex-col gap-3">
        <h3
          className={cn('text-moss-black', {
            'text-20 font-medium leading-7': hasIcon,
            'text-h0': !hasIcon,
          })}
        >
          {headline}
        </h3>
        <p className="text-body text-grey-550">{body}</p>
        {hasRTE && (
          <PrismicRichText
            field={body_rte}
            components={{
              paragraph: ({children}) => <p className="text-body text-grey-550">{children}</p>,
              hyperlink: ({children, node}) => (
                <Link
                  className="text-body text-center text-grey-550 underline underline-offset-2 md:text-start"
                  href={linkResolver(node.data)}
                  {...(isFilled.link(node.data) && node.data.link_type === 'Web' && {target: node.data.target})}
                >
                  {children}
                </Link>
              ),
            }}
          />
        )}
      </div>
    </>
  )
}
