import {Container} from 'src/common/Container'
import {Swiper, SwiperSlide, SwiperClass} from 'swiper/react'
import {Pagination} from 'swiper/modules'
import {useState} from 'react'
import SwiperNavButtons from 'src/common/SwiperNavButtons'
import {Card} from './Card'
import {Context} from 'src/common/types'
import cn from 'classnames'
import {CustomerStoriesV2SliceDefaultItem, CustomerStoriesV2SliceDefaultPrimary} from 'prismicio-types'

type DefaultCustomerStoriesV2Props = {
  slice: {
    primary: CustomerStoriesV2SliceDefaultPrimary
    items: CustomerStoriesV2SliceDefaultItem[]
    slice_type: string
    variation: string
  }
  pageUid: string
  componentIndex: number
  context: Context
}

/**
 * Component for "CustomerStoriesV2" Slices.
 */
export const DefaultCustomerStoriesV2 = ({
  slice: {primary, items},
  slice,
  context,
  componentIndex,
}: DefaultCustomerStoriesV2Props): JSX.Element => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)

  return (
    <Container
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      backgroundColor={slice.primary.background_color || 'Moss White'}
    >
      <div className="grid max-w-[1200px] scroll-mt-16 grid-cols-4 px-5 md:gap-12 xl:px-0">
        <div className="col-span-4 mb-12 flex items-end justify-between gap-6 md:mb-0">
          <h3 className="text-24 font-medium leading-8 text-moss-black lg:text-32">{primary.title}</h3>
          <SwiperNavButtons swiperInstance={swiperInstance} key={activeIndex} />
        </div>
        <Swiper
          className="col-span-4 max-w-full !overflow-visible md:w-full"
          modules={[Pagination]}
          onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          spaceBetween={24}
          slidesPerView="auto"
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          wrapperClass="ease-in-out duration-100"
        >
          {items.map((card, index) => (
            <SwiperSlide
              className={cn('w-full max-w-[1200px] rounded-xl', {
                'bg-beige-200': primary.background_color === 'Moss White',
                'bg-moss-white': primary.background_color === 'Beige/100',
              })}
              key={index}
            >
              <Card
                title={card.card_title}
                description={card.card_description}
                buttonLink={card.secondary_button_link}
                buttonLabel={card.secondary_button_label}
                image={card.card_image}
                logo={card.company_logo}
                index={index}
                pageUid={context.pageUid}
                componentIndex={componentIndex}
                {...(card.link_locale && {locale: card.link_locale})}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Container>
  )
}
