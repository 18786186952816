import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {FormVariations} from './variations/FormVariations'
import {CtaVariation} from './variations/CtaVariation'

/**
 * Props for `BannerV2`.
 */
export type BannerV2Props = SliceComponentProps<Content.BannerV2Slice> & {
  context: {
    pageUid: string
  }
}

/**
 * Component for "BannerV2" Slices.
 */
const BannerV2 = ({slice, index, context}: BannerV2Props): JSX.Element => {
  switch (slice.variation) {
    case 'cta':
      return <CtaVariation slice={slice} index={index} context={context} />

    // Single image and default variation are handled by the same component
    default:
      return <FormVariations slice={slice} index={index} context={context} />
  }
}

export default BannerV2
