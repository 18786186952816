import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import {useLinkResolver} from 'prismicio'
import {CtaSmallSlice} from 'prismicio-types'
import {memo} from 'react'
import {ActionButton} from 'src/common/ActionButton'
import {Button} from 'src/common/Button'

const CtaSmall = (props: {slice: CtaSmallSlice}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <section
      className={cn('grid scroll-mt-16 grid-cols-main items-center gap-y-8 sm:gap-y-5', {
        'bg-5a7269': props.slice.primary.color === 'Default' || !props.slice.primary.color,
        'bg-3f8896': props.slice.primary.color === 'Blue',
        'bg-926077': props.slice.primary.color === 'Purple',
        'bg-b56659': props.slice.primary.color === 'Red',
        'bg-b47b52': props.slice.primary.color === 'Orange',
      })}
      id={String(props.slice.primary.id_anchor)}
    >
      <div className="col-[inner] row-start-1 grid gap-y-6 pt-10 md:col-end-[track-end_7] md:py-10 lg:col-end-[track-end_7]">
        <PrismicRichText
          components={{
            heading2: ({children}) => (
              <h2 className="text-36 leading-11 -tracking-common text-ffffff sm:text-32 sm:leading-10 md:text-36 md:leading-11 xl:text-48 xl:leading-14 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
                {children}
              </h2>
            ),
            heading3: ({children}) => (
              <h2 className="text-32 leading-10 -tracking-common text-ffffff lg:text-36 lg:leading-11 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
                {children}
              </h2>
            ),
            strong: ({children}) => <strong className="tracking-normal">{children}</strong>,
          }}
          field={props.slice.primary.title}
        />

        <div className="grid grid-flow-col gap-x-2 justify-self-start">
          {props.slice.primary.primary_link_anchor && (
            <Button href={`#${props.slice.primary.primary_link_anchor}`} scroll={false} variant="fill">
              {props.slice.primary.link_label}
            </Button>
          )}

          {!props.slice.primary.primary_link_anchor && (
            <ActionButton kind="demo" data-page-location="bottom">
              {props.slice.primary.link_label}
            </ActionButton>
          )}

          {(isFilled.link(props.slice.primary.secondary_link) || props.slice.primary.secondary_link_anchor) && (
            <Button
              href={
                props.slice.primary.secondary_link_anchor
                  ? `#${props.slice.primary.secondary_link_anchor}`
                  : linkResolver(props.slice.primary.secondary_link, 'secondary-button-cta-small')
              }
              {...(props.slice.primary.secondary_link_anchor && {scroll: false})}
              {...(isFilled.link(props.slice.primary.secondary_link) &&
                props.slice.primary.secondary_link.link_type === 'Web' && {
                  target: props.slice.primary.secondary_link.target,
                })}
              variant="outline"
            >
              {props.slice.primary.secondary_link_label}
            </Button>
          )}
        </div>
      </div>

      {isFilled.image(props.slice.primary.image_mobile) && (
        <PrismicNextImage
          className="col-[outer] w-full max-w-none md:hidden"
          field={props.slice.primary.image_mobile}
        />
      )}

      <PrismicNextImage
        className={cn(
          'col-start-[track-start_6] col-end-[entire-end] row-start-1 h-full w-full lg:col-start-[track-start_7] xl:col-start-[track-start_8] 2xl:col-start-[track-start_9]',
          {
            'max-md:hidden': isFilled.image(props.slice.primary.image_mobile),
          },
        )}
        field={props.slice.primary.image}
      />
    </section>
  )
}

export default memo(CtaSmall)
