import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import {useLinkResolver} from 'prismicio'
import {OurStorySlice} from 'prismicio-types'
import {memo} from 'react'
import {Button} from 'src/common/Button'

const OurStory = (props: {slice: OurStorySlice}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <section
      className="grid scroll-mt-16 grid-cols-main overflow-x-hidden bg-ffffff py-12 md:pb-24 xl:pb-32 xl:pt-20"
      id={String(props.slice.primary.id_anchor)}
    >
      <PrismicNextImage
        className="col-[entire] max-h-44 w-auto min-w-full max-w-none justify-self-center md:max-h-56 xl:max-h-none"
        field={props.slice.primary.image}
      />

      <PrismicRichText
        components={{
          heading2: ({children}) => (
            <h2 className="col-[inner] mt-16 text-center text-32 leading-10 tracking-common text-232f2a lg:text-36 xl:leading-11 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
              {children}
            </h2>
          ),
        }}
        field={props.slice.primary.title}
      />

      <div className="col-[inner] mt-8 grid gap-y-4 text-center text-16 leading-6 text-72716e md:col-start-[track-start_2] md:col-end-[track-end_11] xl:col-start-[track-start_3] xl:col-end-[track-end_10]">
        <PrismicRichText field={props.slice.primary.description} />
      </div>

      {(isFilled.link(props.slice.primary.link) || props.slice.primary.link_anchor) && (
        <Button
          className="col-[inner] mt-8 justify-self-center"
          href={
            props.slice.primary.link_anchor
              ? `#${props.slice.primary.link_anchor}`
              : linkResolver(props.slice.primary.link)
          }
          {...(props.slice.primary.link_anchor && {scroll: false})}
          {...(isFilled.link(props.slice.primary.link) &&
            props.slice.primary.link.link_type === 'Web' && {
              target: props.slice.primary.link.target,
            })}
          variant="fill"
        >
          {props.slice.primary.link_label}
        </Button>
      )}
    </section>
  )
}

export default memo(OurStory)
