import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {SpotlightVariation} from './SpotlightVariation'
import LinksDefaultColumn from './DefaultVariation'

/**
 * Props for `LinksColumn`.
 */
export type LinksColumnProps = SliceComponentProps<Content.LinksColumnSlice> & {
  context: {
    shouldHideTitle?: boolean
    pageUid: string
  }
}

/**
 * Component for "LinksColumn" Slices.
 */
const LinksColumn = ({slice, context, index}: LinksColumnProps): JSX.Element => {
  switch (slice.variation) {
    case 'spotlightColumn':
      return <SpotlightVariation slice={slice} componentIndex={index} context={context} />
    default:
      return <LinksDefaultColumn slice={slice} componentIndex={index} context={context} />
  }
}

export default LinksColumn
