import {Dispatch, SetStateAction, createContext, useState} from 'react'

type DialogContextType = {
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>
  isDialogOpen: boolean
}

export const DialogContext = createContext<DialogContextType | null>(null)

export const DialogContextProvider = ({children}: {children: React.ReactNode}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  return <DialogContext.Provider value={{isDialogOpen, setIsDialogOpen}}>{children}</DialogContext.Provider>
}
