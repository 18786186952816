import {Content} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {SliceComponentProps} from '@prismicio/react'
import {useWindowSize} from 'react-use'
import {ConditionalLink} from './ConditionalLink'
import cn from 'classnames'

/**
 * Props for `SmallBadges`.
 */
export type SmallBadgesProps = SliceComponentProps<Content.SmallBadgesSlice>

/**
 * Component for "SmallBadges" Slices.
 */
const SmallBadges = ({slice: {items, slice_type, variation}}: SmallBadgesProps): JSX.Element => {
  const {width} = useWindowSize()
  const isMobile = width < 768
  const limitedItems = items.slice(0, 3)

  return (
    <div
      data-slice-type={slice_type}
      data-slice-variation={variation}
      className="flex h-[65px] justify-end gap-3 md:h-[86px]"
    >
      {limitedItems.map((item, index) => (
        <ConditionalLink key={index} item={item}>
          <PrismicNextImage
            field={item.badge}
            className={cn({
              'h-full max-h-[65px] w-full max-w-[80px] md:max-h-[86px]': isMobile,
              'max-h-[86px] max-w-[80px]': !isMobile,
            })}
          />
        </ConditionalLink>
      ))}
    </div>
  )
}

export default SmallBadges
