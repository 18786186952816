import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {createClient, useLinkResolver} from 'prismicio'
import {ArticleDocument, AuthorDocument, LatestArticlesSlice} from 'prismicio-types'
import {memo, useCallback, useEffect, useState} from 'react'
import {Button} from 'src/common/Button'

const LatestArticles = (props: {slice: LatestArticlesSlice}) => {
  const [latestArticles, setLatestArticles] = useState<Array<ArticleDocument>>([])
  const router = useRouter()
  const {linkResolver} = useLinkResolver()

  const fetchArticles = useCallback(async () => {
    const prismicClient = createClient()
    let data: Array<ArticleDocument> = []

    try {
      data = await prismicClient.getAllByType('article', {
        graphQuery: `
          {
            article {
              author {
                ...on author {
                  photo
                  name
                }
              }

              is_featured
              tag
              poster
              title
              parent_page
            }
          }
        `,
        lang: router.locale,
        limit: 3,
        orderings: {field: 'my.article.date', direction: 'desc'},
        fetchLinks: ['author.name', 'author.photo'],
      })
    } catch (error) {
      console.error(error)
      data = []
    }

    setLatestArticles(data)
  }, [router])

  // Fetch lates articles
  useEffect(() => {
    fetchArticles()
  }, [fetchArticles])

  return (
    <section
      className="grid scroll-mt-16 grid-cols-main gap-y-8 bg-ffffff py-12 md:gap-y-12 md:pb-20 md:pt-14 xl:gap-y-14 xl:pb-15 xl:pt-20"
      id={String(props.slice.primary.id_anchor)}
    >
      <PrismicRichText
        components={{
          heading2: ({children}) => (
            <h2 className="col-[inner] text-32 leading-10 text-2a2928 lg:text-36 lg:leading-11 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
              {children}
            </h2>
          ),
        }}
        field={props.slice.primary.title}
      />

      <div className="col-[inner] grid gap-x-6 gap-y-8 sm:grid-cols-2 xl:grid-cols-3">
        {latestArticles.map((article, index) => (
          <Link
            className="grid grid-rows-[auto_auto_auto_1fr] rounded-xl bg-f8f8f8 p-4 transition-shadow hover:shadow-article max-xl:last:hidden sm:pb-6 md:p-6"
            href={linkResolver(article)}
            key={index}
          >
            <PrismicNextImage className="aspect-[285/178] w-full rounded-xl object-cover" field={article.data.poster} />

            <div className="mt-6 flex h-[22px] items-center justify-self-start rounded-4 bg-5a7269 px-2 text-12 font-medium leading-4 text-ffffff sm:mt-9">
              {article.data.tag}
            </div>

            <PrismicRichText
              components={{
                heading1: ({children}) => (
                  <h3 className="mt-3 text-18 leading-6 text-2a2928 sm:text-14 sm:leading-5 md:text-20 md:leading-6 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
                    {children}
                  </h3>
                ),
                strong: ({children}) => <span>{children}</span>,
              }}
              field={article.data.title}
            />

            {isFilled.contentRelationship(article.data.author) && (
              <div className="mt-3 flex items-center gap-x-2 self-end text-14 leading-5 text-666563">
                <PrismicNextImage
                  className="h-6 w-6 rounded-full object-cover"
                  field={(article.data.author as unknown as AuthorDocument).data.photo}
                />
                <span>{(article.data.author as unknown as AuthorDocument).data.name}</span>
              </div>
            )}
          </Link>
        ))}
      </div>

      {isFilled.link(props.slice.primary.link) && (
        <Button
          className="col-[inner] justify-self-center xl:-mt-8"
          href={linkResolver(props.slice.primary.link)}
          variant="fill"
          {...(props.slice.primary.link.link_type === 'Web' && {target: props.slice.primary.link.target})}
        >
          {props.slice.primary.link_label}
        </Button>
      )}
    </section>
  )
}

export default memo(LatestArticles)
