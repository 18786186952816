import {FaqSlice} from 'prismicio-types'
import {memo} from 'react'
import DefaultFaq from './DefaultVariation'
import FaqV2 from './FaqV2Variation'

const Faq = (props: {slice: FaqSlice}) => {
  switch (props.slice.variation) {
    case 'v2':
      return <FaqV2 slice={props.slice} />
    default:
      return <DefaultFaq slice={props.slice} />
  }
}

export default memo(Faq)
