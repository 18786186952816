import {HeroV2SliceDefaultItem, HeroV2SliceVideoPrimaryRatingsItem} from 'prismicio-types'
import {StarRating} from 'src/common/StarRating'

type StarRatingsProps = {
  review: HeroV2SliceDefaultItem | HeroV2SliceVideoPrimaryRatingsItem
  color?: 'Neon/500' | 'Grey'
  isActive?: boolean
}

export const StarRatings = ({review}: StarRatingsProps) => (
  <div className="flex justify-center gap-[6px] text-14 leading-5 tracking-[.28px] text-grey-550">
    <p>{review.review_platform}</p>
    <StarRating rating={review.rating || 5} />
    <p>{review.rating}</p>
  </div>
)
