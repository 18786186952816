import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {CustomerStoryDocument} from 'prismicio-types'
import {Container} from 'src/common/Container'
import {CustomerStoryCard} from 'src/common/CustomerStoryCard'
import cn from 'classnames'
import {useMemo} from 'react'

const MAX_CARDS_AMOUNT = 3

/**
 * Props for `MoreCustomerStories`.
 */
export type MoreCustomerStoriesProps = SliceComponentProps<Content.MoreCustomerStoriesSlice> & {
  context: {
    moreCustomerStories: CustomerStoryDocument[]
  }
}

/**
 * Component for "MoreCustomerStories" Slices.
 */
const MoreCustomerStories = ({
  slice: {primary},
  context: {moreCustomerStories: stories},
}: MoreCustomerStoriesProps): JSX.Element => {
  const storiesToDisplay = useMemo(() => stories.slice(0, MAX_CARDS_AMOUNT), [stories])

  if (stories?.length === 0) {
    return <></>
  }

  return (
    <Container className="px-5 md:px-10 lg:px-5 xl:px-0">
      <div className="grid-base gap-y-8 md:gap-y-12">
        <h2 className=" text-h3 md:text-h2 col-span-full text-center text-moss-black">{primary.title}</h2>
        {storiesToDisplay.map((story, index) => (
          <div
            className={cn('col-span-full md:col-span-6 lg:col-span-4', {
              'md:max-lg:hidden': MAX_CARDS_AMOUNT === index + 1,
            })}
            key={story.id}
          >
            <CustomerStoryCard customerStory={story} readMoreLabel={primary.read_more_card_label} />
          </div>
        ))}
      </div>
    </Container>
  )
}

export default MoreCustomerStories
