import cn from 'classnames'
import NextLink from 'next/link'
import {HTMLAttributeAnchorTarget, memo, ReactNode} from 'react'
import {EVENTS, track} from 'src/common/helpers'
import {UrlObject} from 'url'

export type ButtonVariants =
  | 'outline'
  | 'fill'
  | 'fillV2'
  | 'fillV2-dark'
  | 'outlineV2'
  | 'outlineV2-dark'
  | 'brand-campaign'

export const Button = memo(function Button(
  props: {
    children: ReactNode
    variant: ButtonVariants
    className?: string
    'data-mktg-id'?: string
    'data-type'?: string
    'data-page-location'?: string
    'data-page-name'?: string
    size?: 'medium' | 'large'
    id?: string
    disabled?: boolean
    width?: 'content' | 'large'
  } & (
    | {
        href?: string | UrlObject
        target?: HTMLAttributeAnchorTarget
        onClick?: VoidFunction
        scroll?: boolean
        type?: never
        locale?: string
      }
    | {
        href?: never
        target?: never
        onClick?: VoidFunction
        scroll?: never
        type?: 'button' | 'submit'
      }
  ),
) {
  const className = cn(
    'grid min-h-10 items-center rounded-lg px-4 py-2 text-center text-14 font-medium transition-colors',
    {
      'bg-3a5f51 text-ffffff hover:bg-2e4d40': props.variant === 'fill',
      'bg-ffffff text-595755 ring-1 ring-dcdbda hover:bg-f2f2f4': props.variant === 'outline',
      'hover:bg-accent-neon-light inline-block bg-accent-neon text-moss-black disabled:text-moss-black disabled:bg-accent-neon/[.8]':
        props.variant === 'fillV2',
      'hover:bg-accent-neon-light inline-block bg-accent-neon text-moss-black disabled:text-grey-700 disabled:bg-grey-300':
        props.variant === 'fillV2-dark',
      'bg-transparent text-grey-700 hover:bg-grey-120 border border-1 border-grey-150 disabled:text-grey-200':
        props.variant === 'outlineV2' && props.width !== 'large',
      'bg-transparent text-grey-150 hover:bg-595755/[45%] border border-1 border-grey-700 disabled:text-grey-550':
        props.variant === 'outlineV2-dark',
      'bg-[#6C69FC] text-beige-100 py-[18px] px-8 h-14 text-16 font-medium leading-5':
        props.variant === 'brand-campaign',
      'py-[10px] h-[50px]': props.size === 'large',
      'min-w-[130px] min-h-10': props.width === 'large',
      'bg-moss-white text-grey-700 hover:bg-grey-120 border border-1 border-grey-150 disabled:text-grey-200':
        props.variant === 'outlineV2' && props.width === 'large',
      'hover:bg-accent-neon inline-block bg-neon-450 text-moss-black disabled:text-moss-black disabled:bg-neon-450/[.8]':
        props.variant === 'fillV2' && props.width === 'large',
    },
    props.className,
  )

  const handleClick = () => {
    track({
      event: EVENTS.CLICK,
      pageName: props['data-page-name'],
      location: props['data-page-location'],
      dataType: props['data-type'],
      mktgId: props['data-mktg-id'],
    })

    props.onClick?.()
  }

  if (props.href) {
    return (
      <NextLink
        {...(typeof props.scroll !== 'undefined' && {scroll: props.scroll})}
        className={className}
        href={props.href}
        target={props.target}
        locale={props.locale}
        {...(props.id && {id: props.id})}
        onClick={handleClick}
      >
        {props.children}
      </NextLink>
    )
  }

  return (
    <button
      className={className}
      onClick={handleClick}
      type={props.type || 'button'}
      {...(props.id && {id: props.id})}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  )
})
