import {TitleSlice} from 'prismicio-types'
import {memo} from 'react'

const Title = (props: {slice: TitleSlice}) => (
  <h2
    className="grid scroll-mt-16 grid-cols-main justify-items-center py-16"
    id={String(props.slice.primary.id_anchor)}
  >
    <span className="tg-h2 col-[inner] max-w-3xl text-center">{props.slice.primary.title}</span>
  </h2>
)

export default memo(Title)
