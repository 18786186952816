import {KeyTextField, RichTextField, isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import {Dispatch, ReactNode, SetStateAction, memo, useEffect, useRef, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {Button} from 'src/common/Button'
import {Icon} from 'src/common/Icon'
import {mappedSection} from './helpers'
import cn from 'classnames'
import {Gradient} from 'src/common/Gradient'
import {useWindowSize} from 'react-use'
import {UseMeasureRef} from 'react-use/lib/useMeasure'
import {useOutsideClick} from 'src/common/hooks'
import {EVENTS, track} from 'src/common/helpers'

type FormSummaryProps = {
  sections: mappedSection[]
  summaryTitle?: KeyTextField
  tag?: KeyTextField
  bottomTitle?: KeyTextField
  bottomText?: RichTextField
  submitLabel?: KeyTextField
  shouldBeFixed: boolean
  summaryRef: UseMeasureRef<HTMLDivElement>
  formRef: React.RefObject<HTMLFormElement>
  shouldExpand: boolean
  setShouldExpand: Dispatch<SetStateAction<boolean>>
  parentIndex: number
  pageUid: string
  description?: KeyTextField
}

export const FormSummary = memo(
  ({
    sections,
    summaryTitle,
    tag,
    bottomTitle,
    bottomText,
    submitLabel,
    shouldBeFixed,
    summaryRef,
    formRef,
    shouldExpand,
    setShouldExpand,
    parentIndex,
    pageUid,
    description,
  }: FormSummaryProps) => {
    const {watch} = useFormContext()
    const isMobile = useWindowSize().width < 768
    const scrollableRef = useRef<HTMLDivElement>(null)
    const [shouldShowBottomGradient, setShouldShowBottomGradient] = useState(false)
    const [shouldShowTopGradient, setShouldShowTopGradient] = useState(false)
    const watchAll = watch()
    const summaryContainerRef = useRef<HTMLDivElement>(null)

    const clickRef = useOutsideClick((event) => {
      if (shouldExpand) {
        event.preventDefault()

        scrollableRef.current?.scrollTo({
          top: 0,
          behavior: 'smooth',
        })

        setTimeout(() => setShouldExpand(false), 250)
      }
    })

    // Scroll to top when summary is not fixed
    useEffect(() => {
      if (!shouldBeFixed && scrollableRef.current) {
        scrollableRef.current?.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    }, [shouldBeFixed])

    return (
      <div
        className={cn(
          'col-span-4 bg-transparent max-lg:h-min md:col-start-8 md:h-full md:rounded-r-md md:bg-dark-green',
          {
            'fixed bottom-5 left-0 w-full animate-translateUpSlow px-5': shouldBeFixed && isMobile,
            'relative col-span-full': !shouldBeFixed,
            'max-h-[460px]': shouldExpand && shouldBeFixed,
          },
        )}
        ref={summaryRef}
      >
        <div
          className={cn(
            'col-span-4 flex flex-col rounded-xl bg-dark-green p-6 transition-all delay-200 md:sticky md:top-[64px] md:col-start-9 md:col-end-13 md:gap-[42px] lg:p-[30px]',
            {
              'gap-6': shouldBeFixed && isMobile && shouldExpand,
              'h-min gap-[42px]': !shouldBeFixed,
            },
          )}
          onClick={() => {
            if (isMobile) {
              setShouldExpand(true)

              track({
                event: EVENTS.CLICK,
                location: `component-${parentIndex}`,
                dataType: 'box',
                mktgId: 'extend-box-clicked',
                pageName: pageUid,
              })
            }
          }}
          ref={clickRef}
        >
          <div className="relative">
            <div
              className={cn('h-full transition-all duration-200', {
                'max-h-[145px] overflow-hidden': shouldBeFixed && isMobile && !shouldExpand,
                'max-h-[340px] overflow-auto': shouldBeFixed && isMobile && shouldExpand,
              })}
              ref={scrollableRef}
              onScroll={() => {
                if (scrollableRef.current?.scrollTop! > 0 && !shouldShowTopGradient) {
                  setShouldShowTopGradient(true)
                }

                if (scrollableRef.current?.scrollTop! === 0 && shouldShowTopGradient) {
                  setShouldShowTopGradient(false)
                }

                if (
                  scrollableRef.current?.scrollTop! + scrollableRef.current?.clientHeight! ===
                    scrollableRef.current?.scrollHeight! &&
                  shouldShowBottomGradient
                ) {
                  setShouldShowBottomGradient(false)
                }

                if (
                  scrollableRef.current?.scrollTop! + scrollableRef.current?.clientHeight! <=
                    scrollableRef.current?.scrollHeight! &&
                  !shouldShowBottomGradient
                ) {
                  setShouldShowBottomGradient(true)
                }
              }}
            >
              <Gradient
                side="top"
                fromColor="Dark Green"
                className={cn('transition-opacity duration-200', {
                  'opacity-0': !shouldShowTopGradient || !shouldBeFixed || !isMobile || shouldExpand,
                  'opacity-100': shouldShowTopGradient && shouldBeFixed && isMobile && shouldExpand,
                })}
              />
              <div className="flex h-full flex-col gap-[30px]" ref={summaryContainerRef}>
                {isFilled.keyText(tag) && (
                  <p className="text-body-xs w-fit rounded-4 bg-grey-150/[10%] px-2 py-1 uppercase text-grey-150">
                    {tag}
                  </p>
                )}
                <div className="flex flex-col gap-2.5">
                  <h4 className="text-h4 text-moss-white">{summaryTitle}</h4>
                  <p className="text-body-s text-moss-white">{description}</p>
                </div>
                <div className="flex flex-col gap-6">
                  {sections?.map(({title, fields}, sectionIndex) => {
                    if (!fields || !fields.find((field) => Boolean(watchAll[field.paramValue]))) {
                      return null
                    }

                    return (
                      <div key={sectionIndex} className="flex flex-col gap-2">
                        <p className="text-body-xxs uppercase text-grey-150">{title as ReactNode}</p>
                        <ul className="text-body-bold-s flex flex-col gap-2 text-moss-white">
                          {fields?.map(({paramValue, option}, fieldIndex) => {
                            const value = watchAll[paramValue]
                            return (
                              value && (
                                <li
                                  key={fieldIndex}
                                  className={cn('flex h-0 items-center gap-2 align-middle opacity-0', {
                                    'animate-fadeInDown h-full flex': Boolean(value),
                                    hidden: !Boolean(value),
                                  })}
                                >
                                  <Icon name="check" className="h-4 w-4" />
                                  {typeof value === 'string' ? value : option}
                                </li>
                              )
                            )
                          })}
                        </ul>
                      </div>
                    )
                  })}
                </div>
                {isFilled.keyText(bottomTitle) && isFilled.richText(bottomText) && (
                  <>
                    <hr className="border-grey-700" />
                    <SummaryStaticZone bottomTitle={bottomTitle} bottomText={bottomText} />
                  </>
                )}
                <Gradient
                  side="bottom"
                  fromColor="Dark Green"
                  className={cn('transition-opacity duration-200', {
                    'opacity-100': (shouldShowBottomGradient || !shouldExpand) && shouldBeFixed && isMobile,
                    'opacity-0': !shouldShowBottomGradient || !shouldBeFixed,
                  })}
                />
              </div>
            </div>
          </div>
          <Button
            variant="fillV2"
            size="large"
            onClick={() => {
              track({
                event: EVENTS.CLICK,
                mktgId: 'request-pricing-button',
                location: `component-${parentIndex}`,
                dataType: 'box',
                pageName: pageUid,
              })

              formRef.current?.requestSubmit()
            }}
            id="demo-button"
          >
            {submitLabel}
          </Button>
        </div>
      </div>
    )
  },
)

const SummaryStaticZone = memo(
  ({bottomTitle, bottomText}: {bottomTitle?: KeyTextField; bottomText?: RichTextField}) => {
    return (
      <div className="flex flex-col gap-2">
        <p className="text-body-xxs uppercase text-grey-200">{bottomTitle}</p>
        <PrismicRichText
          components={{
            list: ({children}) => <ul className="text-body-bold-s flex flex-col gap-2 text-moss-white">{children}</ul>,
            listItem: ({children}) => (
              <li className="flex items-center gap-2 align-middle">
                <Icon name="check" className="h-4 w-4" />
                {children}
              </li>
            ),
          }}
          field={bottomText}
        />
      </div>
    )
  },
)
