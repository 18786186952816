import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import cn from 'classnames'
import {useLinkResolver} from 'prismicio'
import {NavLinksGroupSlice} from 'prismicio-types'
import {memo, useCallback, useState} from 'react'
import {Icon} from 'src/common/Icon'
import {Link} from 'src/common/Link'
import {track} from 'src/common/helpers'
import {Context} from 'src/common/types'

const NavLinksGroup = ({
  context,
  slice,
  index: componentIndex,
}: {
  context: Context
  slice: NavLinksGroupSlice
  index: number
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = useCallback(() => setIsOpen((prev) => !prev), [])
  const mktgIdCategory = slice.primary.title!.replace(' ', '-').toLowerCase()
  const {linkResolver} = useLinkResolver()

  return (
    <div
      className={cn(
        'group relative text-14',
        {'font-medium grid max-lg:border-b max-lg:border-b-f2f2f4 max-lg:last:border-none': !context.isFooter},
        {'max-lg:grid-rows-[auto_0fr]': !context.isFooter && !isOpen},
        {'max-lg:grid-rows-[auto_1fr]': !context.isFooter && isOpen},
        {'grid content-start gap-y-4': context.isFooter},
      )}
    >
      {(slice.items.length > 1 || context.isFooter) && (
        <div
          className={cn(
            'flex items-center justify-between',
            {
              'max-lg:pl-6 max-lg:pr-8 py-4 cursor-default leading-5 tracking-[.28px]': !context.isFooter,
            },
            {'text-ffffff/50 font-bold': context.isFooter},
            {
              'text-moss-black lg:text-moss-white': context.isDarkNavigation,
            },
          )}
          onClick={() => {
            toggle()

            if (!context.isFooter) {
              track({
                event: 'website_click',
                dataType: `order-${componentIndex}`,
                location: 'main-nav-bar',
                mktgId: `category-${slice.primary.title?.toLowerCase()}`,
                pageName: context.pageUid,
              })
            }
          }}
        >
          <span>{slice.primary.title}</span>
          <Icon className={cn('h-3 w-3 rotate-90 lg:hidden', {hidden: context.isFooter})} name="pagination-arrow" />
        </div>
      )}

      <ul
        className={cn(
          'grid min-h-0',
          {
            'text-moss-black lg:pointer-events-none lg:invisible lg:absolute top-full left-1/2 lg:-translate-x-1/2 whitespace-nowrap rounded-lg bg-ffffff lg:p-3 lg:opacity-0 lg:shadow-small lg:group-hover:pointer-events-auto lg:group-hover:visible lg:group-hover:opacity-100':
              slice.items.length > 1 && !context.isFooter,
          },
          {'justify-items-start gap-y-4': context.isFooter},
          {
            'opacity-0 invisible pointer-events-none max-lg:overflow-hidden':
              !context.isFooter && !isOpen && slice.items.length > 1,
          },
          {'max-lg:py-4': slice.items.length > 1 && !context.isFooter && isOpen},
          {
            'text-moss-black lg:text-moss-white':
              !context.isFooter && slice.items.length === 1 && context.isDarkNavigation,
          },
        )}
      >
        {slice.items.length > 1 && !context.isFooter && (
          <div className="absolute -mt-1.5 h-3 w-3 rotate-45 justify-self-center bg-ffffff max-lg:hidden" />
        )}

        {slice.items.map((item, index) => {
          const hasSubmenu = slice.items.length > 1
          return (
            <li key={index}>
              <Link
                className={cn(
                  'flex items-center transition-colors',
                  {'max-lg:px-6 hover:!text-inherit active:!text-inherit': !context.isFooter},
                  {
                    'lg:p-2 hover:bg-f3f3f3 active:bg-f3f3f3 max-lg:py-1.5': !context.isFooter && hasSubmenu,
                  },
                  {'max-lg:py-4': !context.isFooter && slice.items.length === 1},
                )}
                {...(linkResolver(item.link).pathname.includes('/guide') && {prefetch: false, shallow: true})}
                href={linkResolver(item.link)}
                isInverted={context.isFooter}
                {...(isFilled.link(item.link) && item.link.link_type === 'Web' && {target: item.link.target})}
                onClick={() => {
                  track({
                    event: 'website_click',
                    dataType: `order-${componentIndex}`,
                    location: context.isFooter ? 'footer' : `main-nav-${hasSubmenu ? 'drop-down' : 'bar'}`,
                    mktgId: `category-${mktgIdCategory}${hasSubmenu && !context.isFooter ? `-${index}` : ''}`,
                    pageName: context.pageUid,
                  })
                }}
              >
                {isFilled.image(item.icon) && (
                  <div className={cn('relative mr-4 h-4 w-4 lg:h-5 lg:w-5', {'lg:hidden': slice.items.length === 1})}>
                    <PrismicNextImage alt="" className="object-contain object-center" field={item.icon} fill />
                  </div>
                )}
                <span>{item.label}</span>

                {isFilled.keyText(item.tag) && (
                  <span className="ml-2 flex h-5 items-center rounded-[4px] bg-e8edfd px-1.5 text-10 font-medium uppercase text-4363d0">
                    {item.tag}
                  </span>
                )}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default memo(NavLinksGroup)
