import {Content, isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import Link from 'next/link'
import {memo} from 'react'
import {Icon} from 'src/common/Icon'
import {Tag} from 'src/common/Tag'

export const HeroSection = memo(({heroData}: {heroData: Content.AiCampaignSlice['primary']['hero'][0]}) => {
  return (
    <section className="relative mx-auto flex flex-col items-center gap-9 bg-transparent pb-10 pt-9 max-sm:max-w-[281px] md:max-w-[601px] md:gap-12 md:pb-[64px] md:pt-10 lg:gap-[84px] lg:pb-[80px] xl:max-w-max xl:pt-18">
      <Link href="/">
        <Icon className="h-[22px] w-[92px] xl:h-7 xl:w-[118px]" name="logo" />
      </Link>
      <div className="flex flex-col items-center gap-4 lg:gap-6">
        {isFilled.keyText(heroData?.tag) && <Tag color="brand-purple" label={heroData?.tag} />}
        <div className="flex flex-col items-center gap-4 xl:gap-8">
          {isFilled.richText(heroData?.title) && (
            <PrismicRichText
              field={heroData?.title}
              components={{
                heading1: ({children}) => (
                  <h1 className="text-h2 animate-fadeInUp20 text-center font-medium text-moss-black opacity-0 md:leading-14 lg:text-48 xl:text-[72px] xl:leading-[80px]">
                    {children}
                  </h1>
                ),
              }}
            />
          )}
          {isFilled.richText(heroData?.subtitle) && (
            <PrismicRichText
              field={heroData?.subtitle}
              components={{
                paragraph: ({children}) => (
                  <p
                    className="animate-fadeInUp30 text-center text-16 leading-6 tracking-[.16px] text-moss-black opacity-0 md:max-w-[601px] lg:text-[18px] lg:leading-[26px] lg:tracking-[0.18px] xl:max-w-[505px]"
                    style={{animationDelay: '.3s'}}
                  >
                    {children}
                  </p>
                ),
              }}
            />
          )}
        </div>
      </div>
    </section>
  )
})
