import {PrismicNextImage} from '@prismicio/next'
import {BadgesSlice} from 'prismicio-types'
import {memo} from 'react'

const Badges = (props: {slice: BadgesSlice}) => (
  <section
    id={String(props.slice.primary.id_anchor)}
    className="grid scroll-mt-16 grid-cols-main items-center bg-2f2e2d pb-5 pt-10 md:py-20"
  >
    <h2 className="tg-h3 col-start-[inner] col-end-[track-end_4] text-ffffff md:col-end-[track-end_5]">
      {props.slice.primary.title}
    </h2>

    <div className="col-start-[track-start_5] col-end-[inner-end] grid auto-cols-fr grid-cols-2 gap-2 justify-self-start md:col-start-[track-start_7] md:grid-flow-col md:gap-x-6">
      {props.slice.items.map((item, index) => (
        <PrismicNextImage key={index} field={item.badge} />
      ))}
    </div>
  </section>
)

export default memo(Badges)
