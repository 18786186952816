import {useCallback, useEffect, useState} from 'react'

export const useTimer = ({
  initialSeconds = 0,
  initiallyRunning = false,
  targetTime = 5,
  laps = 2,
  loop = true,
} = {}) => {
  const [seconds, setSeconds] = useState(initialSeconds)
  const [running, setRunning] = useState(initiallyRunning)
  const [lap, setLap] = useState(0)

  const tick = useCallback(() => (running ? setSeconds((currentSeconds) => currentSeconds + 1) : undefined), [running])

  const start = () => {
    setRunning(true)
  }

  const pause = () => {
    setRunning(false)
  }

  const reset = () => {
    setSeconds(0)
  }

  const fullReset = () => {
    setSeconds(0)
    setLap(0)
  }

  const resetToLap = (selectedLap: number) => {
    setSeconds(0)
    setLap(selectedLap)
  }

  const stop = () => {
    pause()
    reset()
  }

  let timeOver = seconds === targetTime

  useSecondsInterval(tick)

  if (timeOver) {
    stop()

    if (!(lap === laps)) {
      start()
      setLap((currentLap) => currentLap + 1)
    } else if (loop) {
      start()
      setLap(0)
    }
  }

  return {pause, start, stop, lap, fullReset, resetToLap, running}
}

const interval =
  (delay = 0) =>
  (callback: () => void) =>
    // Set and clear interval
    useEffect(() => {
      const id = setInterval(callback, delay)

      return () => clearInterval(id)
    }, [callback])

const useSecondsInterval = interval(1000)
