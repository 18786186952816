import {zodResolver} from '@hookform/resolvers/zod'
import {PrismicRichText} from '@prismicio/react'
import {useRouter} from 'next/router'
import {useLinkResolver} from 'prismicio'
import {BookDemoSectionSliceDirectMailPrimary} from 'prismicio-types'
import {useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useEffectOnce} from 'react-use'
import {Button} from 'src/common/Button'
import {EVENTS, track} from 'src/common/helpers'
import {MossUIField, MossUIFieldLabel, MossUITextInput} from 'src/common/MossUIComponents'
import * as z from 'zod'

const LEAD_FUNNEL_URL = 'https://getmoss.com/get-started'

type EmailValidationFormProps = Omit<
  BookDemoSectionSliceDirectMailPrimary,
  'asset_section' | 'badges' | 'ratings_title'
> & {
  pageUid: string
}

export const EmailValidationForm = ({
  title,
  company_email_field,
  company_name,
  continue_button_label,
  calendar_link,
  description,
  hubspot_form_id,
  campaign_id,
  pageUid,
  disclaimer,
}: EmailValidationFormProps) => {
  const {linkResolver} = useLinkResolver()
  const [searchParams, setSearchParams] = useState<URLSearchParams | null>(null)
  const [company, setCompany] = useState<string | null>(null)
  const router = useRouter()
  const fallbackUrl = `${LEAD_FUNNEL_URL}?${searchParams?.toString()}`
  const redirectUserToFallback = () => router.push(fallbackUrl)

  const emailValidationSchema = z.object({
    expectedCompanyName: z.string(),
    email: z.string().email(company_email_field[0]?.error_message || ''),
    formId: z.string(),
    utm_source: z.string().optional(),
    utm_medium: z.string().optional(),
    utm_campaign: z.string().optional(),
    utm_term: z.string().optional(),
    salesforce_campaign_id: z.string().optional(),
  })

  const {handleSubmit, control, register, formState, setValue} = useForm({
    resolver: zodResolver(emailValidationSchema),
    defaultValues: {
      expectedCompanyName: '',
      email: '',
      formId: hubspot_form_id!,
      utm_source: searchParams?.get('utm_source') || '',
      utm_medium: searchParams?.get('utm_medium') || '',
      utm_campaign: searchParams?.get('utm_campaign') || '',
      utm_term: searchParams?.get('utm_term') || '',
      salesforce_campaign_id: campaign_id || '',
    },
  })

  // Get search params in the client side
  useEffectOnce(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const decodedCompany = getDecodedCompany(urlSearchParams.get('company'))

    if (decodedCompany) {
      setValue('expectedCompanyName', decodedCompany)
    }

    setSearchParams(urlSearchParams)
    setCompany(decodedCompany)
  })

  const onSubmit = async (formData: z.infer<typeof emailValidationSchema>) => {
    try {
      const response = await fetch('/api/email-validation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      const data = await response.json()

      track({
        event: EVENTS.FORM_SUBMIT,
        dataType: data.isValid ? 'valid-email' : 'invalid-email',
        pageName: pageUid,
        location: 'direct-mail',
        mktgId: 'direct-mail-form',
      })

      if (data.isValid) {
        router.push(linkResolver(calendar_link))
      } else {
        redirectUserToFallback()
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- error should be handled with isValid false value
    } catch (error) {
      track({
        event: EVENTS.FORM_SUBMIT,
        dataType: 'error',
        pageName: pageUid,
        location: 'direct-mail',
        mktgId: 'direct-mail-form',
      })

      redirectUserToFallback()
    }
  }

  return (
    <div className="flex flex-col gap-10 text-moss-black">
      <div className="flex flex-col gap-3 xl:max-w-[80%]">
        <h1 className="text-h3">{title}</h1>
        <PrismicRichText
          field={description}
          components={{
            paragraph: ({children}) => <p className="text-body">{children}</p>,
            strong: ({children}) => <strong className="text-body font-medium">{children}</strong>,
          }}
        />
      </div>
      <div className="flex w-full flex-col gap-4 md:max-w-[400px]">
        <div className="">
          <p className="text-body flex justify-between text-grey-700">
            {`${company_name[0]?.label}: `}
            <span>{company}</span>
          </p>
          <div className="mt-2 h-[1px] w-full bg-grey-150" />
        </div>
        <form className="flex w-full flex-col gap-5" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register('expectedCompanyName')} value={company || ''} />
          <input type="hidden" {...register('formId')} value={hubspot_form_id!} />
          <input type="hidden" {...register('utm_source')} value={searchParams?.get('utm_source') || ''} />
          <input type="hidden" {...register('utm_medium')} value={searchParams?.get('utm_medium') || ''} />
          <input type="hidden" {...register('utm_campaign')} value={searchParams?.get('utm_campaign') || ''} />
          <input type="hidden" {...register('utm_term')} value={searchParams?.get('utm_term') || ''} />
          <input type="hidden" {...register('salesforce_campaign_id')} value={campaign_id || ''} />
          <div className="">
            <Controller
              name="email"
              control={control}
              rules={{required: company_email_field[0]?.error_message!}}
              render={({field: {value, onChange}, fieldState}) => (
                <MossUIField error={fieldState.error?.message}>
                  <MossUIFieldLabel fieldVariant="inline">
                    <span className="text-body font-normal text-grey-700">{company_email_field[0]?.label}:</span>
                  </MossUIFieldLabel>
                  <MossUITextInput value={value} onChange={onChange} />
                </MossUIField>
              )}
            />
          </div>
          <Button
            type="submit"
            variant="fillV2"
            className="col-span-full w-min"
            data-mktg-id="book-demo-button"
            data-page-name={pageUid}
            data-type="direct-mail"
            disabled={!formState.isValid || formState.isSubmitting}
          >
            {continue_button_label}
          </Button>
        </form>
        <PrismicRichText
          field={disclaimer}
          components={{
            paragraph: ({children}) => <p className="text-body-xs text-grey-700">{children}</p>,
            strong: ({children}) => <strong className="text-body-xs font-medium text-grey-700">{children}</strong>,
            hyperlink: ({node, children}) => (
              <a
                href={node.data.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-body-xs text-grey-700 underline underline-offset-2"
              >
                {children}
              </a>
            ),
          }}
        />
      </div>
    </div>
  )
}

const getDecodedCompany = (encodedCompany: string | null | undefined) => {
  if (!encodedCompany) {
    return null
  }

  return decodeURIComponent(encodedCompany)
}
