import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {HeadlineSectionSliceDefaultItem, HeadlineSectionSliceDefaultPrimary} from 'prismicio-types'
import {Container} from 'src/common/Container'
import {Headline} from 'src/common/Headline'
import {Context} from 'src/common/types'

type DefaultHeadlineSectionProps = {
  slice: {
    primary: HeadlineSectionSliceDefaultPrimary
    items: HeadlineSectionSliceDefaultItem[]
    slice_type: string
    variation: string
  }
  pageUid: string
  componentIndex: number
}

/**
 * Component for "HeadlineSection" Slices.
 */
export const DefaultHeadlineSection = ({
  slice: {
    primary: {center_aligned: isCentered, subtext_with_full_width: isSubtextFullWidth, heading_level},
    primary,
    variation,
    slice_type,
    items,
  },
  pageUid,
  componentIndex,
  context,
}: DefaultHeadlineSectionProps & {context: Context}): JSX.Element => {
  return (
    <Container
      {...(slice_type && {'data-slice-type': slice_type})}
      {...(variation && {'data-slice-variation': variation})}
      backgroundColor={primary.background_color}
      className="flex flex-col items-center gap-12 md:gap-[80px] md:px-5 xl:px-0"
    >
      <Headline
        isCentered={isCentered}
        eyebrow={primary.eyebrow}
        title={primary.title}
        subtext={primary.subtext}
        isSubtextFullWidth={isSubtextFullWidth}
        primaryButtonLabel={primary.primary_button_label}
        primaryButtonLink={primary.primary_button_link}
        primaryButtonAnchor={primary.primary_button_anchor}
        secondaryButtonLabel={primary.secondary_button_label}
        secondaryButtonLink={primary.secondary_button_link}
        headingLevel={heading_level || 'h1'}
        primaryButtonMktgId={primary.primary_button_mktg_id}
        componentIndex={componentIndex}
        pageUid={pageUid}
        parentComponent="headline"
        subtextRte={primary.subtext_rte}
        ratings={items}
        ratingsOnTop={primary.ratings_on_top}
        secondaryButtonMktgId={primary.secondary_button_mktg_id}
        ratingsType={primary.ratings_type}
        primaryButton={primary.primary_button}
        secondaryButton={primary.secondary_button}
        buttonsData={context.buttonsData}
      />
      {isFilled.group(primary.modules) && (
        <div className="mx-auto flex w-full max-w-[1200px] flex-col gap-12 md:flex-row">
          {primary.modules.map((module, index) => (
            <div className="flex w-full flex-col items-center gap-3 md:items-start" key={module.title! + index}>
              <PrismicNextImage field={module.icon} className="h-6 w-6 object-cover" />
              <div className="flex flex-col gap-3">
                <h3 className="text-h5 text-center leading-7 text-moss-black md:text-start">{module.title}</h3>
                <p className="text-body-s text-center text-grey-450 md:text-start">{module.subtext}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </Container>
  )
}
