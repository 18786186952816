import {Tab, Transition} from '@headlessui/react'
import {isFilled} from '@prismicio/client'
import cn from 'classnames'
import {PartnerCategoryDocumentData, PartnersSlice} from 'prismicio-types'
import {memo, useMemo, useState} from 'react'
import {Card} from './Card'
const notNull = (param: string | null): param is string => param !== null

const Partners = memo(function Partners(props: {slice: PartnersSlice}) {
  const [activeCategory, setActiveCategory] = useState(0)

  const partnerCategories = useMemo(
    () =>
      props.slice.items
        .map((partner) =>
          isFilled.contentRelationship(partner.category)
            ? (partner.category.data as PartnerCategoryDocumentData).name
            : null,
        )
        .filter(notNull)
        .filter((value, index, array) => array.indexOf(value) === index),
    [props.slice.items],
  )

  const cards = useMemo(
    () => [
      {cards: props.slice.items},
      ...partnerCategories.map((item) => ({
        cards: props.slice.items.filter(
          (partner) =>
            isFilled.contentRelationship(partner.category) &&
            (partner.category.data as PartnerCategoryDocumentData).name === item,
        ),
      })),
    ],
    [props.slice.items, partnerCategories],
  )

  return (
    <div
      className="grid scroll-mt-16 grid-cols-main items-start gap-y-6 pb-18 text-16 font-medium leading-6"
      id={String(props.slice.primary.id_anchor)}
    >
      <Tab.Group selectedIndex={activeCategory} onChange={setActiveCategory}>
        <Tab.List className="col-[entire] grid justify-start gap-x-7.5 gap-y-2 overflow-x-auto px-6 pb-2 max-md:grid-flow-col max-md:whitespace-nowrap sm:px-10 md:sticky md:top-20 md:col-start-[inner-start] md:col-end-[track-end_3] md:px-0">
          <Tab
            className={cn(
              'text-left outline-none',
              {'text-666563': activeCategory !== 0},
              {'text-7290f3': activeCategory === 0},
            )}
            type="button"
          >
            {props.slice.primary.all_categories_label}
          </Tab>

          {partnerCategories.map((partnerCategory, index) => (
            <Tab
              className={cn(
                'text-left outline-none',
                {'text-666563': activeCategory !== index + 1},
                {'text-7290f3': activeCategory === index + 1},
              )}
              type="button"
              key={index}
            >
              {partnerCategory}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className="col-[inner] grid items-start md:col-start-[track-start_4]">
          {cards.map((item, index) => (
            <Tab.Panel key={index}>
              <Transition
                appear
                className="grid gap-x-6 gap-y-3 sm:grid-cols-2 lg:grid-cols-3"
                show={index === activeCategory}
                enter="transition-opacity duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {item.cards.map((card, cardsIndex) => (
                  <Card data={card} key={cardsIndex} />
                ))}

                {item.cards.length === 0 && (
                  <div className="col-span-full bg-dddddd p-2.5 text-14 leading-5 text-333333">
                    {props.slice.primary.empty_category_message}
                  </div>
                )}
              </Transition>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
})

export default Partners
