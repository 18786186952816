import {CustomerStoriesSlice} from 'prismicio-types'
import {Context} from 'src/common/types'
import {CustomerStoriesV2Variation} from './v2'
import {CustomerStoriesDefault} from './Default'

const CustomerStories = (props: {context: Context; slice: CustomerStoriesSlice}) => {
  switch (props.slice.variation) {
    case 'v2':
      return <CustomerStoriesV2Variation slice={props.slice} context={props.context} />
    case 'default':
      return <CustomerStoriesDefault slice={props.slice} context={props.context} />
  }
}

export default CustomerStories
