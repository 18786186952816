import cn from 'classnames'
import {Content, isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {SliceComponentProps} from '@prismicio/react'
import {useLinkResolver} from 'prismicio'
import {Button} from 'src/common/Button'

/**
 * Props for `Banner`.
 */
export type BannerProps = SliceComponentProps<Content.BannerSlice>

/**
 * Component for "Banner" Slices.
 */
const Banner = ({slice}: BannerProps): JSX.Element => {
  const {linkResolver} = useLinkResolver()
  return (
    <section className="grid scroll-mt-16 grid-cols-main items-center gap-y-6 py-6">
      <div
        className={cn(
          'col-[inner] grid max-w-[46.25rem] grid-cols-12 grid-rows-1 gap-6 justify-self-center overflow-hidden rounded-lg',
          {
            'bg-2a2928': slice.primary.background_color === 'Moss Black',
            'bg-e2dcd5': slice.primary.background_color === 'Beige',
          },
        )}
      >
        <div className="col-span-12 flex flex-col justify-center gap-6 p-4 md:col-span-7">
          <div
            className={cn('flex flex-col gap-y-2.5', {
              'text-ffffff': slice.primary.background_color === 'Moss Black',
              'text-2a2928': slice.primary.background_color === 'Beige',
            })}
          >
            <p className="text-12 font-medium uppercase tracking-[.02em]">{slice.primary.tag_line}</p>
            <h3 className="text-28 font-medium">{slice.primary.title}</h3>
          </div>
          {isFilled.link(slice.primary.primary_button_link) && (
            <Button
              className="z-20 w-max md:z-0"
              variant="fill"
              href={linkResolver(slice.primary.primary_button_link, 'primary-button-banner')}
            >
              {slice.primary.primary_button_label}
            </Button>
          )}
        </div>
        {isFilled.image(slice.primary.image) && (
          <div className="hidden md:col-span-5 md:flex md:items-center md:justify-center">
            <PrismicNextImage field={slice.primary.image} className="max-h-[200px] w-auto" />
          </div>
        )}
      </div>
    </section>
  )
}

export default Banner
