import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {memo} from 'react'
import {Context} from 'src/common/types'
import ProductDeepDiveDefault from './Default/ProductDeepDiveDefault'
import {ProductDeepDiveDocumentData, ProductDeepDiveVerticalScrollDocumentData} from 'prismicio-types'
import dynamic from 'next/dynamic'
import SingleSectionVariation from './SingleSectionVariation'

const VerticalScrollVariation = dynamic(
  async () => (await import('./VerticalScrollVariation')).VerticalScrollVariation,
  {ssr: false},
)

/**
 * Props for `ProductDeepDive`.
 */
export type ProductDeepDiveProps = SliceComponentProps<Content.ProductDeepDiveSlice>

/**
 * Component for "ProductDeepDive" Slices.
 */

const ProductDeepDive = ({
  slice,
  context: {product_deep_dive_data, pageUid},
  index: componentIndex,
}: ProductDeepDiveProps & {context: Context}): JSX.Element => {
  if (!product_deep_dive_data && slice.variation !== 'singleSection') {
    return <></>
  }

  switch (slice.variation) {
    case 'verticalScroll':
      return (
        <VerticalScrollVariation
          slice={slice}
          productDeepDiveData={product_deep_dive_data as ProductDeepDiveVerticalScrollDocumentData}
          index={componentIndex}
          context={{pageUid}}
          slice_type={slice.slice_type}
        />
      )
    case 'singleSection':
      return (
        <SingleSectionVariation
          slice={slice}
          productDeepDiveData={product_deep_dive_data as ProductDeepDiveDocumentData}
          index={componentIndex}
          context={{pageUid}}
          slice_type={slice.slice_type}
        />
      )
    default:
      return (
        <ProductDeepDiveDefault
          slice={slice}
          context={{pageUid}}
          index={componentIndex}
          productDeepDiveData={product_deep_dive_data as ProductDeepDiveDocumentData}
          slice_type={slice.slice_type}
        />
      )
  }
}

export default memo(ProductDeepDive)
