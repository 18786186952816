import {KeyTextField, RichTextField} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {memo} from 'react'
import {Icon} from 'src/common/Icon'

export const Card = memo(function Card(props: {
  className?: string
  description: RichTextField
  list: RichTextField
  title: KeyTextField
}) {
  const {linkResolver} = useLinkResolver()
  return (
    <div className={cn('grid content-start gap-y-6', props.className)}>
      <div className="grid gap-y-2">
        <h3 className="text-24 font-bold leading-8 -tracking-common text-2a2928 sm:text-32 sm:leading-10">
          {props.title}
        </h3>

        <PrismicRichText
          components={{
            paragraph: ({children}) => <p className="text-16 leading-6 text-666563">{children}</p>,
            hyperlink: ({children, node}) => (
              <Link
                className="text-7290f3"
                href={linkResolver(node.data)}
                {...(node.data.link_type === 'Web' && node.data.target && {target: node.data.target})}
              >
                {children}
              </Link>
            ),
          }}
          field={props.description}
        />
      </div>

      <PrismicRichText
        components={{
          list: ({children}) => <ul className="grid gap-y-3">{children}</ul>,
          listItem: ({children}) => (
            <li className="grid grid-flow-col items-start justify-start gap-x-3.5 text-2f2e2d md:items-center">
              <Icon className="h-5 w-5" name="fill-check" noMask />
              <div className="text-16 leading-6 tracking-common">{children}</div>
            </li>
          ),
          paragraph: ({children}) => <p className="text-16 leading-6 text-666563">{children}</p>,
          hyperlink: ({children, node}) => (
            <Link
              className="text-16 leading-6 text-666563 underline"
              href={linkResolver(node.data)}
              {...(node.data.link_type === 'Web' && node.data.target && {target: node.data.target})}
            >
              {children}
            </Link>
          ),
        }}
        field={props.list}
      />
    </div>
  )
})
