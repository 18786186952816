import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {PressSliderSlice} from 'prismicio-types'
import {memo, useState} from 'react'
import {useWindowSize} from 'react-use'
import {Icon} from 'src/common/Icon'
import {Navigation} from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import {Swiper, SwiperSlide, SwiperClass} from 'swiper/react'

const PressSlider = (props: {slice: PressSliderSlice}) => {
  const {width} = useWindowSize()
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass>()
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [isLastSlideVisible, setIsLastSlideVisible] = useState(false)
  const {linkResolver} = useLinkResolver()

  return (
    <section
      className="grid scroll-mt-16 grid-cols-main gap-y-8 bg-f3f3f3 pb-12 pt-10 sm:py-16 md:gap-y-12 xl:pt-20"
      id={String(props.slice.primary.id_anchor)}
    >
      <PrismicRichText
        components={{
          heading2: ({children}) => (
            <h2 className="col-[inner] text-28 leading-8 text-232f2a [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
              {children}
            </h2>
          ),
        }}
        field={props.slice.primary.title}
      />

      <Swiper
        className="col-[entire] w-full"
        modules={[Navigation]}
        spaceBetween={16}
        slidesPerView="auto"
        slidesOffsetBefore={24}
        slidesOffsetAfter={width - 288 - 16}
        breakpoints={{
          640: {spaceBetween: 24, slidesOffsetBefore: 40, slidesOffsetAfter: 40},
          768: {spaceBetween: 24, slidesOffsetBefore: 40, slidesOffsetAfter: 40},
          1280: {
            spaceBetween: 24,
            slidesOffsetBefore: 160,
            slidesOffsetAfter: 160,
          },
          1440: {
            spaceBetween: 24,
            slidesOffsetBefore: (width - 1440) / 2 + 160,
            slidesOffsetAfter: (width - 1440) / 2 + 160,
          },
        }}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        resizeObserver={false}
        watchSlidesProgress
      >
        {props.slice.items.map((item, index) => (
          <SwiperSlide className="!w-72 sm:!w-80 md:!w-96 xl:!w-[450px]" key={index}>
            <Link
              className="grid h-full content-between gap-y-20 rounded-xl bg-ffffff px-8 pb-8 pt-6 sm:gap-y-11 md:gap-y-16"
              href={linkResolver(item.link)}
              target="_blank"
            >
              <PrismicNextImage className="h-10 w-30 object-contain object-left" field={item.logo} />

              <div className="grid gap-y-4 text-14 leading-5 text-72716e">
                <PrismicRichText field={item.description} />
                <div className="font-medium text-456e5e">{item.link_label}</div>
              </div>
            </Link>
          </SwiperSlide>
        ))}

        <SwiperSlide className="!w-0">
          {({isVisible}) => {
            setIsLastSlideVisible(isVisible)
            return null
          }}
        </SwiperSlide>
      </Swiper>

      <div className="col-[inner] grid grid-flow-col items-center justify-between">
        <button
          className={cn({'pointer-events-none invisible opacity-0': swiperInstance?.isBeginning})}
          onClick={() => swiperInstance?.slidePrev()}
          type="button"
        >
          <Icon className="h-9 w-5 rotate-180" name="pagination-arrow" />
        </button>

        <div className="flex gap-x-2">
          {props.slice.items.map((_item, index) => (
            <div
              className={cn(
                'h-1 w-10 rounded-full',
                {'bg-b8b6b4': index !== activeIndex},
                {'bg-232f2a': index === activeIndex},
              )}
              key={index}
            />
          ))}
        </div>

        <button
          className={cn({'pointer-events-none invisible opacity-0': isLastSlideVisible})}
          onClick={() => swiperInstance?.slideNext()}
          type="button"
        >
          <Icon className="h-9 w-5" name="pagination-arrow" />
        </button>
      </div>
    </section>
  )
}

export default memo(PressSlider)
