import {PrismicRichText} from '@prismicio/react'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {FormEventHandler} from 'react'
import {UseFormRegister, UseFormStateReturn} from 'react-hook-form'
import {Button} from 'src/common/Button'
import {Icon} from 'src/common/Icon'
import cn from 'classnames'
import {Container} from 'src/common/Container'
import {SignupSectionSliceV2Primary} from 'prismicio-types'

type FormData = {
  firstName: string
  lastName: string
  email: string
  checkbox: boolean
}

/**
 * Props for `SignupSection`.
 */
export type SignupSectionV2Props = {
  slice: {
    primary: SignupSectionSliceV2Primary
    variation: string
    slice_type: string
  }
  formHandler: FormEventHandler<HTMLFormElement>
  register: UseFormRegister<FormData>
  formState: UseFormStateReturn<FormData>
}

/**
 * Component for "SignupSection" Slices.
 */
export const SignupSectionV2 = ({
  slice: {primary, slice_type, variation},
  formHandler,
  register,
  formState: {isSubmitSuccessful, isSubmitting, isValid},
}: SignupSectionV2Props): JSX.Element => {
  const {linkResolver} = useLinkResolver()

  return (
    <Container
      className="scroll-mt-16 px-5 md:px-10 lg:px-5"
      data-slice-type={slice_type}
      data-slice-variation={variation}
      id="sign-up-section"
    >
      <div
        className={cn(
          'flex max-w-[1200px] flex-col items-center gap-6 rounded-lg px-6 py-12 md:w-full md:gap-10 md:rounded-2xl md:px-8 lg:flex-row lg:justify-between lg:p-20 xl:p-[120px]',
          {
            'bg-beige-100': primary.background_color === 'Beige/100',
            'bg-beige-200': primary.background_color === 'Beige/200',
            'bg-beige-300': primary.background_color === 'Beige/300',
            'bg-moss-black text-moss-white': primary.background_color === 'Moss Black',
            'bg-moss-green-500': primary.background_color === 'Green/500',
            'bg-5a7269': primary.background_color === 'Green (legacy)',
          },
        )}
      >
        <div className="flex flex-col gap-3 text-center lg:max-w-[384px]">
          <PrismicRichText
            components={{
              heading2: ({children}) => (
                <h2
                  className={cn('text-h4 md:text-h3 lg:text-h2 lg:text-start', {
                    'text-moss-black': primary.background_color !== 'Moss Black',
                    'text-moss-white':
                      primary.background_color === 'Moss Black' ||
                      primary.background_color === 'Green (legacy)' ||
                      primary.background_color === 'Green/500',
                  })}
                >
                  {children}
                </h2>
              ),
            }}
            field={primary.title}
          />

          <PrismicRichText
            components={{
              paragraph: ({children}) => (
                <p
                  className={cn('text-body md:max-w-[384px] md:self-center lg:text-start', {
                    'text-moss-black': primary.background_color !== 'Moss Black',
                    'text-moss-white':
                      primary.background_color === 'Moss Black' || primary.background_color === 'Green (legacy)',
                    'text-moss-white/[.6]': primary.background_color === 'Green/500',
                  })}
                >
                  {children}
                </p>
              ),
            }}
            field={primary.description}
          />
        </div>

        <div className="flex flex-col gap-6 md:h-full md:w-full lg:max-w-[380px]">
          <form
            className={cn(
              'grid grid-cols-2 place-content-center gap-2 text-moss-black transition-all duration-300 md:w-[380px]  md:self-center',
              {
                hidden: isSubmitSuccessful,
              },
            )}
            onSubmit={formHandler}
          >
            <label>
              <div className="hidden">{primary.first_name_label || 'First Name'}</div>

              <input
                className="h-10 w-full rounded-lg border border-dcdbda bg-moss-white px-4 text-14 leading-5 outline-none transition-shadow placeholder:text-8f8d8b focus:shadow-card"
                placeholder={primary.first_name_placeholder || 'Andrea'}
                type="text"
                {...register('firstName', {required: 'Please enter your first name.'})}
              />
            </label>

            <label>
              <div className="hidden">{primary.last_name_label || 'Last Name'}</div>

              <input
                className="h-10 w-full rounded-lg border border-dcdbda bg-moss-white px-4 text-14 leading-5 outline-none transition-shadow placeholder:text-8f8d8b focus:shadow-card"
                placeholder={primary.last_name_placeholder || 'Smith'}
                type="text"
                {...register('lastName', {required: 'Please enter your last name.'})}
              />
            </label>

            <label className="col-span-2">
              <div className="hidden">{primary.email_label || 'Your work email'}</div>

              <input
                className="h-10 w-full rounded-lg border border-dcdbda bg-moss-white px-4 text-14 leading-5 outline-none transition-shadow placeholder:text-8f8d8b focus:shadow-card"
                placeholder={primary.email_placeholder || 'email@company.com'}
                type="text"
                {...register('email', {required: 'Please enter your email.'})}
              />
            </label>

            <label className="col-span-2 grid grid-flow-col justify-start gap-x-3 text-14 leading-5">
              <div className="grid">
                <input
                  className="h-3 w-3 appearance-none rounded-4 border border-dcdbda bg-ffffff outline-none transition-shadow span-full placeholder:text-8f8d8b checked:border-4e7e6c checked:bg-4e7e6c focus:shadow-card"
                  type="checkbox"
                  {...register('checkbox', {required: 'Please confirm this.'})}
                />
                <Icon className="h-3 w-3 text-moss-white span-full" name="tick" />
              </div>

              <PrismicRichText
                components={{
                  paragraph: ({children}) => (
                    <div className="text-10 leading-[14px] tracking-[.2px] text-moss-white">{children}</div>
                  ),
                  hyperlink: ({children, node}) => (
                    <Link
                      className="text-4e7e6c"
                      href={linkResolver(node.data)}
                      {...(node.data.link_type === 'Web' && node.data.target && {target: node.data.target})}
                    >
                      {children}
                    </Link>
                  ),
                }}
                field={primary.checkbox_label}
              />
            </label>

            <div className="col-span-full pt-4">
              <Button
                className="justify-self-center lg:justify-self-start"
                variant="fillV2"
                type="submit"
                disabled={!isValid}
              >
                {isSubmitting ? primary.button_submitting_label : primary.button_label}
              </Button>
            </div>
          </form>
          <div
            className={cn(
              'flex h-full w-full flex-col items-center justify-center gap-6 rounded-2xl bg-moss-white max-md:px-5 max-md:py-6 max-md:text-center md:max-lg:min-h-[174px] md:max-lg:max-w-[384px] md:max-lg:self-center',
              {
                hidden: !isSubmitSuccessful,
              },
            )}
          >
            <Icon className="h-12 w-12 text-moss-green-500" name="outline-check-v2" noMask />
            <div className="flex flex-col gap-3 text-center">
              <h3 className="text-h4 md:text-h3 text-moss-green-500">{primary.success_title || 'Super!'}</h3>

              <PrismicRichText
                components={{
                  paragraph: ({children}) => <p className="text-body text-moss-green-500">{children}</p>,
                }}
                field={primary.success_description}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
