import {Icon} from 'src/common/Icon'

export const AvailabilityIcon = ({available}: {available: boolean}) => {
  return (
    <div
      className={`flex h-5 w-5 items-center justify-center rounded-[600px] ${
        available ? 'bg-accent-neon' : 'bg-ui-red-150'
      }`}
    >
      <Icon
        name={available ? 'check-mark' : 'cross-bold'}
        className={`${available ? 'h-[9px] w-[11px] text-ui-green-900' : 'h-[10px] w-[10px] text-ui-red-900'}`}
      />
    </div>
  )
}
