import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {ToolsSlice} from 'prismicio-types'
import {memo} from 'react'

const Tools = (props: {slice: ToolsSlice}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <section
      className="grid scroll-mt-16 grid-cols-main gap-y-6 pt-16 md:py-28 xl:gap-y-20"
      id={String(props.slice.primary.id_anchor)}
    >
      <h2 className="tg-h2 col-[inner] max-w-lg justify-self-center text-center">{props.slice.primary.title}</h2>

      <div className="col-[inner] grid auto-cols-fr grid-cols-2 gap-6 text-2a2928 max-lg:max-w-[350px] max-lg:justify-self-center lg:grid-flow-col">
        {props.slice.items.map((item, index) => (
          <Link
            key={index}
            className="grid place-content-center gap-y-2 rounded-2xl bg-ffffff p-4 shadow-lp transition-colors hover:bg-f8f8f8 md:h-32 md:gap-y-3 md:px-4 md:py-0"
            href={linkResolver(item.link)}
          >
            {item.image.url && (
              <PrismicNextImage alt="" aria-hidden className="select-none justify-self-center" field={item.image} />
            )}

            <PrismicRichText
              components={{
                paragraph: ({children}) => (
                  <p className="grid min-h-[40px] content-center text-center text-14 font-medium">{children}</p>
                ),
              }}
              field={item.title}
            />
          </Link>
        ))}
      </div>
    </section>
  )
}

export default memo(Tools)
