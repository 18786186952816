import {Content, isFilled} from '@prismicio/client'
import {PrismicRichText, SliceComponentProps} from '@prismicio/react'
import {useLinkResolver} from 'prismicio'
import {Fragment, useCallback, useState} from 'react'
import {Link} from 'src/common/Link'
import {Context} from 'src/common/types'
import NextLink from 'next/link'
import {PrismicNextImage} from '@prismicio/next'
import {CookieConsentPopup} from 'src/common/Layout/Footer/CookieConsentPopup'

/**
 * Props for `FooterNoNav`.
 */
export type FooterNoNavProps = SliceComponentProps<Content.FooterNoNavSlice>

/**
 * Component for "FooterNoNav" Slices.
 */
const FooterNoNav = ({context: {navigation_data}}: FooterNoNavProps & {context: Context}): JSX.Element => {
  const [isPopupShown, setIsPopupShown] = useState(false)
  const close = useCallback(() => setIsPopupShown(false), [])
  const {linkResolver} = useLinkResolver()

  const showCookieConsent = useCallback(() => {
    if (typeof window !== 'undefined') {
      // @ts-ignore -- usercentrics script is loaded by GTM
      window.UC_UI?.showSecondLayer()
    }
  }, [])

  return (
    <footer className="grid grid-cols-main bg-2a2928 pb-20 pt-10 text-ffffff">
      <div className="col-[inner] grid gap-y-6 md:col-end-[track-start_9] md:row-start-5 lg:col-end-[track-end_8]">
        <div className="grid items-center justify-start gap-4 sm:grid-flow-col">
          {navigation_data.underline_links.map((link, index) => (
            <Fragment key={index}>
              <Link
                className="text-14"
                href={linkResolver(link.link)}
                {...(isFilled.link(link.link) && link.link.link_type === 'Web' && {target: link.link.target})}
                isInverted
              >
                {link.label}
              </Link>

              <hr className="hidden h-3 border-l border-t-0 border-l-ffffff sm:block" />
            </Fragment>
          ))}

          <button className="text-start text-14" onClick={showCookieConsent} type="button">
            {navigation_data.cookie_consent_label}
          </button>
        </div>

        <div className="text-10 text-b8b6b4">
          <PrismicRichText field={navigation_data.description} />
        </div>

        <div className="grid grid-flow-col justify-start gap-x-6 text-10 text-b8b6b4">
          <span>{navigation_data.copyright}</span>
          <span>{navigation_data.additional_text}</span>
        </div>
      </div>

      {isFilled.image(navigation_data.image) && (
        <NextLink
          aria-label={navigation_data.image.alt || undefined}
          className="col-start-[track-start_5] col-end-[track-end_6] row-start-5 mt-7.5 justify-self-end md:col-start-[track-start_12] md:mt-0 md:self-end"
          href={linkResolver(navigation_data.image_link)}
          {...(isFilled.link(navigation_data.image_link) &&
            navigation_data.image_link.link_type === 'Web' && {target: navigation_data.image_link.target})}
        >
          <PrismicNextImage field={navigation_data.image} />
        </NextLink>
      )}

      <CookieConsentPopup isShown={isPopupShown} navigationData={navigation_data} close={close} />
    </footer>
  )
}

export default FooterNoNav
