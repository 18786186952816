import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import {InvestorsSlice} from 'prismicio-types'
import {memo} from 'react'

const Investors = (props: {slice: InvestorsSlice}) => (
  <section
    className="grid scroll-mt-16 grid-cols-main bg-926077 pb-16 pt-14 text-center text-ffffff sm:pt-16 md:pt-20 xl:pb-20 xl:pt-24"
    id={String(props.slice.primary.id_anchor)}
  >
    <h2 className="col-[inner] text-28 font-bold leading-8 xl:text-32 xl:leading-9">{props.slice.primary.title}</h2>

    <PrismicRichText
      components={{
        paragraph: ({children}) => (
          <p className="col-[inner] mt-6 text-20 leading-7 xl:text-22 xl:leading-8">{children}</p>
        ),
      }}
      field={props.slice.primary.description}
    />

    <div className="col-[inner] mt-16 flex flex-wrap justify-center gap-x-4 gap-y-8 sm:gap-x-6 md:col-start-[track-start_2] md:col-end-[track-end_11] md:gap-x-8 lg:gap-x-16 xl:col-start-[track-start_3] xl:col-end-[track-end_10] 2xl:gap-x-30">
      {props.slice.items.map((item, index) => (
        <div className="grid w-[calc(50%_-_theme(spacing.2))] justify-items-center gap-y-6 sm:w-[166px]" key={index}>
          <div className="relative h-9 w-full">
            <PrismicNextImage className="object-contain" fill field={item.logo} />
          </div>

          <div className="grid gap-y-1 text-12 leading-4 text-ffffff/70">
            <PrismicRichText field={item.description} />
          </div>
        </div>
      ))}
    </div>
  </section>
)

export default memo(Investors)
