import {Content, isFilled, KeyTextField} from '@prismicio/client'
import {PrismicRichText, SliceComponentProps} from '@prismicio/react'
import {Container} from 'src/common/Container'
import {TextLink} from 'src/common/TextLink'
import cn from 'classnames'
import {PricingSectionSliceDefaultPrimaryFirstPackageItem} from 'prismicio-types'
import {useEffect, useState} from 'react'
import {animate, motion, useMotionValue, useTransform} from 'framer-motion'
import {interpolate} from 'flubber'
import * as paths from './IconPaths'
import {LinkWrapper} from 'src/common/LinkWrapper'

/**
 * Props for `PricingSection`.
 */
export type PricingSectionProps = SliceComponentProps<Content.PricingSectionSlice>

/**
 * Component for "PricingSection" Slices.
 */
const PricingSection = ({slice}: PricingSectionProps): JSX.Element => {
  return (
    <Container
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      backgroundColor="Beige/100"
      id="pricing-section"
    >
      <div className="grid-base max-w-[1200px] gap-y-14 px-5 md:px-10 lg:gap-y-[88px] lg:px-0">
        <div className="col-span-full flex flex-col gap-4 md:col-span-10 md:col-start-2 lg:col-span-6 lg:col-start-4">
          {isFilled.keyText(slice.primary.title) && (
            <h3 className="text-h3 text-center text-moss-black">{slice.primary.title}</h3>
          )}
          {isFilled.richText(slice.primary.subtext) && (
            <PrismicRichText
              field={slice.primary.subtext}
              components={{
                paragraph: ({children}) => <p className="text-body text-center text-grey-550">{children}</p>,
              }}
            />
          )}
          {isFilled.keyText(slice.primary.text_link_label) && (
            <div className="pt-2 text-center">
              <TextLink field={slice.primary.text_link_url} label={slice.primary.text_link_label!} />
            </div>
          )}
        </div>
        <div className="col-span-full grid grid-cols-subgrid gap-y-10">
          <PackageSection title={slice.primary.first_package_title} modules={slice.primary.first_package} />
          <PackageSection title={slice.primary.second_package_title} modules={slice.primary.second_package} />
          <PackageSection title={slice.primary.third_package_title} modules={slice.primary.third_package} />
        </div>
      </div>
    </Container>
  )
}

export default PricingSection

type PackageSectionProps = {
  title: KeyTextField
  modules: PricingSectionSliceDefaultPrimaryFirstPackageItem[]
}

const PackageSection = ({title, modules}: PackageSectionProps) => {
  return (
    <div
      className={cn('col-span-full flex flex-col gap-4 lg:grid lg:grid-cols-subgrid', {
        'lg:col-span-3': modules.length === 1,
        'lg:col-span-6': modules.length === 2,
        'lg:col-span-9': modules.length === 3,
        'lg:col-span-full': modules.length === 4,
      })}
    >
      <span className="text-body-xs uppercase lg:col-span-full">{title}</span>
      <div className="flex flex-col gap-4 lg:col-span-full lg:row-start-2 lg:grid lg:grid-cols-subgrid lg:gap-6">
        {modules.map((module, index) => (
          <ModuleCard {...module} key={module.module_title! + index} />
        ))}
      </div>
    </div>
  )
}

type ModuleCardProps = PricingSectionSliceDefaultPrimaryFirstPackageItem

const ModuleCard = ({link_url, module_title, background_color, module_icon, description}: ModuleCardProps) => {
  const [pathIndex, setPathIndex] = useState(0)
  const indexValue = useMotionValue(pathIndex)

  const handleHover = () => setPathIndex(1)
  const handleMouseLeave = () => setPathIndex(0)

  const pathsArr = [paths[module_icon!], paths.ExternalLink]

  const path = useTransform(indexValue, [0, 1], pathsArr, {
    mixer: (a, b) => interpolate(a, b, {maxSegmentLength: 1}),
  })

  // Trigger animation on path change
  useEffect(() => {
    const animation = animate(indexValue, pathIndex, {
      duration: 0.2,
      ease: 'linear',
      delay: 0,
    })

    return () => {
      animation.stop()
    }
  }, [indexValue, pathIndex])

  return (
    <LinkWrapper
      className="group lg:col-span-3"
      link={link_url}
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={cn('flex flex-col gap-3 rounded-xl p-[22px] lg:col-span-3 lg:h-[288px] lg:justify-between', {
          'bg-moss-white border border-grey-135': background_color === 'Moss White',
          'bg-moss-green-700': background_color === 'Green/700',
          'bg-beige-300': background_color === 'Beige/300',
        })}
      >
        <div className="flex justify-between">
          <span
            className={cn('text-body-bold-l text-moss-black group-hover:underline group-hover:underline-offset-4', {
              'text-moss-black': background_color !== 'Green/700',
              'text-moss-white': background_color === 'Green/700',
            })}
          >
            {module_title}
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <motion.path fillRule="evenodd" clipRule="evenodd" d={path} fill="#85B833" />
          </svg>
        </div>
        <PrismicRichText
          field={description}
          components={{
            paragraph: ({children}) => (
              <p
                className={cn('text-body-s', {
                  'text-grey-550': background_color !== 'Green/700',
                  'text-grey-120': background_color === 'Green/700',
                })}
              >
                {children}
              </p>
            ),
          }}
        />
      </div>
    </LinkWrapper>
  )
}
