import {useEffect, useState} from 'react'
import {configs, getProfile, Profile} from 'src/common/helpers/config'

export const useGetConfig = () => {
  const [profile, setProfile] = useState<Profile>('dev')

  // Get profile on first render
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const activeProfile = getProfile()
      setProfile(activeProfile)
    }
  }, [])

  return configs[profile]
}
