import {Button} from 'src/common/Button'
import {isFilled} from '@prismicio/client'
import {useLinkResolver} from 'prismicio'
import {
  ButtonDocument,
  HeroV2SliceDefaultItem,
  HeroV2SliceSideAssetItem,
  HeroV2SliceSideAssetPrimary,
  HeroV2SliceSideAssetPrimaryRatingsItem,
} from 'prismicio-types'
import {Tag} from 'src/common/Tag'
import {PrismicNextImage} from '@prismicio/next'
import {Container} from 'src/common/Container'
import {AnimationPlayer} from 'slices/ProductDeepDive/VerticalScrollVariation/AnimationPlayer'
import {IconRatings} from './RatingsSection/IconRatings'
import {sanitizeHtml} from 'src/common/helpers/sanitizeHtml'
import {TextLink} from 'src/common/TextLink'
import {PrismicRichText} from '@prismicio/react'
import Link from 'next/link'
import {StarRating} from 'src/common/StarRating'
import {locales, REVIEW_PLATFORMS} from 'src/common/constants'
import {useRouter} from 'next/router'
import cn from 'classnames'
import {EVENTS, getButtonsData, track} from 'src/common/helpers'

type DefaultHeroV2Props = {
  slice: {
    primary: HeroV2SliceSideAssetPrimary
    items: HeroV2SliceSideAssetItem[]
    slice_type: string
    variation: string
  }
  pageUid: string
  componentIndex: number
  buttonsData?: ButtonDocument[]
}

export const SideAssetHeroV2 = ({slice, pageUid, componentIndex, buttonsData}: DefaultHeroV2Props) => {
  const {linkResolver} = useLinkResolver()
  const sanitizedTitle = sanitizeHtml(slice.primary.heading!)
  const {locale} = useRouter()

  const {primaryButtonData, secondaryButtonData} = getButtonsData({
    buttonsData,
    primaryButton: slice.primary.primary_button,
    secondaryButton: slice.primary.secondary_button,
  })

  return (
    <Container
      backgroundColor="Beige/300"
      className="flex flex-col gap-12 !py-12 md:gap-[60px] md:!pt-10"
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div className="mx-auto grid w-full max-w-[1200px] scroll-mt-16 grid-cols-4 gap-4 gap-y-12 px-5 md:grid-cols-12 md:gap-x-6 md:px-10 lg:px-5 xl:px-0">
        <div className="col-span-full grid grid-cols-subgrid gap-y-12 md:grid-cols-subgrid md:gap-y-0">
          <div
            className="col-span-4 flex flex-col items-center gap-6 md:col-span-6 md:items-start md:gap-8 md:self-center lg:col-span-5 lg:gap-10"
            data-aos="fade-up"
            data-aos-offset="0"
          >
            <div className="col-span-4 grid grid-cols-4 gap-4 gap-y-3 text-center md:col-span-6 md:grid-cols-6 md:gap-x-6 md:gap-y-4 md:text-start">
              <h1
                className="text-h2 lg:text-h0 col-span-full text-moss-black"
                dangerouslySetInnerHTML={{__html: sanitizedTitle}}
              />
              <p className="text-body col-span-full text-grey-550">{slice.primary.subtext}</p>
            </div>
            <div className="flex gap-2">
              {(isFilled.keyText(slice.primary.primary_button_label) || primaryButtonData) && (
                <Button
                  variant="fillV2"
                  href={
                    slice.primary.primary_button_anchor
                      ? `#${slice.primary.primary_button_anchor}`
                      : linkResolver(primaryButtonData?.link || slice.primary.primary_button_url, 'primary-button-hero')
                  }
                  data-mktg-id={primaryButtonData?.marketing_id || slice.primary.primary_button_mktg_id || ''}
                  data-page-location={`component-${componentIndex}-hero`}
                  data-type={primaryButtonData?.intent || 'hero-button'}
                  data-page-name={pageUid}
                  data-label={primaryButtonData?.label || slice.primary.primary_button_label}
                  id={`component-${componentIndex}-hero-1`}
                  width={primaryButtonData?.width}
                >
                  {primaryButtonData?.label || slice.primary.primary_button_label}
                </Button>
              )}
              {(isFilled.keyText(slice.primary.secondary_button_label) || secondaryButtonData) && (
                <Button
                  href={linkResolver(
                    secondaryButtonData?.link || slice.primary.secondary_button_url,
                    'secondary-button-hero',
                  )}
                  variant="outlineV2"
                  data-mktg-id={secondaryButtonData?.marketing_id || slice.primary.secondary_button_mktg_id || ''}
                  data-page-location={`component-${componentIndex}-hero`}
                  data-type={secondaryButtonData?.intent || 'hero-button'}
                  data-page-name={pageUid}
                  data-label={secondaryButtonData?.label || slice.primary.secondary_button_label}
                  id={`component-${componentIndex}-hero-2`}
                  width={secondaryButtonData?.width}
                >
                  {secondaryButtonData?.label || slice.primary.secondary_button_label}
                </Button>
              )}
            </div>
            {isFilled.keyText(slice.primary.tag) && <Tag label={slice.primary.tag} />}
            {isFilled.group(slice.primary.ratings) && (
              <RatingsSection
                onTop={slice.primary.ratings_on_top}
                rating={slice.primary.ratings}
                locale={locale as keyof typeof locales}
              />
            )}
            {isFilled.keyText(slice.primary.disclaimer) && (
              <p className="text-body-s text-grey-300">{slice.primary.disclaimer}</p>
            )}
          </div>
          <div className="col-span-full flex w-full justify-center overflow-hidden rounded-xl align-middle md:col-span-6 md:rounded-2xl lg:col-start-7">
            {isFilled.link(slice.primary.lottie_animation) ? (
              <AnimationPlayer src={slice.primary.lottie_animation} loop={slice.primary.loop_animation} />
            ) : (
              <PrismicNextImage
                field={slice.primary.background_image_desktop}
                className="aspect-square max-h-[334px] w-auto object-contain md:col-span-6 md:max-h-[341px] md:self-center lg:max-h-[586px]"
              />
            )}
          </div>
        </div>
        {!!slice.items.length && (
          <div className="col-span-full flex flex-col gap-6 md:flex-row">
            {slice.items.map((item, index) => {
              const trackInTextLink = () =>
                track({
                  location: `component-${componentIndex}-hero`,
                  pageName: pageUid,
                  dataType: 'hero-link',
                  mktgId: `hero-link-${index}`,
                  event: EVENTS.CLICK,
                })

              return (
                <div className="flex w-full flex-col items-center gap-6 md:items-start" key={item.title! + index}>
                  <PrismicNextImage field={item.icon} className="h-6 w-6 object-cover" />
                  <div className="flex flex-col gap-3">
                    <h3 className="text-h5 text-center leading-7 text-moss-black md:text-start">{item.title}</h3>
                    {isFilled.richText(item.subtext_rte) && (
                      <PrismicRichText
                        components={{
                          paragraph: ({children}) => (
                            <p className="text-body text-center text-grey-450 md:text-start">{children}</p>
                          ),
                          hyperlink: ({children, node}) => (
                            <Link
                              className="text-body text-center text-grey-450 underline underline-offset-2 md:text-start"
                              href={linkResolver(node.data)}
                              {...(isFilled.link(node.data) &&
                                node.data.link_type === 'Web' && {target: node.data.target})}
                              onClick={trackInTextLink}
                            >
                              {children}
                            </Link>
                          ),
                        }}
                        field={item.subtext_rte}
                      />
                    )}
                    {/* Delete after migrating content to RTE field */}
                    {item.subtext && !isFilled.richText(item.subtext_rte) && (
                      <p className="text-body text-center text-grey-450 md:text-start">{item.subtext}</p>
                    )}
                    {item.learn_more_label && (
                      <TextLink
                        field={item.learn_more_link}
                        label={item.learn_more_label!}
                        mktgId={`hero-learn-more-${index}`}
                        pageName={pageUid}
                        location={`component-${componentIndex}-hero`}
                        dataType="hero-learn-more"
                      />
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </Container>
  )
}

const RatingsSection = ({
  onTop,
  rating,
  locale,
}: {
  onTop: boolean
  rating: HeroV2SliceSideAssetPrimaryRatingsItem[]
  locale: keyof typeof locales
}) => {
  return (
    <div
      className={cn('flex gap-3', {
        '-order-1': onTop,
      })}
    >
      {rating.map((platform, index) => {
        return platform.stars_ratings ? (
          <div className="text-body flex items-center gap-[6px] text-moss-black">
            <span>{REVIEW_PLATFORMS[platform.review_platform || 'G2'][locale]}</span>
            <StarRating rating={platform.rating!} color="Neon/500" />
            <span>{platform.rating?.toFixed(1)}</span>
          </div>
        ) : (
          <IconRatings key={index} review={platform as HeroV2SliceDefaultItem} />
        )
      })}
    </div>
  )
}
