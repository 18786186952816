import {isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import NextLink from 'next/link'
import {useLinkResolver} from 'prismicio'
import {TextBlockSlice} from 'prismicio-types'
import {memo} from 'react'
import {ActionButton} from 'src/common/ActionButton'
import {Button} from 'src/common/Button'

const TextBlock = (props: {slice: TextBlockSlice}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <section
      className="grid scroll-mt-16 grid-cols-main gap-y-6 pb-8 pt-4 text-232f2a sm:pb-12 sm:pt-6 md:pb-16 md:pt-10 lg:pb-16 lg:pt-20"
      id={String(props.slice.primary.id_anchor)}
    >
      <h2 className="col-[inner] text-28 font-bold -tracking-common md:col-end-[track-end_6]">
        {props.slice.primary.title}
      </h2>

      <div className="col-[inner] grid gap-y-6 md:col-start-[track-start_7]">
        <div>
          <PrismicRichText
            components={{
              paragraph: ({children}) => <p className="text-16 leading-6">{children}</p>,
              hyperlink: ({children, node}) => (
                <NextLink
                  className="text-22 leading-7"
                  href={linkResolver(node.data)}
                  {...(isFilled.link(node.data) && node.data.link_type === 'Web' && {target: node.data.target})}
                >
                  {children}
                </NextLink>
              ),
              heading3: ({children}) => <p className="text-22 leading-7">{children}</p>,
            }}
            field={props.slice.primary.text}
          />
        </div>

        {isFilled.link(props.slice.primary.link) && !props.slice.primary.is_link_intro && (
          <Button
            className="justify-self-start"
            href={linkResolver(props.slice.primary.link, 'primary-button-text-block')}
            {...(isFilled.link(props.slice.primary.link) &&
              props.slice.primary.link.link_type === 'Web' && {
                target: props.slice.primary.link.target,
              })}
            variant="fill"
          >
            {props.slice.primary.link_label}
          </Button>
        )}

        {props.slice.primary.is_link_intro && (
          <ActionButton className="justify-self-start" kind="demo" funnel={1}>
            {props.slice.primary.link_label}
          </ActionButton>
        )}
      </div>
    </section>
  )
}

export default memo(TextBlock)
