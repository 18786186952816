import {ImageField, isFilled, KeyTextField} from '@prismicio/client'
import {TAG_COLORS} from 'src/common/constants'
import cn from 'classnames'
import {PrismicNextImage} from '@prismicio/next'

type TagProps = {
  icon?: ImageField
  label: KeyTextField
  color?: keyof typeof TAG_COLORS
  variant?: 'default' | 'small' | 'xsmall'
}

export const Tag = ({label, color, variant = 'default', icon}: TagProps) => (
  <p
    className={cn('flex h-fit w-fit items-center rounded-full bg-[#222B271A]/10 font-medium uppercase text-grey-450', {
      'text-body-xs px-2 py-1': variant === 'default',
      'text-body-xxs px-1.5 py-0.5': variant === 'small',
      'text-body-xs-caps-500 px-1.5 py-0.5': variant === 'xsmall',
      'flex gap-2 py-2 px-5 text-body': isFilled.image(icon),
    })}
    {...(color && {style: TAG_COLORS[color]})}
  >
    {isFilled.image(icon) && <PrismicNextImage field={icon} className="h-5 w-5" />}
    {label}
  </p>
)
