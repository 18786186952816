import {LinkProps} from 'next/link'
import cn from 'classnames'
import {EVENTS, TrackingProps, track} from 'src/common/helpers'
import {memo} from 'react'
import {PrismicNextLink} from '@prismicio/next'
import {isFilled, LinkField} from '@prismicio/client'

type TextLinkProps = {
  label: string
  variation?: TextLinkVariations
  disabled?: boolean
  underline?: boolean
  target?: string
  field?: LinkField
  color?: 'moss-black' | 'neon-550' | 'accent-neon'
} & Omit<LinkProps, 'href'> &
  Omit<TrackingProps, 'event'>

type TextLinkVariations = 'normal' | 'small' | 'x-small' | 'responsive'

const TextLinkComponent = ({
  label,
  variation = 'normal',
  underline = false,
  disabled = false,
  mktgId,
  pageName,
  location,
  dataType,
  field,
  color = 'moss-black',
}: TextLinkProps) => {
  const isLinkToDoc = isFilled.link(field) && field.link_type === 'Document'

  return (
    <div className="isolate">
      <PrismicNextLink
        field={field}
        {...(isLinkToDoc && {locale: field.lang})}
        className={cn(
          'group/textlink w-fit gap-1.5 font-medium tracking-[.28px] focus:border-2 focus:border-blue-550',
          {
            'text-body-xs lg:text-body-s': variation === 'x-small',
            'text-16 leading-6': variation === 'normal',
            'text-14 leading-5': variation === 'small',
            'text-14 leading-5 lg:text-16 lg:leading-6': variation === 'responsive',
            'text-grey-200 pointer-events-none': disabled,
            'bg-text-link bg-[100%_1px,0_1px] bg-no-repeat hover:bg-[0_1px,100%_1px] transition-all duration-700 w-max pb-1 [background-position:100%_100%,0_100%]':
              underline,
            'text-moss-black': color === 'moss-black',
            'text-neon-550': color === 'neon-550',
            'text-accent-neon': color === 'accent-neon',
          },
        )}
        onClick={() => {
          track({
            event: EVENTS.CLICK,
            pageName,
            location,
            dataType,
            label,
            mktgId,
          })
        }}
        aria-disabled={disabled}
      >
        {label}
        {!underline && <LinkArrow />}
      </PrismicNextLink>
    </div>
  )
}

export const LinkArrow = () => (
  <span className="inline-block pl-1.5 duration-200 group-hover/textlink:translate-x-[3px] group-hover:ease-in-out">
    →
  </span>
)

export const TextLink = memo(TextLinkComponent)
