import {Content, isFilled} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {default as NextScript} from 'next/script'

export type ScriptProps = SliceComponentProps<Content.ScriptSlice>

const Script = ({slice}: ScriptProps) => {
  if (!isFilled.keyText(slice.primary.script_source)) {
    return null
  }

  return <NextScript src={slice.primary.script_source} />
}

export default Script
