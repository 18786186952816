import {Content, isFilled} from '@prismicio/client'
import {PrismicRichText, SliceComponentProps} from '@prismicio/react'
import cn from 'classnames'
import {PrismicNextImage} from '@prismicio/next'
import {useState, useEffect, useRef} from 'react'
import {IconTag} from 'src/common/IconTag'

/**
 * Props for `GatedAsset`.
 */
export type GatedAssetProps = SliceComponentProps<Content.GatedAssetSlice>

/**
 * Component for "GatedAsset" Slices.
 */
const GatedAsset = ({slice}: GatedAssetProps): JSX.Element => {
  const [hideFloatingButton, setHideFloatingButton] = useState(false)
  const myRef = useRef<HTMLElement | null>(null)

  const handleScroll = () => {
    setHideFloatingButton(myRef.current ? window.scrollY > myRef.current?.offsetHeight / 3 : false)
  }

  //track scroll offset for hiding the mobile floating button
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  }, [])

  return (
    <>
      <section
        ref={myRef}
        className={cn(
          'flex flex-[1_1_50%] flex-col items-start gap-4 bg-beige-100 px-12 py-6 md:px-16 md:py-10 xl:px-28 xl:py-24',
        )}
      >
        {isFilled.keyText(slice.primary.tag) && (
          <IconTag
            {...(isFilled.select(slice.primary.tag_icon_name) && {iconName: slice.primary.tag_icon_name})}
            label={slice.primary.tag}
          />
        )}

        <PrismicRichText
          field={slice.primary.title}
          components={{
            heading4: ({children}) => <h2 className="text-h4 text-moss-black">{children}</h2>,
            paragraph: ({children}) => <p className="text-h3 text-moss-black">{children}</p>,
          }}
        />

        <PrismicRichText
          field={slice.primary.description}
          components={{
            paragraph: ({children}) => <p className="text-body leading-[1.4rem] text-grey-550">{children}</p>,
          }}
        />

        <PrismicNextImage
          field={slice.primary.image}
          priority
          quality={90}
          sizes="(max-width: 640px) calc(100vw - 48px), (max-width: 768px) calc(100vw - 80px), (max-width: 1440px) 57vw, 815px"
          className="py-2"
        />

        <div className="flex flex-col gap-2">
          <PrismicRichText
            field={slice.primary.asset_description}
            components={{
              paragraph: ({children}) => <p className="text-body text-grey-550">{children}</p>,
              list: ({children}) => <ul className="list-disc pl-4">{children}</ul>,
              listItem: ({children}) => <li className="text-body text-grey-550">{children}</li>,
              heading6: ({children}) => <h4 className="text-body-bold text-moss-black">{children}</h4>,
            }}
          />
        </div>
      </section>
      <a
        className={cn(
          'fixed bottom-10 right-[50%] z-10 flex translate-x-2/4 items-center justify-center rounded-lg bg-ffffff px-4 py-2.5 text-14 text-595755 drop-shadow-lg md:hidden',
          {hidden: hideFloatingButton},
        )}
        href={slice.primary.form_id!}
      >
        <button>{slice.primary.floating_button_text}</button>
      </a>
    </>
  )
}

export default GatedAsset
