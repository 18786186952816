import {KeyTextField} from '@prismicio/client'
import cn from 'classnames'
import {FieldValues, UseFormRegister} from 'react-hook-form'

export const TextInput = ({
  errors,
  field,
  register,
  required = true,
}: {
  errors:
    | {
        fieldName: string
      }[]
    | null
  field: {
    name?: KeyTextField
    type?: KeyTextField
    placeholder?: KeyTextField
  }
  register: UseFormRegister<FieldValues>
  required?: boolean
}) => {
  return (
    <input
      className={cn(
        `
          col-span-1
          max-h-10
          w-full
          rounded-lg
          border
          border-grey-150
          px-3
          py-[18px]
          text-16
          font-normal
          leading-5
          tracking-[.16px]
          placeholder:text-grey-300
          hover:bg-grey-110
          focus:outline-none
          md:max-h-14
          `,
        {
          'outline-1 outline-[red] outline -outline-offset-1': errors?.some((error) => {
            return error.fieldName === field.name
          }),
        },
      )}
      type={field.type || 'text'}
      placeholder={field.placeholder!}
      {...register(field.name!, {
        required,
      })}
    />
  )
}
