import {Button} from 'src/common/Button'
import {isFilled} from '@prismicio/client'
import cn from 'classnames'
import {useLinkResolver} from 'prismicio'
import {Source} from 'src/common/SourceSet'
import {ButtonDocument, HeroV2SliceDefaultItem, HeroV2SliceDefaultPrimary} from 'prismicio-types'
import Image from 'next/image'
import {Container} from 'src/common/Container'
import {sanitizeHtml} from 'src/common/helpers/sanitizeHtml'
import {RatingsSection} from './RatingsSection'
import {getButtonsData} from 'src/common/helpers'

type DefaultHeroV2Props = {
  slice: {
    primary: HeroV2SliceDefaultPrimary
    items: HeroV2SliceDefaultItem[]
    slice_type: string
    variation: string
  }
  pageUid: string
  componentIndex: number
  buttonsData?: ButtonDocument[]
}

export const DefaultHeroV2 = ({slice, pageUid, componentIndex, buttonsData}: DefaultHeroV2Props) => {
  const {linkResolver} = useLinkResolver()
  const sanitizedHeading = sanitizeHtml(slice.primary.heading!)

  const {primaryButtonData, secondaryButtonData} = getButtonsData({
    buttonsData,
    primaryButton: slice.primary.primary_button,
    secondaryButton: slice.primary.secondary_button,
  })

  return (
    <Container
      backgroundColor="Beige/200"
      className="flex flex-col gap-12 pb-0 pt-12 md:gap-[60px] md:pb-0 "
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div className="mx-auto grid w-full max-w-[1200px] scroll-mt-16 grid-cols-4 gap-4 gap-y-12 overflow-hidden px-5 md:grid-cols-12 md:gap-x-6 md:gap-y-[60px] md:pt-12 xl:px-0">
        <div
          className={cn('col-span-4 flex flex-col gap-6 md:col-start-2 md:col-end-12', {
            'lg:col-start-4 lg:col-end-10': !slice.primary.extended_text_width,
            'lg:col-start-3 lg:col-end-11': slice.primary.extended_text_width,
          })}
          data-aos="fade-up"
          data-aos-offset="0"
        >
          <div className="col-span-4 grid grid-cols-4 gap-4 gap-y-3 text-center md:grid-cols-6 md:gap-x-6">
            <h1
              className="col-span-full text-40 font-medium leading-[48px] text-moss-black md:text-[56px] md:leading-[64px]"
              dangerouslySetInnerHTML={{__html: sanitizedHeading}}
            />
            <p className="col-span-full text-16 leading-6 tracking-[.16px] text-grey-550 md:col-start-2 md:col-end-6">
              {slice.primary.subtext}
            </p>
          </div>
          <div className="mx-auto mb-6 flex flex-wrap gap-2">
            {(isFilled.keyText(slice.primary.primary_button_label) || primaryButtonData) && (
              <Button
                variant="fillV2"
                href={linkResolver(primaryButtonData?.link || slice.primary.primary_button_url, 'primary-button-hero')}
                data-mktg-id={primaryButtonData?.marketing_id || slice.primary.primary_button_mktg_id || ''}
                data-page-location={`component-${componentIndex}-hero`}
                data-type={primaryButtonData?.intent || 'primary-button'}
                data-page-name={pageUid}
                data-label={primaryButtonData?.label || slice.primary.primary_button_label}
                id={`component-${componentIndex}-hero-1`}
              >
                {primaryButtonData?.label || slice.primary.primary_button_label}
              </Button>
            )}
            {(isFilled.keyText(slice.primary.secondary_button_label) || secondaryButtonData) && (
              <Button
                href={linkResolver(
                  secondaryButtonData?.link || slice.primary.secondary_button_url,
                  'secondary-button-hero',
                )}
                variant="outlineV2"
                data-mktg-id={secondaryButtonData?.marketing_id || slice.primary.secondary_button_mktg_id || ''}
                data-page-location={`component-${componentIndex}-hero`}
                data-type={secondaryButtonData?.intent || 'hero-button'}
                data-page-name={pageUid}
                data-label={secondaryButtonData?.label || slice.primary.secondary_button_label}
                id={`component-${componentIndex}-hero-2`}
              >
                {secondaryButtonData?.label || slice.primary.secondary_button_label}
              </Button>
            )}
          </div>
          <div
            className={cn('flex justify-center gap-3', {
              '-order-1': slice.primary.ratings_on_top,
            })}
          >
            <RatingsSection ratings={slice.items} type={slice.primary.ratings_type} />
          </div>
        </div>
      </div>
      <div
        className="relative left-[20px] max-h-[480px] w-full md:left-0 md:overflow-hidden"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        {isFilled.image(slice.primary.background_image_desktop) &&
          isFilled.image(slice.primary.background_image_mobile) && (
            <picture>
              <Source src={slice.primary.background_image_mobile.url || ''} media="(max-width: 680px)" />
              <Image
                className="mx-auto"
                src={slice.primary.background_image_desktop.url!}
                alt=""
                width={1200}
                height={500}
                priority
              />
            </picture>
          )}
      </div>
    </Container>
  )
}
