import cn from 'classnames'
import {CSSProperties, memo} from 'react'
import styles from './icon.module.css'

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- This rule is disabled to keep the `iconNames` array in the codebase.
const iconNames = [
  'arrow-right',
  'avatars',
  'calendar',
  'check',
  'check-mark',
  'facebook-icon',
  'fill-check',
  'linkedin-icon',
  'logo',
  'plus',
  'map',
  'minus',
  'pagination-arrow',
  'small-logo',
  'tick',
  'twitter-icon',
  'right-arrow',
  'chevron',
  'G2',
  'Capterra',
  'check-mark-v2',
  'info',
  'info-hover',
  'search',
  'cross',
  'moss-logo',
  'card',
  'wallet',
  'invoice',
  'insights',
  'accounting',
  'request',
  'org-chart',
  'filled-check-v2',
  'play',
  'pause',
  'sound-active',
  'sound-muted',
  'zoom-in',
  'zoom-out',
  'play-circle',
  'replay',
  'outline-check-v2',
  'quotation-mark',
  'map-pin',
  'shopping-cart',
  'monitor',
  'company',
  'dinner',
  'filled-cross',
  'plus-bold',
  'lock',
  'cross-bold',
] as const

export type IconType = (typeof iconNames)[number]

export const Icon = memo(function Icon(
  props: {
    className?: string
    label?: string
    noMask?: boolean
    testId?: string
  } & (
    | {
        name: IconType
        path?: never
      }
    | {
        name?: never
        path: string
      }
  ),
) {
  return (
    <span
      {...((props.label || props.name) && {'aria-label': props.label || props.name})}
      className={cn(
        styles.icon,
        'pointer-events-none inline-block',

        {
          'bg-current': !props.noMask,
          'no-mask': props.noMask,
        },

        props.className,
      )}
      {...(props.testId && {'data-testid': props.testId})}
      role="img"
      style={
        {
          '--image': `url("${props.path ?? `/icons/${props.name}.svg`}")`,
          '--webkit-transform': 'translate3d(0,0,0)',
        } as CSSProperties
      }
    />
  )
})
