import {LinkField, isFilled} from '@prismicio/client'
import {PrismicNextLink} from '@prismicio/next'
import {useLinkResolver} from 'prismicio'
import {ReactNode, memo} from 'react'
import {EVENTS, TrackingProps, track} from 'src/common/helpers'

type LinkWrapperProps = {
  link: LinkField
  children: ReactNode
  className?: string
} & Omit<TrackingProps, 'event'> & {
    onMouseEnter?: () => void
    onMouseLeave?: () => void
  }

export const LinkWrapper = memo(
  ({link, children, className, mktgId, location, pageName, dataType, onMouseEnter, onMouseLeave}: LinkWrapperProps) => {
    const {linkResolver} = useLinkResolver()

    if (!isFilled.link(link)) {
      return children
    }

    return (
      <PrismicNextLink
        href={linkResolver(link)}
        className={className ?? ''}
        onClick={() =>
          track({
            event: EVENTS.CLICK,
            mktgId,
            location,
            pageName,
            dataType,
          })
        }
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </PrismicNextLink>
    )
  },
)
