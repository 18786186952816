import {isFilled} from '@prismicio/client'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {memo} from 'react'
import {Icon} from 'src/common/Icon'
import {Context} from 'src/common/types'

export const Feature = memo(function Feature(props: {
  feature: Context['features'][number]
  replacementText?: string
  isApplied: boolean
}) {
  const {linkResolver} = useLinkResolver()
  return (
    <li
      className={cn('group relative flex items-center gap-x-1.5 text-14 leading-5', {
        'cursor-pointer': isFilled.richText(props.feature.tooltip),
      })}
    >
      <Icon
        className={cn('h-4 w-4', {'text-b8b6b4': !props.isApplied}, {'text-6c9d8b': props.isApplied})}
        name={props.isApplied ? 'check' : 'minus'}
      />
      <span
        className={cn(
          'leading-[19px]',
          {'text-b8b6b4 line-through': !props.isApplied},
          {'border-b border-dashed border-b8b6b4 font-medium text-2a2928': props.isApplied},
        )}
      >
        {props.replacementText || props.feature.name}
      </span>

      {isFilled.richText(props.feature.tooltip) && (
        <div className="pointer-events-none invisible absolute left-10 top-0 z-10 grid w-full rounded-lg bg-ffffff px-4 py-3 text-14 leading-5 text-595755 opacity-0 shadow-tooltip group-hover:pointer-events-auto group-hover:visible group-hover:opacity-100">
          <PrismicRichText
            components={{
              paragraph: ({children}) => <p className="">{children}</p>,
              hyperlink: ({children, node}) => (
                <Link
                  className="mt-8 block text-right text-14 font-medium leading-5 text-4c6ee5"
                  href={linkResolver(node.data)}
                  {...(node.data.link_type === 'Web' && node.data.target && {target: node.data.target})}
                >
                  {children}
                </Link>
              ),
              list: ({children}) => <ul className="grid gap-y-5">{children}</ul>,
              listItem: ({children}) => (
                <li className="flex items-start gap-x-2">
                  <Icon className="mt-1.5 h-4 w-4 shrink-0 text-6c9d8b" name="check" />
                  <span>{children}</span>
                </li>
              ),
            }}
            field={props.feature.tooltip}
          />
        </div>
      )}
    </li>
  )
})
