import {Content, isFilled} from '@prismicio/client'
import NextLink from 'next/link'
import {useLinkResolver} from 'prismicio'
import {Simplify} from 'prismicio-types'

type ConditionalLinkProps = {
  item: Simplify<Content.SmallBadgesSliceDefaultItem>
  children: React.ReactNode
}

export const ConditionalLink = ({item, children}: ConditionalLinkProps) => {
  const isLink = isFilled.link(item.badge_link)
  const {linkResolver} = useLinkResolver()

  return isLink ? (
    <NextLink
      aria-label={item.badge.alt || undefined}
      href={linkResolver(item.badge_link)}
      {
        // @ts-ignore isLink validates the existance of badge_link
        ...(isLink && item.badge_link.link_type === 'Web' && {target: item.badge_link.target})
      }
    >
      {children}
    </NextLink>
  ) : (
    <div>{children}</div>
  )
}
