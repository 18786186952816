import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import cn from 'classnames'
import NextLink from 'next/link'
import {useLinkResolver} from 'prismicio'
import {TabsSlice} from 'prismicio-types'
import {memo, useCallback, useEffect, useState} from 'react'
import {Tab} from './Tab'

const Tabs = (props: {slice: TabsSlice}) => {
  const {linkResolver} = useLinkResolver()
  const [activeTab, setActiveTab] = useState<string | null>(props.slice.items[0].tab_name)

  const changeTab = useCallback((tabName: string) => {
    setActiveTab(tabName)
  }, [])

  // Update activeTab on locale change
  useEffect(() => {
    setActiveTab(props.slice.items[0].tab_name)
  }, [props.slice.items])

  const isDefaultVariation = props.slice.variation === 'default'
  const isImageOnlyVariation = props.slice.variation === 'imageOnly'
  const isCardNarrow = props.slice.variation === 'default' && props.slice.primary.is_card_narrow

  return (
    <section
      className={cn('grid scroll-mt-16 grid-cols-main', {
        'py-12 sm:py-10 md:py-20': isDefaultVariation,
        'pt-12 md:pt-20': isImageOnlyVariation,
      })}
      {...(isFilled.color(props.slice.primary.background_color) && {
        style: {backgroundColor: props.slice.primary.background_color},
      })}
      id={String(props.slice.primary.id_anchor)}
    >
      <PrismicRichText
        components={{
          heading2: ({children}) => (
            <h2
              className={cn(
                'col-[inner] -tracking-common [&_>_br]:max-md:content-[""] [&_>_br]:max-md:after:content-["_"]',
                {
                  'text-28 leading-8 sm:text-center': isDefaultVariation && isCardNarrow,
                  'text-center text-28 leading-8 md:text-32 md:leading-10 lg:text-36 lg:leading-11':
                    isDefaultVariation && !isCardNarrow,
                  'text-center text-32 leading-10 lg:text-36 lg:leading-11': isImageOnlyVariation,
                },
              )}
            >
              {children}
            </h2>
          ),
          strong: ({children}) => <strong className="tracking-normal">{children}</strong>,
        }}
        field={props.slice.primary.title}
      />

      <div
        className={cn(
          'col-[outer] flex gap-y-2 overflow-x-auto whitespace-nowrap px-6 pb-5 md:col-[inner] md:flex-wrap md:justify-center md:px-0 md:pb-0',
          {
            'mt-12 sm:mt-16 md:mt-14 xl:mt-16': isDefaultVariation,
            'mt-8 md:mt-14': isImageOnlyVariation,
          },
        )}
      >
        {props.slice.items.map(
          (item, index) =>
            isFilled.keyText(item.tab_name) && (
              <Tab
                isImageOnlyVariation={isImageOnlyVariation}
                key={index}
                tabName={item.tab_name}
                activeTab={activeTab}
                onClick={changeTab}
              />
            ),
        )}
      </div>

      {isImageOnlyVariation && (
        <div className="col-[outer] mt-6 grid sm:col-[inner] sm:mt-8 md:mt-14 xl:col-start-[track-start_2] xl:col-end-[track-end_11] xl:mt-10">
          {props.slice.items.map((item, index) => (
            <div
              className={cn('transition-[opacity,visibility] span-full', {
                'invisible opacity-0': activeTab !== item.tab_name,
                'opacity-100': activeTab === item.tab_name,
              })}
              key={index}
            >
              <PrismicNextImage field={item.image} />
            </div>
          ))}
        </div>
      )}

      {props.slice.variation === 'default' && (
        <div
          className={cn('col-[inner] mt-6 grid items-start sm:mt-10', {
            'xl:col-start-[track-start_2] xl:col-end-[track-end_11]': isCardNarrow,
          })}
        >
          {props.slice.items.map((item, index) => (
            <div
              key={index}
              className={cn(
                'grid gap-x-6 rounded-xl bg-ffffff transition-[opacity,visibility] span-full md:grid-cols-12',
                {
                  'pointer-events-none invisible opacity-0': activeTab !== item.tab_name,
                  'opacity-100': activeTab === item.tab_name,
                  'xl:grid-cols-10': isCardNarrow,
                },
              )}
            >
              <div
                className={cn(
                  'relative overflow-hidden rounded-t-xl md:col-span-4 md:rounded-l-xl md:rounded-r-none',
                  'aspect-[327/132] md:aspect-auto',
                  {'xl:col-span-3': isCardNarrow},
                )}
              >
                <PrismicNextImage className="object-cover object-top" field={item.image} fill />
              </div>

              <div
                className={cn('mt-6 min-h-0 px-4 pb-9 md:col-span-8 md:mt-0 md:py-10 md:pl-6 md:pr-12', {
                  'xl:col-span-7': isCardNarrow,
                })}
              >
                <PrismicRichText
                  components={{
                    heading3: ({children}) => (
                      <h3 className="text-28 leading-8 -tracking-common text-1e2a25 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
                        {children}
                      </h3>
                    ),
                  }}
                  field={item.title}
                />

                <PrismicRichText
                  components={{
                    paragraph: ({children}) => <p className="mt-6 leading-6 text-72716e md:mt-8">{children}</p>,
                  }}
                  field={item.description}
                />

                {isFilled.link(item.link) && (
                  <NextLink
                    className="mt-8 inline-block font-medium leading-6 text-4e7e6c"
                    href={linkResolver(item.link)}
                    {...(isFilled.link(item.link) && item.link.link_type === 'Web' && {target: item.link.target})}
                  >
                    {item.link_field}
                  </NextLink>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  )
}

export default memo(Tabs)
