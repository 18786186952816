import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {useLinkResolver} from 'prismicio'
import {Button} from 'src/common/Button'
import {Icon} from 'src/common/Icon'
import Link from 'next/link'
import {getButtonsData} from 'src/common/helpers'
import {Context} from 'src/common/types'
import {BASE_URL} from 'src/common/constants'

/**
 * Props for `StaticHeader`.
 */
export type StaticHeaderProps = SliceComponentProps<Content.StaticHeaderSlice>

/**
 * Component for "StaticHeader" Slices.
 */
const StaticHeader = ({
  slice: {primary},
  context: {pageUid, buttonsData},
}: StaticHeaderProps & {context: Context}): JSX.Element => {
  const {linkResolver} = useLinkResolver()

  const {primaryButtonData} = getButtonsData({
    buttonsData,
    primaryButton: primary.primary_button,
  })

  const buttonLink = primaryButtonData
    ? linkResolver(primaryButtonData.link, 'primary-button-header')
    : linkResolver(primary.primary_button_link, 'primary-button-header')

  return (
    <>
      <header className="fixed top-0 z-20 w-full border-b-[1px] border-grey-135 bg-grey-110/[90%] text-moss-black backdrop-blur-[10px] transition-all duration-100">
        <div className="mx-auto flex h-16 max-w-[1200px] items-center justify-between px-5 xl:px-0">
          {primary.enable_moss_logo_link ? (
            <Link className="flex" href={linkResolver({link_type: 'Web', url: BASE_URL})}>
              <Icon className="h-6 w-[101px]" name="logo" />
            </Link>
          ) : (
            <Icon className="h-6 w-[101px]" name="logo" />
          )}
          {(primaryButtonData || primary.primary_button_label) && (
            <Button
              className="whitespace-nowrap"
              href={buttonLink}
              data-page-location="nav-bar"
              variant="fillV2"
              data-mktg-id={primaryButtonData?.marketing_id || primary.primary_button_mktg_id || ''}
              data-page-name={pageUid}
              data-type={primaryButtonData?.intent || 'primary-button'}
              width={primaryButtonData?.width}
            >
              {primaryButtonData?.label || primary.primary_button_label}
            </Button>
          )}
        </div>
      </header>
      <div className="h-16 w-full bg-grey-110" />
    </>
  )
}

export default StaticHeader
