import {Content, isFilled} from '@prismicio/client'
import {PrismicRichText, SliceComponentProps} from '@prismicio/react'
import {useLinkResolver} from 'prismicio'
import {Button} from 'src/common/Button'
import {Container} from 'src/common/Container'
import {Icon} from 'src/common/Icon'
import {Tag} from 'src/common/Tag'
import {LinkArrow} from 'src/common/TextLink'

/**
 * Props for `FeatureDisplay`.
 */
export type FeatureDisplayProps = SliceComponentProps<Content.FeatureDisplaySlice> & {
  context: {
    pageUid: string
  }
}

/**
 * Component for "FeatureDisplay" Slices.
 */
const FeatureDisplay = ({slice, index: componentIndex, context: {pageUid}}: FeatureDisplayProps): JSX.Element => {
  const {linkResolver} = useLinkResolver()
  return (
    <Container
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      backgroundColor={slice.primary.background_color}
    >
      <div className="grid-base max-w-[1200px] px-5 lg:px-0">
        <div className="col-span-full grid grid-cols-subgrid gap-y-5 rounded-md border border-grey-135 bg-moss-white p-5 md:p-[30px]">
          <div className="col-span-full flex items-center gap-2">
            <p className="text-body-bold-l text-moss-black">{slice.primary.title}</p>
            {isFilled.keyText(slice.primary.tag) && (
              <Tag color={slice.primary.tag_color || 'green'} label={slice.primary.tag} variant="xsmall" />
            )}
          </div>
          <PrismicRichText
            field={slice.primary.description}
            components={{
              paragraph: ({children}) => (
                <p className="text-body-s col-span-full text-moss-black lg:col-span-6">{children}</p>
              ),
              list: ({children}) => (
                <ul className="text-body-s col-span-full flex flex-col gap-2 text-moss-black lg:flex-row lg:flex-wrap lg:gap-3">
                  {children}
                </ul>
              ),
              listItem: ({children}) => (
                <li className="flex items-center gap-2 align-middle md:w-fit">
                  <Icon name="filled-check-v2" className="max-h-5 min-h-5 min-w-5 max-w-5 text-grey-450" />
                  {children}
                </li>
              ),
            }}
          />
          {isFilled.keyText(slice.primary.primary_button_label) && (
            <Button
              variant="fillV2"
              className="group/textlink col-span-full mt-3 w-fit gap-2"
              href={linkResolver(slice.primary.primary_button_link, 'primary-button-feature-display')}
              data-mktg-id={slice.primary.primary_button_mktg_id || ''}
              data-page-location={`component-${componentIndex}-feature-display`}
              data-page-name={pageUid}
              data-type="feature-display-button"
              id={`component-${componentIndex}-feature-display-1`}
            >
              <span>
                {slice.primary.primary_button_label}
                <LinkArrow />
              </span>
            </Button>
          )}
        </div>
      </div>
    </Container>
  )
}

export default FeatureDisplay
