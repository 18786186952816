import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {LinksColumnSliceSpotlightColumn} from 'prismicio-types'
import {TextLink} from 'src/common/TextLink'

type SpotlightVariationProps = {
  slice: LinksColumnSliceSpotlightColumn
  componentIndex: number
  context: {shouldHideTitle?: boolean; pageUid: string}
}

export const SpotlightVariation = ({slice, componentIndex, context: {pageUid}}: SpotlightVariationProps) => (
  <div
    className="col-span-2 flex w-full flex-col gap-4 pt-4 text-moss-black md:col-span-4 lg:gap-6 lg:p-0 lg:group-hover:opacity-100"
    data-slice-variation={slice.variation}
  >
    {isFilled.keyText(slice.primary.column_title) && (
      <span className="text-body-bold lg:text-body-bold-l w-full">{slice.primary.column_title}</span>
    )}
    <div className="flex w-full flex-col gap-4">
      <PrismicNextImage
        field={slice.primary.image}
        className="max-h-[176px] w-full rounded-lg lg:max-h-[144px]"
        loading="eager"
      />
      <div className="flex flex-col gap-2">
        <span className="text-body-bold-xs lg:text-body-bold">{slice.primary.title}</span>
        <span className="text-body-xs lg:text-body-s">{slice.primary.description}</span>
        <TextLink
          field={slice.primary.link_url}
          label={slice.primary.link_label!}
          variation="x-small"
          pageName={pageUid}
          mktgId={slice.primary.marketing_id || ''}
          dataType={`order-${componentIndex}`}
          location="main-nav-drop-down"
        />
      </div>
    </div>
  </div>
)
