import {Content, isFilled} from '@prismicio/client'
import {SliceComponentProps, SliceZone} from '@prismicio/react'
import {ReactNode, memo, useEffect, useMemo, useRef, useState} from 'react'
import {useForm, FormProvider} from 'react-hook-form'
import {Container} from 'src/common/Container'
import {Context} from 'src/common/types'
import {createInitialFormState, mapFormDataSections} from './helpers'
import {FormSummary} from './FormSummary'
import {components} from 'slices'
import {useMeasure} from 'react-use'
import cn from 'classnames'
import {useScroll} from 'framer-motion'
import {Tag} from 'src/common/Tag'
import {useGetPricingFormHandler} from 'src/common/hooks/useGetPricingFormHandler/useGetPricingFormHandler'

/**
 * Props for `FormSection`.
 */
export type FormSectionProps = SliceComponentProps<Content.FormSectionSlice> & {context: Context}

/**
 * Component for "FormSection" Slices.
 */
const FormSection = ({slice, context: {form_data, pageUid}, index: componentIndex}: FormSectionProps): JSX.Element => {
  const sections = useMemo(() => mapFormDataSections(form_data?.data), [form_data?.data])
  const successUrl = isFilled.link(slice.primary.success_url) ? slice.primary.success_url.url : ''
  const {pricingFormHandler} = useGetPricingFormHandler({successUrl})
  const formRef = useRef<HTMLFormElement>(null)
  const initialFormState = createInitialFormState(sections)
  const methods = useForm({defaultValues: initialFormState})
  const [shouldFixSummary, setShouldFixSummary] = useState(false)
  const summaryPositionRef = useRef<HTMLDivElement>(null)
  const {handleSubmit} = methods
  const onSubmit = handleSubmit(pricingFormHandler)
  const [summaryRef, {height: summaryRefHeight}] = useMeasure<HTMLDivElement>()
  const [shouldExpand, setShouldExpand] = useState(false)

  const {scrollYProgress} = useScroll({
    target: formRef,
    offset: ['-.2 start', 'end .55'],
  })

  // Set fixed summary state
  useEffect(() => {
    const handleSummary = (latest: number) => {
      const fixedNumber = latest

      if ((fixedNumber < 0.06 && shouldFixSummary) || (fixedNumber > 0.96 && !shouldFixSummary)) {
        setShouldFixSummary(false)
      }

      if (fixedNumber > 0.06 && fixedNumber < 0.95 && !shouldFixSummary) {
        setShouldFixSummary(true)
      }
    }

    scrollYProgress.on('change', handleSummary)

    return () => {
      scrollYProgress.destroy()
    }
  }, [scrollYProgress, shouldFixSummary])

  let observableRef = useRef<IntersectionObserver | null>(null)

  // Create a correct observer according to summary height
  useEffect(() => {
    const intersectionRatio = Number((summaryRefHeight / summaryPositionRef.current?.clientHeight!).toFixed(2))

    if (
      (intersectionRatio > 0.5 && intersectionRatio < 0.65) ||
      (intersectionRatio > 0.75 && intersectionRatio < 0.99)
    ) {
      observableRef.current?.disconnect()
      observableRef.current = null

      observableRef.current = new IntersectionObserver(
        (entries) => {
          const summaryEntry = entries[0]

          if (summaryEntry.isIntersecting) {
            setShouldFixSummary(false)
            setShouldExpand(false)
          }
        },
        {
          threshold: intersectionRatio + 0.1,
        },
      )

      observableRef.current.observe(summaryPositionRef.current!)
    }

    return () => observableRef.current?.disconnect()
  }, [summaryRefHeight])

  return (
    <Container
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      backgroundColor="Moss White"
      className="md:min-h-[858px] md:overflow-visible lg:min-h-[798px]"
      id="form-section"
    >
      <FormProvider {...methods}>
        <div className="grid-base max-w-[1200px] bg-beige-100 px-5 md:grid md:grid-cols-10 md:gap-0 lg:px-0">
          <form
            className="col-span-4 grid h-min gap-y-6 bg-beige-100 pt-10 md:col-span-7 md:rounded-l-md md:px-[30px] md:py-8 md:pr-[60px] lg:gap-y-5"
            onSubmit={onSubmit}
            ref={formRef}
          >
            {isFilled.keyText(slice.primary.title) && (
              <div className="flex items-center justify-center gap-2.5 pb-1.5 lg:pb-2.5">
                <p className="text-h3 tracking-[-.32px] text-moss-black">{slice.primary.title}</p>
                {isFilled.keyText(slice.primary.tag) && <Tag color="green" label={slice.primary.tag} />}
              </div>
            )}
            {sections?.map((section, index) => {
              if (!section.slices) {
                return null
              }

              return (
                <fieldset className="col-span-full flex flex-col gap-2.5" key={index}>
                  <p className="text-body-xs uppercase text-grey-550">{section.title as ReactNode}</p>
                  <div
                    className={cn('flex flex-col gap-0 divide-y divide-grey-150', {
                      'lg:grid lg:grid-cols-12 lg:gap-0 lg:rounded-lg lg:divide-x lg:divide-y-0': section.isGrid,
                    })}
                  >
                    <SliceZone
                      slices={section.slices}
                      components={components}
                      context={{
                        parentFormType: section.type,
                        pageUid: pageUid,
                        isGrid: section.isGrid,
                        ...(section.type === 'Radio' && {fieldName: section.title}),
                        sectionLength: section.slices.length,
                      }}
                    />
                  </div>
                </fieldset>
              )
            })}
          </form>
          <div
            className={cn(
              'relative col-span-4 flex flex-col gap-6 rounded-xl bg-transparent md:col-span-3 md:h-full md:min-h-full',
              {
                'h-[475px]': shouldFixSummary,
                'h-min': !shouldFixSummary,
              },
            )}
            ref={summaryPositionRef}
          >
            <FormSummary
              sections={sections!}
              summaryTitle={form_data?.data.summary_title}
              tag={form_data?.data.summary_tag}
              bottomTitle={form_data?.data.summary_bottom_section_title}
              bottomText={form_data?.data.summary_bottom_text}
              submitLabel={form_data?.data.summary_submit_button}
              shouldBeFixed={shouldFixSummary}
              summaryRef={summaryRef}
              formRef={formRef}
              shouldExpand={shouldExpand}
              setShouldExpand={setShouldExpand}
              parentIndex={componentIndex}
              pageUid={pageUid}
              description={form_data?.data.summary_description}
            />
          </div>
        </div>
      </FormProvider>
    </Container>
  )
}

export default memo(FormSection)
