import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import {useLinkResolver} from 'prismicio'
import {HighlightsSlice} from 'prismicio-types'
import {memo} from 'react'
import {Button} from 'src/common/Button'
import {Link} from 'src/common/Link'

const Highlights = (props: {slice: HighlightsSlice}) => {
  const {linkResolver} = useLinkResolver()
  return (
    <section
      className="grid scroll-mt-16 grid-cols-main bg-ffffff py-14 md:py-20"
      id={String(props.slice.primary.id_anchor)}
    >
      <div className="col-[inner] grid gap-y-4">
        <PrismicRichText
          components={{
            heading1: ({children}) => (
              <h1 className="tg-h3 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">{children}</h1>
            ),
            heading2: ({children}) => (
              <h2 className="tg-h3 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">{children}</h2>
            ),
            heading3: ({children}) => (
              <h3 className="tg-h3 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">{children}</h3>
            ),
            heading4: ({children}) => (
              <h4 className="tg-h3 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">{children}</h4>
            ),
            heading5: ({children}) => (
              <h5 className="tg-h3 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">{children}</h5>
            ),
            heading6: ({children}) => (
              <h6 className="tg-h3 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">{children}</h6>
            ),
            paragraph: ({children}) => <p className="text-20 leading-6 text-72716e">{children}</p>,
          }}
          field={props.slice.primary.title}
        />
      </div>

      <div className="col-[inner] mt-24 grid gap-x-6 gap-y-8 sm:grid-cols-2 sm:gap-y-11 md:grid-cols-4 xl:mt-16">
        {props.slice.items.map((item, index) => (
          <div className="grid place-content-start" key={index}>
            <PrismicNextImage alt="" field={item.icon} />

            <div className="mt-4 text-18 font-medium leading-6 text-232f2a sm:text-20 xl:text-20">{item.title}</div>

            <PrismicRichText
              components={{
                paragraph: ({children}) => <p className="mt-2 text-16 leading-6 text-8f8d8b">{children}</p>,
                hyperlink: ({children, node}) => (
                  <Link
                    className="text-7290f3"
                    href={linkResolver(node.data)}
                    {...(node.data.link_type === 'Web' && node.data.target && {target: node.data.target})}
                  >
                    {children}
                  </Link>
                ),
              }}
              field={item.description}
            />
          </div>
        ))}
      </div>

      {(isFilled.link(props.slice.primary.link) || props.slice.primary.link_anchor) && (
        <Button
          className="col-[inner] mt-10 justify-self-start"
          href={
            props.slice.primary.link_anchor
              ? `#${props.slice.primary.link_anchor}`
              : linkResolver(props.slice.primary.link)
          }
          {...(props.slice.primary.link_anchor && {scroll: false})}
          {...(isFilled.link(props.slice.primary.link) &&
            props.slice.primary.link.link_type === 'Web' && {
              target: props.slice.primary.link.target,
            })}
          variant="fill"
        >
          {props.slice.primary.link_label}
        </Button>
      )}
    </section>
  )
}

export default memo(Highlights)
