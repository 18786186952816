import {PrismicRichText} from '@prismicio/react'
import {MagazineSlice} from 'prismicio-types'
import {Fragment, memo, useMemo} from 'react'
import {Context} from 'src/common/types'
import {Card} from './Card'

const Magazine = memo(function Magazine(props: {slice: MagazineSlice; context: Context}) {
  const featuredArticle = useMemo(
    () => props.context.articles.find((article) => article.data.is_featured),
    [props.context.articles],
  )

  return (
    <Fragment>
      {featuredArticle && (
        <section className="grid grid-cols-main">
          <Card article={featuredArticle} className="col-[inner]" isFeatured magazineUid={props.context.pageUid} />
        </section>
      )}

      {((featuredArticle && props.context.articles.length > 1) ||
        (!featuredArticle && props.context.articles.length > 0)) && (
        <section className="mt-10 grid grid-cols-main gap-y-10 pb-20 sm:pb-16 md:pb-24 lg:mt-20 lg:pb-40">
          <PrismicRichText
            components={{
              heading2: ({children}) => (
                <h2 className="col-[inner] text-28 leading-8 -tracking-common text-1e2a25">{children}</h2>
              ),
            }}
            field={props.slice.primary.latest_articles_title}
          />

          <div className="col-[inner] grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {props.context.articles
              .filter((article) => article.id !== featuredArticle?.id)
              .map((article, index) => (
                <Card article={article} key={index} magazineUid={props.context.pageUid} />
              ))}
          </div>
        </section>
      )}
    </Fragment>
  )
})

export default Magazine
