import {isFilled} from '@prismicio/client'
import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import {PricingSlice} from 'prismicio-types'
import {memo} from 'react'
import {ActionButton} from 'src/common/ActionButton'
import {Context} from 'src/common/types'
import {FeaturesList} from './FeaturesList'

const Pricing = memo(function Pricing(props: {context: Context; slice: PricingSlice}) {
  return (
    <div className="grid scroll-mt-16 grid-cols-main pb-12 pt-30" id={String(props.slice.primary.id_anchor)}>
      <PrismicRichText
        components={{
          heading1: ({children}) => (
            <h1 className="col-[inner] mb-7 max-w-common justify-self-center text-center text-36 font-bold leading-none -tracking-common text-2a2928 sm:text-48 md:text-64 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']">
              {children}
            </h1>
          ),
        }}
        field={props.slice.primary.title}
      />

      <PrismicRichText
        components={{
          paragraph: ({children}) => (
            <p className="col-[inner] max-w-common justify-self-center text-center text-16 leading-6 text-2a2928 sm:text-18">
              {children}
            </p>
          ),
        }}
        field={props.slice.primary.description}
      />

      <div className="col-[entire] snap-x scroll-pl-6 overflow-x-auto sm:scroll-pl-10 lg:contents">
        <div className="my-14 grid w-[calc(300vw_-_theme(spacing.12))] grid-cols-3 gap-x-6 px-6 sm:w-[calc(200vw_-_theme(spacing.20))] sm:px-10 md:w-[calc(160vw_-_theme(spacing.20))] lg:col-[inner] lg:w-auto lg:px-0">
          {props.slice.items.map((card, index) => (
            <div
              className="relative grid snap-start content-between rounded-2xl bg-ffffff px-6 pb-6 pt-8 shadow-lp"
              key={index}
            >
              {(card.tag || isFilled.image(card.tag_image)) && (
                <div className="absolute left-6 top-0 flex h-11 -translate-y-1/2 items-center gap-x-2 rounded-full bg-2a2928 pl-3.5 pr-4 text-ffffff">
                  <PrismicNextImage alt="" className="shrink-0" field={card.tag_image} />
                  <span className="text-14 font-medium">{card.tag}</span>
                </div>
              )}

              <div>
                <h2 className="text-32 font-bold leading-10 text-2a2928 md:text-36 md:leading-11 lg:text-32 lg:leading-10">
                  {card.title}
                </h2>

                <PrismicRichText
                  components={{
                    paragraph: ({children}) => <p className="mt-5 text-14 leading-5 text-212121">{children}</p>,
                  }}
                  field={card.description}
                />
              </div>

              <div>
                <FeaturesList appliedFeatures={card.top_features} features={props.context.top_features} />

                <ActionButton
                  className="mt-3 text-center"
                  isOutline={!card.link_variant}
                  kind="demo"
                  data-page-location={['starter-price', 'prof-price', 'enterprise-price'][index]}
                >
                  {card.link_label}
                </ActionButton>

                <FeaturesList
                  appliedFeatures={card.features}
                  features={props.context.features}
                  title={props.context.features_label}
                />

                <FeaturesList
                  appliedFeatures={card.other_features}
                  features={props.context.other_features}
                  title={props.context.other_features_label}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
})

export default Pricing
