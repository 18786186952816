import {useEffect, useMemo, useState} from 'react'
import {MossUIField, MossUIMenuItem, MossUISelect, MossUIMenuItemLabel} from './MossUiComponents'
import {Control, Controller, FieldValues} from 'react-hook-form'
import styles from './signupSection.module.css'

export const Dropdown = ({
  control,
  name,
  placeholder,
}: {
  control: Control<FieldValues>
  name: string
  placeholder?: string
}) => {
  const [selectItemWidth, setSelectItemWidth] = useState(0)

  const options = useMemo(() => {
    return DROPDOWN_OPTIONS[name as keyof typeof DROPDOWN_OPTIONS] || []
  }, [name])

  // Get min width for menu items
  useEffect(() => {
    setSelectItemWidth(document.getElementById('dropdown-container')?.offsetWidth || 0)
  }, [])

  return (
    <div className={styles.signUpDropdown} id="dropdown-container">
      <MossUIField>
        <Controller
          control={control}
          name={name}
          rules={{required: true}}
          render={({field}) => (
            <MossUISelect selectedItem={field?.value?.label} placement="bottom-start" selectPlaceholder={placeholder}>
              {options.map((role: {label: string; value: string}, index: number) => (
                <MossUIMenuItem
                  minWidth={`${selectItemWidth}px`}
                  key={index}
                  selected={role.value === field?.value?.value}
                  onClick={() => {
                    field.onChange(role)
                  }}
                >
                  <MossUIMenuItemLabel textWrap>{role.label}</MossUIMenuItemLabel>
                </MossUIMenuItem>
              ))}
            </MossUISelect>
          )}
        />
      </MossUIField>
    </div>
  )
}

const ROLES = [
  {label: 'CEO/Founder', value: 'CEO/Founder'},
  {label: 'CFO', value: 'CFO'},
  {label: 'C-Level/Director', value: 'C-Level/Director'},
  {label: 'Finance Manager/Controller', value: 'Finance Manager/Controller'},
  {label: 'Bookkeeper/Accountant', value: 'Bookkeeper/Accountant'},
  {label: 'FP&A', value: 'FP&A'},
  {label: 'COO/Operations', value: 'COO/Operations'},
  {label: 'Office Manager', value: 'Office Manager'},
  {label: 'Working Student/Intern', value: 'Working Student/Intern'},
  {label: 'Other', value: 'Other'},
]

const COMPANY_SIZES = [
  {label: 'self-employed/freelancer', value: 'self-employed/freelancer'},
  {label: '1-14', value: '1-14'},
  {label: '15-29', value: '15-29'},
  {label: '30-100', value: '30-100'},
  {label: '101-250', value: '101-250'},
  {label: '250+', value: '250+'},
]

const DROPDOWN_OPTIONS = {
  hs_persona: ROLES,
  '0-2/numberofemployees': COMPANY_SIZES,
}
