import {Button} from 'src/common/Button'
import {Controller, useForm} from 'react-hook-form'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {PrivacyCheckbox} from 'slices/AiCampaign/components/SignupSection/PrivacyCheckbox'

import {KeyTextField, RichTextField} from '@prismicio/client'
import {MossUITextInput, MossUIField, MossUIFieldLabel, MossUIPhoneInput} from 'src/common/MossUIComponents'
import {marketingConsentUrl, UTM_PARAMS_KEY} from 'src/common/constants'
import {useCookie} from 'react-use'
import {useSearchParams} from 'next/navigation'
import {
  BookDemoSectionSliceDefaultPrimaryCompanyEmailFieldItem,
  BookDemoSectionSliceDefaultPrimaryCompanyNameFieldItem,
  BookDemoSectionSliceDefaultPrimaryFirstNameFieldItem,
  BookDemoSectionSliceDefaultPrimaryLastNameFieldItem,
  BookDemoSectionSliceDefaultPrimaryPhoneFieldItem,
} from 'prismicio-types'
import {useMossApi} from 'src/common/hooks/useMossApi'

export type PersonalDetailsValues = {
  firstName: string
  lastName: string
  companyName: string
  email?: string
  phone: string
  privacyPolicy: boolean
}

const personalDetailsDefaultValues = {
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  phone: '',
  privacyPolicy: false,
}

export const UserDetails = ({
  formTitle,
  firstNameField,
  lastNameField,
  companyEmailField,
  companyNameField,
  phoneField,
  privacyPolicy,
  continueButtonLabel,
  onContinue,
  consentUrl,
  consentId,
}: {
  formTitle: KeyTextField
  firstNameField: BookDemoSectionSliceDefaultPrimaryFirstNameFieldItem
  lastNameField: BookDemoSectionSliceDefaultPrimaryLastNameFieldItem
  companyNameField: BookDemoSectionSliceDefaultPrimaryCompanyNameFieldItem
  companyEmailField: BookDemoSectionSliceDefaultPrimaryCompanyEmailFieldItem
  phoneField: BookDemoSectionSliceDefaultPrimaryPhoneFieldItem
  privacyPolicy: RichTextField
  continueButtonLabel: KeyTextField
  onContinue: (data: PersonalDetailsValues, id: string) => void
  consentUrl: string
  consentId: string
}) => {
  const {handleSubmit, control} = useForm<PersonalDetailsValues>({
    defaultValues: personalDetailsDefaultValues,
    mode: 'onSubmit',
    shouldFocusError: true,
  })

  const [deviceId] = useCookie('deviceId')
  const [utmParams] = useCookie(UTM_PARAMS_KEY)
  const searchParams = useSearchParams()
  const {mossApi} = useMossApi()

  const {executeRecaptcha} = useGoogleReCaptcha()

  const onSubmit = async (data: PersonalDetailsValues) => {
    if (!executeRecaptcha) {
      return
    }

    try {
      const token = await executeRecaptcha()

      if (!token) {
        return
      }

      const metadata = {
        ...parseParamsFromCookies(utmParams),
        ...Object.fromEntries(searchParams),
      }

      const payload = {
        firstName: data.firstName,
        lastName: data.lastName,
        companyName: data.companyName,
        email: data.email,
        phone: data.phone,
        privacyPolicyAccepted: data.privacyPolicy,
        captchaToken: token,
        privacyPolicyUrl: consentUrl,
        marketingConsentUrl,
        acceptedConsents: [consentId],
        deviceId: deviceId || '',
        metadata,
      }

      const response = await mossApi.apiDemoController.create(payload)
      onContinue(data, response.data.id)
    } catch (error) {
      console.error('error', error)
    }
  }

  return (
    <>
      <h3 className="text-h4 lg:text-h3">{formTitle}</h3>
      <form className="grid h-full grid-cols-6 gap-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-span-full lg:col-span-3">
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: firstNameField.error_message!,
            }}
            render={({field: {value, onChange}, fieldState}) => (
              <MossUIField error={fieldState.error?.message}>
                <MossUIFieldLabel>{firstNameField.label}</MossUIFieldLabel>
                <MossUITextInput value={value} onChange={onChange} />
              </MossUIField>
            )}
          />
        </div>
        <div className="col-span-full lg:col-span-3">
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: lastNameField.error_message!,
            }}
            render={({field: {value, onChange}, fieldState}) => (
              <MossUIField error={fieldState.error?.message}>
                <MossUIFieldLabel>{lastNameField.label}</MossUIFieldLabel>
                <MossUITextInput value={value} onChange={onChange} />
              </MossUIField>
            )}
          />
        </div>
        <div className="col-span-full">
          <Controller
            name="companyName"
            control={control}
            rules={{
              required: companyNameField.error_message!,
            }}
            render={({field: {value, onChange}, fieldState}) => (
              <div className="col-span-full">
                <MossUIField error={fieldState.error?.message}>
                  <MossUIFieldLabel>{companyNameField.label}</MossUIFieldLabel>
                  <MossUITextInput value={value} onChange={onChange} />
                </MossUIField>
              </div>
            )}
          />
        </div>
        <div className="col-span-full">
          <Controller
            name="email"
            control={control}
            rules={{
              required: companyEmailField.error_message!,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Invalid email address',
              },
            }}
            render={({field: {value, onChange}, fieldState}) => (
              <div className="col-span-full">
                <MossUIField error={fieldState.error?.message}>
                  <MossUIFieldLabel>{companyEmailField.label}</MossUIFieldLabel>
                  <MossUITextInput value={value} onChange={onChange} />
                </MossUIField>
              </div>
            )}
          />
        </div>
        <Controller
          name="phone"
          control={control}
          rules={{
            required: phoneField.error_message!,
          }}
          render={({field: {value, onChange}, fieldState}) => (
            <div className="col-span-full">
              <MossUIField variant="stack" error={fieldState.error?.message} flexGrow={0}>
                <MossUIFieldLabel>{phoneField.label}</MossUIFieldLabel>
                <MossUIPhoneInput
                  value={value}
                  onChange={onChange}
                  error={undefined}
                  width="100%"
                  userCountryAsDefault
                />
              </MossUIField>
            </div>
          )}
        />
        <PrivacyCheckbox
          control={control}
          label={privacyPolicy}
          textColor="text-moss-black"
          linkColor="text-blue-450"
          textSize="x-small"
        />
        <Button type="submit" variant="fill" className="col-span-full w-min">
          {continueButtonLabel}
        </Button>
      </form>
    </>
  )
}

const parseParamsFromCookies = (cookie: string | null) => {
  try {
    return JSON.parse(cookie || '')
  } catch {
    console.error('no utm params from cookies or invalid format')
    return null
  }
}
