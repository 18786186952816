'use client'
import {Content} from '@prismicio/client'
import {SliceComponentProps} from '@prismicio/react'
import {useRouter} from 'next/navigation'
import {linkResolver} from 'prismicio'
import {useEffect} from 'react'

/**
 * Props for `Redirect`.
 */
export type RedirectProps = SliceComponentProps<Content.RedirectSlice>

/**
 * Component for "Redirect" Slices.
 */
const Redirect = ({slice}: RedirectProps): JSX.Element => {
  const router = useRouter()

  // Redirect to the URL specified in the Prismic CMS.
  useEffect(() => {
    if (slice.primary?.redirect_url) {
      router.push(linkResolver(slice.primary.redirect_url))
    }
  }, [router, slice.primary?.redirect_url])

  return <></>
}

export default Redirect
