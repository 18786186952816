import {SliceZone} from '@prismicio/react'
import {memo, useContext, useEffect, useState} from 'react'
import {components} from 'slices'
import {ProductDeepDiveContext} from './ProductDeepDiveDefault'
import cn from 'classnames'
import {KeyTextField, Content, SliceZone as SliceZoneType} from '@prismicio/client'

type SectionType = {
  sectionLabel: KeyTextField
  slices: SliceZoneType<Content.ProductDeepDiveContentSlice>
}

const PDDSection = () => {
  const {sections, activeTab, isMobile} = useContext(ProductDeepDiveContext)!

  const ppdSections = sections.map((section, index) => <Section key={index} section={section} isMobile={isMobile} />)

  return <>{ppdSections[activeTab]}</>
}

export default memo(PDDSection)

const Section = ({section, isMobile}: {section: SectionType; isMobile: boolean}) => {
  const [isMounted, setIsMounted] = useState(false)

  // add mounted transition
  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <div
      className={cn(
        'col-span-full grid min-h-full max-w-screen-xl grid-cols-12 items-center gap-4 opacity-0 transition-all duration-700 ease-in-out',
        {
          'opacity-100 translate-y-0': isMounted,
          'translate-y-[100px]': !isMounted,
        },
      )}
    >
      <div className="col-span-full flex h-full w-full flex-col md:col-start-1 md:col-end-6">
        <SliceZone components={components} slices={section.slices} />
      </div>
      {isMounted && !isMobile && (
        <div className="relative col-start-7 col-end-13 h-full w-full overflow-hidden rounded-xl bg-beige-300 md:h-[540px]">
          <div id="animation-container" className="relative flex h-full w-full items-center" />
        </div>
      )}
    </div>
  )
}
