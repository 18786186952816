import {PrismicNextImage} from '@prismicio/next'
import {PrismicRichText} from '@prismicio/react'
import Link from 'next/link'
import {useLinkResolver} from 'prismicio'
import {FormEventHandler} from 'react'
import {UseFormRegister, UseFormStateReturn} from 'react-hook-form'
import {Button} from 'src/common/Button'
import {Icon} from 'src/common/Icon'
import cn from 'classnames'
import {Container} from 'src/common/Container'
import {SignupSectionSliceDefaultPrimary} from 'prismicio-types'

type FormData = {
  firstName: string
  lastName: string
  email: string
  checkbox: boolean
}

/**
 * Props for `SignupSection`.
 */
export type SignupSectionProps = {
  slice: {
    primary: SignupSectionSliceDefaultPrimary
    variation: string
    slice_type: string
  }
  formHandler: FormEventHandler<HTMLFormElement>
  register: UseFormRegister<FormData>
  formState: UseFormStateReturn<FormData>
}

/**
 * Component for "SignupSection" Slices.
 */
export const SignupSectionDefault = ({
  slice: {primary, slice_type, variation},
  formHandler,
  register,
  formState: {isSubmitted, isSubmitting},
}: SignupSectionProps): JSX.Element => {
  const {linkResolver} = useLinkResolver()

  return (
    <Container
      className="grid scroll-mt-16 grid-cols-main items-center gap-y-10 bg-5a7269 py-10 md:py-14"
      data-slice-type={slice_type}
      data-slice-variation={variation}
      backgroundColor={primary.background_color || 'Beige/300'}
      id="sign-up-section"
    >
      <div className="col-[inner] grid gap-y-4 md:col-end-[track-end_6]">
        <PrismicNextImage alt="" field={primary.icon} className="max-w-6" />

        <PrismicRichText
          components={{
            heading2: ({children}) => (
              <h2
                className={cn(
                  "text-32 leading-10 xl:text-36 xl:leading-11 [&_>_br]:max-md:content-[''] [&_>_br]:max-md:after:content-['_']",
                  {
                    'text-moss-black': primary.background_color !== 'Moss Black',
                    'text-moss-white':
                      primary.background_color === 'Moss Black' || primary.background_color === 'Green (legacy)',
                  },
                )}
              >
                {children}
              </h2>
            ),
          }}
          field={primary.title}
        />

        <PrismicRichText
          components={{
            paragraph: ({children}) => (
              <p
                className={cn('text-20 leading-7 xl:text-22 xl:leading-8', {
                  'text-moss-black': primary.background_color !== 'Moss Black',
                  'text-moss-white':
                    primary.background_color === 'Moss Black' || primary.background_color === 'Green (legacy)',
                })}
              >
                {children}
              </p>
            ),
          }}
          field={primary.description}
        />
      </div>

      <div className="col-[inner] grid rounded-2xl bg-ffffff px-4 py-6 sm:p-8 md:col-start-[track-start_7]">
        <form
          className={cn('grid grid-cols-2 place-content-center gap-x-3 text-72716e span-full', {
            'pointer-events-none invisible opacity-0': isSubmitted,
          })}
          onSubmit={formHandler}
        >
          <label className="grid gap-y-2">
            <div className="text-12 font-medium leading-4">{primary.first_name_label || 'First Name'}</div>

            <input
              className="h-11 w-full rounded-lg border border-dcdbda bg-transparent px-4 text-14 leading-5 outline-none transition-shadow placeholder:text-8f8d8b focus:shadow-card"
              placeholder={primary.first_name_placeholder || 'Andrea'}
              type="text"
              {...register('firstName', {required: 'Please enter your first name.'})}
            />
          </label>

          <label className="grid gap-y-2">
            <div className="text-12 font-medium leading-4">{primary.last_name_label || 'Last Name'}</div>

            <input
              className="h-11 w-full rounded-lg border border-dcdbda bg-transparent px-4 text-14 leading-5 outline-none transition-shadow placeholder:text-8f8d8b focus:shadow-card"
              placeholder={primary.last_name_placeholder || 'Smith'}
              type="text"
              {...register('lastName', {required: 'Please enter your last name.'})}
            />
          </label>

          <label className="col-span-2 mt-3 grid gap-y-2">
            <div className="text-12 font-medium leading-4">{primary.email_label || 'Your work email'}</div>

            <input
              className="h-11 w-full rounded-lg border border-dcdbda bg-transparent px-4 text-14 leading-5 outline-none transition-shadow placeholder:text-8f8d8b focus:shadow-card"
              placeholder={primary.email_placeholder || 'email@company.com'}
              type="text"
              {...register('email', {required: 'Please enter your email.'})}
            />
          </label>

          <label className="col-span-2 mt-3.5 grid grid-flow-col items-center justify-start gap-x-4 text-14 leading-5 sm:mt-6">
            <div className="grid">
              <input
                className="h-5 w-5 appearance-none rounded-4 border border-dcdbda bg-ffffff outline-none transition-shadow span-full placeholder:text-8f8d8b checked:border-4e7e6c checked:bg-4e7e6c focus:shadow-card"
                type="checkbox"
                {...register('checkbox', {required: 'Please confirm this.'})}
              />
              <Icon className="h-5 w-5 text-ffffff span-full" name="tick" />
            </div>

            <PrismicRichText
              components={{
                paragraph: ({children}) => <div className="text-2a2928">{children}</div>,
                hyperlink: ({children, node}) => (
                  <Link
                    className="text-4e7e6c"
                    href={linkResolver(node.data)}
                    {...(node.data.link_type === 'Web' && node.data.target && {target: node.data.target})}
                  >
                    {children}
                  </Link>
                ),
              }}
              field={primary.checkbox_label}
            />
          </label>

          <Button className="col-start-2 mt-7 justify-self-end sm:mt-10" variant="fill" type="submit">
            {isSubmitting ? primary.button_submitting_label : primary.button_label}
          </Button>
        </form>

        <div
          className={cn('grid place-content-center justify-items-center gap-y-8 span-full', {
            'pointer-events-none invisible opacity-0': !isSubmitted,
          })}
        >
          <Icon className="h-12 w-12" name="fill-check" noMask />
          <h3 className="text-32 font-bold leading-10 text-2f2e2d">{primary.success_title || 'Super!'}</h3>

          <PrismicRichText
            components={{
              paragraph: ({children}) => <p className="text-16 leading-6 text-2a2928">{children}</p>,
            }}
            field={primary.success_description}
          />
        </div>
      </div>
    </Container>
  )
}
