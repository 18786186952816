import {ReactNode, SyntheticEvent, useState} from 'react'
import {IconType, Icon} from 'src/common/Icon'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {EVENTS, track} from 'src/common/helpers'

type InfoTooltipProps = {
  id: string
  children: ReactNode
  className?: string
  place?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'right'
    | 'right-start'
    | 'right-end'
  'data-page-location'?: string
  'data-type'?: string
  'data-mktg-id'?: string
  'data-page-name'?: string
}

export const InfoTooltip = ({id, children, place = 'right', ...props}: InfoTooltipProps) => {
  const [iconName, setIconName] = useState<IconType>('info')
  return (
    <>
      <div className={`tooltip-container ${props.className ?? ''}`}>
        <a
          className="group/tooltip relative flex items-center"
          id={`tooltip-${id}`}
          onMouseEnter={() => setIconName('info-hover')}
          onMouseLeave={() => setIconName('info')}
          onClick={(e: SyntheticEvent) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Icon name={iconName} className="h-[16px] w-[16px] bg-grey-200 group-hover/tooltip:bg-moss-black" />
        </a>
        <ReactTooltip
          anchorSelect={`#tooltip-${id}`}
          place={place}
          className="tooltip-padding tooltip-show tooltip-background z-10 max-w-[300px] md:max-w-[409px]"
          afterShow={() =>
            track({
              event: EVENTS.TOOLTIP_OPEN,
              location: props['data-page-location'] || '',
              dataType: props['data-type'] || '',
              mktgId: props['data-mktg-id'] || '',
              pageName: props['data-page-name'] || '',
            })
          }
        >
          {children}
        </ReactTooltip>
      </div>
    </>
  )
}
